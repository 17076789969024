import React from "react";
import Banner from "../../Common/Banner";
import img1 from "../../../Assets/Images/img-whatis-banner@3x.png";
import CSService from "./CSService";
import QAService from "./QAService";
import MarketingService from "./MarketingService";
import MornitoringService from "./MornitoringService";
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../Modules/routesString";
import * as Scroll from "react-scroll"
function IntroPage() {
  const navigation = useNavigate();
  const scroll = Scroll.animateScroll
  return (
    <main>
      <Banner
        img={img1}
        title={`ADD-UP服务的新名词, \n CSbye`}
        subTitle="客户满意的服务, CSbye的开始"
        detail={`'CSbye'是现有APP-UP服务CS,  QA,  营销和监控服务的统称。
        可以在一个地方解决现有服务的APP-UP服务的新名称。`}
        onPressButton={() => {
          scroll.scrollToTop()
          navigation(RoutesString.Customer_cn);
         
        }}
        textButton={"服务咨询"}
        backgroundColor="#63aeff"
      />
      <CSService />
      <QAService />
      <MarketingService />
      <MornitoringService />
    </main>
  );
}

export default IntroPage;
