import React from 'react'
import Banner from '../../Common/Banner3'
import img from "../../../Assets/Images/img-platform-banner@3x.png"
import Intro from './Intro'
import Reason from './Reason'
import Instruction from './Instruction'
import PlatformMap from './Map'
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../Modules/routesString";
import * as Scroll from "react-scroll"
function Platform() {
  const navigation = useNavigate();
  const scroll = Scroll.animateScroll
  return (
    <main>
        <Banner
        img={img}
        title={`更接近顾客满意的 \n CSBYE平`}
        subTitle="实现与顾客的顺畅沟通、高服务满意度"
        detail={`CSBYE平台是一个利用ADD-UP客户管理服务诀窍的CRM平台。
        基于网络，易于访问，把重点放在了咨询员的便利性上。
        可应用于各个领域，提供稳定、高效的服务，提高客户满意度。`}
        onPressButton={() => {
          scroll.scrollToTop()
          navigation(RoutesString.free_cn);
        }}
        textButton={"免费试用"}
        textButtonn={"平台手册"}
        backgroundColor="#162a56"
      />
      <Intro />
      <Reason />
      <Instruction />
      <PlatformMap />
    </main>
  )
}

export default Platform