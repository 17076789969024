import React from 'react'
import HeroSection from './HeroSection'
import Intro from './IntroSection'
import Partner from './PartnerSection'
import Server from './ServerSection'
import Service from './ServiceSection'

function Home_en() {
  return (
    <main>
      <HeroSection />
      <Server />
      <Service />
      <Intro />
      <Partner />
    </main>
  )
}

export default Home_en