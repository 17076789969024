import React from "react";
import logo from "../../../Assets/Images/ic-logo-bk@3x.png";
import img1 from "../../../Assets/Images/btm-icon-01@3x.png";
import img2 from "../../../Assets/Images/btm-icon-02@3x.png";
import coopImg1 from "../../../Assets/Images/site-01@3x.png";
import coopImg2 from "../../../Assets/Images/site-02@3x.png";
import "./styles.scss";
import { Link } from "react-router-dom";
import { RoutesString } from "../../Modules/routesString";
import * as Scroll from "react-scroll";

function Footer() {
  let scroll = Scroll.animateScroll;
  return (
    <footer>
      <div className="leftItem">
        <a href="/" className="logo">
          <img src={logo} alt="" />
        </a>

        <div className="detail">
          <div className="detail-contact">
            <div className="addup">
              <div className="contact-item2">주식회사 에드업</div>
            </div>
            <div className="phone">
              <div className="contact-item">대표이사 : 강한</div>
              <div className="contact-item itemRight">
                사업자등록번호 : 571-88-01208
              </div>
            </div>
            <div className="phone">
              <div className="contact-item">
                통신판매번호 : 제2020-광주동구-0216호
              </div>
            </div>
            <div className="address">
              <div className="contact-item">
                주소 : 광주광역시 동구 금남로 245(전일빌딩), 7층 701호
              </div>
            </div>

            <div className="contact">
              <div className="contact-item">대표전화 : 1670-2055</div>
              <div className="contact-item itemRight">팩스 : 070-7610-7260</div>
              <div className="contact-item itemRight">
                메일 : contact@add-up.co.kr
              </div>
            </div>

            <div className="mail">
              <div className="contact-item">개인정보책임자 : 박지혜</div>
              <div className="contact-item itemRight">
                연락처 : contact@add-up.co.kr
              </div>
            </div>
          </div>
        </div>

        <div className="private">
          <Link
            onClick={() => {
              scroll.scrollToTop();
            }}
            to={RoutesString.Privacy}>
            개인정보처리방침
          </Link>
        </div>

        <p className="copyright">Copyright © ADD-UP All rights reserved.</p>
      </div>

      <div className="rightItem">
        <div className="cooperate">
          <p>협력사이트</p>
          <a href="https://www.add-up.co.kr">
            <div className="cooperate-img">
              <img src={coopImg1} alt="" />
            </div>
          </a>
          <a href="https://www.add-fit.com/">
            <div className="cooperate-img">
              <img src={coopImg2} alt="" />
            </div>
          </a>
        </div>

        <div className="items">
          <a href="https://www.add-up.co.kr/asset/COMPANY_PROFILE_ADD-UP(0503).pdf">
            <div className="item">
              <div className="item-img">
                <img src={img1} alt="" />
              </div>
              <div className="item-detail">
                회사소개서 <br />
                다운로드
              </div>
            </div>
          </a>

          <a href="https://www.add-up.co.kr/theme/addup/ebook/add-up_ebook.html">
            <div className="item">
              <div className="item-img">
                <img src={img2} alt="" />
              </div>
              <div className="item-detail">
                e-book <br />
                보기
              </div>
            </div>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
