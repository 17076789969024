import { useState } from "react";
import Button from "Components/Atoms/Button/button";
import H3 from "Components/Atoms/Heading/h3";
import StarPoint from "Components/Atoms/StarPoint/starPoint";
import Space from "Components/Atoms/Space/space";
import { async } from "q";
import { postStarPoint } from "Apis/apis";
import { useParams } from "react-router";
import "./index.scss";
import { useLocation } from "react-router-dom";

const Star = () => {
  const [star, setStar] = useState(5);
  const [memo, setMemo] = useState("");

  const location = useLocation();
  const arr = location.pathname.split("/");
  const [a, b, qnaNo, replyNo] = arr;

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (memo === "") {
      alert("내용을 입력 해주세요");
      return;
    }
    const body = {
      qnaNo,
      replyNo,
      star,
      memo,
    };
    await postStarPoint(body);
    alert("답변이 완료되었습니다.");
  };

  return (
    <div id="star" className="bg-f2f3f8">
      <div className="con">
        <img className="logo" width={130} src="/imgs/logo.png" alt="" />
        <Space y={12} />
        <H3 name="CSbye 고객센터입니다." />
        <Space y={12} />
        <p>안녕하세요 고객님! 문의하신 내용에 대한 답변은 잘 받아보셨나요?</p>
        <p>
          만족하셨다면 회원님의 문의 답변에 대한 별점을 남겨주시면
          감사하겠습니다.
        </p>
        <Space y={24} />
        <hr />
        <Space y={24} />
        <form className="text-center" onSubmit={onSubmit}>
          <p className="sub_title">문의답변에 대한 만족도는 어떠셨나요?</p>
          <Space y={24} />
          <StarPoint score={star} setScore={setStar} />
          <Space y={48} />
          <textarea
            placeholder="답변에 대한 의견을 남겨주세요"
            className="textarea"
            onChange={(e) => setMemo(e.target.value)}
            value={memo}
          />
          <Space y={24} />
          <button className="addup_btn" type="submit">
            제출하기
          </button>
        </form>
      </div>
    </div>
  );
};

export default Star;
