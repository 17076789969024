import React from "react";
import img1 from "../../../../Assets/Images/01-img-01.png";
import img2 from "../../../../Assets/Images/img-platform-function-02@3x.png";
import img3 from "../../../../Assets/Images/img-platform-function-03@3x.png";
import img4 from "../../../../Assets/Images/img-platform-function-04@3x.png";
import "./styles.scss";

function membermain() {
    return (
      <div className="instruction">
        {data.map((item, index) => (
          <div
            key={index}
            style={{
              background: item.backgroundColor,
            }}
            className="instruction-container"
          >
            <div
              style={{ flexDirection: index % 2 !== 0 ? "row-reverse" : "row" }}
              className="instruction-container-item"
            >
              <div className="content">
                <div className="titleFm">{item.title}</div>
                <ul className="detailFm">
                  <li>{item.detail1}</li>
                  <li>{item.detail2}</li>
                  <li>{item.detail3}</li>
                  <li>{item.detail4}</li>
                </ul>
              </div>
              
              <div
                style={{
                  justifyContent: index % 2 !== 0 ? "flex-start" : "flex-end",
                }}
                className="img"
              >
                <img src={item.img} alt="" />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
  
  export default membermain;
  
  const data = [
    {
      title: `Check my inquiry details at once`,
      detail1: "·  Collecting replies distributed by service \n at once ",
      detail2: "·  Solve additional inquiries at once in CSbye",
      detail3: "·  Previous customer inquiries can also \n be viewed",
      detail4: "·  Receive inquiries and check progresses",
      img: img1,
      backgroundColor: "#63aeff",
    },

  ];
  