import { useEffect } from "react";
import "./emailCheck.scss";

import Space from "Components/Atoms/Space/space";

import { useNavigate } from "react-router-dom";
import { getEmailCheckApi } from "Apis/apis";

function EmailCheck() {
  const navigate = useNavigate();

  const companyId = localStorage.getItem("joinId") || "";

  const emailSuccess = async (e: any) => {
    e.preventDefault();
    const { data } = await getEmailCheckApi();
    console.log(data);
    if (data.email_check) {
      alert(data.message);
      return navigate("/companyJoin");
    } else {
      alert(data.message);
      return;
    }
  };

  return (
    <div id="emailCheck" className="login-bg more_wrap">
      <div className="domian_login_contents p-center">
        <img className="logo" src="/imgs/logo.png" alt="" />
        <Space y={60} />
        <p>안녕하세요 CSbye 고객센터입니다.</p>
        <Space y={40} />
        <p>{companyId} 님,</p>
        <Space y={8} />
        <p>인증메일이 발송되었습니다.</p>
        <Space y={8} />
        <p>이메일 인증 후 추가 회원가입을 진행바랍니다.</p>
        <Space y={40} />

        <button onClick={emailSuccess} className="addup_btn" type="submit">
          이메일 인증하기
        </button>

        <Space y={20} />

        <p style={{ color: "red" }}>
          혹시 10분이 지나도 인증 메일이 오지 않았다면,
        </p>
        <p style={{ color: "red" }}>
          메일의 스팸함을 한 번 확인해주시기 바랍니다!
        </p>
        <Space y={20} />

        <div className="flex">
          가입을 시도하지 않으셨다면
          <Space x={4} />
          <a
            href="https://add_up.csbye.com/menu/275"
            target="_blank"
            rel="noreferrer"
          >
            고객센터
          </a>
          로 문의주시기 바랍니다.
        </div>
      </div>
    </div>
  );
}

export default EmailCheck;
