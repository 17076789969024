import Router from "./Routers/Router";
import "./App.scss";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    console.log(process.env.NODE_ENV);
    if (
      process.env.NODE_ENV !== "development" &&
      document.location.protocol === "http:"
    ) {
      document.location.href = document.location.href.replace(
        "http:",
        "https:"
      );
    }
  }, []);

  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
