import React from "react";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/ic-whatis-marketing-01@3x.png";
import img2 from "../../../../Assets/Images/ic-whatis-marketing-02@3x.png";
import img3 from "../../../../Assets/Images/ic-whatis-marketing-03@3x.png";
import img4 from "../../../../Assets/Images/ic-whatis-marketing-04@3x.png";
import "./styles.scss";
function MarketingService() {
  return (
    <div className="marketingService">
      <div className="marketingService-container">
        <Title
          title={
            <>
              マーケティングサービス <span>Marketing Service</span>
            </>
          }
          detail={`速やかに変化する最近のトレンドと様々なSNS部分の拡大で色んな所からのマーケティングが大事になった時代です。 \n
          バイラル、ブログポスティング、インスタグラムなど各種のSNS基盤のマーケティングの代行業務を進行し、 \n
          イベント企画及び処理、景品の発送までワンストップでマーケティングできます。`}
        />

        <ul className="marketingService-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="title4">{item.title}</div>
              <div className="detail4">{item.detail}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default MarketingService;

const data = [
  {
    img: img1,
    title: "SNSマーケティング代行",
    detail: `ブログ及びFaceBookなどソーシャルマーケティングの大量ポスティング進行、高品質インプルエンサーを通じる露出`,
  },
  {
    img: img2,
    title: "イベント企画及び管理",
    detail: `イベント企画及びウエブページ制作など御客社のニーズに合わせたイベント企画、イベントウエブページ制作、イベント関連CS相談業務処理`,
  },
  {
    img: img3,
    title: "バイラル映像制作",
    detail: `ユーチューブなど様々なバイラル映像制作、御客社予算に合わせて映像制作、低費用で高効率の映像を製作 `,
  },
  {
    img: img4,
    title: "景品発送業務",
    detail: `イベント結果による景品の発送、御客に結果通報及び製細工課金収納、宅配社との契約で景品発送が容易`,
  },
];
