import React from "react";
import Title from "../../../Common/Title";
import img from "../../../../Assets/Images/img-platform-contact-graph@3x_tw.png";
import img_mobile from "../../../../Assets/Images/img-platform-contact-graph@3x.png";
import userIcon from "../../../../Assets/Images/e-img-platform-change-01@3x.png";
import globalIcon from "../../../../Assets/Images/e-img-platform-change-02@3x.png";
import downloadIcon from "../../../../Assets/Images/e-img-platform-change-03@3x.png";
import "./styles.scss";
function Intro() {
  return (
    <div className="platform-intro">
      <Title
        title={
          <>
            <span>CSbye</span>帶來的新變化
          </>
        }
        detail={`CSbye平台是引領客戶服務創新的有力工具。

        通過數據可視化實時監控情況并快速做出決策。

        通過這些可以提高顧客服務的效率和質量，對提高顧客滿意度有很大的幫助。`}
      />
      <div className="platform-intro-img">
        <img src={img} alt="" />
      </div>
      <div className="platform-intro-img-mobile">
        <div className="topItems">
          <div className="topItem">
            <div className="topItem-tit">‘A’公司客戶</div>
            <div className="topItem-detail">
              <p>
              “使用電話、郵件、Kakao諮詢等的 <br />諮詢頁面多種多樣，太複雜了”
              </p>
            </div>
          </div>
          <div className="topItem">
            <div className="topItem-tit">‘B’公司客戶</div>
            <div className="topItem-detail">
              <p>
              “我需要翻譯，需要另外聘請人嗎？”
              </p>
            </div>
          </div>
          <div className="topItem">
            <div className="topItem-tit">‘C’公司客戶</div>
            <div className="topItem-detail">
              <p>
              “我以前也遇到過這個問題，你是怎麼解決的？”
              </p>
            </div>
          </div>
        </div>

        <div className="divide"></div>

        <div className="middleItem">
          <span>使用 CSbye</span>
        </div>

        <div className="divide"></div>

        <div className="bottomItems">
          <div className="bottomItem">
            <div className="icon">
              <img src={userIcon} alt="" />
            </div>
            <div className="text">
              <span>
              擴大客戶接觸點，<br />
              減少諮詢員的壓力，
                <br />
                減少離職率
              </span>
            </div>
          </div>
          <div className="bottomItem">
            <div className="icon">
              <img src={globalIcon} alt="" />
            </div>
            <div className="text">
              <span>
              提供進入國際市場<br />的起點， 降低聘用外語人員的成本 
              </span>
            </div>
          </div>
          <div className="bottomItem">
            <div className="icon">
              <img src={downloadIcon} alt="" />
            </div>
            <div className="text">
              <span>
              用層層積累的<br />數據快速解決及預<br />防突如其來的問題
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Intro;
