import React from "react";
import img1 from "../../../../Assets/Images/01-img-01.png";
import img2 from "../../../../Assets/Images/img-platform-function-02@3x.png";
import img3 from "../../../../Assets/Images/img-platform-function-03@3x.png";
import img4 from "../../../../Assets/Images/img-platform-function-04@3x.png";
import "./styles.scss";

function membermain() {
  return (
    <div className="instruction">
      {data.map((item, index) => (
        <div
          key={index}
          style={{
            background: item.backgroundColor,
          }}
          className="instruction-container"
        >
          <div
            style={{ flexDirection: index % 2 !== 0 ? "row-reverse" : "row" }}
            className="instruction-container-item"
          >
            <div className="content">
              <div className="titleFm">{item.title}</div>
              <ul className="detailFm">
                <li>{item.detail1}</li>
                <li>{item.detail2}</li>
                <li>{item.detail3}</li>
                <li>{item.detail4}</li>
              </ul>
            </div>

            <div
              style={{
                justifyContent: index % 2 !== 0 ? "flex-start" : "flex-end",
              }}
              className="img"
            >
              <img src={item.img} alt="" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default membermain;

const data = [
  {
    title: `나의 문의 내역들 \n 한번에 확인하기`,
    detail1: "·  서비스 별로 분산된 문의 답변 한번에 모아보기 ",
    detail2: "·  추가 문의사항도 CSbye에서 한번에 해결",
    detail3: "·  이전 고객문의 내역도 조회 가능",
    detail4: "·  문의 접수 및 진행 상황 확인 가능",
    img: img1,
    backgroundColor: "#63aeff",
  },
];
