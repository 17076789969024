import React from "react";
import "./styles.scss";
import { Collapse } from "antd";
function Privacy() {
  return (
    <div className="privacy">
      <div className="privacy-title">개인정보 처리방침</div>

      <article className="privacy-content">
        <div className="content-detail">
          <span>
            *귀하가 소속되어 있는 기업 및 조직에서 에드업와 별도의 계약을 체결한
            경우, 해당 계약에 따라 아래 개인정보처리방침에 대한 내용과 다를 수
            있습니다. 자세한 내용은 기업 및 조직의 관리자에게 문의하시기
            바랍니다.
          </span>
        </div>

        <div className="content-list">
          <div className="item">
            <div className="title">
              <span>0.</span>목적
            </div>
            <div className="item-content">
              <p>
                에드업(이하 “회사”) 는 개인정보 보호법 제30조에 따라 귀하(또는
                “이용자”)가 관리하는 개인정보와 귀하의 직원들의 개인정보를
                보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록
                하기 위하여 다음과 같이 이용자 정보 보호 처리방침 (이하 “방침”)
                을 수립하였습니다. 이용자의 범위는 귀하 및 귀하의 조직에 소속된
                임직원을 포함하며, 이용자 정보에는 귀하가 관리하는 고객들의 개인
                정보와 고객 문의 정보 뿐만 아니라, 귀하의 조직에 소속된 직원들
                개인정보와 업무 과정에서 생긴 정보를 포함합니다. 회사는 본
                이용자 정보 보호 처리방침을 통하여 에드업 웹사이트, 소프트웨어
                및 서비스 (이하 “서비스”)를 사용할 때 회사가 이용자와 그에
                속하는 직원의 개인 정보를 포함한 관련 정보의 수집, 이용 및
                취급하는 방법을 기술합니다. 아래의 처리방침에는 개인을 식별할 수
                있는 정보(이하 “개인 정보”) 뿐만 아니라 비식별 정보의 처리내용
                또한 일부 포함되어 있습니다.
              </p>
            </div>
          </div>

          <div className="item">
            <div className="title">
              <span>1.</span>정보의 수집 및 처리 항목과 목적
            </div>
            <div className="item-content">
              <div className="item-content-tit">
                1.1. 회사는 서비스를 제공, 개선 및 보호하기 위한 목적으로 다음과
                같은 정보를 합법적인 비즈니스 요건에 맞게 수집 및 이용합니다.
              </div>

              <ul>
                <li>
                  • 계정 정보: 계정을 생성하고 수정할 때 아래와 같이 귀하 및
                  귀하의 소속 직원이 직접 입력한 회사 및 조직 계정 정보, 개인
                  계정 정보 등 을 수집합니다. 이러한 정보는 ‘서비스 이용 및 회원
                  관리’ , ‘민원 처리’ 및 ‘서비스 마케팅 활동’의 목적으로 수집될
                  수 있습니다.
                  <br />
                  <table>
                    <tr>
                      <td>
                        <p>개인 정보</p>
                      </td>
                      <td>
                        <p>이메일 주소, 전화번호</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>회사 및 조직 정보</p>
                      </td>
                      <td>
                        <p>
                          회사(조직)명, 전화번호, 사용 도메인, 산업 구분, 대표
                          고객지원 이메일정보 등
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>주요 수집 목적</p>
                      </td>
                      <td>
                        <p>1. 서비스 이용 및 회원관리</p>
                        <p>
                          서비스 제공에 따른 본인 식별 · 인증, 서비스 부정이용
                          방지, 분쟁 조정을 위한 기록보존, 각종 고지ㆍ통지 등을
                          목적으로 개인정보를 처리합니다.
                        </p>
                        <p>2. 민원 처리</p>
                        <p>
                          민원인의 신원 확인, 민원사항 확인, 사실조사를 위한
                          연락ㆍ통지, 처리결과 통보 등의 목적으로 개인정보를
                          처리합니다.
                        </p>
                        <p>3. 서비스 마케팅 활용</p>
                        <p>
                          서비스 사용에 직· 간접적으로 도움이 되는 이벤트 및
                          정보, 또는 CSbye 운영사에 관련한 광고성 정보를
                          제공하는 등 서비스 마케팅 목적으로 개인정보를 처리할
                          수 있습니다.
                        </p>
                      </td>
                    </tr>
                  </table>
                </li>
              </ul>

              <ul>
                <li>
                  • 시스템 자동 수집 정보: Google Analytics는 쿠키 및 기타 추적
                  기술을 사용하여 자동 수집합니다. Google Analytics를 사용하여
                  이용자가 사이트를 어떻게 사용하는지를 볼 수 있게 함으로서
                  사용자 경험 향상을 목적으로 목적으로 사용할 수 있습니다.
                  Google 웹로그 분석에서 수집한 정보의 사용 및 공유 권한은
                  Google Analytics
                  이용약관(http://www.google.com/analytics/terms/us.html) 및
                  Google 개인 정보
                  취급방침(http://www.google.com/policies/privacy/)에 따라
                  제한됩니다. 회사 내부에는 별도로 아래 정보들이 저장되지
                  않습니다.
                  <br />
                  <table>
                    <tr>
                      <td>
                        <p>장치 정보</p>
                      </td>
                      <td>
                        <p>
                          IP 주소, 운영 체제, 브라우저 ID 및 시스템 및 연결에
                          대한 기타 정보와 같이 서비스 액세스에 사용하는 장치 및
                          응용 프로그램에 대한 정보를 수집합니다.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>로그 데이터</p>
                      </td>
                      <td>
                        <p>
                          당사의 웹 서버는 장치가 해당 서버에 액세스 할 때마다
                          데이터를 기록하는 로그 파일을 유지하며 로그 파일에는
                          원래 IP 주소를 포함하여 각 액세스의 성격에 대한
                          데이터가 포함됩니다. 또한 서비스에 업로드하는 파일과
                          관련된 메타 데이터 및 기타 정보에 액세스 할 수
                          있습니다.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>제품 사용 데이터</p>
                      </td>
                      <td>
                        <p>
                          당사는 귀하가 서비스를 이용할 때마다 귀하에 관한 사용
                          데이터를 수집하며, 귀하가 서비스에 액세스 한 날짜와
                          시간 및 귀하의 브라우징 활동 (예 : 서비스의 사용 부분
                          등)이 포함될 수 있습니다. 또한 귀하는 서비스를 통해
                          보내는 전자 메일 및 기타 통신의 배달 가능성과 관련된
                          측정 항목을 포함하여 서비스의 성능과 관련된 정보를
                          수집합니다.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>주요 수집 목적</p>
                      </td>
                      <td>
                        <p>1. 귀하와 귀하 고객의 사용 편의 제공</p>
                        <p>
                          예) 위치정보 판단을 통해 서비스 언어 판별의 용도로
                          활용
                        </p>
                        <p>2. 서비스 내용 및 운영의 개선</p>
                        <p>3. 이벤트 및 광고 정보의 제공</p>
                        <p>4. 불법 접근 판별 기준으로 활용</p>
                      </td>
                    </tr>
                  </table>
                </li>
                <li>
                  • 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서
                  이용자는 웹브라우저에서 옵션을 설정하여 모든 쿠키를
                  허용하거나, 거부할 수 있습니다. 다만 쿠키 저장을 거부할 경우
                  맞춤형 서비스 이용에 어려움이 발생할 수 있습니다. 이용자가
                  쿠키 수집에 대한 거부를 원할 경우 다음과 같이 설정할 수
                  있습니다.
                  <br />
                  ① [도구]매뉴에서 [인터넷 옵션]선택
                  <br />
                  ② [개인정보 탭]을 클릭
                  <br />③ [개인정보처리 수준]을 설정
                </li>
              </ul>

              <ul>
                <li>
                  • 이용자의 ‘자료’ 정보: 회사는 귀하가 본 서비스를 사용하여
                  귀하의 고객(이하 “최종 고객”) 의 문의를 처리하는 과정에서
                  발생한 모든 자료를 저장, 처리 및 전송합니다. 이 과정에서
                  수집하는 정보는 아래와 같습니다. 최종 고객 정보와 이들이 남긴
                  문의에 대한 자료는 이용자가 직접 입력 또는 생성하거나,
                  서비스를 통해 직접 수집하는 방법 뿐만 아니라, 제 3자 API
                  (Google API 포함) 를 통해 수집할 수 있습니다.
                  <br />
                  <table>
                    <tr>
                      <td>
                        <p>최종 고객 정보</p>
                      </td>
                      <td>
                        <p>- 계정 정보: 전화번호, 이메일 주소</p>
                        <p>- 장치 정보</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>문의 처리 과정에서 발생한 자료</p>
                      </td>
                      <td>
                        <p>
                          최종 고객의 문의 및 이용자에 의해 남겨지는 답변글,
                          문의 처리 목적의 문서 및 이미지 자료 등
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>이용자 협업 과정에서 발생한 자료</p>
                      </td>
                      <td>
                        <p>
                          이용자의 소속 직원이 다른 동료 직원에게 남기는 메세지,
                          문서 및 이미지 등
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>주요 수집 목적</p>
                      </td>
                      <td>
                        <p>1. 다중 장치에서 접근 및 작업 목적</p>
                        <p>2. 신규 기능에 대한 연구 개발 및 학술 용도</p>
                        <p>
                          예) 문의처리 과정에서 발생한 메세지들은 개인
                          식별정보를 제거한 후, 신규 기능 연구 개발을 위한 기계
                          학습에 활용
                        </p>
                      </td>
                    </tr>
                  </table>
                </li>
              </ul>

              <ul>
                <li>
                  • Google Accounts 로부터 제공받은 모든 정보의 사용은 Google
                  API Service User Data Policy를 따라 제한적으로 사용합니다.
                </li>
              </ul>

              <div className="item-content-tit">
                1.2. 수집된 정보는 일반적으로 개인적 고지, 이용자의 서비스
                이용과 관련하여 발생하는 질문 또는 분쟁의 처리, 결제 서비스 관련
                컨텐츠의 전송 또는 결제 과정에 사용될 수 있습니다. 또한, 이들
                정보는 새로운 맞춤형 서비스를 위한 통계적 분석, 이벤트 및 광고
                정보의 제공, 관련 법령 및 규제에 필요한 의무의 준수, 관련 법령
                또는 본 이용약관을 위반하여 이용자에게 피해를 발생시키는
                부적절한 정보의 이용 방지 등을 위하여 사용될 수 있습니다. 회사는
                기타 다른 목적으로 이용자의 정보를 처리하는 경우 사전에 이용자의
                동의를 요청합니다.
              </div>
            </div>
          </div>

          <div className="item">
            <div className="title">
              <span>2.</span>개인정보의 제3자 제공에 관한 사항
            </div>
            <div className="item-content">
              <div className="item-content-tit">
                2.1. 회사는 이용자의 개인정보를 위에서(제 1조에서) 언급한
                개인정보의 처리 목적에서 명시한 범위내에서만 처리하며, 이용자의
                동의 없이는 동의 범위를 초과하여 이용하거나 원칙적으로 이용자의
                개인 정보를 외부에 공개하지 않습니다.
              </div>
              <div className="item-content-tit">
                2.2. 회사는 다음 대상에 대해 이용자 정보를 공유할 수 있으나,
                광고업체나 제 3자에게 판매하지 않습니다.
              </div>

              <ul>
                <li>
                  • 협력 업체: 회사는 스토리지, 네트워크 및 기술 서비스, 정보
                  메일링 등에 대해 타사 공급업체를 사용하고 있습니다. 이들
                  업체는 회사로 부터 위탁 받아 작업을 처리해야하는 경우에만 본
                  이용자 정보 보호 처리방침에 따라 이용자 정보에 접근하며, 이들
                  업체가 반드시 회사의 지침에 따라 사용자 정보를 취급하도록
                  당사가 책임을 집니다.
                </li>
                <li>
                  • 다른 이용자: 서비스는 이용자의 이름, 프로필 사진, 이메일
                  주소와 같은 정보를 동일 조직 소속의 다른 이용자들에게
                  표시합니다. 본인의 자료를 다른 사람들과 공유하도록 선택할 수
                  있습니다. 회사나 단체에서 소유한 도메인의 이메일 주소를 사용해
                  CSbye 계정을 만든 경우 사용자 이름, 팀 이름, 프로필 사진,
                  이메일 주소 등 이용자의 기본 정보를 동일한 도메인에 있는 다른
                  사용자들에게 표시하여 공동 작업자와 관리자가 이 사용자 및
                  사용자의 팀을 찾을 수 있도록 할 수 있습니다. 특정 기능을
                  사용할 때 이 외의 정보가 추가로 다른 이용자들에게 공개될 수
                  있습니다.
                </li>
                <li>
                  • 이용자의 기업 및 조직의 CSbye 계정 관리자: CSbye의 관리자
                  계정 소유자는 이용자의 계정에 접근하고 이를 제어할 수
                  있습니다. 이에 대한 궁금한 점이 있는 경우는 기업 및 조직 내부
                  정책을 참조하시기 바랍니다.
                </li>
                <li>
                  • 법률 및 공익: 회사는 (a) 해당 법률, 규정, 법적 절차 또는
                  적절한 정부 요청을 준수하거나, (b) 사망 또는 심각한 부상
                  위기에 처한 사람을 보호하거나, (c) 회사 또는 서비스 이용자의
                  사기 또는 남용을 방지하거나, (d) 회사의 권리, 재산, 안전 또는
                  이익을 보호하거나, (e) 공익을 위한 업무 수행에 합당하다고
                  판단하는 경우 사용자 정보를 제3자에게 공개할 수 있습니다.
                </li>
              </ul>
            </div>
          </div>

          <div className="item">
            <div className="title">
              <span>3.</span>개인정보의 보유 및 이용 기간
            </div>
            <div className="item-content">
              <div className="item-content-tit">
                3.1. 이용자의 개인정보는 원칙적으로 개인정보의 수집 및
                이용목적이 달성되면 지체 없이 파기합니다.
              </div>
              <div className="item-content-tit">
                3.2. 개인 식별이 가능한 계정 정보를 포함한 개인정보는 계정 해지
                또는 개인정보 유효기간이 만료되면 파기됩니다.
              </div>
              <div className="item-content-tit">
                3.3. 3.2.의 유효기간이라 함은 1년간 로그인 하지 않은 경우이며,
                계정 해지 등의 조치가 적용됩니다. 단, 상법, 전자상거래 등에서의
                소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가
                있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원 정보를
                보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만
                이용하며 계정 해지 이후 보존기간은 아래와 같습니다.
              </div>

              <table>
                <th>기록</th>
                <th>보존 이유 (관계 법령)</th>
                <th>보존 기간</th>
                <tr>
                  <td>
                    <p>표시 및 광고에 대한 기록</p>
                  </td>
                  <td>
                    <p>전자상거래 등에서 소비자보호에 관한 법률</p>
                  </td>
                  <td>
                    <p>6개월</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>계약 및 청약 철회에 관한 기록</p>
                  </td>
                  <td>
                    <p>전자상거래 등에서의 소비자보호에 관한 법률</p>
                  </td>
                  <td>
                    <p>5년</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>대금결제 및 재화 등의 공급에 관한 기록</p>
                  </td>
                  <td>
                    <p>전자상거래 등에서의 소비자보호에 관한 법률</p>
                  </td>
                  <td>
                    <p>5년</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>소비자의 불만 또는 분쟁처리에 관한 기록</p>
                  </td>
                  <td>
                    <p>전자상거래 등에서의 소비자보호에 관한 법률</p>
                  </td>
                  <td>
                    <p>3년</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>전자 금융거래에 대한 기록</p>
                  </td>
                  <td>
                    <p>전자금융거래법</p>
                  </td>
                  <td>
                    <p>5년</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>세법 규정에 따른 장부 및 모든 거래와 관련한 서증</p>
                  </td>
                  <td>
                    <p>국세기본법</p>
                  </td>
                  <td>
                    <p>5년</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>로그인 기록 및 위치 기록</p>
                  </td>
                  <td>
                    <p>통신비밀보호법</p>
                  </td>
                  <td>
                    <p>3개월</p>
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div className="item">
            <div className="title">
              <span>4.</span>파기에 관한 사항
            </div>
            <div className="item-content">
              <div className="item-content-tit">
                4.1. 이용자의 개인정보는 원칙적으로 개인정보의 수집 및
                이용목적이 달성되면 지체 없이 파기합니다. 회사의 개인정보
                파기절차 및 방법은 다음과 같습니다.
              </div>

              <ul>
                <li>
                  • 파기절차
                  <ul>
                    <li>
                      • 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된
                      후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침
                      및 기타 관련 법령에 의한정보보호 사유에 따라(보유 및
                      이용기간 참조)일정 기간 저장된 후 파기됩니다.
                    </li>
                    <li>
                      • 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는
                      이외의 다른 목적으로 이용되지 않습니다.
                    </li>
                  </ul>
                </li>
                <li>
                  • 파기방법
                  <ul>
                    <li>
                      • 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을
                      통하여 파기합니다.
                    </li>
                    <li>
                      • 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수
                      없는 기술적 방법을 사용하여 삭제합니다.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>

          <div className="item">
            <div className="title">
              <span>5.</span>이용자와 법정대리인의 권리・의무 및 그 행사방법에
              관한 사항
            </div>
            <div className="item-content">
              <div className="item-content-tit">
                5.1. 이용자는 회사에 대해 언제든지 개인정보
                열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
                이러한 권리 행사는 사용자의 법정대리인이나 위임을 받은 자 등
                대리인을 통하여 하실 수 있습니다. 회사는 정보주체 권리에 따른
                열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를
                한 자가 이용자 본인이거나 정당한 대리인인지를 확인할 수
                있습니다.
              </div>
              <div className="item-content-tit">
                5.2. 개인정보 열람 및 처리정지 요구는 “개인정보보호법 제35조
                제4항, 제37조 제2항”에 의하여 사용자의 권리가 제한 될 수
                있습니다. 또한 개인정보의 정정 및 삭제 요구는 다른 법령에서 그
                개인정보가 수집 대상으로 명시 되어 있는 경우에는 그 삭제를
                요구할 수 없습니다.
              </div>
            </div>
          </div>

          <div className="item">
            <div className="title">
              <span>6.</span>이용자 정보 보호 처리방침의 적용 배제
            </div>
            <div className="item-content">
              <div className="item-content-tit">
                6.1. 본 이용자 정보 보호 처리방침은 이용자가 본 서비스 또는 다른
                수단을 통하여 CSbye 서비스 피드백 또는 자발적으로 제공한 정보에
                대하여 적용되지 않습니다.
              </div>
              <div className="item-content-tit">
                6.2. 이러한 정보는 새로운 상품 또는 서비스, 현존하는 상품 또는
                서비스의 변경, 기타 자발적인 의견제시 등을 포함(포괄 하여 이하
                “피드백 및 자발적 정보”)하며 그에 제한되지 않습니다.
              </div>
              <div className="item-content-tit">
                6.3. 모든 피드백 및 자발적 정보는 비밀이 아니며 이용자에게
                전속되지 않은 것으로 간주하며, 회사는 제한 없이 피드백 및 자발적
                정보의 저장, 재생산, 사용, 변경, 배포, 전시, 2차 저작물 창작 및
                상업화 등을 영구적으로 자유롭게 할 수 있습니다.
              </div>
            </div>
          </div>

          <div className="item">
            <div className="title">
              <span>7.</span>이용자 정보 보호 처리방침의 변경에 관한 사항
            </div>
            <div className="item-content">
              <div className="item-content-tit">
                7.1. 회사는 CSbye 서비스 또는 관련 법령등의 변경 등을 반영하기
                위하여 본 방침을 변경할 수 있습니다. 본 방침이 변경되는 경우
                귀하의 관리자 메일로 이 사항이 공지될 것입니다. 본 웹사이트와
                제품 및 서비스를 계속 사용하는 것은 동시에 현재의 방침에
                동의함을 나타냅니다. 이용자가 변경된 이용자 정보 보호 방침에
                동의하지 않는 경우, 회사는 서비스 공급을 중단할 수 있습니다.
              </div>
            </div>
          </div>

          <div className="item">
            <div className="title">
              <span>8.</span>개인정보의 안전성 확보조치에 관한 사항
            </div>
            <div className="item-content">
              <div className="item-content-tit">
                8.1. 회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를
                취하고 있습니다.
              </div>
              <ul>
                <li>
                  • 관리적 조치: 내부관리계획 수립․시행, 정기적 직원 교육 등
                </li>
                <li>
                  • 기술적 조치: 개인정보처리시스템 등의 접근 권한 관리,
                  접근통제시스템 설치, 고유 식별 정보 등의 암호화, 보안프로그램
                  설치
                </li>
                <li>• 물리적 조치: 전산실, 자료보관실 등의 접근통제</li>
              </ul>
              <div className="item-content-tit">
                8.2. 또한 회사는 다른 회사와 정보를 전송 및 수신 할 때 업계 표준
                기술을 사용하여 보안을 유지합니다.
              </div>
              <div className="item-content-tit">
                8.3. 서비스는 우발적이거나 불법적 접근의 위험으로 부터 회사의
                통제하에 있는 정보를 보호하기 위한 최대한의 보안 조치를 취하고
                있습니다.
              </div>
            </div>
          </div>

          <div className="item">
            <div className="title">
              <span>9.</span>개인정보 보호책임자에 관한 사항
            </div>
            <div className="item-content">
              <div className="item-content-tit">
                9.1. 회사의 대표이사는 개인정보 보호에 대한 전문가로서 개인정보
                처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한
                정보주체의 불만처리 및 피해구제 등을 위하여 노력합니다.
              </div>
              <ul>
                <li>• 개인정보 보호책임자: 박 지 혜</li>
                <li>• 개인정보 보호 관련 문의: happyhunya@add-up.co.kr</li>
              </ul>
              <div className="item-content-tit">
                9.2. 귀하는 회사의 서비스를 이용 하시면서 발생한 모든 개인정보
                보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 위 연락처로
                문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체없이
                답변 및 처리 해드릴 것입니다.
              </div>
            </div>
          </div>
        </div>

        <div className="content-footer">시행일: 2023년 09월 08일 </div>
      </article>
    </div>
  );
}

export default Privacy;
