import React from "react";
import Space from "Components/Atoms/Space/space";
import { Link } from "react-router-dom";

/**
 * @param {string} name span태그 내용
 * @param {string} url Link 이동경로
 */
const LoginJoin = ({ name, url }: { name: string; url: string }) => {
  return (
    <div className="flex items-center justify-center">
      <span className="text-969696 text-14 desc">{name}</span>
      <Space x={8} />
      <Link to={url} className="text-14 bold text-primary">
        회원가입
      </Link>
    </div>
  );
};

export default LoginJoin;
