import React from "react";
import img1 from "../../../../Assets/Images/img-platform-function-01@3x.a35d6c5c54b66eac2ba3_jp.png";
import img2 from "../../../../Assets/Images/img-platform-function-02@3x.dc9d14bc9f3c166175f6_jp.png";
import img3 from "../../../../Assets/Images/img-platform-function-03@3x.bac07996fd58dbff576b_jp.png";
import img4 from "../../../../Assets/Images/img-platform-function-04@3x.88302ed3611be7d453aa_jp.png";
import "./styles.scss";
function Instruction() {
  return (
    <div className="instruction">
      {data.map((item, index) => (
        <div
          key={index}
          style={{
            background: item.backgroundColor,
          }}
          className="instruction-container"
        >
          <div
            style={{ flexDirection: index % 2 !== 0 ? "row-reverse" : "row" }}
            className="instruction-container-item"
          >
            <div className="content">
              <div className="title1">{item.title}</div>
              <ul className="detail1">
                <li>{item.detail1}</li>
                <li>{item.detail2}</li>
                <li>{item.detail3}</li>
              </ul>
            </div>
            <div
              style={{
                justifyContent: index % 2 !== 0 ? "flex-start" : "flex-end",
              }}
              className="img"
            >
              <img src={item.img} alt="" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Instruction;

const data = [
  {
    title: `お問い合わせタイプ別\n分類及び情報入力様式生成`,
    detail1: "·  大カテゴリ、中カテゴリに分けてお問い合わせタイプを分類",
    detail2: "·  中カテゴリの下に小カテゴリを登録して入力紙を生成",
    detail3: "·  カテゴリを使用して迅速になるイッシュ把握と対応",
    img: img1,
    backgroundColor: "#fff",
  },
  {
    title: `御客方法自動入力`,
    detail1: "·  御客データお問い合わせホームで自動連結可能",
    detail2: "·  御客UID及びニックネーム自動収集で御客便意上昇",
    detail3:
      "·  デバイス情報経路環境、OS、経路/収集で容易い御客情報収集",
    img: img2,
    backgroundColor: "#f1f8ff",
  },
  {
    title: `御客管理`,
    detail1: "·  VIP、ブラックリストなど御客タイプ分類可能",
    detail2: "·  VIP、通知機能ブラックリストお問い合わせ箱など\nオーダーメイドサービス提供",
    detail3: "·  メモを使用して御客性向特徴記録",
    img: img3,
    backgroundColor: "#fff",
  },
  {
    title: `相談員管理`,
    detail1: "· 職責による相談員等級別権限差別化",
    detail2: "·  相談員業務によってサービスとチケット振り分け設定",
    img: img4,
    backgroundColor: "#f1f8ff",
  },
];
