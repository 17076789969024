import React from "react";
import Title from "../../../Common/Title";
// import bg from "../../../../Assets/Images/map-platform.png"
import img from "../../../../Assets/Images/memberimg.png";
import item1 from "../../../../Assets/Images/reason022.png";
import item2 from "../../../../Assets/Images/reason011.png";
import item3 from "../../../../Assets/Images/reason033.png";
import "./styles.scss";

function MemberMap() {
  return (
    <div className="memberMap">
      {/* <div className="memberMap-bg">
            <img src={bg} alt="" />
        </div> */}

      <div className="memberMap-container">
        <Title title={<>{}</>} />
        <div className="map">{/* <img src={img} alt="" /> */}</div>

        <div className="map-list">
          {dataMap.map((item, index) => (
            <div className="item" key={index}>
              <div className="img">
                <img src={item.img} alt="" />
              </div>
              <div className="content">
                <div className="title">{item.title}</div>
                <div className="detail">{item.detail}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MemberMap;

const dataMap = [
  {
    img: item1,
    title: "내가 어떤 문의를 했었지?",
    detail: "CSbye에서 나의 문의와 답변 모두 확인!",
  },
  {
    img: item2,
    title: "매일 방문시 포인트 적립기회 제공",
    detail: "가입만해도 2,000P 즉시 지급!",
  },
  {
    img: item3,
    title: "문제가 완전히 해결되지 않았다면?",
    detail: "답변 확인 후 동일 서비스 내 추가 문의 가능",
  },
];
