import React from "react";
import Banner from "../../Common/Banner";
import img1 from "../../../Assets/Images/img-whatis-banner@3x.png";
import CSService from "./CSService";
import QAService from "./QAService";
import MarketingService from "./MarketingService";
import MornitoringService from "./MornitoringService";
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../Modules/routesString";
import * as Scroll from "react-scroll"
function IntroPage_en() {
  const navigation = useNavigate();
  const scroll = Scroll.animateScroll
  return (
    <main>
      <Banner
        img={img1}
        title={`The new name of\nADD-UP,CSbye`}
        subTitle="Customer Satisfaction, the start of CSbye"
        detail={`CSbye’ is an integrated name for ADD-UP CS, QA, Marketing,\nand Monitoring services.
        It is a new name for ADD-UP service where you can utilize the services of ADD-UP in one place.`}
        onPressButton={() => {
          scroll.scrollToTop()
          navigation(RoutesString.Customer);
         
        }}
        textButton={"Inquire for service"}
        backgroundColor="#63aeff"
      />
      <CSService />
      <QAService />
      <MarketingService />
      <MornitoringService />
    </main>
  );
}

export default IntroPage_en;
