import React, { useEffect, useLayoutEffect, useState } from "react";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/03-icon-1@3x.png";
import img2 from "../../../../Assets/Images/03-icon-2@3x.png";
import img3 from "../../../../Assets/Images/03-icon-3@3x.png";
import img4 from "../../../../Assets/Images/03-icon-4@3x.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./styles.scss";
import Button from "../../../Common/Button";
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../../Modules/routesString";
import { Power4 } from "gsap/all";
import * as Scroll from "react-scroll"


gsap.registerPlugin(ScrollTrigger);

function Service() {
  const scroll = Scroll.animateScroll
  const navigation = useNavigate()
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  
  const isTable = width <= 1280;

  //animation
  useLayoutEffect(() => {
    if(isTable) return
    let ctx = gsap.context(() => {
      let timeline = gsap.timeline({
        scrollTrigger: {
          trigger: `.service-container`,
          start: "top center",
        },
      });

      timeline.from(`.service-list`, {
        x: 1030,
        duration: 2,
        autoAlpha: 0,
        ease: Power4.easeOut,
        // delay: 1
      });
    });

    return () => ctx.revert();
  }, [isTable]);
  return (
    <div id="homeService" className="service">
      <div className="service-container">
        <Title
          title={
            <div>
              <span>CSbye</span> Service
            </div>
          }
          detail={`주식회사 에드업은 고객관리 서비스 노하우를 보유한 기업으로 고객운영관리(CS) 서비스, \n
        QA(품질보증), 마케팅, 모니터링 등  폭넓은 영역의 서비스를 제공하여 고객만족을 실현하는 \n
        운영관리 서비스 기업입니다.`}
        />

        <ul className="service-list">
          {data.map((item, index) => (
            <li key={index} className="service-item">
              <div className="service-item-img">
                <img src={item.img} alt="" />
                <div className="content">
                  <div className="content-title">{item.title}</div>
                  <div className="content-detail">
                    <p>{item.detail1}</p>
                    <p>{item.detail2}</p>
                    <p>{item.detail3}</p>
                    <p>{item.detail4}</p>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
    
        <div className="service-button">
          <Button rightIcon onClick={() => {
                  scroll.scrollToTop()
            navigation(RoutesString.Intro);
          }} text="자세히보기" size="normal" />
        </div>
      </div>
    </div>
  );
}

export default Service;

const data = [
  {
    img: img1,
    title: "고객운영관리 서비스",
    detail1: "· 마켓 및 커뮤니티 관리",
    detail2: "· VOC(통계) 리포트를 통한 서비스 방향 제시",
    detail3: "· 문의 접수, 결제 및 환불 관리",
    detail4: "· VIP 우선 대응 및 서비스 제공",
  },
  {
    img: img2,
    title: "품질보증 QA",
    detail1: "· 앱 및 웹 기능성 테스트",
    detail2: "· 단말기 호환성 테스트",
    detail3: "· 업데이트 유지보수 테스트",
    detail4: "· 기타 품질보증 테스트",
  },
  {
    img: img3,
    title: "마케팅 서비스",
    detail1: "· SNS 포스팅 및 마케팅 업무",
    detail2: "· 이벤트 기획 및 CS관리",
    detail3: "· 다양한 바이럴 영상 제작 ",
    detail4: "· 이벤트 결과 전달 및 경품 발송 업무",
  },
  {
    img: img4,
    title: "24시간 밀착 모니터링",
    detail1: "· 24시간 실시간 대응",
    detail2: "· 마켓 리뷰 대응",
    detail3: "· 장애 발생시 신속한 대처",
    detail4: "· 시간별 리포트 제공",
  },
];
