// 회원가입 set
export const setJoin = (token: string, joinId: string) => {
  localStorage.setItem("joinToken", token);
  localStorage.setItem("joinId", joinId);
};
// 회원가입 get
export const getJoin = () => {
  localStorage.getItem("joinToken");
  localStorage.getItem("joinId");
};
// 회원가입 del
export const delJoin = () => {
  localStorage.removeItem("joinToken");
  localStorage.removeItem("joinId");
};
