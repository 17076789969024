import React from "react";
import logo from "../../../Assets/Images/ic-logo-bk@3x.png";
import img1 from "../../../Assets/Images/btm-icon-01@3x.png";
import img2 from "../../../Assets/Images/btm-icon-02@3x.png";
import coopImg1 from "../../../Assets/Images/site-01@3x.png";
import coopImg2 from "../../../Assets/Images/site-02@3x.png";
import "./styles.scss";
import { Link } from "react-router-dom";
import { RoutesString } from "../../Modules/routesString";
import * as Scroll from "react-scroll";

function Footer() {
  let scroll = Scroll.animateScroll;
  return (
    <footer>
      <div className="leftItem">
        <a href="/" className="logo">
          <img src={logo} alt="" />
        </a>

        <div className="detail">
          <div className="detail-contact">
            <div className="addup">
              <div className="contact-item2">ADD-UP Co., Ltd</div>
            </div>
            <div className="phone">
              <div className="contact-item">CEO : KANG HAN</div>
            </div>
            <div className="phone">
              <div className="contact-item">
                Company Registration Number : 571-88-01208
              </div>
            </div>
            <div className="phone">
              <div className="contact-item">
                Mail order number : 2020-Dong-gu Gwangju-0216ho
              </div>
            </div>
            <div className="address">
              <div className="contact-item">
                Address : 701 Ho, 245, Geumnam-ro, Dong-gu, Gwangju
              </div>
            </div>

            <div className="contact">
              <div className="contact-item">
                Representative Contact : 1670-2055
              </div>
            </div>
            <div className="contact">
              <div className="contact-item">FAX : 070-7610-7260</div>
            </div>
            <div className="contact">
              <div className="contact-item">Mail : contact@add-up.co.kr</div>
            </div>

            <div className="mail">
              <div className="contact-item">
                Personal information manager : PARK JIHYE
              </div>
            </div>
            <div className="mail">
              <div className="contact-item">
                Manager contact : contact@add-up.co.kr
              </div>
            </div>
          </div>
        </div>

        <div className="private">
          <Link
            onClick={() => {
              scroll.scrollToTop();
            }}
            to={RoutesString.Privacy}>
            Privacy Policy
          </Link>
        </div>

        <p className="copyright">Copyright © ADD-UP All rights reserved.</p>
      </div>

      <div className="rightItem">
        <div className="cooperate">
          <p>Cooperation site</p>
          <a href="https://www.add-up.co.kr">
            <div className="cooperate-img">
              <img src={coopImg1} alt="" />
            </div>
          </a>
          <a href="https://www.add-fit.com/">
            <div className="cooperate-img">
              <img src={coopImg2} alt="" />
            </div>
          </a>
        </div>

        <div className="items">
          <a href="https://www.add-up.co.kr/asset/COMPANY_PROFILE_ADD-UP(0503).pdf">
            <div className="item">
              <div className="item-img">
                <img src={img1} alt="" />
              </div>
              <div className="item-detail">
                Download <br />
                company profile
              </div>
            </div>
          </a>

          <a href="https://www.add-up.co.kr/theme/addup/ebook/add-up_ebook.html">
            <div className="item">
              <div className="item-img">
                <img src={img2} alt="" />
              </div>
              <div className="item-detail">
                See <br />
                e-book
              </div>
            </div>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
