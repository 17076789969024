import React, { useEffect, useState } from "react";
import "./styles.scss";
import checkIcon from "../../../../Assets/Images/ic-price-check-01@3x.png";
import img1 from "../../../../Assets/Images/ic-price-service@3x.png";
import img2 from "../../../../Assets/Images/ic-price-page@3x.png";
import img3 from "../../../../Assets/Images/ic-price-respone@3x.png";
import img4 from "../../../../Assets/Images/ic-price-function@3x.png";
import img5 from "../../../../Assets/Images/ic-price-notification@3x.png";
import img6 from "../../../../Assets/Images/ic-price-customer@3x.png";
import img7 from "../../../../Assets/Images/ic-price-global@3x.png";
import img8 from "../../../../Assets/Images/ic-price-cooperation@3x.png";
import img9 from "../../../../Assets/Images/ic-price-security@3x.png";
import img10 from "../../../../Assets/Images/ic-price-report@3x.png";
import img11 from "../../../../Assets/Images/icon-price-support@3x.png";
function PriceTable() {
  const [isMenuFixed, setIsMenuFixed] = useState(false);
  const header = document.querySelector(".headerSection") as any;

  const stickNavbar = () => {
    const menu = document.querySelector(".priceTable-tab") as any;
    const header = document.querySelector(".headerSection") as any;

    const menuOffset = menu?.offsetTop;
    const scrollPos = window.scrollY;

    if (scrollPos >= menuOffset - header?.clientHeight) {
      setIsMenuFixed(true);
    } else {
      setIsMenuFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  return (
    <div className="priceTable">
      <div className="priceTable-container">
        <div style={{ width: "100%" }} className="priceTable-tab"></div>
        <div
          style={{ top: header?.clientHeight }}
          className={`title-table ${isMenuFixed ? "fixed-menu" : ""}`}
        >
          <div className="main-title title">플랜별 기능</div>
          <div className="detail">
            <div className="detail-item free">Basic</div>
            <div className="detail-item basic">Basic+</div>
            <div className="detail-item premium">Premium</div>
            <div className="detail-item special">Special</div>
          </div>
        </div>

        <div className="list-table">
          {data.map((item, index) => (
            <div key={index} className="table-item">
              <div className="table-item-title">
                <img src={item.imgAvt} alt="" />
                <span>{item.title}</span>
              </div>
              <div className="table-item-content">
                {item.content.map((content: any, index) => (
                  <div key={content.titleContent} className="item">
                    <div className="title">{content.titleContent}</div>
                    <div className="detail">
                      {content.value.map((child: any, index: number) => (
                        <div key={`${child}+${index}`} className="detail-child">
                          {child === true ? (
                            <img src={checkIcon} alt="checkIcon" />
                          ) : child === false ? (
                            <span>미제공</span>
                          ) : (
                            child
                          )}
                        </div>
                      ))}
                    </div>
                    {content.subTitContent! && (
                      <div className="subdetail">{content.subTitContent}</div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="table-note">
          <span>* 제품 결제 후 계정 생성까지 최대 3영업일 소요</span>
        </div>
      </div>
    </div>
  );
}

export default PriceTable;

const data = [
  {
    imgAvt: img1,
    title: "서비스 운영 및 고객 포털 운영",
    content: [
      {
        titleContent: "서비스 등록",
        value: ["1개", "1개", "3개", "6개"],
      },
      {
        titleContent: "일일 문의 답변 개수 제한",
        value: ["60개", "무제한", "무제한", "무제한"],
      },
      {
        titleContent: "고객 포털 생성",
        value: [true, true, true, true],
      },
      {
        titleContent: "FAQ, 약관/정책, 주요 공지사항 삽입 기능",
        value: [true, true, true, true],
      },
      {
        titleContent: "문의 유형별 분류",
        value: [true, true, true, true],
      },
      {
        titleContent: "티켓 세부 기재 항목 생성",
        value: [true, true, true, true],
      },
    ],
  },
  {
    imgAvt: img2,
    title: "효율적인 답변 처리",
    content: [
      {
        titleContent: "중복 문의 일괄 처리",
        value: [true, true, true, true],
      },
      {
        titleContent: "고객 정보 및 문의 내역 확인",
        value: [true, true, true, true],
      },
      {
        titleContent: "고객 정보 수집 API",
        value: [true, true, true, true],
      },
    ],
  },

  {
    imgAvt: img3,
    title: "상담 편의 기능",
    content: [
      {
        titleContent: "문의 접수 메일 디자인",
        value: [true, true, true, true],
      },
      {
        titleContent: "문의 답변 메일 디자인",
        value: [true, true, true, true],
      },
      {
        titleContent: "답변 템플릿 등록 기능",
        value: [true, true, true, true],
      },
      {
        titleContent: "답변 미리보기 기능",
        value: [true, true, true, true],
      },
      {
        titleContent: "티켓 숨김 기능",
        value: [true, true, true, true],
      },
      {
        titleContent: "상담 중 바로가기 편의 기능",
        value: [false, true, true, true],
      },
      {
        titleContent: "서비스별 라벨링 기능",
        value: [false, true, true, true],
      },
      {
        titleContent: "예약 답변 기능",
        value: [false, false, true, true],
      },
      {
        titleContent: "임시 답변 기능",
        value: [false, false, true, true],
      },
      {
        titleContent: "SMS 전송 기능",
        value: [false, false, true, true],
      },
    ],
  },

  {
    imgAvt: img4,
    title: "알림 서비스",
    content: [
      {
        titleContent: "VIP 고객 문의 알림",
        value: [false, false, true, true],
      },
      {
        titleContent: "전체 문의 알림",
        value: [false, false, false, true],
      },
      {
        titleContent: "문의 키워드 알림 등록 (서비스 당)",
        value: [false, false, "5개", "무제한"],
      },
    ],
  },

  {
    imgAvt: img6,
    title: "고객 관리",
    content: [
      {
        titleContent: "VIP 등록 및 문의 우선 처리",
        value: [true, true, true, true],
      },
      {
        titleContent: "Black 문의 간접 노출",
        value: [true, true, true, true],
      },
      {
        titleContent: "고객 성향 메모",
        value: [false, false, true, true],
      },
      {
        titleContent: "답변 만족도 수집",
        value: [false, false, true, true],
      },
    ],
  },

  {
    imgAvt: img7,
    title: "글로벌",
    content: [
      {
        titleContent: "고객 포털 언어별 운영",
        value: [false, false, "4개 언어", "4개 언어"],
      },
      {
        titleContent: "상담 센터 언어별 운영",
        value: [false, false, "4개 언어", "4개 언어"],
      },
      {
        titleContent: "문의 및 답변 내용 번역 기능",
        value: [false, false, "4개 언어", "4+@개 언어"],
      },
    ],
  },

  {
    imgAvt: img8,
    title: "협업",
    content: [
      {
        titleContent: "티켓 메모",
        value: [true, true, true, true],
      },
      {
        titleContent: "티켓 자동 할당",
        value: [true, true, true, true],
      },
      {
        titleContent: "티켓 지정 할당",
        value: [false, true, true, true],
      },
      {
        titleContent: "티켓 자동 할당 비율 조절",
        value: [false, false, false, true],
      },
      {
        titleContent: "결원 시 문의 순환 배정",
        value: [false, false, false, true],
      },
    ],
  },

  {
    imgAvt: img9,
    title: "보안",
    content: [
      {
        titleContent: "동일 계정 동시 작업 방지",
        value: [false, true, true, true],
      },
      {
        titleContent: "상담원 담당 서비스 설정",
        value: [false, true, true, true],
      },
      {
        titleContent: "팀원 활동 기록 확인",
        value: [false, false, true, true],
      },
      {
        titleContent: "상담원 등급별 권한 설정",
        value: [false, false, false, true],
      },
    ],
  },
  {
    imgAvt: img10,
    title: "리포트",
    content: [
      {
        titleContent: "서비스 별 문의 처리 현황 리포트",
        value: [true, true, true, true],
      },
      {
        titleContent: "상담원 별 활동 리포트",
        value: [false, false, true, true],
      },
      {
        titleContent: "리포트 데이터 다운로드",
        value: [false, false, false, true],
      },
    ],
  },
  {
    imgAvt: img5,
    title: "마케팅",
    content: [
      {
        titleContent: "메일 광고 배너 설정",
        value: [false, false, "1개", "3개"],
      },
      {
        titleContent: "개인 회원 광고 배너 등록",
        value: [false, false, true, true],
      },
    ],
  },
  {
    imgAvt: img11,
    title: "이용 지원",
    content: [
      {
        titleContent: "이메일 연동 지원",
        value: [true, true, true, true],
      },
      {
        titleContent: "커스터마이징 상품 문의",
        value: [false, "문의 후 진행", "문의 후 진행", "문의 후 진행"],
      },
      {
        titleContent: "운영 인력 서비스 문의",
        value: [false, "문의 후 진행", "문의 후 진행", "문의 후 진행"],
      },
    ],
  },
];
