import React, { useLayoutEffect } from "react";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/02-icon-1@3x.png";
import img2 from "../../../../Assets/Images/02-icon-2@3x.png";
import img3 from "../../../../Assets/Images/02-icon-3@3x.png";
import img4 from "../../../../Assets/Images/02-icon-4@3x.png";
import img5 from "../../../../Assets/Images/02-icon-5@3x.png";
import img6 from "../../../../Assets/Images/02-icon-6@3x.png";
import img7 from "../../../../Assets/Images/02-icon-7@3x.png";
import img8 from "../../../../Assets/Images/02-icon-8@3x.png";
import img9 from "../../../../Assets/Images/02-icon-9@3x.png";
import img10 from "../../../../Assets/Images/02-icon-10@3x.png";
import "./styles.scss";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap, { Power4 } from "gsap";

gsap.registerPlugin(ScrollTrigger);
function Server() {
  //animation
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      let timeline = gsap.timeline({
        scrollTrigger: {
          trigger: `.server-container`,
          start: "top center",
        },
      });
      data.map((item, index) => {
        timeline.from(
          `.item-${index}`,
          {
            scale: 0.1,
            duration: 2,
            autoAlpha: 0,
            ease: Power4.easeOut,
          },
          `frame1+=${index / data.length}`
        );
      });
    });

    return () => ctx.revert();
  }, []);

  return (
    <div className="server">
      <div className="server-container">
        <Title
          title={
            <div>
              ADD-UP 服务的新名称<span>：CSbye</span>
            </div>
          }
          detail={`这是一个随着消费者品味和标准的多样化，更细致、更准确的客户满意度服务变得非常重要的时代。 \n
          CS专业咨询人员以顾客管理经验为基础, 系统应对消费者咨询，提供快速、准确的答复，努力提高顾客满意度。`}
        />

        <ul className="server-list">
          {data.map((item, index) => (
            <li key={index} className={`server-list-item item-${index}`}>
              <img src={item.img} alt="" />
              <p>{item.title}</p>
            </li>
          ))}
        </ul>
      </div>

      <div className="one">
        <div className="two"></div>
      </div>
    </div>
  );
}

export default Server;

const data = [
  {
    img: img1,
    title: "一站式确认多个渠道的查询",
  },
  {
    img: img2,
    title: "快速处理\n所有客户咨询",
  },
  {
    img: img3,
    title: "消费者咨询\n履历管理",
  },
  {
    img: img4,
    title: "可以提供\n英语、日语、中文",
  },
  {
    img: img5,
    title: "提供日报、\n周报和月报",
  },
  {
    img: img6,
    title: "执行产品质量\n验证QA工作",
  },
  {
    img: img7,
    title: "提供营销策划及\n运营",
  },
  {
    img: img8,
    title: "游戏及顾客动向等24小时监控",
  },
  {
    img: img9,
    title: "减少人力管理负担及灵活运用人力",
  },
  {
    img: img10,
    title: "长期签约时\n适用折扣优惠",
  },
];
