import axios, { AxiosResponse } from "axios";
// import { getToken } from "Util/function";

const v1 = process.env.REACT_APP_API_V1;

// 변수로 작성시 getToken함수를 매번 실행하지 않음(최초 진입시 빈값)
const instance = () =>
  axios.create({
    baseURL: v1,
    // headers: { Authorization: `Bearer ${getToken()}` },
  });

// ================================================ 웹 api ================================================
// ================================================ 웹 api ================================================
// ================================================ 웹 api ================================================

const errorHandleApi = async (
  api: () => Promise<AxiosResponse<any, any>>
): Promise<any> => {
  return await api().catch((err: any) => {
    // 에러안의 데이터 접근
    // || {} 안쓰면 err.response.data 없을때 로직 끝남
    const { details = null } = err.response.data || {};
    details && alert(details);
    console.log(err);
    throw err;
  });
};

// get api
export const getApi = async (url: string) =>
  errorHandleApi(() => instance().get(url));

// post api
export const postApi = async (url: string, body?: any) =>
  errorHandleApi(() => instance().post(url, body));

// put api
export const putApi = async (url: string, body: any) =>
  errorHandleApi(() => instance().put(url, body));

// delete api
export const deleteApi = async (url: string) =>
  errorHandleApi(() => instance().delete(url));

// ip체크
// export const getIpApi = () => {
//   const data = axios.get(`https://api.ipify.org?callback=?`, {});
//   return data;
// };

// 기업회원가입용 getapi
export const getJoinApi = async (url: string) => {
  const token = localStorage.getItem(process.env.REACT_APP_JOIN_TOKEN!) || "";
  const data = await axios.get(`${v1}${url}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return data;
};

// 회원가입용 putapi
export const putJoinApi = async (url: string, body: any) => {
  const token = localStorage.getItem(process.env.REACT_APP_JOIN_TOKEN!) || "";
  try {
    const data = await axios.put(`${v1}${url}`, body, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (err) {
    const e: any = err;
    const details = e.response.data.details;
    details && alert(e.response.data.details);
    throw err;
  }
};
