import React from "react";
import img1 from "../../../../Assets/Images/img-platform-function-01@3x.png";
import img2 from "../../../../Assets/Images/img-platform-function-02@3x.png";
import img3 from "../../../../Assets/Images/img-platform-function-03@3x.png";
import img4 from "../../../../Assets/Images/img-platform-function-04@3x.png";
import "./styles.scss";
function Instruction() {
  return (
    <div className="instruction">
      {data.map((item, index) => (
        <div
          key={index}
          style={{
            background: item.backgroundColor,
          }}
          className="instruction-container"
        >
          <div
            style={{ flexDirection: index % 2 !== 0 ? "row-reverse" : "row" }}
            className="instruction-container-item"
          >
            <div className="content">
              <div className="title">{item.title}</div>
              <ul className="detail">
                <li>{item.detail1}</li>
                <li>{item.detail2}</li>
                <li>{item.detail3}</li>
              </ul>
            </div>
            <div
              style={{
                justifyContent: index % 2 !== 0 ? "flex-start" : "flex-end",
              }}
              className="img"
            >
              <img src={item.img} alt="" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Instruction;

const data = [
  {
    title: `문의 유형별 분류 및 \n 정보 입력 양식 생성`,
    detail1: "·  대 카테고리 중 , 카테고리로 구분하여 문의 유형을 분류",
    detail2: "·  중 카테고리 아래에 소 카테고리를 등록하여 입력지를 생성",
    detail3: "·  카테고리를 사용한 빠른 이슈 파악 및 대응",
    img: img1,
    backgroundColor: "#fff",
  },
  {
    title: `고객 정보 자동 입력`,
    detail1: "·  고객 데이터 문의 폼으로 자동 연동 가능",
    detail2: "·  고객 UID 및 닉네임 자동 수집으로 고객 편의 상승",
    detail3:
      "·  디바이스 정보 경로 환경, OS, 경로/수집 으로 쉬운 고객 정보 수집",
    img: img2,
    backgroundColor: "#f1f8ff",
  },
  {
    title: `고객 관리`,
    detail1: "·  VIP, 블랙리스트 등 고객 유형 분류 가능",
    detail2: "·  VIP, 알림 기능 블랙리스트 문의함 등 맞춤 서비스를 제공",
    detail3: "·  메모를 통한 고객 성향 특징 , 기록",
    img: img3,
    backgroundColor: "#fff",
  },
  {
    title: `상담원 관리`,
    detail1: "· 직책에 맞는 상담원 등급별 권한 차별화",
    detail2: "·  상담원 업무에 따라 서비스와 티켓 배분 설정",
    img: img4,
    backgroundColor: "#f1f8ff",
  },
];
