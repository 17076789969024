import { gsap } from "gsap";
import { Power4 } from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useLayoutEffect, useState } from "react";
import img from "../../../../Assets/Images/img-main-partner@3x.png";
import Title from "../../../Common/Title";
import "./styles.scss";

gsap.registerPlugin(ScrollTrigger);

function Partner() {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useLayoutEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  //animation
  useEffect(() => {
    if (isMobile) return;
    let ctx = gsap.context(() => {
      let timeline = gsap.timeline({
        scrollTrigger: {
          trigger: `.partner-container`,
          start: "top center",
        },
      });

      timeline.from(`.partner-list`, {
        y: 1030,
        duration: 2,
        autoAlpha: 0,
        ease: Power4.easeOut,
        // delay: 1
      });
    });

    return () => ctx.revert();
  }, [isMobile]);

  return (
    <div className="partner">
      <div className="partner-container">
        <Title
          title={
            <>
              需要客户管理运营的地方是？ <br />
              提供量身定做的解决方案
            </>
          }
          detail="游戏、健康领域、购物中心、虚拟货币交易所、区块链等"
        />

        <div className="partner-list">
          <img src={img} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Partner;
