import React, { useEffect, useState, ReactNode } from "react";
import "./checkbox.scss";
import { ReactComponent as True } from "Util/Svg/true.svg";
import { ReactComponent as False } from "Util/Svg/false.svg";
import { ClickInputForm } from "types/interface";

/**
 * 체크박스 atomic
 * @param {stirng} id label에 사용할 ID
 * @param {React.ChangeEventHandler<HTMLInputElement>} onChange 인풋 값 변경 함수
 * @param {string | number} value 인풋에 들어가는값
 */
const Checkbox = ({
  value,
  setValue,
  dataList,
}: {
  value: number[];
  setValue: React.Dispatch<React.SetStateAction<number[]>>;
  dataList: ClickInputForm[];
}) => {
  const onClickCheckbox = (idx: number) => {
    if (value.findIndex((item) => item === idx) !== -1) {
      const minusArr = value.filter((item: number) => item !== idx);
      setValue(minusArr);
    } else {
      setValue(value.concat(idx));
    }
  };

  return (
    <>
      {dataList.map((data) => {
        return (
          <button
            onClick={() => onClickCheckbox(data.idx)}
            className="flex checkbox_btn"
            type="button"
            key={data.idx}
          >
            {value.findIndex((item) => item === data.idx) !== -1 ? (
              <True />
            ) : (
              <False />
            )}
            <span className="ml-3">{data.label}</span>
          </button>
        );
      })}
    </>
  );
};

export default Checkbox;
