import React from "react";
import Banner from "../../Common/Banner";
import img1 from "../../../Assets/Images/img-whatis-banner@3x.png";
import CSService from "./CSService";
import QAService from "./QAService";
import MarketingService from "./MarketingService";
import MornitoringService from "./MornitoringService";
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../Modules/routesString";
import * as Scroll from "react-scroll"
function IntroPage() {
  const navigation = useNavigate();
  const scroll = Scroll.animateScroll
  return (
    <main>
      <Banner
        img={img1}
        title={`ADD-UPサービスの \n 新たな名前、\n CSbye`}
        subTitle="御客満足サービス、CSbyeの始まり"
        detail={`'CSbye'は既存のADD-UPサービスであるCS、QA。マーケティング、モニタリングサービスの統合名称でございます。
        ばらばらだったサービスを一所で解決できるADD-UPサービスの新たな名前でございます。`}
        onPressButton={() => {
          scroll.scrollToTop()
          navigation(RoutesString.Customer);
         
        }}
        textButton={"サービスお問い合わせする"}
        backgroundColor="#63aeff"
      />
      <CSService />
      <QAService />
      <MarketingService />
      <MornitoringService />
    </main>
  );
}

export default IntroPage;
