import React, { useEffect, useState } from "react";
import "./companyJoin.scss";

// import { getDomainLoginApi } from "Apis/apis";
import H2 from "Components/Atoms/Heading/h2";
import Space from "Components/Atoms/Space/space";
import Input from "Components/Atoms/Input/input";
import { ReactComponent as Require } from "Util/Svg/require.svg";
import { ReactComponent as Check } from "Util/Svg/check.svg";
import { ReactComponent as Fail } from "Util/Svg/fail.svg";

import { delJoin, setJoin } from "Util/function";
import { useNavigate } from "react-router-dom";
import { postJoinApi, putJoinDomainApi } from "Apis/apis";
import { ClickInputForm, RadioForm } from "types/interface";
import Checkbox from "Components/Atoms/Checkbox/checkbox";
import PolicyModal from "Components/Modal/PolicyModal/policyModal";

const AllAgree: ClickInputForm[] = [{ idx: 0, label: "전체 동의합니다." }];
const CheckAgree: RadioForm[] = [
  { idx: 1, label: "본인은 만14세 이상입니다. [필수]", id: "age" },
  { idx: 2, label: "이용약관 동의 [필수]", id: "use" },
  { idx: 3, label: "개인정보 처리방침 동의 [필수]", id: "notice" },
  { idx: 4, label: "마케팅 정보 수집 동의 [선택]", id: "advert" },
];

function CompanyJoin() {
  const navigate = useNavigate();

  const [company, setCompany] = useState("");
  const [subDomain, setSubDomain] = useState("");
  const [allCheck, setAllCheck] = useState<number[]>([]);
  const [joinCheck, setJoinCheck] = useState<number[]>([]);
  const [modal, setModal] = useState(false);

  const pass = (): boolean => {
    if (company === "") {
      alert("기업명을 입력주세요.");
      return false;
    } else if (subDomain === "") {
      alert("기업도메인을 입력해주세요.");
      return false;
    } else if (joinCheck.filter((el) => el !== 4).length !== 3) {
      alert("필수 체크박스를 체크해주세요.");
      return false;
    }
    return true;
  };

  const putJoin = async (e: any) => {
    e.preventDefault();
    const isPass: boolean = pass();
    if (!isPass) return;
    const body = {
      company,
      subDomain,
    };

    await putJoinDomainApi(body);
    alert("회원가입이 완료되었습니다.");
    delJoin();
    navigate("/domainlogin");
  };

  useEffect(() => {
    if (joinCheck.length === 4) {
      setAllCheck([0]);
    } else if (joinCheck.length < 4) {
      setAllCheck([]);
    }
  }, [joinCheck.length]);

  return (
    <div id="companyJoin" className="login-bg more_wrap">
      <div className="domian_login_contents p-center">
        <img className="logo" src="/imgs/logo.png" alt="" />
        <Space y={60} />
        <p>CSbye와 함께할 기업정보를 입력해 주세요</p>
        <Space y={20} />

        <form onSubmit={putJoin}>
          {/* 기업명 */}
          <div>
            <div className="flex">
              <label className="text-primary">기업명</label>
              <Space x={4} />
              <Require />
            </div>
            <Space y={8} />
            <Input
              required
              id="mail"
              type="text"
              bottom={false}
              placeholder="이메일을 입력해주세요."
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
          </div>
          <Space y={24} />
          {/* 기업도메인 */}
          <div>
            <div className="flex">
              <label className="text-primary">기업 도메인</label>
              <Space x={4} />
              <Require />
            </div>
            <Space y={8} />
            <div className="flex item-center">
              <Input
                id="web"
                type="text"
                bottom={false}
                placeholder="주소 입력"
                value={subDomain}
                onChange={(e) => setSubDomain(e.target.value)}
              />
              <Space x={8} />
              <span> .csbye.com</span>
            </div>
          </div>
          <Space y={24} />

          <p>개인정보 처리 방침 및 이용약관동의</p>
          <div>
            선택항목에 동의하지 않는 경우도 회원 가입 및 일반적인 서비스를
            이용할 수 있습니다.
          </div>
          <Space y={24} />
          <Checkbox
            value={allCheck}
            setValue={(idx) =>
              setAllCheck(() => {
                if (idx.length === 1) {
                  setJoinCheck([1, 2, 3, 4]);
                  return [0];
                } else {
                  setJoinCheck([]);
                  return [];
                }
              })
            }
            dataList={AllAgree}
          />
          <Checkbox
            value={joinCheck}
            setValue={setJoinCheck}
            dataList={CheckAgree}
          />
          <Space y={12} />
          <button
            onClick={() => setModal(true)}
            className="addup_btn mini"
            type="button"
          >
            약관보기
          </button>
          <Space y={24} />
          <button className="addup_btn" type="submit">
            다음
          </button>
        </form>
      </div>
      {modal && <PolicyModal setModal={setModal} />}
    </div>
  );
}

export default CompanyJoin;
