import React, { useEffect, useLayoutEffect, useState } from "react";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/03-icon-1@3x.png";
import img2 from "../../../../Assets/Images/03-icon-2@3x.png";
import img3 from "../../../../Assets/Images/03-icon-3@3x.png";
import img4 from "../../../../Assets/Images/03-icon-4@3x.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./styles.scss";
import Button from "../../../Common/Button";
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../../Modules/routesString";
import { Power4 } from "gsap/all";
import * as Scroll from "react-scroll"


gsap.registerPlugin(ScrollTrigger);

function Service() {
  const scroll = Scroll.animateScroll
  const navigation = useNavigate()
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  
  const isTable = width <= 1280;

  //animation
  useLayoutEffect(() => {
    if(isTable) return
    let ctx = gsap.context(() => {
      let timeline = gsap.timeline({
        scrollTrigger: {
          trigger: `.service-container`,
          start: "top center",
        },
      });

      timeline.from(`.service-list`, {
        x: 1030,
        duration: 2,
        autoAlpha: 0,
        ease: Power4.easeOut,
        // delay: 1
      });
    });

    return () => ctx.revert();
  }, [isTable]);
  return (
    <div id="homeService" className="service">
      <div className="service-container">
        <Title
          title={
            <div>
              <span>CSbye</span> Service
            </div>
          }
          detail={`株式会社ADD-UPは御客管理サービスノーハウを保有する企業として

          御客運営管理(CS)サービス、QA(品質保証)、マーケティング、モニタリングなど広い分野のサービスを提供して
          
          御客満足を実現させる 運営管理サービス企業です。`}
        />

        <ul className="service-list">
          {data.map((item, index) => (
            <li key={index} className="service-item">
              <div className="service-item-img">
                <img src={item.img} alt="" />
                <div className="content2">
                  <div className="content2-title">{item.title}</div>
                  <div className="content2-detail">
                    <p>{item.detail1}</p>
                    <p>{item.detail2}</p>
                    <p>{item.detail3}</p>
                    <p>{item.detail4}</p>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
    
        <div className="service-button">
          <Button rightIcon onClick={() => {
                  scroll.scrollToTop()
            navigation(RoutesString.Intro);
          }} text="詳しく見る" size="normal" />
        </div>
      </div>
    </div>
  );
}

export default Service;

const data = [
  {
    img: img1,
    title: "御客運営管理サービス",
    detail1: "· マーケット及びコミュニティ",
    detail2: "· VOC(統計)レポートを通じてサービス方向提示",
    detail3: "· お問い合わせ受付、決済、お払い戻し管理",
    detail4: "· VIP優先対応及びサービス提供",
  },
  {
    img: img2,
    title: "品質保証QA",
    detail1: "· アプリ及びウエブ機能性テスト",
    detail2: "· 端末互換性テスト",
    detail3: "· アップデートメンテナンステスト",
    detail4: "· その他、品質保証テスト",
  },
  {
    img: img3,
    title: "マーケティングサービス",
    detail1: "· SNSポスティング及びマーケティング業務",
    detail2: "· イベント企画お及びCS管理",
    detail3: "· 様々なバイラル映像制作 ",
    detail4: "· イベント結果お伝え及び景品発送業務",
  },
  {
    img: img4,
    title: "24時間密着モニタリング",
    detail1: "· 24時間リアルタイム対応",
    detail2: "· マーケットレビュー対応",
    detail3: "· 不具合発生時、迅速な対処",
    detail4: "· 時間別レポート提供",
  },
];
