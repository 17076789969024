import React from "react";
import Banner from "../../Common/Banner";
import img1 from "../../../Assets/Images/img-whatis-banner@3x.png";
import CSService from "./CSService";
import QAService from "./QAService";
import MarketingService from "./MarketingService";
import MornitoringService from "./MornitoringService";
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../Modules/routesString";
import * as Scroll from "react-scroll"
function IntroPage() {
  const navigation = useNavigate();
  const scroll = Scroll.animateScroll
  return (
    <main>
      <Banner
        img={img1}
        title={`에드업 서비스의 새로운 이름, \n CSbye`}
        subTitle="고객만족서비스, CSbye의 시작"
        detail={`‘CSbye’는 기존 에드업의 서비스인 CS, QA, 마케팅, 모니터링 서비스의 통합 \n 명칭입니다. 기존의 서비스를 한 곳에서 해결할 수 있는 에드업 서비스의 새로운 \n 이름입니다.`}
        onPressButton={() => {
          scroll.scrollToTop()
          navigation(RoutesString.Customer);
         
        }}
        textButton={"서비스 문의하기"}
        backgroundColor="#63aeff"
      />
      <CSService />
      <QAService />
      <MarketingService />
      <MornitoringService />
    </main>
  );
}

export default IntroPage;
