import React from 'react'
import HeroSectionCustomer from './HeroSection'
import Question from './Questions'

function Customer() {
  return (
    <main>
        <HeroSectionCustomer />
        <Question />
    </main>
  )
}

export default Customer