import React from "react";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/ic-whatis-marketing-01@3x.png";
import img2 from "../../../../Assets/Images/ic-whatis-marketing-02@3x.png";
import img3 from "../../../../Assets/Images/ic-whatis-marketing-03@3x.png";
import img4 from "../../../../Assets/Images/ic-whatis-marketing-04@3x.png";
import "./styles.scss";
function MarketingService() {
  return (
    <div className="marketingService">
      <div className="marketingService-container">
        <Title
          title={
            <>
              营销服务 <span>Marketing Service</span>
            </>
          }
          detail={`随着近年来潮流的快速变化以及SNS在各个领域的扩展，现在是一个各种渠道营销变得更加重要的时代。\n
          我们以病毒式视频传播、博客发布、Instagram发帖 等各种 SNS 为基础开展营销代理工作，\n
          活动企划及处理、赠品发送等一站式营销成为可能。`}
        />

        <ul className="marketingService-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="title">{item.title}</div>
              <div className="detail">{item.detail}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default MarketingService;

const data = [
  {
    img: img1,
    title: "SNS 营销代理",
    detail: `社交营销大量发帖 \n 例如博客和 Facebook \n 通过优质网红进行曝光.`,
  },
  {
    img: img2,
    title: "活动企划及管理",
    detail: `活动企划及网页制作等 \n 符合客户需求的活动企划,\n活动网页制作, \n 处理活动相关CS咨询工作`,
  },
  {
    img: img3,
    title: "病毒式视频制作",
    detail: `制作YouTube等各种\n病毒视频，\n根据客户预算制作视频，\n低成本、高效率的视频制作`,
  },
  {
    img: img4,
    title: "奖品发送服务",
    detail: `根据活动结果发送奖品、\n通知客户结果、\n征收税费和公共费用、\n通过与快递合同\n轻松发送奖品`,
  },
];
