import React from "react";
import Button from "../../../Common/Button";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/ic-platform-reason-01@3x.png";
import img2 from "../../../../Assets/Images/ic-platform-reason-02@3x.png";
import img3 from "../../../../Assets/Images/ic-platform-reason-03@3x.png";
import img4 from "../../../../Assets/Images/ic-platform-reason-04@3x.png";
import img5 from "../../../../Assets/Images/ic-platform-reason-05@3x.png";
import "./styles.scss";

function Reason() {
  return (
    <div className="reason3">
      <div className="reason3-container">
        <Title
          detail="효율적인 고객관리의 시작, 에드업 CSbye 플랫폼"
          title={
            <>
              <span>CSbye 플랫폼</span>을 선택해야 하는 이유
            </>
          }
        />
        <ul className="reason3-container-list">
          {data.map((item, index) => (
            <li key={index} className="item3">
              <img src={item.img} alt="" />
              <div className="title3">{item.title}</div>
              <div className="detail3">{item.detail}</div>
            </li>
          ))}
        </ul>
        {/* <div className="reason-container-button">
          <Button onClick={() => {alert('준비중입니다.')}} text="무료로 체험하기" rightIcon type="outline" />
        </div> */}
      </div>
    </div>
  );
}

export default Reason;

const data = [
  {
    img: img1,
    title: `고객 상담 특화 \n Web 플랫폼 구성`,
    detail: `고객상담 특화 Web 플랫폼으로 \n 빠르게 고객과 소통하여 \n 문제를 해결할 수 있습니다.`,
  },
  {
    img: img2,
    title: `영·중·일 \n 자동 번역 기능`,
    detail: `3개 국어 자동 번역 기능을 통해 \n 인력 채용 비용과 글로벌 시장 \n 진출의 디딤돌이 되겠습니다.`,
  },
  {
    img: img3,
    title: `VIP 응대 \n 서비스 지원`,
    detail: `VIP 응대 서비스를 통해 집중 \n 관리 되어야 할 고객을 분류하여 \n 서비스 제공할 수 있습니다.`,
  },
  {
    img: img4,
    title: `실시간 이슈 확인 \n 통계 자료 추출`,
    detail: `갑작스러운 이슈들을 한 눈에 \n 파악할 수 있는 통계자료 추출로 \n 빠른 문제해결을 도와 드립니다.`,
  },
  {
    img: img5,
    title: `맞춤설정기능으로 \n 손쉬운 고객관리운영`,
    detail: `원하는 서비스만 이용하여 \n 저비용 고효율의 고객관리 \n 운영을 도와드립니다.`,
  },
];
