import React from "react";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/img-whatis-qaservice-01@3x.png";
import img2 from "../../../../Assets/Images/img-whatis-qaservice-02@3x.png";
import img3 from "../../../../Assets/Images/img-whatis-qaservice-03@3x.png";
import img4 from "../../../../Assets/Images/img-whatis-qaservice-04@3x.png";
import "./styles.scss";
function QAService() {
  return (
    <div className="qaService">
      <div className="qaService-container">
        <Title
          title={
            <>
              품질보증 서비스 <span>QA Service</span>
            </>
          }
          detail={`온라인/모바일 서비스 런칭 및 업데이트 전 안정적인 서비스를 위하여 다양한 QA를 통해 서비스 결함을 최소화해야 합니다. \n
        오랜 경력을 가진 전문인력이 다양한 디바이스 및 OS로 표준화된 절차를 수행하여 고객사에게는 서비스 안정성을, 고객에게는 만족을 제공합니다. `}
        />

        <ul className="qaService-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="item-content">
                <div className="title">{item.title}</div>
                <div className="detail">{item.detail}</div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default QAService;

const data = [
  {
    img: img1,
    title: "기능 테스트",
    detail: `명세 리뷰 및 테스트 설계 \n 기법 기능 테스트\n 테스트 환경 구축 \n 테스트 및 버그 트래킹`,
  },
  {
    img: img2,
    title: "호환성 테스트",
    detail: `다양한 단말기 및 환경 테스트, \n 사양(최처/권장) 및 기준 환경 테스트, \n 모바일 단말기 및 OS별 PC OS / IE 별 \n 다운로드 및 실행 테스트`,
  },
  {
    img: img3,
    title: "유지보수 테스트",
    detail: `업데이트 및 서비스 이슈 대응 \n 업데이트 내역에 대한 기능 테스트 \n 라이브 이슈 재현 및 리포트`,
  },
  {
    img: img4,
    title: "기타 품질보증 내용",
    detail: `국내 서비스를 위한 현지화 테스트 필터링 등 \n 로컬라이징 테스트 품질 향상 밸런스 테스트 유저의 \n 원활한 사용감을 위한 FGT.`,
  },
];
