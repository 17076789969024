import React, { useLayoutEffect } from "react";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/02-icon-1@3x.png";
import img2 from "../../../../Assets/Images/02-icon-2@3x.png";
import img3 from "../../../../Assets/Images/02-icon-3@3x.png";
import img4 from "../../../../Assets/Images/02-icon-4@3x.png";
import img5 from "../../../../Assets/Images/02-icon-5@3x.png";
import img6 from "../../../../Assets/Images/02-icon-6@3x.png";
import img7 from "../../../../Assets/Images/02-icon-7@3x.png";
import img8 from "../../../../Assets/Images/02-icon-8@3x.png";
import img9 from "../../../../Assets/Images/02-icon-9@3x.png";
import img10 from "../../../../Assets/Images/02-icon-10@3x.png";
import "./styles.scss";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap, { Power4 } from "gsap";

gsap.registerPlugin(ScrollTrigger);
function Server() {
  //animation
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      let timeline = gsap.timeline({
        scrollTrigger: {
          trigger: `.server-container`,
          start: "top center",
        },
      });
      data.map((item, index) => {
        timeline.from(
          `.item-${index}`,
          {
            scale: 0.1,
            duration: 2,
            autoAlpha: 0,
            ease: Power4.easeOut,
          },
          `frame1+=${index / data.length}`
        );
      });
    });

    return () => ctx.revert();
  }, []);

  return (
    <div className="server">
      <div className="server-container">
        <Title
          title={
            <div>
              에드업 서비스의 새로운 이름, <span>CSbye</span>
            </div>
          }
          detail={`소비자의 취향과 기준이 다양해져서, 더욱 디테일하고 정확한 고객 만족 서비스가 중요한 시대입니다. \n
                CS 전문 상담 인력들은 고객관리 경험을 바탕으로, 소비자 문의를 체계적으로 대응하며 빠르고 정확한 \n
                답변을 제공하여 고객 만족도를 높이는 데 노력하고 있습니다.`}
        />

        <ul className="server-list">
          {data.map((item, index) => (
            <li key={index} className={`server-list-item item-${index}`}>
              <img src={item.img} alt="" />
              <p>{item.title}</p>
            </li>
          ))}
        </ul>
      </div>

      <div className="one">
        <div className="two"></div>
      </div>
    </div>
  );
}

export default Server;

const data = [
  {
    img: img1,
    title: "여러 채널의 문의를 \n 한 곳에서 확인",
  },
  {
    img: img2,
    title: "모든 고객 문의를 \n 빠르게 처리",
  },
  {
    img: img3,
    title: "소비자 상담 \n 이력 관리",
  },
  {
    img: img4,
    title: "영어, 일본어, \n 중국어 지원 가능",
  },
  {
    img: img5,
    title: "일일, 주간, 월간 \n 보고서 제공",
  },
  {
    img: img6,
    title: "제품 품질 검증 \n QA 업무 수행",
  },
  {
    img: img7,
    title: "마케팅 기획 및 \n 운영 업무 가능",
  },
  {
    img: img8,
    title: "게임 및 고객 동향 등 \n 24시간 모니터링",
  },
  {
    img: img9,
    title: "인력관리 부담 감소 및 \n 인력 활용 유연화",
  },
  {
    img: img10,
    title: "장기 계약 시 \n 할인 혜택 적용",
  },
];
