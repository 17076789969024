import React from 'react'
import Title from '../../../Common/Title'
import img1 from "../../../../Assets/Images/img-whatis-csservice-01@3x.png"
import img2 from "../../../../Assets/Images/img-whatis-csservice-02@3x.png"
import img3 from "../../../../Assets/Images/img-whatis-csservice-03@3x.png"
import img4 from "../../../../Assets/Images/img-whatis-csservice-04@3x.png"
import "./styles.scss"
function CSService() {
  return (
    <div className='csService'>
        <Title title={<>
            御客運営管理サービス <span>CS Service</span>
        </>}
        detail={`消費者の好みと基準が多様になり、ディテールで正確な御客合わせサービスが重要な時代です。\n
        CS専門相談人力はカスタマーサービス経験を基盤として、消費者のお問い合わせを体系的に対応して\n
        速やかで正確なご返答で御客満足度を上げるため努力しております。`}
        />

        <ul className="csService-list">
            {data.map((item,index) => (
                <li key={index} className='csService-list-item'>
                    <img src={item.img} alt="" />
                    <div className="content">
                        <div className="title11">{item.title}</div>
                        <div className="detail11">
                            <p>{item.detail1}</p>
                            <p>{item.detail2}</p>
                            <p>{item.detail3}</p>
                            <p>{item.detail4}</p>
                            <p>{item.detail5}</p>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    </div>
  )
}

export default CSService

const data = [
    {
        img: img1,
        title: 'マーケット及びコミュニティ',
        detail1: '·  ユーザーとの疎通及びケア進行',
        detail2: '·  不満動向分析及び抽出',
        detail3: '·  お知らせ及び返答で疎通進行',
        detail4: '·  ゲーム内不具合現象即時確認',
        detail5: '·  英、中、日自動翻訳機能',
    },
    {
        img: img2,
        title: 'VOC(統計)レポート',
        detail1: '·  周期別動向把握、サービス方向提示',
        detail2: '·  お問い合わせを通じて確認された動向',
        detail3: '·  カフェ及びレビューなど統計現況',
        detail4: '·  各ご意見収集',
        detail5: '·  一日、週刊、月刊報告書提供',
    },
    {
        img: img3,
        title: '決済及び払い戻し管理',
        detail1: '·  お問い合わせ受付から払い戻しまで管理',
        detail2: '·  払い戻し及び決済キャンセル進行',
        detail3: '·  対外公文など業務代行',
        detail4: '·  払い戻しアビュージングに関するモニタリング',
    },
    {
        img: img4,
        title: 'VIPサービス応対',
        detail1: '·  VIPサービス提供で優先対応可能',
        detail2: '·  類型別分類システム保有',
        detail3: '·  VIP対応プロセス保有',
        detail4: '·  終了後追跡管理進行',
    },
]














