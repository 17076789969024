import React from "react";
import Button from "../../../Common/Button";
import Title2 from "../../../Common/Title2";
import img1 from "../../../../Assets/Images/ic-platform-reason-01@3x2.png";
import img2 from "../../../../Assets/Images/ic-platform-reason-02@3x2.png";
import img3 from "../../../../Assets/Images/ic-platform-reason-03@3x2.png";
import img4 from "../../../../Assets/Images/ic-platform-reason-04@3x2.png";
import img5 from "../../../../Assets/Images/ic-platform-reason-05@3x2.png";
import "./styles.scss";

function mreason() {
  return (
    <div className="reason">
      <div className="reason-container">
        <Title2
          detail="CS에 대한 궁금증, 확인 절차 이제는 BYE~
          나의 모든 문의 답변 확인은 CSbye에서!"
          title={
            <>
              <span>CSbye</span>에 가입해야 하는 이유
            </>
          }
        />
        <ul className="reason-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="title">{item.title}</div>
              <div className="detail">{item.detail}</div>
            </li>
          ))}
        </ul>
        {/* <div className="reason-container-button">
          <Button onClick={() => {alert('준비중입니다.')}} text="무료로 체험하기" rightIcon type="outline" />
        </div> */}
      </div>
    </div>
  );
}

export default mreason;

const data = [
  {
    img: img1,
    title: `이용 서비스 \n 문의 확인`,
    detail: `내가 이용하는 서비스의 \n 문의를 한 곳에서 한 번에 \n 확인할 수 있습니다.`,
  },
  {
    img: img2,
    title: `답변 관리 및 \n 추가문의 접수`,
    detail: `문의 답변 진행상황 \n 추가문의 접수가 가능합니다.\n `,
  },
  {
    img: img3,
    title: `서비스 공지사항 \n 및 맞춤광고`,
    detail: `이벤트 및 신규 게임출시등 \n 서비스의 공지사항 확인 및 \n 맞춤광고를 추천해줍니다.`,
  },
  {
    img: img4,
    title: `포인트 적립 & 교환 \n (개발중)`,
    detail: `문의 접수, 배너 클릭, 별점 리뷰 등, \n CSbye 활동 만으로 포인트 적립 후 \n 다양한 혜택으로 교환드립니다.`,
  },
  {
    img: img5,
    title: `SNS를 통한 간편한 \n 로그인, 회원가입`,
    detail: `현재 이용하시는 SNS로 \n 간편하게 CSbye 서비스 \n 사용이 가능합니다.`,
  },
  

];
