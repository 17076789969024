import React from "react";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/ic-whatis-mornitoring-01@3x.png";
import img2 from "../../../../Assets/Images/ic-whatis-mornitoring-02@3x.png";
import img3 from "../../../../Assets/Images/ic-whatis-mornitoring-03@3x.png";
import img4 from "../../../../Assets/Images/ic-whatis-mornitoring-04@3x.png";
import "./styles.scss";
import Button from "../../../Common/Button";
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../../Modules/routesString";
import * as Scroll from "react-scroll"

function MornitoringService() {
  const navigation = useNavigate()
  const scroll = Scroll.animateScroll
  return (
    <div className="mornitoringService">
      <div className="mornitoringService-container">
        <Title
          title={
            <>
              모니터링 서비스  <span>Mornitoring Service</span>
            </>
          }
          detail={`언제 발생될지 모르는 돌발 장애 대응과 취약시간 대에도 유저들의 실시간 동향파악이 가능하도록 전문 \n
           모니터링 요원을 통해 24시간 365일 관리가 가능합니다. 고객사의 서비스에 이상 증상이 노출되지 \n 
           않도록 저희 에드업에서 매시간 밀착 모니터링을 진행합니다.`}
        />

        <ul className="mornitoringService-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="content">
                <div className="title">{item.title}</div>
                <div className="detail">{item.detail}</div>
              </div>
            </li>
          ))}
        </ul>

            {/* <div className="mornitoringService-container-button">
                <Button onClick={() => {
                  scroll.scrollToTop()
                  navigation(RoutesString.Customer)
                  }} rightIcon text="서비스 문의하기" />
            </div> */}

      </div>
    </div>
  );
}

export default MornitoringService;

const data = [
  {
    img: img1,
    title: "24시간 실시간 대응",
    detail: `상시적인 모니터링 상황 전달, 카페 및 홈페이지 모니터링,\n 인게임 접속 모니터링`,
  },
  {
    img: img2,
    title: "마켓 리뷰 대응",
    detail: `구글 · 앱스토어 · 원스토어 등 리뷰 확인, 리뷰 확인 및 \n 응대 가능, 각종 이벤트 관리 가능`,
  },
  {
    img: img3,
    title: "장애 전파",
    detail: `장애 상황 시 신속한 전파 진행, 유선 전화 · 메일 등을 \n 활용한 전파, 요청에 따라 선 조치(공지) 대응`,
  },
  {
    img: img4,
    title: "리포트 제공",
    detail: `상황별 리포트 제공, 매 시간별 모니터링 리포트 전달, 주요 \n 의견 취합 및 공유`,
  },
];
