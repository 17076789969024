import React from "react";
import img from "../../../../Assets/Images/img-customer-banner@3x.png";
import img1 from "../../../../Assets/Images/ic-customer-phone@3x.png";
import img2 from "../../../../Assets/Images/ic-customer-time@3x.png";
import Button from "../../../Common/Button";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../../Modules/routesString";
import * as Scroll from "react-scroll"
function HeroSectionCustomer() {
  const navigation = useNavigate();
  const scroll = Scroll.animateScroll
  return (
    <div className="heroSectionCustomer">
      <div className="section">
        <div className="section-container">
          <div className="section-container-content">
            <div className="content">
              <div className="title">
                <p>How can I help you?</p>
              </div>
              <div className="detail">
                <p>{`Contact us directly or check our frequently asked questions (FAQs) \n to find answers faster.
\n Tell you all about CSbye!`}</p>
              </div>
              <a href="https://add-up.csbye.com/menu/342">
                <div className="button">
                  <Button onClick={() => {}} rightIcon text="Inquire 1:1" />
                </div>
              </a>
            </div>
            <div className="img">
              <img src={img} alt="" />
            </div>
          </div>

          <div className="contact">
            <div className="contact-item">
              <div className="contact-item-img">
                <img src={img1} alt="" />
              </div>
              <div className="contact-item-content">
                <div className="subTit">Representative Number</div>
                <div className="title">1670 - 2055</div>
              </div>
            </div>

            <div className="contact-item">
              <div className="contact-item-img">
                <img src={img2} alt="" />
              </div>
              <div className="contact-item-content">
                <div className="subTit">Business Time</div>
                <div className="content-detail">
                  <span>Only weekdays</span>09:00~18:00
                </div>
                <div className="content-detail">
                  <span>Lunch</span>13:00~14:00
                </div>
              </div>
              <div className="contact-item-note2">
                * Closed on weekends and holidays
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSectionCustomer;
