import React, { useEffect, useState } from "react";
import "./styles.scss";
import checkIcon from "../../../../Assets/Images/ic-price-check-01@3x.png";
import img1 from "../../../../Assets/Images/ic-price-service@3x.png";
import img2 from "../../../../Assets/Images/ic-price-page@3x.png";
import img3 from "../../../../Assets/Images/ic-price-respone@3x.png";
import img4 from "../../../../Assets/Images/ic-price-function@3x.png";
import img5 from "../../../../Assets/Images/ic-price-notification@3x.png";
import img6 from "../../../../Assets/Images/ic-price-customer@3x.png";
import img7 from "../../../../Assets/Images/ic-price-global@3x.png";
import img8 from "../../../../Assets/Images/ic-price-cooperation@3x.png";
import img9 from "../../../../Assets/Images/ic-price-security@3x.png";
import img10 from "../../../../Assets/Images/ic-price-report@3x.png";
import img11 from "../../../../Assets/Images/icon-price-support@3x.png";
function PriceTable() {
  const [isMenuFixed, setIsMenuFixed] = useState(false);
  const header = document.querySelector(".headerSection") as any;

  const stickNavbar = () => {
    const menu = document.querySelector(".priceTable-tab") as any;
    const header = document.querySelector(".headerSection") as any;

    const menuOffset = menu?.offsetTop;
    const scrollPos = window.scrollY;

    if (scrollPos >= menuOffset - header?.clientHeight) {
      setIsMenuFixed(true);
    } else {
      setIsMenuFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  return (
    <div className="priceTable">
      <div className="priceTable-container">
        <div style={{ width: "100%" }} className="priceTable-tab"></div>
        <div
          style={{ top: header?.clientHeight }}
          className={`title-table ${isMenuFixed ? "fixed-menu" : ""}`}
        >
          <div className="main-title title">按计划分类的功能</div>
          <div className="detail">
            <div className="detail-item free">Basic</div>
            <div className="detail-item basic">Basic+</div>
            <div className="detail-item premium">Premium</div>
            <div className="detail-item special">Special</div>
          </div>
        </div>

        <div className="list-table">
          {data.map((item, index) => (
            <div key={index} className="table-item">
              <div className="table-item-title">
                <img src={item.imgAvt} alt="" />
                <span>{item.title}</span>
              </div>
              <div className="table-item-content">
                {item.content.map((content: any, index) => (
                  <div key={content.titleContent} className="item">
                    <div className="title">{content.titleContent}</div>
                    <div className="detail">
                      {content.value.map((child: any, index: number) => (
                        <div key={`${child}+${index}`} className="detail-child">
                          {child === true ? (
                            <img src={checkIcon} alt="checkIcon" />
                          ) : child === false ? (
                            <span>不提供</span>
                          ) : (
                            child
                          )}
                        </div>
                      ))}
                    </div>
                    {content.subTitContent! && (
                      <div className="subdetail">{content.subTitContent}</div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="table-note">
          <span>* 从产品付款到账户创建最多需要 3 个工作日</span>
        </div>
      </div>
    </div>
  );
}

export default PriceTable;

const data = [
  {
    imgAvt: img1,
    title: "服务运营及客户门户运营",
    content: [
      {
        titleContent: "服务注册",
        value: ["1种", "1种", "3种", "6种"],
      },
      {
        titleContent: "限制每日咨询回复数量",
        value: ["60种", "不限", "不限", "不限"],
      },
      {
        titleContent: "创建客户门户",
        value: [true, true, true, true],
      },
      {
        titleContent: "FAQ、条款/政策、主要公告事项插入功能",
        value: [true, true, true, true],
      },
      {
        titleContent: "按咨询类型分类",
        value: [true, true, true, true],
      },
      {
        titleContent: "创建票券详细记录项目",
        value: [true, true, true, true],
      },
    ],
  },
  {
    imgAvt: img2,
    title: "高效的应对处理",
    content: [
      {
        titleContent: "批量处理重复咨询",
        value: [true, true, true, true],
      },
      {
        titleContent: "确认客户信息及咨询详情",
        value: [true, true, true, true],
      },
      {
        titleContent: "客户信息采集API",
        value: [true, true, true, true],
      },
    ],
  },

  {
    imgAvt: img3,
    title: "商谈便捷功能",
    content: [
      {
        titleContent: "咨询接待邮件设计",
        value: [true, true, true, true],
      },
      {
        titleContent: "咨询回复邮件设计",
        value: [true, true, true, true],
      },
      {
        titleContent: "回复模板注册功能",
        value: [true, true, true, true],
      },
      {
        titleContent: "回复预览功能",
        value: [true, true, true, true],
      },
      {
        titleContent: "票券隐藏功能",
        value: [true, true, true, true],
      },
      {
        titleContent: "咨询时快捷便利功能",
        value: [false, true, true, true],
      },
      {
        titleContent: "按服务标记功能",
        value: [false, true, true, true],
      },
      {
        titleContent: "预约回复功能",
        value: [false, false, true, true],
      },
      {
        titleContent: "临时回复功能",
        value: [false, false, true, true],
      },
      {
        titleContent: "SMS发送功能",
        value: [false, false, true, true],
      },
    ],
  },

  {
    imgAvt: img4,
    title: "通知服务",
    content: [
      {
        titleContent: "VIP客户咨询通知",
        value: [false, false, true, true],
      },
      {
        titleContent: "全部咨询通知",
        value: [false, false, false, true],
      },
      {
        titleContent: "注册咨询关键词通知（每项服务）",
        value: [false, false, "5种", "不限"],
      },
    ],
  },

  {
    imgAvt: img6,
    title: "客户服务",
    content: [
      {
        titleContent: "VIP注册及优先处理咨询",
        value: [true, true, true, true],
      },
      {
        titleContent: "间接曝光Black咨询",
        value: [true, true, true, true],
      },
      {
        titleContent: "客户倾向备忘录",
        value: [false, false, true, true],
      },
      {
        titleContent: "收集回复满意度",
        value: [false, false, true, true],
      },
    ],
  },

  {
    imgAvt: img7,
    title: "全球服务",
    content: [
      {
        titleContent: "顾客门户网站按语言分类运营",
        value: [false, false, "4种语言", "4种语言"],
      },
      {
        titleContent: "咨询中心按语言类别运营",
        value: [false, false, "4种语言", "4种语言"],
      },
      {
        titleContent: "咨询及回复内容翻译功能",
        value: [false, false, "4种语言", "4+@种语言"],
      },
    ],
  },

  {
    imgAvt: img8,
    title: "合作",
    content: [
      {
        titleContent: "票券备忘录",
        value: [true, true, true, true],
      },
      {
        titleContent: "票券自动分配",
        value: [true, true, true, true],
      },
      {
        titleContent: "票券指定分配",
        value: [false, true, true, true],
      },
      {
        titleContent: "调整票券自动分配比率",
        value: [false, false, false, true],
      },
      {
        titleContent: "职员空缺时咨询循环分配",
        value: [false, false, false, true],
      },
    ],
  },

  {
    imgAvt: img9,
    title: "保安",
    content: [
      {
        titleContent: "防止同时操作同一账户",
        value: [false, true, true, true],
      },
      {
        titleContent: "设置咨询员负责服务",
        value: [false, true, true, true],
      },
      {
        titleContent: "确认团队成员活动记录",
        value: [false, false, true, true],
      },
      {
        titleContent: "咨询员按等级设置权限",
        value: [false, false, false, true],
      },
    ],
  },
  {
    imgAvt: img10,
    title: "报告",
    content: [
      {
        titleContent: "各服务咨询处理现状报告",
        value: [true, true, true, true],
      },
      {
        titleContent: "各咨询员的活动报告",
        value: [false, false, true, true],
      },
      {
        titleContent: "报告数据下载",
        value: [false, false, false, true],
      },
    ],
  },
  {
    imgAvt: img5,
    title: "营销",
    content: [
      {
        titleContent: "设置邮件广告横幅",
        value: [false, false, "1种", "3种"],
      },
      {
        titleContent: "注册个人会员广告横幅",
        value: [false, false, true, true],
      },
    ],
  },
  {
    imgAvt: img11,
    title: "支持使用",
    content: [
      {
        titleContent: "支持电子邮件联动",
        value: [true, true, true, true],
      },
      {
        titleContent: "咨询定制商品",
        value: [false, "询问后进行", "询问后进行", "询问后进行"],
      },
      {
        titleContent: "运营人员服务咨询",
        value: [false, "询问后进行", "询问后进行", "询问后进行"],
      },
    ],
  },
];
