import imgBanner from "../../../../Assets/Images/img-price-banner@3x.png";

import { useNavigate } from "react-router-dom";
import basicIcon from "../../../../Assets/Images/basic@3x.png";
import customizeIcon from "../../../../Assets/Images/customize@3x.png";
import freeIcon from "../../../../Assets/Images/free@3x.png";
import SubImg5 from "../../../../Assets/Images/ic-price-basic@3x.png";
import SubImg4 from "../../../../Assets/Images/ic-price-customize@3x.png";
import SubImg2 from "../../../../Assets/Images/ic-price-premium@3x.png";
import SubImg3 from "../../../../Assets/Images/ic-price-special@3x.png";
import SubImg1 from "../../../../Assets/Images/ic-price-standard@3x.png";
import premiumIcon from "../../../../Assets/Images/premium@3x.png";
import specialIcon from "../../../../Assets/Images/special@3x.png";
import Button from "../../../Common/Button";
import { RoutesString } from "../../../Modules/routesString";

import * as Scroll from "react-scroll";
import "./styles.scss";

function PriceBanner() {
  const navigation = useNavigate();
  const scroll = Scroll.animateScroll;

  const dataPriceTypes = [
    {
      type: "Basic",
      quantity: "1인 사용",
      img: SubImg1,
      sheet: "시트 당",
      title: "무료",
      iconCheck: freeIcon,
      feature: (
        <>
          {/* <span style={{ color: "#b5b5b5" }}>+Free </span> 의 모든 기능 */}
        </>
      ),
      detail: [
        {
          content: `딱 필요한 기본 <span>상담 기능만</span>`,
        },
        {
          content: `일일 문의 답변 개수 <span>60개 제공</span>`,
        },
      ],
      textButton: "무료로 시작하기",
      mainColor: "#b8daff",
      onPressButton: () => {
        scroll.scrollToTop();
        navigation(RoutesString.free);
      },
    },
    {
      type: "Basic+",
      quantity: "1~2인, 소규모 팀에 적합",
      img: SubImg5,
      sheet: "시트 당",
      title: "월 19,000원",
      iconCheck: basicIcon,
      mainColor: "#90c4fd",
      feature: (
        <>
          +<span style={{ color: "#b8daff" }}> Basic </span> 의 모든 기능
        </>
      ),
      detail: [
        {
          content: `문의 접수,답변까지 <span>원활한 소통</span>`,
        },
        {
          content: `나에게 맞는 <span>상담센터 맞춤 설정</span>`,
        },
        {
          content: `소규모 팀에 적합한 <span>기본 시스템</span>`,
        },
      ],
      textButton: "결제",
      onPressButton: () => {
        alert("로그인이 필요합니다.");
        scroll.scrollToTop();
        navigation(RoutesString.domainLogin);
      },
    },
    {
      type: "Premium",
      quantity: "대규모 팀에 적합",
      img: SubImg2,
      sheet: "시트 당",
      title: "월 29,000원",
      iconCheck: premiumIcon,
      mainColor: "#228cff",
      feature: (
        <>
          +<span style={{ color: "#90c4fd" }}> Basic+</span> 요금제의 모든 기능
        </>
      ),
      detail: [
        {
          content: `<span>SMS / APP 연동 </span> 통한 실시간 확인`,
        },
        {
          content: `메모, 예약, 할당 등 <span>상담 특화 기능</span>`,
        },
        {
          content: `복수 서비스 동시 <span>이용 가능</span>`,
        },
        {
          content: `보고서 <span>양식 제공</span>`,
        },
      ],

      textButton: "결제",
      onPressButton: () => {
        alert("로그인이 필요합니다.");
        scroll.scrollToTop();
        navigation(RoutesString.domainLogin);
      },
    },
    {
      type: "Special",
      quantity: "대규모 협업에 적합",
      img: SubImg3,
      sheet: "시트 당",
      mainColor: "#0062f9",
      iconCheck: specialIcon,
      title: "월 39,000원",
      feature: (
        <>
          +<span style={{ color: "#228cff" }}> Premium</span> 요금제의 모든 기능
        </>
      ),
      detail: [
        {
          content: `서비스 내 문의 <span>순환 배정</span>`,
        },
        {
          content: `리포트 <span>데이터 다운로드</span>`,
        },
        {
          content: `플랫폼 전체 <span>기능 사용</span>`,
        },
        {
          content: `메일 광고 <span>키워드 알림 기능</span>`,
        },
        {
          content: `전체 문의, <span>배너 설정</span>`,
        },
        {
          content: `상담원 <span>등급별 권한 설정</span>`,
        },
      ],

      textButton: "결제",
      onPressButton: () => {
        alert("로그인이 필요합니다.");
        scroll.scrollToTop();
        navigation(RoutesString.domainLogin);
      },
    },
    {
      type: "Customize",
      quantity: "인력 서비스 제공 가능",
      img: SubImg4,
      iconCheck: customizeIcon,
      sheet: "시트 당",
      mainColor: "#222222",
      title: "상담 후 결정",
      feature: (
        <>
          +<span style={{ color: "#0062f9" }}> Special</span> 요금제의 모든 기능
        </>
      ),
      detail: [
        {
          content: `서비스 등록 <span>개수 조정</span>`,
        },
        {
          content: ` 상담원 <span>시트 개수 조정</span>`,
        },
        {
          content: `선택적 기능 추가 <span>또는 삭제</span>`,
        },
        {
          content: `운영 인력 <span>서비스 문의</span>`,
        },
        {
          content: `개인 회원 광고 <span>배너 등록</span>`,
        },
      ],
      textButton: "문의하기",
      onPressButton: () => {
        scroll.scrollToTop();
        navigation(RoutesString.Customer);
      },
    },
  ];

  return (
    <div className="priceBanner">
      <div className="priceBanner-container">
        <div className="priceBanner-container-content">
          <div className="content">
            <p className="bold">기업규모에 맞게 필요한 서비스만</p>
            <p>에드업 CSbye 플랜</p>
            <div className="detail">
              원하는 기능을 직접 세팅하는 맞춤형 플랫폼 구조로 문의채널 확인,{" "}
              <br />
              답변 시간 지연 등 다양한 문제를 빠르고 효율적으로 관리할 수
              있습니다. <br />
              이제 고객과 직접 소통하세요.
            </div>
          </div>
          <div className="img">
            <img src={imgBanner} alt="" />
          </div>
        </div>

        <div className="priceTypes">
          {dataPriceTypes.map((item, index) => (
            <PriceType
              key={index}
              type={item.type}
              quantity={item.quantity}
              img={item.img}
              sheet={item.sheet}
              title={item.title}
              feature={item.feature}
              detail={item.detail}
              textButton={item.textButton}
              onPressButton={item.onPressButton}
              mainColor={item.mainColor}
              iconCheck={item.iconCheck}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default PriceBanner;

interface Props {
  type: string;
  quantity: string;
  img: any;
  sheet: string;
  title: string;
  feature?: any;
  detail: { content: any }[];
  textButton: string;
  onPressButton: () => void;
  mainColor: string;
  iconCheck:any
}
const PriceType = ({
  type,
  quantity,
  img,
  sheet,
  title,
  detail,
  textButton,
  onPressButton,
  feature,
  mainColor,
  iconCheck
}: Props) => {
  return (
    <div className="priceType">
      <div className="priceType-container">
        <div style={{ background: mainColor }} className="type">
          <span>{type}</span>
        </div>

        <div className="content">
          <div className="quantity">{quantity}</div>
          <div className="img">
            <img src={img} alt="" />
          </div>
          <div className="sheet">{sheet}</div>
          <div className="title">{title}</div>
          <div className="feature">{feature && feature}</div>
          <div className="detail">
            {detail.map((item, index) => (
              <div key={index} className="detail-item">
                <img src={iconCheck} alt="" />
                <div
                  className="detail-item-content"
                  dangerouslySetInnerHTML={{ __html: item.content }}
                ></div>
              </div>
            ))}
          </div>
        </div>

        <div className="button">
          <Button
            onClick={onPressButton}
            className="button-price"
            text={textButton}
            type={"primary"}
            style={{ background: mainColor }}
          />
        </div>
      </div>
    </div>
  );
};
