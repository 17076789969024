import React from "react";
import img1 from "../../../../Assets/Images/service_gm.png";
import img2 from "../../../../Assets/Images/img-platform-function-02@3x.png";
import img3 from "../../../../Assets/Images/img-platform-function-03@3x.png";
import img4 from "../../../../Assets/Images/img-platform-function-04@3x.png";
import "./styles.scss";

function FREEmain() {
    return (
      <div className="instruction">
        {data.map((item, index) => (
          <div
            key={index}
            style={{
              background: item.backgroundColor,
            }}
            className="instruction-container"
          >
            <div
              style={{ flexDirection: index % 2 !== 0 ? "row-reverse" : "row" }}
              className="instruction-container-item"
            >
              <div className="content">
                <div className="titleFm">{item.title}</div>
                <ul className="detailFm">
                  <li>{item.detail1}</li>
                  <li>{item.detail2}</li>
                </ul>
              </div>
              <div
                style={{
                  justifyContent: index % 2 !== 0 ? "flex-start" : "flex-end",
                }}
                className="img"
              >
                <img src={item.img} alt="" />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
  
  export default FREEmain;
  
  const data = [
    {
      title: `御客管理費用と時間の心配はもうBYE~~`,
      detail1: "単純なお問い合わせ一つも心配になるスタートアップ、",
      detail2: "小規模企業の心を知っているから!",
      img: img1,
      backgroundColor: "#63aeff",
    },

  ];
  