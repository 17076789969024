import { getApi, getJoinApi, postApi, putJoinApi } from "./baseApis";
import * as T from "types/interface.d";

// 도메인로그인
export const getDomainLoginApi = (body: any) => {
  const url = `enterprises/signin/domain`;
  const data = postApi(url, body);
  return data;
};

// 기업회원가입
export const postJoinApi = (body: T.PostJoin) => {
  const url = `enterprises/signup`;
  const data = postApi(url, body);
  return data;
};

// 이메일 기업인증확인
export const getEmailCheckApi = () => {
  const url = `enterprises/signup/emails`;
  const data = getJoinApi(url);
  return data;
};

// 기업회원가입 도메인 추가
export const putJoinDomainApi = (body: T.putJoinDomain) => {
  const url = `enterprises/signup/domain`;
  const data = putJoinApi(url, body);
  return data;
};
// 도메인 찾기
export const getDomainsApi = (domain: string) => {
  const url = `enterprises/domains?companyId=${domain}`;
  const data = getApi(url);
  return data;
};
// 별점남기기
export const postStarPoint = (body: any) => {
  const url = `qna/stars`;
  const data = postApi(url, body);
  return data;
};
