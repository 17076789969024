import React from 'react'
import Title from '../../../Common/Title'
import img1 from "../../../../Assets/Images/img-whatis-csservice-01@3x.png"
import img2 from "../../../../Assets/Images/img-whatis-csservice-02@3x.png"
import img3 from "../../../../Assets/Images/img-whatis-csservice-03@3x.png"
import img4 from "../../../../Assets/Images/img-whatis-csservice-04@3x.png"
import "./styles.scss"
function CSService() {
  return (
    <div className='csService'>
        <Title title={<>
            Customer Service and Management <span>CS Service</span>
        </>}
        detail={`As consumers' taste and standard is diversified, Customer Service\nwith much precision and detail is important in current times.\n
        Based on customer management experience,\nCS professional counselors are systematically responding to inquiries\nand providing quick and accurate replies to increase customer satisfaction.
        `}
        />

        <ul className="csService-list">
            {data.map((item,index) => (
                <li key={index} className='csService-list-item'>
                    <img src={item.img} alt="" />
                    <div className="content">
                        <div className="title22">{item.title}</div>
                        <div className="detail22">
                            <p>{item.detail1}</p>
                            <p>{item.detail2}</p>
                            <p>{item.detail3}</p>
                            <p>{item.detail4}</p>
                            <p>{item.detail5}</p>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    </div>
  )
}

export default CSService

const data = [
    {
        img: img1,
        title: 'Market and Community',
        detail1: '·  Communication and Care for Users',
        detail2: '·  Complaint Identification and Trend Analysis',
        detail3: '·  Communicating with Replies and Notice',
        detail4: '·  Immediate verification of in-game issues',
        detail5: '·  Auto translation of EN/CN/JP languages',
    },
    {
        img: img2,
        title: 'VOC(statistics) Report',
        detail1: '·  Analyze trend by cycle and suggest service direction',
        detail2: '·  Trend reports confirmed from inquiries',
        detail3: '·  Statistics report analyzed from communities and reviews',
        detail4: '·  Collect and organize suggestions',
        detail5: '·  Daily/Weekly/Monthly reports',
    },
    {
        img: img3,
        title: 'Management of payment and refund',
        detail1: '·  Comprehensive management from inquiries to refunds ',
        detail2: '·  Proceed refund and payment cancelation',
        detail3: '·  External public affairs management',
        detail4: '·  Monitor for refund abuse',
    },
    {
        img: img4,
        title: 'VIP service',
        detail1: '·  Prioritise VIP responses',
        detail2: '·  Possess classification system by type',
        detail3: '·  Possess VIP response process',
        detail4: '·  Proceed with tracking management after termination.',
    },
]














