import React from 'react'
import Banner22 from '../../Common/Banner2'
import img1111 from "../../../Assets/Images/member_0003.png"
// import img1111 from "../../../Assets/Images/memberimg.png"
import Membermain from '../member_en/membermain'
import Mreason from "../member_en/mreason"
import Member_Map from '../member_en/memberMap'
import MemberNotice from '../member_en/memberNotice'
import MemberNotice2 from '../member_en/memberNotice2'
// import FREEVersion from './FREEVersion'
//import img1 from "../../../../Assets/Images/freeimg.png";

import MemberMap from './memberMap'
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../Modules/routesString";
import * as Scroll from "react-scroll"
function Member_en() {
  const scroll = Scroll.animateScroll
  return (
    <main>

       
        <Banner22
        img1111={img1111}
        title={`CSbye, your private inquiry box`}
        subTitle="If I want to check my inquiry status and previous history at once?"
        detail={``}

       textButton={"Log in as a general member"}
        onPressButton={() => {{alert('preparing')}}}
          // scroll.scrollToTop()
          // navigation(RoutesString.Customer);       
        textButton2={"Redeem points (preparing)"}
        // textButton={"서비스 문의하기"}
        backgroundColor="#63aeff"
      />
      
      
      <Member_Map/>
      <Mreason/>
      <Membermain/>
      <MemberNotice/>
      <MemberNotice2/>
      
      
      {/* <FREEplatform/> */}
      {/* <Policy /> */}
    </main>
  )
}




//function Free() {
  //return (
    //<main>
      //  <Banner
        //img={img}
 //       title={`CSbye 플랫폼 평생무료!`}
   //     subTitle="문의량이 적은 소규모, 스타트업 기업이라면?"
     //   detail={``}
       // onPressButton={() => {alert('준비중입니다.')}}
     //   textButton={"CSbye플랫폼 시작하기"}
   //     backgroundColor="#c1e0ff"
    //  />
     // <FREEmain />
 //   </main>
//  )
//}


//const Free = () => {
  //return (
    //<div id="star" className="bg-f2f3f8">
      //무료이용중
     
    //</div>

  //);
  

//};

export default Member_en;
