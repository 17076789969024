import React from "react";
import img from "../../../../Assets/Images/img-customer-banner@3x.png";
import img1 from "../../../../Assets/Images/ic-customer-phone@3x.png";
import img2 from "../../../../Assets/Images/ic-customer-time@3x.png";
import Button from "../../../Common/Button";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../../Modules/routesString";
import * as Scroll from "react-scroll";
function HeroSectionCustomer() {
  const navigation = useNavigate();
  const scroll = Scroll.animateScroll;
  return (
    <div className="heroSectionCustomer">
      <div className="section">
        <div className="section-container">
          <div className="section-container-content">
            <div className="content">
              <div className="title">
                <p>무엇을 도와드릴까요?</p>
              </div>
              <div className="detail">
                <p>{`직접 문의하거나 자주 묻는 질문(FAQ)을 확인하여 더 빠르게 답을 찾아보세요. \n CSbye의 모든 것을 알려드립니다!`}</p>
              </div>
              <a href="https://add-up.csbye.com/menu/342">
                <div className="button">
                  <Button onClick={() => {}} rightIcon text="1:1 문의하기" />
                </div>
              </a>
            </div>
            <div className="img">
              <img src={img} alt="" />
            </div>
          </div>

          <div className="contact">
            <div className="contact-item">
              <div className="contact-item-img">
                <img src={img1} alt="" />
              </div>
              <div className="contact-item-content">
                <div className="subTit">대표번호</div>
                <div className="title">1670 - 2055</div>
              </div>
            </div>

            <div className="contact-item">
              <div className="contact-item-img">
                <img src={img2} alt="" />
              </div>
              <div className="contact-item-content">
                <div className="subTit">운영시간</div>
                <div className="content-detail">
                  <span>평일</span>09:00~18:00
                </div>
                <div className="content-detail">
                  <span>점심</span>13:00~14:00
                </div>
              </div>
              <div className="contact-item-note">*주말 및 공휴일은 휴무</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSectionCustomer;
