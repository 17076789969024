import React from "react";
import Button from "../../../Common/Button";
import Title2 from "../../../Common/Title2";
import img1 from "../../../../Assets/Images/ic-platform-reason-01@3x2.png";
import img2 from "../../../../Assets/Images/ic-platform-reason-02@3x2.png";
import img3 from "../../../../Assets/Images/ic-platform-reason-03@3x2.png";
import img4 from "../../../../Assets/Images/ic-platform-reason-04@3x2.png";
import img5 from "../../../../Assets/Images/ic-platform-reason-05@3x2.png";
import "./styles.scss";

function mreason() {
  return (
    <div className="reason">
      <div className="reason-container">
        <Title2
          detail="对CS的好奇心，现在确认步骤是BYE~
          在CSbye确认我所有咨询的答复！"
          title={
            <>
              必须加入<span>CSbye</span>的理由
            </>
          }
        />
        <ul className="reason-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="title">{item.title}</div>
              <div className="detail">{item.detail}</div>
            </li>
          ))}
        </ul>
        {/* <div className="reason-container-button">
          <Button onClick={() => {alert('준비중입니다.')}} text="무료로 체험하기" rightIcon type="outline" />
        </div> */}
      </div>
    </div>
  );
}

export default mreason;

const data = [
  {
    img: img1,
    title: `使用服务 \n 咨询确认`,
    detail: `可以在一个地方一次性 \n 确认我使用的 \n 服务的咨询。`,
  },
  {
    img: img2,
    title: `管理答复及 \n 接收追加咨询`,
    detail: `咨询答复进行情 \n 况可以追加接受咨询。\n `,
  },
  {
    img: img3,
    title: `服务公告及 \n 定制广告`,
    detail: `确认活动及新游戏上 \n 市等服务的公告事项， \n 并推荐定制广告。`,
  },
  {
    img: img4,
    title: `积分累积 & 交换 \n （开发中）`,
    detail: `仅通过接受咨询、点击横幅、\n 评分评论等CSbye活动 \n 积累积分后，可兑换多种福利。`,
  },
  {
    img: img5,
    title: `通过SNS轻松登录， \n 注册会员`,
    detail: `现在使用的SNS可以 \n 简便地使用CSbye服务。`,
  },
  

];
