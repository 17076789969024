import React from "react";
import img1 from "../../../../Assets/Images/ball_001.png";
import img2 from "../../../../Assets/Images/ball_002.png";
import img3 from "../../../../Assets/Images/01-img-01.png";
import img4 from "../../../../Assets/Images/img-platform-function-04@3x.png";
import "./styles.scss";
import * as Scroll from "react-scroll"
import Button from "../../../Common/Button";
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../../Modules/routesString";

function MemberNotice() {
  const navigation = useNavigate()
  const scroll = Scroll.animateScroll
  return (
    <div className="memberNotice">
      {data.map((item, index) => (
        <div
          key={index}
          style={{
            background: item.backgroundColor,
          }}
          className="memberNotice-container"
        >
          <div
            style={{ flexDirection: index % 2 !== 0 ? "row-reverse" : "row" }}
            className="memberNotice-container-item"
          >
           

            <div className="content">
              <div className="title">{item.title}</div>
              <ul className="detail">
                <li>{item.detail1}</li>
                <li>{item.detail2}</li>
                <li>{item.detail3}</li>
                <li>{item.detail4}</li>
                <li>{item.detail5}</li>
                <li></li>
              </ul>
              
              <div className="notice-container-button">
                <Button
                 size="small"
                  onClick={() => {{alert('준비중입니다.')}}}
                  rightIcon text="포인트 교환하기 (준비중)" />
            </div>

            </div>
            <div
              style={{
                justifyContent: index % 2 !== 0 ? "flex-start" : "flex-end",
              }}
              className="img"
            >
              <img src={item.img} alt="" />
            </div>
            
          </div>
        </div>
      ))}
    </div>
  );
}

export default MemberNotice;

const data = [
  // {
  //   title: `이제 문의 답변은 CSbye~`,
  //   detail1: "·  사이트 별로 나눠진 문의 답변 한번에 모아보기 ",
  //   detail2: "·  추가 문의사항도 CSbye에서 한번에 해결",
  //   detail3: "·  이전 고객문의 내역도 조회 가능",
  //   img: img3,
  //   backgroundColor: "#fff",

  // },
  
  {
    title: `문의만 해도 적립되는 \n CSbye 포인트`,
    detail1: "·  가입만해도 2,000P 즉시 지급 ",
    detail2: "·  추가 정보 기입시 1,000P 지급",
    detail3: "·  문의마다 접수되는 CSbye 포인트 ",
    detail4: "·  배너 클릭 등 간단 미션으로 포인트 쌓기 ",
    detail5: "·  적립된 포인트로 만나는 다양한 혜택 (개발 중)  ",
    img: img2,
    backgroundColor: "#f1f8ff",
  },
 // {
 //   title: `모바일로 간편하게 \n 언제 어디서나 확인 가능`,
  //  detail1: "·  한눈에 보기 쉬운 나의 개인 문의함 ",
 //   detail2: "·  추가문의도 모바일에서 쉽고 빠르게 가능  ",
 //   detail3: "·  SNS로 간편하게 로그인 가능",
 //   img: img1,
    
 //   backgroundColor: "#fff",
 // },
  
  // {
  //   title: `고객 관리`,
  //   detail1: "·  VIP, 블랙리스트 등 고객 유형 분류 가능",
  //   detail2: "·  VIP, 알림 기능 블랙리스트 문의함 등 맞춤 서비스를 제공",
  //   detail3: "·  메모를 통한 고객 성향 특징 , 기록",
  //   img: img3,
  //   backgroundColor: "#fff",
  // },
  // {
  //   title: `상담원 관리`,
  //   detail1: "· 직책에 맞는 상담원 등급별 권한 차별화",
  //   detail2: "·  상담원 업무에 따라 서비스와 티켓 배분 설정",
  //   img: img4,
  //   backgroundColor: "#f1f8ff",
  // },
];
