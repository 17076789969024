import React from "react";
import Button from "../Button";
import "./styles.scss";

interface Props {
  title: string;
  detail: string;
  subTitle: string;
  onPressButton: () => void;
  img: any;
  textButton: string;
  backgroundColor: string;
}
function Banner({
  title,
  detail,
  subTitle,
  onPressButton,
  img,
  textButton,
  backgroundColor,
}: Props) {
  return (
    <div style={{ background: backgroundColor }} className="banner">
      <div className="section">
        <div className="section-container">
          <div className="section-container-content">
            <div className="subtit">
              <span>{subTitle}</span>
            </div>
            <div className="title">
              <p>{title}</p>
            </div>
            <div className="detail">
              <p>{detail}</p>
            </div>

            <div className="button">
              <Button
                size="normal"
                type="outline"
                onClick={onPressButton}
                rightIcon
                text={textButton}
              />
            </div>
          </div>

          <div className="section-img">
            <img src={img} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
