import { Select } from "antd";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import * as Scroll from "react-scroll";
import logo from "../../../Assets/Images/ic-logo-wh@3x.png";
import { RoutesString } from "../../Modules/routesString";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import "./header.scss";

const Header: React.FC = () => {
  const { pathname } = useLocation();
  const navigation = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);
  const [navbar, setNavbar] = useState(false);
  let scroll = Scroll.animateScroll;

  const changeBackground = () => {
    if (window.scrollY > 10) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <header
        id="header"
        style={{
          backgroundColor:
            navbar ||
            pathname === RoutesString.Privacy ||
            pathname === RoutesString.Home_tw
              ? "#162a56"
              : "transparent",
          boxShadow: !navbar ? "none" : undefined,
        }}
        className="header headerSection"
      >
        <div className="header_menu">
          <div className="header_title">
            <a href="/_tw" className="logo">
              <img src={logo} alt="logo" />
            </a>
          </div>
          {/* //header menu  */}
          <ul className="list_menu">
            {dataRouter.map((item, index) => (
              <li key={index} className="menu_items">
                <Link
                  onClick={() => {
                    scroll.scrollToTop();
                  }}
                  to={item.router}
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="header_login">
          {/* <div className="login">
            <Link to="/choice">로그인</Link>
          </div> */}
          <div className="login">
            <Link
              onClick={() => {
                scroll.scrollToTop();
              }}
              to="/member_tw"
            >
              一般會員登錄
            </Link>
          </div>
          <div className="login">
            <Link to="/domainlogin">企業會員登錄</Link>
          </div>
          <Language />

          <Button
            rightIcon
            onClick={() => {
              scroll.scrollToTop();
              navigation(RoutesString.free_tw);
            }}
            text="免費試用"
            size="small"
          />
        </div>

        <div
          onClick={() => setOpenMenu(!openMenu)}
          className="header_mobile_icon"
        >
          {iconMobile}
        </div>
      </header>

      {/* //MOBILE  */}
      <aside className={`header-mobile ${openMenu ? "openMenu" : ""}`}>
        <div className="icon-close">
          <span
            onClick={() => {
              setOpenMenu(!openMenu);
            }}
          >
            X
          </span>
        </div>
        <div className="menu-mobile-items">
          <ul className="list_menu">
            {dataRouter.map((item, index) => (
              <li key={index} className="menu_items">
                <Link
                  onClick={() => {
                    setOpenMenu(!openMenu);
                    scroll.scrollToTop();
                  }}
                  to={item.router}
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="menu-mobile-login">
          {/* <div className="login">
            <Link to="/choice">로그인</Link>
          </div> */}

          <div className="menu-mobile-login">
            <Link
              onClick={() => {
                setOpenMenu(!openMenu);
                scroll.scrollToTop();
              }}
              to="/member_tw"
            >
              一般會員登錄
            </Link>
          </div>

          <div className="menu-mobile-login">
            <Link to="/domainlogin">企業會員登錄</Link>
          </div>

          <Language />
          <Button
            style={{ marginTop: 20 }}
            rightIcon
            onClick={() => {
              scroll.scrollToTop();
              navigation(RoutesString.free_tw);
            }}
            text="免費試用"
            size="small"
          />
        </div>
      </aside>
    </>
  );
};

export default Header;

const dataRouter = [
  {
    name: "什麼是CSbye？",
    router: RoutesString.Intro_tw,
  },
  {
    name: "什麼是CSbye平台？",
    router: RoutesString.Platform_tw,
  },
  {
    name: "價格指南",
    router: RoutesString.Price_tw,
  },
  {
    name: "客戶服務中心",
    router: RoutesString.Customer_tw,
  },
];

const iconMobile = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5"
    />
  </svg>
);

const Language = () => {
  return (
    <div className="lang">
      <Select
        defaultValue="Language"
        style={{ width: 115 }}
        onChange={(dataLang) => {
          const url = window.location.origin;
          if (dataLang === "ko") {
            window.location.href = `${url}`;
          } else {
            window.location.href = `${url}/_${dataLang}`;
          }
        }}
        options={dataLang}
      />
    </div>
  );
};

const dataLang = [
  { value: "ko", label: "KO" },
  { value: "en", label: "EN" },
  { value: "jp", label: "JP" },
  { value: "cn", label: "ZH-CN" },
  { value: "tw", label: "ZH-TW" },
];
