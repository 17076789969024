import React from "react";
import img1 from "../../../../Assets/Images/img-platform-function-01@3x.a35d6c5c54b66eac2ba3_en.png";
import img2 from "../../../../Assets/Images/img-platform-function-02@3x.dc9d14bc9f3c166175f6_en.png";
import img3 from "../../../../Assets/Images/img-platform-function-03@3x.bac07996fd58dbff576b_en.png";
import img4 from "../../../../Assets/Images/img-platform-function-04@3x.88302ed3611be7d453aa_en.png";
import "./styles.scss";
function Instruction() {
  return (
    <div className="instruction">
      {data.map((item, index) => (
        <div
          key={index}
          style={{
            background: item.backgroundColor,
          }}
          className="instruction-container"
        >
          <div
            style={{ flexDirection: index % 2 !== 0 ? "row-reverse" : "row" }}
            className="instruction-container-item"
          >
            <div className="content2">
              <div className="title">{item.title}</div>
              <ul className="detail">
                <li>{item.detail1}</li>
                <li>{item.detail2}</li>
                <li>{item.detail3}</li>
              </ul>
            </div>
            <div
              style={{
                justifyContent: index % 2 !== 0 ? "flex-start" : "flex-end",
              }}
              className="img"
            >
              <img src={item.img} alt="" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Instruction;

const data = [
  {
    title: `Classification by \ninquiry type and creation of information input form
    `,
    detail1: "·  Among the large categories, classify the type of inquiry by dividing it into categories",
    detail2: "·  Create input paper by registering the S category under the M category",
    detail3: "·  Prompt issue identification and response using category",
    img: img1,
    backgroundColor: "#fff",
  },
  {
    title: `Auto input of \ncustomer information`,
    detail1: "·  Customer data inquiries can be automatically linked with a form",
    detail2: "·  Increase customer convenience by auto-collecting customer UID and nickname ",
    detail3: "·  Easy customer information collection by collecting device information path environment and path",
    img: img2,
    backgroundColor: "#f1f8ff",
  },
  {
    title: `Customer \nManagement`,
    detail1: "·  Able to classify customer types such as VIP, Blacklist, etc. ",
    detail2: "·  Provides customized services such as VIP notification and blacklist inquiry",
    detail3: "·  Record customer propensity and characteristics through memos",
    img: img3,
    backgroundColor: "#fff",
  },
  {
    title: `Counselor \nManagement`,
    detail1: "· Differentiation of authority by counselor level according to position",
    detail2: "·  Determining service and ticket allocation based on counselor duties",
    img: img4,
    backgroundColor: "#f1f8ff",
  },
];
