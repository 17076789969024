import React from 'react'
import Banner from '../../Common/Banner3'
import img from "../../../Assets/Images/img-platform-banner@3x.png"
import Intro from './Intro'
import Reason from './Reason'
import Instruction from './Instruction'
import PlatformMap from './Map'
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../Modules/routesString";
import * as Scroll from "react-scroll"
function Platform() {
  const navigation = useNavigate();
  const scroll = Scroll.animateScroll
  return (
    <main>
        <Banner
        img={img}
        title={`御客満足に近付ける \n CSbye ホーム`}
        subTitle="御客との円滑な疎通、高いサービス満足度達成"
        detail={`CSbyeホームはADD-UP御客管理サービスノーハウを活用したCRMホームです。
        ウェブを基盤で接近しやすく、相談員便意性に重点を置きました。
        様々な分野に適用でき、安定的で効率的なサービスを提供して御客満足を高めます。`}
        onPressButton={() => {
          scroll.scrollToTop()
          navigation(RoutesString.free_jp);
        }}
        textButton={"無料で体験する"}
        textButtonn={"ホームマニュアル"}
        backgroundColor="#162a56"
      />
      <Intro />
      <Reason />
      <Instruction />
      <PlatformMap />
    </main>
  )
}

export default Platform