import React from "react";
import Title from "../../../Common/Title";
import imgM from "../../../../Assets/Images/ic-price-basic.png";
import img1 from "../../../../Assets/Images/60_60 01.png";
import img2 from "../../../../Assets/Images/60_60 02.png";
import img3 from "../../../../Assets/Images/60_60 03.png";
import img4 from "../../../../Assets/Images/60_60 04.png";
import img5 from "../../../../Assets/Images/60_60 05.png";
import img6 from "../../../../Assets/Images/60_60 06.png";
import img7 from "../../../../Assets/Images/60_60 07.png";
import "./styles.scss";
import Button from "../../../Common/Button";
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../../Modules/routesString";
import * as Scroll from "react-scroll"


function FREEservice() {
  const navigation = useNavigate()
  const scroll = Scroll.animateScroll
  return (
    <div className="FREEservice">
      <div className="FREEservice-container">
        <Title
          title={
            <>
              <span style={{ color: "#fff" }}>CSbye Platform Basics</span>
            </>
          }
          detail={'Free SRM platform for newly starting start-up companies.'}
          
        />
         <div className="FREEVversion">
            <div className="FREEVversion22">
<ul className="basic_M">
<li className="imgMM">
<img src={imgM} alt="" />
</li>
<li>
<ul className="basic__M">
<li className="BASIC"><span style={{ color: "#fff" }}>Basic</span> </li>
<li className="BASIC22">- Basic consultation features - </li>
<li className="BASIC33">One Service registration</li>
<li className="BASIC33">One Counselor(manager)</li>
<li className="BASIC33">Available up to 60 replies per day</li>


</ul>
</li>

</ul>

</div>
        </div>


        <ul className="FREEservice-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="title">{item.title}</div>
              <hr className="linee"></hr>
              <div className="detail">{item.detail}</div>
            </li>
          ))}
        </ul>
        <a href="https://www.csbye.com/domainlogin">
        <div className="mornitoringService-container-button">
                <Button onClick={() => {
                  }} rightIcon text="Start CSBYE platform free" />
            </div> </a>



      </div>
    </div>
  );
}

export default FREEservice;

const data = [
  {
    img: img1,
    title: "Service customer web portal",
    detail: `· Create a customer web portal \n · Upload FAQ, terms/policies, major notices \n · Classify inquiries by type. \n · Create ticket details`,
  },
  {
    img: img2,
    title: "Convenience function for replies",
    detail: `· Batch replies of duplicate inquiries \n · Check customer information and inquiry details \n · Customer information collection API`,
  },
  {
    img: img3,
    title: "Convenience function for counseling",
    detail: `· Inquire/Reply mail design \n · Register Reply Template \n · Reply preview and hide tickets`,
  },
  {
    img: img4,
    title: "Customer Management",
    detail: `· VIP registration and Prioritise reply \n · Indirect exposure of Black inquiries`,
  },
  {
    img: img5,
    title: "Cooperation",
    detail: `· Ticket Memo \n · Automatic ticket assignment`,
  },
  {
    img: img6,
    title: "Report",
    detail: `· Inquiry processing status report by service`,
  },
  {
    img: img7,
    title: "Support for use",
    detail: `· Provide email connection`,
  },
];
