import React, { useEffect, useLayoutEffect, useState } from "react";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/03-icon-1@3x.png";
import img2 from "../../../../Assets/Images/03-icon-2@3x.png";
import img3 from "../../../../Assets/Images/03-icon-3@3x.png";
import img4 from "../../../../Assets/Images/03-icon-4@3x.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./styles.scss";
import Button from "../../../Common/Button";
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../../Modules/routesString";
import { Power4 } from "gsap/all";
import * as Scroll from "react-scroll"


gsap.registerPlugin(ScrollTrigger);

function Service() {
  const scroll = Scroll.animateScroll
  const navigation = useNavigate()
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  
  const isTable = width <= 1280;

  //animation
  useLayoutEffect(() => {
    if(isTable) return
    let ctx = gsap.context(() => {
      let timeline = gsap.timeline({
        scrollTrigger: {
          trigger: `.service-container`,
          start: "top center",
        },
      });

      timeline.from(`.service-list`, {
        x: 1030,
        duration: 2,
        autoAlpha: 0,
        ease: Power4.easeOut,
        // delay: 1
      });
    });

    return () => ctx.revert();
  }, [isTable]);
  return (
    <div id="homeService" className="service">
      <div className="service-container">
        <Title
          title={
            <div>
              <span>CSbye</span> Service
            </div>
          }
          detail={`ADD-UP（股份）有限公司是擁有顧客管理服務經驗的企業, \n 
          (CS)服務、QA(質量保證)、營銷、監控等以廣泛領域的服務實現客戶滿意度的運營管理服務公司。`}
        />

        <ul className="service-list">
          {data.map((item, index) => (
            <li key={index} className="service-item">
              <div className="service-item-img">
                <img src={item.img} alt="" />
                <div className="content">
                  <div className="content-title">{item.title}</div>
                  <div className="content-detail">
                    <p>{item.detail1}</p>
                    <p>{item.detail2}</p>
                    <p>{item.detail3}</p>
                    <p>{item.detail4}</p>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
    
        <div className="service-button">
          <Button rightIcon onClick={() => {
                  scroll.scrollToTop()
            navigation(RoutesString.Intro_tw);
          }} text="查看更多" size="normal" />
        </div>
      </div>
    </div>
  );
}

export default Service;

const data = [
  {
    img: img1,
    title: "客戶運營管理服務",
    detail1: "· 市場與社區",
    detail2: "· 通過VOC（統計）報告建議服務方向",
    detail3: "· 諮詢受理、結算及退款管理",
    detail4: "· VIP優先應對及服務提供",
  },
  {
    img: img2,
    title: "質量保證QA",
    detail1: "· 應用程序和網絡功能測試",
    detail2: "· 終端兼容性測試",
    detail3: "· 更新維護測試",
    detail4: "· 其他質量保證測試",
  },
  {
    img: img3,
    title: "營銷服務",
    detail1: "· SNS發布及營銷工作",
    detail2: "· 活動策劃和CS管理",
    detail3: "· 製作多種病毒視頻",
    detail4: "· 活動結果傳達及獎品發送業務",
  },
  {
    img: img4,
    title: "24小時密切監控",
    detail1: "· 24小時實時響應",
    detail2: "· 市場評論應對",
    detail3: "· 出現故障時及時響應",
    detail4: "· 每小時提供報告",
  },
];
