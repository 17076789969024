import React from 'react'
import Title from '../../../Common/Title'
// import bg from "../../../../Assets/Images/map-platform.png"
import img from "../../../../Assets/Images/memberimg.png"
import item1 from "../../../../Assets/Images/reason022.png"
import item2 from "../../../../Assets/Images/reason011.png"
import item3 from "../../../../Assets/Images/reason033.png"
import "./styles.scss"

function MemberMap() {
  return (
    <div className='memberMap'>
        
        {/* <div className="memberMap-bg">
            <img src={bg} alt="" />
        </div> */}

        <div className="memberMap-container">
        <Title title={<>{}</>} />
        <div className="map">
            {/* <img src={img} alt="" /> */}
        </div>
        
        <div className="map-list">
          {dataMap.map((item,index) => (
            <div className="item" key={index}>
              <div className="img">
                <img src={item.img} alt="" />
              </div>
              <div className="content">
                <div className="title_en">{item.title}</div>
                <div className="detail">{item.detail}</div>
              </div>
            </div>
          ))}
        </div>
        
        </div>
    </div>
  )
}

export default MemberMap

const dataMap = [
    {
      img: item1,
      title: 'What did I Inquire?',
      detail: 'Check your inquires and replies at CSbye!'
    },
    {
      img: item2,
      title: 'Earn points for daily visits',
      detail: 'Sign-up and get 2,000P immediately!'
    },
    {
      img: item3,
      title: 'If the problem is not fully resolved?',
      detail: 'After checking the reply, you can make further inquiries within the same service'
    },
]