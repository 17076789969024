import React from "react";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/ic-whatis-marketing-01@3x.png";
import img2 from "../../../../Assets/Images/ic-whatis-marketing-02@3x.png";
import img3 from "../../../../Assets/Images/ic-whatis-marketing-03@3x.png";
import img4 from "../../../../Assets/Images/ic-whatis-marketing-04@3x.png";
import "./styles.scss";
function MarketingService() {
  return (
    <div className="marketingService">
      <div className="marketingService-container">
        <Title
          title={
            <>
              營銷服務 <span>Marketing Service</span>
            </>
          }
          detail={`隨著近年來潮流的快速變化以及SNS在各個領域的擴展，現在是一個各種渠道營銷變得更加重要的時代。\n
          我們以病毒式視頻傳播、博客發布、Instagram發帖 等各種 SNS 為基礎開展營銷代理工作，\n
          活動企劃及處理、贈品發送等一站式營銷成為可能。`}
        />

        <ul className="marketingService-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="title">{item.title}</div>
              <div className="detail">{item.detail}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default MarketingService;

const data = [
  {
    img: img1,
    title: "SNS 營銷代理",
    detail: `社交營銷大量發帖， \n 例如博客和 Facebook，\n 通過優質網紅進行曝光`,
  },
  {
    img: img2,
    title: "活動企劃及管理",
    detail: `活動企劃及網頁製作等 \n符合客戶需求的活動企劃、\n活動網頁製作、\n處理活動相關CS諮詢工作`,
  },
  {
    img: img3,
    title: "病毒式視頻製作",
    detail: `製作YouTube等各種\n病毒視頻，\n 根據客戶預算製作視頻，\n 低成本、高效率的視頻製作`,
  },
  {
    img: img4,
    title: "獎品發送服務",
    detail: `根據活動結果發送獎品、\n通知客戶結果、\n徵收稅費和公共費用、\n通過與快遞合同\n輕鬆發送獎品`,
  },
];
