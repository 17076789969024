import React, { useEffect, useState } from "react";
import "./styles.scss";
import checkIcon from "../../../../Assets/Images/ic-price-check-01@3x.png";
import img1 from "../../../../Assets/Images/ic-price-service@3x.png";
import img2 from "../../../../Assets/Images/ic-price-page@3x.png";
import img3 from "../../../../Assets/Images/ic-price-respone@3x.png";
import img4 from "../../../../Assets/Images/ic-price-function@3x.png";
import img5 from "../../../../Assets/Images/ic-price-notification@3x.png";
import img6 from "../../../../Assets/Images/ic-price-customer@3x.png";
import img7 from "../../../../Assets/Images/ic-price-global@3x.png";
import img8 from "../../../../Assets/Images/ic-price-cooperation@3x.png";
import img9 from "../../../../Assets/Images/ic-price-security@3x.png";
import img10 from "../../../../Assets/Images/ic-price-report@3x.png";
import img11 from "../../../../Assets/Images/icon-price-support@3x.png";
function PriceTable() {
  const [isMenuFixed, setIsMenuFixed] = useState(false);
  const header = document.querySelector(".headerSection") as any;

  const stickNavbar = () => {
    const menu = document.querySelector(".priceTable-tab") as any;
    const header = document.querySelector(".headerSection") as any;

    const menuOffset = menu?.offsetTop;
    const scrollPos = window.scrollY;

    if (scrollPos >= menuOffset - header?.clientHeight) {
      setIsMenuFixed(true);
    } else {
      setIsMenuFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  return (
    <div className="priceTable">
      <div className="priceTable-container">
        <div style={{ width: "100%" }} className="priceTable-tab"></div>
        <div
          style={{ top: header?.clientHeight }}
          className={`title-table ${isMenuFixed ? "fixed-menu" : ""}`}
        >
          <div className="main-title title">按計劃分類的功能</div>
          <div className="detail">
            <div className="detail-item free">Basic</div>
            <div className="detail-item basic">Basic+</div>
            <div className="detail-item premium">Premium</div>
            <div className="detail-item special">Special</div>
          </div>
        </div>

        <div className="list-table">
          {data.map((item, index) => (
            <div key={index} className="table-item">
              <div className="table-item-title">
                <img src={item.imgAvt} alt="" />
                <span>{item.title}</span>
              </div>
              <div className="table-item-content">
                {item.content.map((content: any, index) => (
                  <div key={content.titleContent} className="item">
                    <div className="title">{content.titleContent}</div>
                    <div className="detail">
                      {content.value.map((child: any, index: number) => (
                        <div key={`${child}+${index}`} className="detail-child">
                          {child === true ? (
                            <img src={checkIcon} alt="checkIcon" />
                          ) : child === false ? (
                            <span>不提供</span>
                          ) : (
                            child
                          )}
                        </div>
                      ))}
                    </div>
                    {content.subTitContent! && (
                      <div className="subdetail">{content.subTitContent}</div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="table-note">
          <span>* 從產品付款到賬戶創建最多需要 3 個工作日</span>
        </div>
      </div>
    </div>
  );
}

export default PriceTable;

const data = [
  {
    imgAvt: img1,
    title: "服務運營及客戶門戶運營",
    content: [
      {
        titleContent: "服務註冊",
        value: ["1種", "1種", "3種", "6種"],
      },
      {
        titleContent: "限制每日諮詢回複數量",
        value: ["60種", "不限", "不限", "不限"],
      },
      {
        titleContent: "創建客戶門戶",
        value: [true, true, true, true],
      },
      {
        titleContent: "FAQ、條款/政策、主要公告事項插入功能",
        value: [true, true, true, true],
      },
      {
        titleContent: "按諮詢類型分類",
        value: [true, true, true, true],
      },
      {
        titleContent: "創建票券詳細記錄項目",
        value: [true, true, true, true],
      },
    ],
  },
  {
    imgAvt: img2,
    title: "高效的應對處理",
    content: [
      {
        titleContent: "批量處理重複諮詢",
        value: [true, true, true, true],
      },
      {
        titleContent: "確認客戶信息及諮詢詳情",
        value: [true, true, true, true],
      },
      {
        titleContent: "客戶信息採集API",
        value: [true, true, true, true],
      },
    ],
  },

  {
    imgAvt: img3,
    title: "商談便捷功能",
    content: [
      {
        titleContent: "諮詢接待郵件設計",
        value: [true, true, true, true],
      },
      {
        titleContent: "諮詢回复郵件設計",
        value: [true, true, true, true],
      },
      {
        titleContent: "回复模板註冊功能",
        value: [true, true, true, true],
      },
      {
        titleContent: "回复預覽功能",
        value: [true, true, true, true],
      },
      {
        titleContent: "票券隱藏功能",
        value: [true, true, true, true],
      },
      {
        titleContent: "諮詢時快捷便利功能",
        value: [false, true, true, true],
      },
      {
        titleContent: "按服務標記功能",
        value: [false, true, true, true],
      },
      {
        titleContent: "預約回复功能",
        value: [false, false, true, true],
      },
      {
        titleContent: "臨時回复功能",
        value: [false, false, true, true],
      },
      {
        titleContent: "SMS發送功能",
        value: [false, false, true, true],
      },
    ],
  },

  {
    imgAvt: img4,
    title: "通知服務",
    content: [
      {
        titleContent: "VIP客戶諮詢通知",
        value: [false, false, true, true],
      },
      {
        titleContent: "全部諮詢通知",
        value: [false, false, false, true],
      },
      {
        titleContent: "註冊諮詢關鍵詞通知（每項服務）",
        value: [false, false, "5種", "不限"],
      },
    ],
  },

  {
    imgAvt: img6,
    title: "客戶服務",
    content: [
      {
        titleContent: "VIP註冊及優先處理諮詢",
        value: [true, true, true, true],
      },
      {
        titleContent: "間接曝光Black諮詢",
        value: [true, true, true, true],
      },
      {
        titleContent: "客戶傾向備忘錄",
        value: [false, false, true, true],
      },
      {
        titleContent: "收集回复滿意度",
        value: [false, false, true, true],
      },
    ],
  },

  {
    imgAvt: img7,
    title: "全球服務",
    content: [
      {
        titleContent: "顧客門戶網站按語言分類運營",
        value: [false, false, "4種語言", "4種語言"],
      },
      {
        titleContent: "諮詢中心按語言類別運營",
        value: [false, false, "4種語言", "4種語言"],
      },
      {
        titleContent: "諮詢及回复內容翻譯功能",
        value: [false, false, "4種語言", "4+@種語言"],
      },
    ],
  },

  {
    imgAvt: img8,
    title: "合作",
    content: [
      {
        titleContent: "票券備忘錄",
        value: [true, true, true, true],
      },
      {
        titleContent: "票券自動分配",
        value: [true, true, true, true],
      },
      {
        titleContent: "票券指定分配",
        value: [false, true, true, true],
      },
      {
        titleContent: "調整票券自動分配比率",
        value: [false, false, false, true],
      },
      {
        titleContent: "職員空缺時諮詢循環分配",
        value: [false, false, false, true],
      },
    ],
  },

  {
    imgAvt: img9,
    title: "保安",
    content: [
      {
        titleContent: "防止同時操作同一賬戶",
        value: [false, true, true, true],
      },
      {
        titleContent: "設置諮詢員負責服務",
        value: [false, true, true, true],
      },
      {
        titleContent: "確認團隊成員活動記錄",
        value: [false, false, true, true],
      },
      {
        titleContent: "諮詢員按等級設置權限",
        value: [false, false, false, true],
      },
    ],
  },
  {
    imgAvt: img10,
    title: "報告",
    content: [
      {
        titleContent: "各服務諮詢處理現狀報告",
        value: [true, true, true, true],
      },
      {
        titleContent: "各諮詢員的活動報告",
        value: [false, false, true, true],
      },
      {
        titleContent: "報告數據下載",
        value: [false, false, false, true],
      },
    ],
  },
  {
    imgAvt: img5,
    title: "營銷",
    content: [
      {
        titleContent: "設置郵件廣告橫幅",
        value: [false, false, "1種", "3種"],
      },
      {
        titleContent: "註冊個人會員廣告橫幅",
        value: [false, false, true, true],
      },
    ],
  },
  {
    imgAvt: img11,
    title: "支持使用",
    content: [
      {
        titleContent: "支持電子郵件聯動",
        value: [true, true, true, true],
      },
      {
        titleContent: "諮詢定製商品",
        value: [false, "詢問後進行", "詢問後進行", "詢問後進行"],
      },
      {
        titleContent: "運營人員服務諮詢",
        value: [false, "詢問後進行", "詢問後進行", "詢問後進行"],
      },
    ],
  },
];
