import React, { useState } from "react";
import "./styles.scss";

// import { getDomainLoginApi } from "Apis/apis";
import H2 from "Components/Atoms/Heading/h2";
import Space from "Components/Atoms/Space/space";
import Input from "Components/Atoms/Input/input";

import LoginJoin from "Components/Molecules/Loginjoin/loginJoin";
import { getDomainLoginApi } from "Apis/apis";
import FindModal from "Components/Modal/FindModal/findModal";

function DomianLogin() {
  const [subDomain, setSubDomain] = useState("");
  const [modal, setModal] = useState(false);

  const goLogin = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (subDomain === "") return alert("주소를 입력해주세요.");
    const body = {
      subDomain,
    };

    await getDomainLoginApi(body);
    window.location.href = `https://${subDomain}.csbye.com/login`;
  };

  return (
    <div id="domian-login" className="login-bg more_wrap">
      <div className="domian_login_contents p-center">
        <H2 name="CS 고민은 이제 안녕, CSbye"></H2>
        <Space y={10} />
        <span className="desc">
          보안을 위해 로그인 하실 도메인를 입력해주세요.
        </span>
        <Space y={40} />

        <form onSubmit={goLogin}>
          <div className="">
            {/* <Label labelfor="web" name="CSbye 도메인" /> */}
            <label className="text-primary">CSbye 도메인</label>
            <Space y={8} />
            <div className="flex item-center">
              <Input
                id="web"
                type="text"
                bottom={false}
                placeholder="주소 입력"
                value={subDomain}
                onChange={(e) => setSubDomain(e.target.value)}
              />
              <Space x={8} />
              <span> .csbye.com</span>
            </div>
            <Space y={8} />
            <button
              onClick={() => setModal(true)}
              type="button"
              className="text-btn"
            >
              도메인이 기억이 안 나시나요?
            </button>
          </div>
          <Space y={24} />
          <button className="addup_btn" type="submit">
            다음
          </button>
          <Space y={20} />
          <LoginJoin name="CSbye가 처음이세요?" url="/join" />
          <Space y={8} />
          <div className="flex justify-center">
            <span className="desc">
              Copyright © ADD-UP Co ,. Ltd All Rights Reserved.
            </span>
          </div>
        </form>
      </div>
      {modal && <FindModal setModal={setModal} />}
    </div>
  );
}

export default DomianLogin;
