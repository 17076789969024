import imgBanner from "../../../../Assets/Images/img-price-banner@3x.png";

import { useNavigate } from "react-router-dom";
import basicIcon from "../../../../Assets/Images/basic@3x.png";
import customizeIcon from "../../../../Assets/Images/customize@3x.png";
import freeIcon from "../../../../Assets/Images/free@3x.png";
import SubImg5 from "../../../../Assets/Images/ic-price-basic@3x.png";
import SubImg4 from "../../../../Assets/Images/ic-price-customize@3x.png";
import SubImg2 from "../../../../Assets/Images/ic-price-premium@3x.png";
import SubImg3 from "../../../../Assets/Images/ic-price-special@3x.png";
import SubImg1 from "../../../../Assets/Images/ic-price-standard@3x.png";
import premiumIcon from "../../../../Assets/Images/premium@3x.png";
import specialIcon from "../../../../Assets/Images/special@3x.png";
import Button from "../../../Common/Button";
import { RoutesString } from "../../../Modules/routesString";

import * as Scroll from "react-scroll";
import "./styles.scss";

function PriceBanner() {
  const navigation = useNavigate();
  const scroll = Scroll.animateScroll;

  const dataPriceTypes = [
    {
      type: "Basic",
      quantity: "1人使用",
      img: SubImg1,
      sheet: "シートあたり",
      title: "無料",
      iconCheck: freeIcon,
      feature: (
        <>
          {/* <span style={{ color: "#b5b5b5" }}>+Free </span> 의 모든 기능 */}
        </>
      ),
      detail: [
        {
          content: `ただ必要な基本相談機能だけ`,
        },
        {
          content: `一日お問い合わせのご返答数60個<br/>提供`,
        },
      ],
      textButton: "無料で始める",
      mainColor: "#b8daff",
      onPressButton: () => {
        scroll.scrollToTop();
        navigation(RoutesString.free);
      },
    },
    {
      type: "Basic+",
      quantity: "1~2人、小規模チームに適合",
      img: SubImg5,
      sheet: "シートあたり",
      title: "月19,000元 \n(韓国ウォン貨)",
      iconCheck: basicIcon,
      mainColor: "#90c4fd",
      feature: (
        <>
          +<span style={{ color: "#b8daff" }}> Basic </span> の機能全部
        </>
      ),
      detail: [
        {
          content: `お問い合わせ受付、返答まで円滑な <br/>疎通`,
        },
        {
          content: `自分に合う相談センターオーダー<br/>メイド設定`,
        },
        {
          content: `小規模チームに適合する基本システム`,
        },
      ],
      textButton: "決済",
      onPressButton: () => {
        alert("ログインが必要なサービスです。");
        scroll.scrollToTop();
        navigation(RoutesString.domainLogin);
      },
    },
    {
      type: "Premium",
      quantity: "大規模チームに適合",
      img: SubImg2,
      sheet: "シートあたり",
      title: "月29,000元\n(韓国ウォン貨)",
      iconCheck: premiumIcon,
      mainColor: "#228cff",
      feature: (
        <>
          +<span style={{ color: "#90c4fd" }}> Basic+</span> 料金プランの全機能
        </>
      ),
      detail: [
        {
          content: `SMS / APP連動を通じてリアルタイムで確認`,
        },
        {
          content: `メモ、予約、割り当てなど相談特化<br/>可能`,
        },
        {
          content: `複数サービス同時利用可能`,
        },
        {
          content: `レポート様式提供`,
        },
      ],

      textButton: "決済",
      onPressButton: () => {
        alert("ログインが必要なサービスです。");
        scroll.scrollToTop();
        navigation(RoutesString.domainLogin);
      },
    },
    {
      type: "Special",
      quantity: "大規模協業に適合",
      img: SubImg3,
      sheet: "シートあたり",
      mainColor: "#0062f9",
      iconCheck: specialIcon,
      title: "月39,000元\n(韓国ウォン貨)",
      feature: (
        <>
          +<span style={{ color: "#228cff" }}> Premium</span> 料金プランの全機能
        </>
      ),
      detail: [
        {
          content: `サービス内お問い合わせ循環配分`,
        },
        {
          content: `レポートデータダウンロード`,
        },
        {
          content: `ホーム機能全体使用可能`,
        },
        {
          content: `メール広告キーワード通知機能`,
        },
        {
          content: `全体お問い合わせ、バナー設定`,
        },
        {
          content: `相談員等級別権限設定`,
        },
      ],

      textButton: "決済",
      onPressButton: () => {
        alert("ログインが必要なサービスです。");
        scroll.scrollToTop();
        navigation(RoutesString.domainLogin);
      },
    },
    {
      type: "Customize",
      quantity: "人力サービス提供可能",
      img: SubImg4,
      iconCheck: customizeIcon,
      sheet: "シートあたり",
      mainColor: "#222222",
      title: "相談後決定",
      feature: (
        <>
          +<span style={{ color: "#0062f9" }}> Special</span> 料金プランの全機能
        </>
      ),
      detail: [
        {
          content: `サービス登録回数調停`,
        },
        {
          content: `相談員シート数調停`,
        },
        {
          content: `選択的機能追加または削除`,
        },
        {
          content: `運営人力サービスお問い合わせ`,
        },
        {
          content: `個人会員広告バナー登録`,
        },
      ],
      textButton: "お問い合わせする",
      onPressButton: () => {
        scroll.scrollToTop();
        navigation(RoutesString.Customer);
      },
    },
  ];

  return (
    <div className="priceBanner">
      <div className="priceBanner-container">
        <div className="priceBanner-container-content">
          <div className="content">
            <p className="bold">企業規模に合わせて必要な<br />サービスだけ</p>
            <p>ADD-UP CSbye プラン</p>
            <div className="detail">
            欲しい機能を直接セッティングするオーダーメイドホーム構造でお問い合わせ<br />チャンネル確認、{" "}
              <br />
              返答時間遅延など様々な問題を速やかで効率的に確認できます。 <br />
              もう御客と直接疎通しましょう。
            </div>
          </div>
          <div className="img">
            <img src={imgBanner} alt="" />
          </div>
        </div>

        <div className="priceTypes">
          {dataPriceTypes.map((item, index) => (
            <PriceType
              key={index}
              type={item.type}
              quantity={item.quantity}
              img={item.img}
              sheet={item.sheet}
              title={item.title}
              feature={item.feature}
              detail={item.detail}
              textButton={item.textButton}
              onPressButton={item.onPressButton}
              mainColor={item.mainColor}
              iconCheck={item.iconCheck}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default PriceBanner;

interface Props {
  type: string;
  quantity: string;
  img: any;
  sheet: string;
  title: string;
  feature?: any;
  detail: { content: any }[];
  textButton: string;
  onPressButton: () => void;
  mainColor: string;
  iconCheck:any
}
const PriceType = ({
  type,
  quantity,
  img,
  sheet,
  title,
  detail,
  textButton,
  onPressButton,
  feature,
  mainColor,
  iconCheck
}: Props) => {
  return (
    <div className="priceType">
      <div className="priceType-container">
        <div style={{ background: mainColor }} className="type">
          <span>{type}</span>
        </div>

        <div className="content">
          <div className="quantity">{quantity}</div>
          <div className="img">
            <img src={img} alt="" />
          </div>
          <div className="sheet">{sheet}</div>
          <div className="title">{title}</div>
          <div className="feature">{feature && feature}</div>
          <div className="detail">
            {detail.map((item, index) => (
              <div key={index} className="detail-item">
                <img src={iconCheck} alt="" />
                <div
                  className="detail-item-content"
                  dangerouslySetInnerHTML={{ __html: item.content }}
                ></div>
              </div>
            ))}
          </div>
        </div>

        <div className="button">
          <Button
            onClick={onPressButton}
            className="button-price"
            text={textButton}
            type={"primary"}
            style={{ background: mainColor }}
          />
        </div>
      </div>
    </div>
  );
};
