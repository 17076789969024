import React, { useLayoutEffect } from "react";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/02-icon-1@3x.png";
import img2 from "../../../../Assets/Images/02-icon-2@3x.png";
import img3 from "../../../../Assets/Images/02-icon-3@3x.png";
import img4 from "../../../../Assets/Images/02-icon-4@3x.png";
import img5 from "../../../../Assets/Images/02-icon-5@3x.png";
import img6 from "../../../../Assets/Images/02-icon-6@3x.png";
import img7 from "../../../../Assets/Images/02-icon-7@3x.png";
import img8 from "../../../../Assets/Images/02-icon-8@3x.png";
import img9 from "../../../../Assets/Images/02-icon-9@3x.png";
import img10 from "../../../../Assets/Images/02-icon-10@3x.png";
import "./styles.scss";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap, { Power4 } from "gsap";

gsap.registerPlugin(ScrollTrigger);
function Server() {
  //animation
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      let timeline = gsap.timeline({
        scrollTrigger: {
          trigger: `.server-container`,
          start: "top center",
        },
      });
      data.map((item, index) => {
        timeline.from(
          `.item-${index}`,
          {
            scale: 0.1,
            duration: 2,
            autoAlpha: 0,
            ease: Power4.easeOut,
          },
          `frame1+=${index / data.length}`
        );
      });
    });

    return () => ctx.revert();
  }, []);

  return (
    <div className="server">
      <div className="server-container">
        <Title
          title={
            <div>
              ADD-UP 服務的新名稱： <span>CSbye</span>
            </div>
          }
          detail={`這是一個隨著消費者品味和標準的多樣化，更細緻、更準確的客戶滿意度服務變得非常重要的時代。\n
          CS專業諮詢人員以顧客管理經驗為基礎, 系統應對消費者諮詢，提供快速、準確的答复，努力提高顧客滿意度`}
        />

        <ul className="server-list">
          {data.map((item, index) => (
            <li key={index} className={`server-list-item item-${index}`}>
              <img src={item.img} alt="" />
              <p>{item.title}</p>
            </li>
          ))}
        </ul>
      </div>

      <div className="one">
        <div className="two"></div>
      </div>
    </div>
  );
}

export default Server;

const data = [
  {
    img: img1,
    title: "一站式確認多個渠道的查詢",
  },
  {
    img: img2,
    title: "快速處理\n所有客戶諮詢",
  },
  {
    img: img3,
    title: "消費者諮詢\n履歷管理",
  },
  {
    img: img4,
    title: "可以提供\n英語、日語、中文",
  },
  {
    img: img5,
    title: "提供日報、\n週報和月報",
  },
  {
    img: img6,
    title: "執行產品質量\n驗證QA工作",
  },
  {
    img: img7,
    title: "提供營銷策劃及\n運營",
  },
  {
    img: img8,
    title: "遊戲及顧客動向等\n24小時監控",
  },
  {
    img: img9,
    title: "減少人力管理負擔\n及靈活運用人力",
  },
  {
    img: img10,
    title: "長期簽約時\n適用折扣優惠",
  },
];
