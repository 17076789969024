import React, { useLayoutEffect } from "react";
import Title from "../../../Common/Title";
import { useNavigate } from "react-router-dom";
import img1 from "../../../../Assets/Images/04-icon-01@3x.png";
import img2 from "../../../../Assets/Images/04-icon-02@3x.png";
import img3 from "../../../../Assets/Images/04-icon-03@3x.png";
import img4 from "../../../../Assets/Images/04-icon-04@3x.png";
import img5 from "../../../../Assets/Images/04-icon-05@3x.png";
import imgSlide1 from "../../../../Assets/Images/img-platform-capture-01@3x.png";
import imgSlide2 from "../../../../Assets/Images/img-platform-capture-02@3x.png";
import imgSlide3 from "../../../../Assets/Images/img-platform-capture-03@3x.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { RoutesString } from "../../../Modules/routesString";
import { Pagination, Autoplay } from "swiper";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import * as Scroll from "react-scroll";

import "./styles.scss";
import Button from "../../../Common/Button";
import { Power4 } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

function Intro() {
  const navigation = useNavigate();
  const scroll = Scroll.animateScroll;
  //animation
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      let timeline = gsap.timeline({
        scrollTrigger: {
          trigger: `.intro-container`,
          start: "top center",
        },
      });
      data.map((item, index) => {
        timeline.from(
          `.intro-item-${index}`,
          {
            scale: 0,
            duration: 3,
            autoAlpha: 0,
            ease: Power4.easeOut,
          },
          `frame1+=${(index / 100) * 30}`
        );
      });
      data.map((item, index) => {
        timeline.from(
          `.intro-item-img-${index}`,
          {
            rotate: 360,
            duration: 3,
            ease: Power4.easeOut,
          },
          `frame1+=${(index / 100) * 30}`
        );
      });
    });

    return () => ctx.revert();
  }, []);

  return (
    <div className="intro">
      <div className="intro-container">
        <Title
          textWhite
          detail="如果只想使用CSBYE平台怎麼辦？"
          title={
            <div>
              高效客戶管理的開始，<br />
              <span>ADD-UP CSbye 平台</span>
            </div>
          }
        />

        <div className="intro-detail3">
          <div className="intro-detail3-slide">
            <Swiper
              modules={[Pagination, Autoplay]}
              spaceBetween={0}
              slidesPerView={1}
              speed={800}
              autoplay
              pagination={{ clickable: true }}
            >
              {dataSlide.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="slide-img">
                    <img src={item.img} alt="" />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <ul className="intro-detail3-list">
            {data.map((item, index) => (
              <li
                className={`intro-detail3-list-item intro-item-${index}`}
                key={index}
              >
                <img
                  className={`intro-item-img-${index}`}
                  src={item.img}
                  alt=""
                />
                <p>{item.detail}</p>
              </li>
            ))}
          </ul>
        </div>

        <ul className="intro-detail3-list-mobile">
          {data.map((item, index) => (
            <li className="intro-detail3-list-item item-mobile" key={index}>
              <img
                className={`intro-item-img-${index}`}
                src={item.img}
                alt=""
              />
              <p>{item.detail}</p>
            </li>
          ))}
        </ul>
     
        <div className="intro-button">
          <Button rightIcon onClick={() => {
            scroll.scrollToTop();
            navigation(RoutesString.Platform_tw);
          }} text="查看更多" size="normal"/>
        </div>
      </div>
    </div>
  );
}

export default Intro;

const data = [
  {
    img: img1,
    detail: ` 專門用於客戶諮詢的 \n WEB平台的構成`,
  },
  {
    img: img2,
    detail: `英文/中文/日文\n 自動翻譯功能`,
  },
  {
    img: img3,
    detail: `提供VIP\n 應對服務`,
  },
  {
    img: img4,
    detail: `可實時確認問題，\n 提取統計資料`,
  },
  {
    img: img5,
    detail: `以定制功能 \n 輕鬆進行客戶管理`,
  },
];

const dataSlide = [{ img: imgSlide1 }, { img: imgSlide2 }, { img: imgSlide3 }];
