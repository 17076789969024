import "./input.scss";

/**
 * 인풋 atomic
 * @param {string} id label에 사용할 ID
 * @param {string} type input 타입
 * @param {boolean} bottom true: 박스 전체테두리 , false : 박스테두리 하단
 * @param {string} placeholder 정의어 ("")
 * @param {string | number} value 인풋에 들어가는값
 * @param {number} width 가로너비 (100)
 * @param {React.ChangeEventHandler<HTMLInputElement>} onChange 인풋 값 변경 함수
 * @param {boolean} disabled 인풋창 필드비활성화 (false)
 */

const Input = ({
  id,
  value,
  bottom = false,
  placeholder = "",
  type = "text",
  width = 100,
  readOnly = false,
  onChange,
  disabled = false,
  required = false,
}: {
  id: string;
  bottom?: boolean;
  type?: string;
  placeholder?: string;
  value?: string;
  width?: number;
  readOnly?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  required?: boolean;
}) => {
  const inputStyle = bottom
    ? "border-e7e7e7 border-b"
    : "border-e2e2e2 rounded pl-8 border-1 border-solid";

  return (
    <input
      id={id}
      type={type}
      placeholder={placeholder}
      className={`input ${inputStyle} text-14 leading-36`} // 시안은 38인데 border때문에 2 줄임
      value={value}
      onChange={onChange}
      style={{ width: `${width}${101 > width ? "%" : "px"}` }}
      readOnly={readOnly}
      disabled={disabled}
      required={required}
      size={1}
    ></input>
  );
};

export default Input;
