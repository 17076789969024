import React from 'react'
import Banner from '../../Common/Banner3'
import img from "../../../Assets/Images/img-platform-banner@3x.png"
import Intro from './Intro'
import Reason from './Reason'
import Instruction from './Instruction'
import PlatformMap from './Map'
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../Modules/routesString";
import * as Scroll from "react-scroll"
function Platform2() {
  const navigation = useNavigate();
  const scroll = Scroll.animateScroll
  return (
    <main>
        <Banner
        img={img}
        title={`고객만족에 더 가까워지는 \n CSbye 플랫폼`}
        subTitle="고객과의 원활한 소통, 높은 서비스 만족도 달성"
        detail={`CSbye 플랫폼은 에드업 고객관리 서비스 노하우를 활용한 CRM 플랫폼입니다.웹 기반으로 접근이 쉬우며, 상담사 편의성에 중점을 두었습니다. 다양한 분야에 적용 가능하고, 안정적이고 효율적인 서비스를 제공하여 고객만족을 높입니다.`}
        onPressButton={() => {
          scroll.scrollToTop()
          navigation(RoutesString.free);
        }}
        textButton={"무료로 체험하기"}

        textButtonn={"플랫폼 메뉴얼"}
        
        
        backgroundColor="#162a56"
      />
      <Intro />
      <Reason />
      <Instruction />
      <PlatformMap />
    </main>
  )
}

export default Platform2