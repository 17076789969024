import React from "react";
import Title from "../../../Common/Title";
import img from "../../../../Assets/Images/img-platform-contact-graph@3x_en.png";
import img_mobile from "../../../../Assets/Images/img-platform-contact-graph@3x.png";
import userIcon from "../../../../Assets/Images/e-img-platform-change-01@3x.png";
import globalIcon from "../../../../Assets/Images/e-img-platform-change-02@3x.png";
import downloadIcon from "../../../../Assets/Images/e-img-platform-change-03@3x.png";
import "./styles.scss";
function Intro() {
  return (
    <div className="platform-intro">
      <Title
        title={
          <>
           Whole new change through <span>CSbye</span>
          </>
        }
        detail={`CSbye is a powerful tool that drives innovation in customer service \n
        Monitor situations in real-time through data visualization and enable quick decision-making. \n
        This improves the efficiency and quality of customer service and helps to increase customer satisfaction.`}
      />
      <div className="platform-intro-img">
        <img src={img} alt="" />
      </div>
      <div className="platform-intro-img-mobile">
        <div className="topItems">
          <div className="topItem">
            <div className="topItem-tit">Customer company ‘A’</div>
            <div className="topItem-detail">
              <p>
                “It's too complicated because there are<br />too many inquiry pages such as phone,<br />e-mail, and Kakao consultation.”
              </p>
            </div>
          </div>
          <div className="topItem">
            <div className="topItem-tit">Customer company ‘B’</div>
            <div className="topItem-detail">
              <p>
                “Ah, I need a translation.<br />Do I need to hire someone?”
              </p>
            </div>
          </div>
          <div className="topItem">
            <div className="topItem-tit">Customer company ‘C’</div>
            <div className="topItem-detail">
              <p>
              “I had this problem before,<br />how did I solve it?”
              </p>
            </div>
          </div>
        </div>

        <div className="divide"></div>

        <div className="middleItem">
          <span>CSbye use</span>
        </div>

        <div className="divide"></div>

        <div className="bottomItems">
          <div className="bottomItem">
            <div className="icon">
              <img src={userIcon} alt="" />
            </div>
            <div className="text">
              <span>
              Expanding customer contact points,
              Reduce counselor's stress,
              Decrease in turnover rate.
              </span>
            </div>
          </div>
          <div className="bottomItem">
            <div className="icon">
              <img src={globalIcon} alt="" />
            </div>
            <div className="text">
              <span>
              Provide a starting point for entering the international market and reduce the cost of hiring foreign language workers
              </span>
            </div>
          </div>
          <div className="bottomItem">
            <div className="icon">
              <img src={downloadIcon} alt="" />
            </div>
            <div className="text">
              <span>
              Quick solving and preventing sudden issues with data that accumulates
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Intro;
