import Space from "Components/Atoms/Space/space";
import { ReactComponent as ModalX } from "Util/Svg/modalX.svg";

const PolicyModal = ({ setModal }: { setModal: any }) => {
  return (
    <div className="fixed p-center modal">
      <div className="flex justify-between">
        <h2>이용약관</h2>
        <button onClick={() => setModal(false)}>
          <ModalX />
        </button>
      </div>
      <Space y={24} />
      <p className="overflow-y-auto h-200">
        <h3>서비스 이용 약관</h3>
        공고일 : 2023년 9월 1일 | 시행일 : 2023년 9월 8일
        <br />
        귀하가 소속되어 있는 기업 및 조직에서 에드업과 오프라인 계약을
        체결하거나 대행업체와 별도의 계약을 체결한 경우, 아래 서비스 약관과 해당
        계약의 내용이 다를 수 있습니다. 해당 내용이 본 약관과 상충하는 경우 개별
        계약이 우선합니다. 자세한 내용은 기업 및 조직의 관리자에게 문의하시기
        바랍니다.
        <br />
        <br />
        <h4>1. 약관의 목적과 성립</h4>
        1.1. 본 “서비스약관(이하 약관)”은 에드업(이하 “회사”)이 제공하는
        서비스로서 소프트웨어(CSbye 플랫폼) 및 CSbye 서비스 (이하 “서비스”)를
        이용하고자 하는 개인 및 기업(이하 “귀하” 또는 “이용자”)과 회사 간 계약
        관계에서 발생하는 권리와 의무, 그 밖에 필요한 기본 사항을 규정합니다.
        <br />
        1.2. 귀하는 서비스에 가입하거나 이용함으로써, 본 약관을 읽고
        이해하였으며 동의하는 것으로 간주합니다. 귀하가 본 약관에 동의하지 않는
        경우, 서비스를 이용할 수 없습니다.
        <br />
        <br />
        <h4>2. 정의</h4>
        2.1. “서비스로서의 소프트웨어 (CSbye 플랫폼)” : 「클라우드컴퓨팅 발전 및
        이용자 보호에 관한 법률」 제2조 제1호에 따라 집적·공유된 정보통신기기,
        정보통신설비 등 정보통신자원을 가상으로 결합하거나 나누어 이용하게 하는
        클라우드 컴퓨팅 기술을 활용하여 제공되는 응용 소프트웨어입니다.
        <br />
        2.2. “응용소프트웨어”: 본 약관 조건에 따라 이용자에게 특별히 라이선스가
        부여된 회사의 독점 소프트웨어를 의미합니다.
        <br />
        2.3. “CSbye 서비스” : 이용자의 고객들의 문의 및 접수를 처리하는 종합
        고객지원 서비스(“CSbye 서비스”)로서, 웹 기반 플랫폼이나 회사가 제공하는
        또 다른 방법을 통해 제공되는 기타 서비스를 의미합니다.
        <br />
        2.4. “이용자 정보”: 서비스를 이용 하면서 이용자 계정에 축적되는 정보를
        의미합니다.
        <br />
        2.5. “개인 정보”: 개인 정보는 이용자 정보에 포함되며, 개인을 알아볼 수
        있는 정보입니다. 이름, 이메일 주소, 쿠키 정보 등이 이에 해당합니다.
        <br />
        2.6. “웹사이트”: www.csbye.com을 의미합니다.
        <br />
        2.7. “서비스 플랜”: 서비스 플랜은 CSbye 플랫폼의 사용 가능한 기능 범위에
        따라 나누어진 서비스 등급입니다. CSbye 플랫폼은 5가지 서비스
        플랜(베이직, 베이직플러스, 프리미엄, 스페셜, 커스터마이즈)을 제공합니다.
        베이직이 가장 기본 서비스 플랜이고, 스페셜이 가장 상위 플랜입니다.
        <br />
        2.8. “팀원”: 실제 서비스를 사용하는 개별 이용자로, 한 계정에는 서로 다른
        이메일 주소를 사용하는 다수의 팀원이 소속될 수 있습니다. 실제로 서비스에
        가입한 메일 주소 한 개가 한 명의 팀원입니다.
        <br />
        2.9. “시트”: 사용할 팀원 수 만큼 구입하는 대상입니다.
        <br />
        2.10. “이용자”: 실제 서비스를 사용하는 개인 및 조직을 의미합니다.
        <br />
        2.11. “업그레이드”: 기존 사용하는 서비스 플랜에서 상위 플랜으로 이동하는
        것을 의미합니다.
        <br />
        2.12. “다운그레이드”: 기존 사용하는 서비스 플랜에서 하위 플랜으로
        이동하는 것을 의미합니다.
        <br />
        2.13. “시트 수 추가”: 팀원 수를 상향 조정하는 것으로, 구매할 시트 수를
        늘리는 것입니다.
        <br />
        2.14. “시트 수 감소”: 팀원 수를 하향 조정하는 것으로, 구매할 시트 수를
        줄이는 것입니다.
        <br />
        <br />
        <h4>3. 서비스의 이용</h4>
        3.1. 이 약관에 포함되는 제한 사항에 따라 회사는 이용자에게 CSbye
        서비스에 접근하고 운영할 수 있도록 결제 내역에 명시된 기간 비독점적이고
        양도 불가능한 라이선스를 부여합니다.
        <br />
        3.2. 팀원당 라이선스가 부여됩니다. 이용자는 활동 팀원 수가 가입한 팀원
        수와 같거나 적음을 보장하여야 합니다. 이용자는 가입한 팀원 로그인 정보가
        공유되지 않도록 힘써야 합니다. 이용자는 이 라이선스 수를 줄이기 위해
        경로를 변경하는 행위(“멀티플렉싱” 또는 “풀링”이라고 함)를 하지 않습니다.
        <br />
        3.3. 서비스 이용
        <br />
         3.3.1. 이용자는 적합한 업무 용도로만 서비스를 이용하며, 계약된 기능을
        수행할 수 있는 제한된 권리와 라이선스가 부여됩니다.
        <br />
         3.3.2. 허가된 이용자에게만 이용이 허가됩니다. 본 약관에 달리 명시되지
        않는 한, “허가 된 이용자“는 i) 이용자의 직원, ii) 회사와 경쟁하지 않는
        이용자의 제 3자 계약자 (“허가된 계약자“) 에 제한합니다. 허가된 계약자는
        이용자의 영업소 또는 이용자에게 소속된 담당자의 관리하에 소프트웨어를
        이용할 수 있습니다. 이용자는 본 약관 및 관련 법에 따라 허가된 계약자의
        계약 이행 태만 및 위반에 대한 전적으로 책임을 집니다.
        <br />
        3.4. 이용자는 회사가 서비스 향상과 기능 개발에 필수적으로 필요한
        이용자의 정보에 접근, 이용, 재생산, 수정, 활용, 표시 및 배포할 수 있는
        비독점적 무상의 라이선스(이용권)를 회사에 부여합니다.
        <br />
        3.5. 라이선스 제한에 대한 추가 사항: 어떤 상황에서도 이용자는 응용
        소프트웨어 또는 기밀 정보를 분해, 디컴파일 또는 리버스엔지니어링 하는
        것을 허용하지 않습니다. 디컴파일 및 리버스엔지니어링은 다음 행위 들을
        포함하지만, 이들에만 제한되지 않습니다.
        <br />
         3.5.1. 응용 소프트웨어를 기계 가독형 데이터 형식에서 사람이 읽을 수
        있는 소스 코드 또는 그와 유사한 형태로 변환
        <br />
         3.5.2. 이를 이용하여 응용 소프트웨어를 분해하거나 디컴파일 하는 행위
        <br />
         3.5.3. 다양한 입력에 대한 응답으로 응용 소프트웨어의 동작을 연구하는
        등의 방법으로 응용 소프트웨어의 작동을 제어하고 원본 소스 코드 또는 그
        근삿값을 작성하는 기계 가독형 오브젝트 코드를 검사하는 행위
        <br />
         3.5.4. 기타 리버스 엔지니어링, 디스어셈블 또는 디컴파일이라고 보일 수
        있는 행위
        <br />
        <br />
        <h4>4. 서비스의 제공</h4>
        4.1. 서비스 가용성(Service Availability): 회사는 웹사이트에 게시되는
        “서비스 이행 수준 협약 (Service Level Agreement)” 에 따라 서비스 가용성
        목표를 달성하기 위해 합당한 노력을 기울일 것입니다.
        <br />
        4.2. 지원 서비스(Support Service): 이용자는 계약한 CSbye 서비스 및 CSbye
        플랫폼 플랜에 따라 에드업 팀의 차별화된 지원 서비스를 받을 수 있습니다.
        <br />
        4.3. 무료 서비스 제공: 회사는 일부 제한된 기능에 대하여 무료로 CSbye
        플랫폼 서비스를 사용할 수 있습니다. 무료로 사용하는 기능에 대해서도
        온라인 고객지원을 받으실 수 있습니다.
        <br />
        4.4. 베타 서비스의 제공: 서비스 향상을 위해 때에 따라 회사는 베타
        서비스를 무료로 제공할 수 있습니다.
        <br />
         4.4.1. 베타 서비스는 평가 목적으로 운영되는 것으로 본 약관에 따르는
        “서비스”의 일부로 간주하지 않습니다. 따라서 이에 대한 서비스 가용성 및
        지원 서비스를 받지 못할 수 있습니다.
        <br />
         4.4.2. 그러나 본 약관에서 제시하는 모든 제한 사항, 회사가 가지는
        서비스에 관한 권리, 이용자의 의무, 제 3의 소프트웨어 및 서비스 이용에
        대한 조건은 베타 서비스에도 동등하게 적용됩니다.
        <br />
         4.4.3. 회사는 회사의 재량에 따라 언제든지 베타 서비스를 중단할 수
        있습니다.
        <br />
         4.4.4. 베타 서비스와 관련하여 발생하는 어떠한 손해에 대해서도 회사는
        책임을 지지 않습니다.
        <br />
        4.5. 프로모션 서비스의 제공: 프로모션 크레딧의 발급, 구독료 할인, 또는
        가입 기간 연장 등과 같은 특정 혜택을 이용자에게 제공 할 수 있습니다.
        이러한 혜택은 이용자의 계정 및 이러한 혜택을 제공하는 동안 이용한 서비스
        플랜에 따라 다를 수 있습니다. 이 혜택에 대해서는 양도가 불가능하며,
        만료일이 있을 수 있습니다.
        <br />
        <br />
        <h4>5. 서비스 플랜 이용 기간, 변경 및 종료</h4>
        5.1. 서비스 플랜 결제일로부터 결제내역에 명시된 기간 서비스 이용에 대한
        권리가 유지됩니다.
        <br />
        5.2. 이용자에 의한 서비스 플랜 업/다운 그레이드와 시트 추가/감소
        <br />
         5.2.1. 이용자는 처음 구매한 서비스 플랜에서 현 계정 상태를 유지한 채로
        상위 플랜으로 업그레이드를 할 수 있습니다. 다운그레이드가 필요한 경우
        변경 신청 전 사전문의를 통해 서비스 변경이 진행되어야 하며, 사전문의
        없이 진행된 다운그레이드로 인해 발생되는 (서비스 내용, 기능 또는 용량의
        손실 등의) 이용자의 손실에 대한 회사는 책임을 지지 않습니다.
        <br />
         5.2.2 이용자는 서비스 사용 기간 언제든지 사용하는 플랜별 제한 범위
        내에서 ‘서비스ID’ 및 ‘시트‘를 추가하거나 감소시킬 수 있습니다. ‘시트’의
        경우 추가 구매가 가능하며, ‘서비스ID’의 경우에는 플랜 업그레이드를 통해
        추가해야 합니다.
        <br />
         5.2.3. 서비스 플랜 업그레이드 및 플랜별 제한 범위 내에서 ‘서비스ID’ 및
        ‘시트’ 추가 신청은 즉시 적용되며, 다운그레이드의 경우도 동일합니다.
        <br />
         5.2.4. 무료 체험 중 기능 사용은 서비스 플랜에 따른 범위 내에서 자유롭게
        사용 가능합니다.
        <br />
         5.2.5. 무료 체험 기간 중 가입한 계정으로 서비스 결제 시점에서 서비스
        플랜 업그레이드 사용이 가능합니다.
        <br />
        5.3. 회사에 의한 서비스 변경
        <br />
         5.3.1. 이용자는 이 약관에 동의함으로써 CSbye 서비스 이용 기간동안
        회사가 기능을 수정할 수 있음을 인정합니다.
        <br />
         5.3.2. 회사가 CSbye 서비스 이행 수준을 포함한 기존 서비스 기능을 변경할
        내용에 대해서는 사후 알릴 의무가 있습니다. 하지만 변경하고자 하는 내용이
        이용자에게 불리하거나 이용자의 업무에 미치는 영향이 큰 경우에는 변경
        14일 이전에 고지하고 동의를 구할 것입니다.
        <br />
        5.4. 회사가 이용자의 접근을 제한할 권리
        <br />
         5.4.1. 이용자 또는 이용자의 고객이 전자적 침해행위로 데이터의 손상,
        서버 정지 등을 초래하거나 이 외에 본 약관 규정을 위반하여 회사의 서비스
        운영 및 업무 수행에 현저한 지장을 주거나, 줄 수 있는 우려가 있는 경우에
        회사는 이용자의 서비스 이용을 제한할 수 있습니다.
        <br />
         5.4.2. 결제일에 결제가 이루어지지 않는 경우, 회사는 서비스 이용을 제한
        할 수 있으며, 해당 내용을 고지한 기간 내에 결제가 진행되지 않는 경우
        서비스를 해지할 수도 있습니다.
        <br />
         5.4.3. 회사와 사전 서면 동의가 있는 경우를 제외하고 이용자가 회사와
        직접적인 경쟁 위치에 있는 경우 서비스 접근을 제한할 수 있습니다.
        <br />
         5.4.4. 이용 제한의 사유가 발견된 경우, 회사가 이용자에게 일정 기간 내에
        해당 사유를 해소하기를 요구할 수 있으며, 해당 사유가 해소되지 않을 때에
        이용자의 계정 및 서비스를 해지 및 데이터를 삭제 할 수 있습니다.
        <br />
        5.5. 서비스 제공의 중단
        <br />
         5.5.1. 회사는 다음의 사항 중 어느 하나라도 해당하면 서비스 제공을
        중단할 수 있으며, 그 사유가 해소되면 지체 없이 서비스 제공을 재개할
        것입니다.
        <br />
          5.5.1.1. 서비스 개선을 위한 시스템 개선, 설비의 증설·보수·점검 및
        시설의 관리 등의 사유로 부득이하게 서비스를 제공할 수 없는 경우
        <br />
          5.5.1.2. 해킹 등 전자적 침해 사고나 통신사고 등으로 인해 예상하지 못한
        서비스의 불안전성에 대응하기 위하여 서비스 중단이 필요한 경우
        <br />
          5.5.1.3. 천재지변, 정전, 서비스 설비의 장애 등으로 인하여 정상적
        서비스 제공이 불가능한 경우
        <br />
         5.5.2. 회사는 5.5.1.1의 경우에 서비스 제공을 중단하기 전 7일까지 관련
        사실을 이용자에게 통지하여야 합니다. 다만, 5.5.1.2, 5.5.1.3의 경우에는
        사전 통지 없이 서비스를 중단할 수 있으나, 중단 후에는 지체 없이 관련
        사실을 이용자에게 통지할 것입니다.
        <br />
         5.5.3. 5.5.1.2. 에 따른 통지에는 중단 기간이 포함되어야 하고, 회사는 그
        기간을 초과한 경우에는 서비스 수준 협약에서 정한 대로 따릅니다.
        <br />
        5.6. 회사는 다음 중 어느 하나라도 해당하면 이용자의 서비스 접근을
        제한하거나 이용자의 계정을 해지할 수 있습니다. 이 경우에는 30일 전까지
        그 사유를 통지하고 회사가 정한 절차에 따라 이의 신청 기간을 드릴
        것입니다. 다만, 이용자에게 책임이 있는 사유로 통지가 불가능할 때에는
        회사는 사전 통지와 이의 신청의 기회 제공을 면할 수 있습니다.
        <br />
         5.6.1. 이용자가 제8항에서 정한 의무 사항을 위반한 경우
        <br />
         5.6.2. 제5.4항 따라 서비스의 이용 제한된 이용자의 경우, 해당 사유를
        요청한 기한 내에 해소하지 않는 경우
        <br />
         5.6.3. 계약 체결 후 서비스가 제공되기 전에 이용자가 파산 등의 사유로
        계약상의 의무를 이행할 수 없거나 그 의무의 이행이 현저하게 곤란한 경우
        <br />
         5.6.4. 이의 신청 내용이 정당하다고 판단되면 회사는 접근을 허가하고,
        이용자가 자신의 고의, 과실이 없었음을 입증한 경우 회사는 귀하에게 정지한
        기간만큼 이용 기간을 연장하여 서비스를 제공할 수 있습니다.
        <br />
        5.7. 이용자에 의한 서비스 이용 종료
        <br />
         5.7.1. 이용자는 가입 기간 종료 일 이전에 통보함으로써 언제든 소유한
        계정 및 서비스 가입을 해지할 수 있습니다. 이용자에 의해 해지할 경우,
        결제 내역에 명시된 기간 까지 서비스는 제공됩니다.
        <br />
         5.7.2. 1년간 로그인 기록이 없을 때 이용자에 의한 서비스 이용 종료로
        판단합니다.
        <br />
        5.8. 서비스 이용의 종료
        <br />
         5.8.1. 어떤 이유로든 서비스 이용이 종료되면, 회사는 이용자의 계정을
        해지할 수 있고, 이용자의 서비스에 대한 권리는 소멸합니다.
        <br />
         5.8.2. 서비스에 대한 이용 요금이 미지급 상태에 있으면, 이에 대한 지급이
        먼저 이루어져야 합니다. 회사의 귀책 사유로 인해 서비스 이용이 종료된
        경우 미지급금과 손해배상금은 상계처리하여 정산할 수 있습니다.
        <br />
         5.8.3. 서비스 이용의 종료 시, 이용자의 데이터는 이용자에 의해 삭제
        요청이 없는 한 개인정보 보호정책에서 명시한 일정 기간 남아있게 됩니다.
        또한 삭제 요청이 있다 하더라도 해당 정보가 관련 법률에서 요구하는 기간이
        있는 경우에는 해당 기간 삭제되지 않습니다. 삭제 이후에는 정보를 복구할
        수 없습니다.
        <br />
         5.8.4. 어떠한 이유로든 서비스 이용이 종료되는 경우, 이용자의 데이터는
        전자파일 형태로 이용자에게 제공될 수 있으며, 회사는 때에 따라 비용을
        청구할 수 있습니다.
        <br />
        5.9. 서비스 이용이 종료된다고 하더라도, 서비스 약관의 일부
        내용(9.,10.,11)은 유지됩니다.
        <br />
        <br />
        <h4>6. 제 3자 소프트웨어 이용</h4>
        6.1. 본 서비스에는 통보 의무 및 추가 이용 약관을 요구하는 제 3자
        소프트웨어 (Third Party Software)가 포함될 수 있습니다. 이럴 때 회사는
        이에 대한 동의를 요청할 수 있으며 이는 서비스 약관의 일부로 작성되거나
        참조로 포함될 수 있습니다. 이용자는 본 서비스 약관을 수락함으로써 부가
        조항 및 조건 또한 동의하는 것으로 동일하게 간주합니다.
        <br />
        6.2. 제 3자 소프트웨어의 판매 중단 등을 포함한 제 3자 소프트웨어 제공
        업체의 사정에 따라 CSbye 서비스 수준이 변경될 수 있으며, 이러 회사는
        특별한 사유가 없는 한 최단 기일 내에 변경된 사항에 대해서 통보할
        것입니다.
        <br />
        <br />
        <h4>7. 이용 요금의 납부 및 환불</h4>
        7.1. 해지 의사가 없는 경우, 유료 서비스 사용은 초기 유료 서비스를 신청한
        기간과 동일하게 자동 연장됩니다. 이를 위해 이용자는 서비스에 적용되는
        정기 가입 요금에 따라 지불 대행사(Payment Agent)가 주기적으로 이용자의
        신용카드를 포함하는 지불 수단에 미리 청구할 수 있는 권한을 부여함을
        동의합니다.
        <br />
         7.1.1. 신청한 사용 기간에 대한 이용 요금은 선납하셔야 합니다.
        <br />
         7.1.2. 정기 결제의 해지와 정기 결제 정보 변경은 서비스 내에서 상시
        가능합니다.
        <br />
         7.1.3. 이전 결제에 신청한 기간 유료 서비스 정기 결제를 해지하는 경우,
        남은 기간 동안 해당 서비스는 이용 가능합니다.
        <br />
         7.1.4. 선납한 사용 기간이 만료되기 전, 서비스 플랜 업그레이드 및 팀원
        수(시트 수) 추가를 신청한 경우 즉시 결제가 진행됩니다. 추가된 팀원 수에
        대해서만 다음 결제일까지 잔여일 만큼 일할 계산하여 선결제됩니다. 다음
        정기 결제일에 변경된 팀원 수에 대한 정상 금액이 결제되며, 정기 결제일은
        변경되지 않습니다.
        <br />
         7.1.5. 선납한 사용 기간이 만료되기 전, 팀원 수(시트 수) 감소를 신청한
        경우 다음 결제일에 해당 변경사항이 적용되어 결제됩니다.
        <br />
          7.1.5.1. 팀원 수(시트 수) 감소를 신청하였어도 다음 결제일에 팀원 수가
        변경 신청한 수를 초과한 경우 해당 시점의 팀원 수를 기준으로 결제됩니다.
        <br />
        7.2. 그러나 귀하가 특수한 경우에 신용카드 이외의 결제 방식이 필요한 경우
        대표문의 번호로 문의하시기 바라며, 이 때 행정 처리 프로세스로 인한 추가
        비용 및 시간이 소요될 수 있음을 미리 알려드립니다.
        <br />
        7.3. 회사는 최고의 CSbye 서비스를 위하여 새로운 기능에 대해 꾸준히
        개발하고 있습니다. 따라서 새롭게 추가되는 기능들에 따라서 가격이 변동될
        수 있습니다. 이로 인한 이용자의 업무에 미치는 영향을 최소화시키기 위해
        변동 사항에 대해서는 서비스 가격이 변동이 적용되어 자동결제가 이루어지기
        최소 30일 이전에 통지할 것입니다.
        <br />
        7.4. 환불
        <br />
         7.4.1. 「전자상거래 등에서의 소비자보호에 관한 법률」 제17조에 따라,
        유료 기능에 대한 충분한 시험사용 기간을 제공하고 있습니다. 따라서 CSbye
        서비스의 중대한 오류가 입증되지 않는 한 미 사용 기간에 대한 환불이
        불가합니다.
        <br />
         7.4.2. CSbye 서비스 자체의 중대한 오류로 인하여 본 약관의 목적을 달성할
        수 없을 때는 이미 납부된 이용요금에 대해 환불을 청구할 수 있습니다.
        <br />
          7.4.2.1 오류의 증명(오류 내용 및 기간을 포함)에 대한 의무는 이용자에게
        있으며, 해당 내용을 포함하여 환불에 대한 청구 의사를 표시하여
        contact@add-up.co.kr으로 보내주시기 바랍니다.
        <br />
          7.4.2.2. 오류가 발생한 날로부터 서비스를 사용하지 않은 남은 기간에
        대하여 이미 지불한 요금에서 일할 계산하여 환불을 청구할 수 있습니다.
        다만 이미 회사가 부담하였거나, 부담할 부대비용, 수수료를 차감하여 환불
        할 수 있습니다.
        <br />
          7.4.2.3. 이용 요금의 환불은 서비스 가입 및 계정이 유지된 상태에서
        지급이 가능합니다. 계약 기간이 종료되기 전에 이용자가 서비스 가입 및
        계정을 해지하기로 한 경우에는 이용자에게 지급되지 않습니다.
        <br />
         7.4.3. 서비스 플랜을 기존 결제 주기 내에 다운그레이드 한 경우, 다음
        결제일 까지 기존 서비스 플랜이 유지되기 때문에 기존 결제보다 더 적은
        금액이 소요될지라도, 이용자는 이전 결제에서 지불한 금액에 대해 환불을
        요구할 수 없습니다.
        <br />
         7.4.4. 유료서비스를 이용 중인 유료 이용자가 계정을 해지하는 경우
        유료서비스는 즉시 중지되며 환불은 불가능합니다. 이 경우 유료 이용자의
        정보와 서비스 이용 내용은 관련 법령이 정하는 경우를 제외하고는 이 약관에
        따라 처리됩니다.
        <br />
        <br />
        <h4>8. 이용자의 의무</h4>
        8.1. 이용자는 이용자가 사용하는 유료 서비스 요금에 대한 납부의 의무가
        있습니다.
        <br />
        8.2. 이용자는 서비스를 이용하는 과정에서 저작권 법 등 관련 법령을
        위반하거나, 선량한 풍속, 기타 사회 질서에 반하는 행위를 하여서는 안
        됩니다.
        <br />
        8.3. 이용자의 아이디 및 비밀번호 등 서비스 접속 정보에 대한 관리 책임이
        있으며, 이용자가 이에 대한 주의 의무를 소홀히 하여 발생한 이용자 정보의
        도용 및 이용자의 고객 개인 정보 이용 문제는 이용자에게 책임이 있습니다.
        <br />
        8.4. 이용자는 본 약관의 규정, 이용 안내 및 서비스와 관련하여 회사로부터
        통지받은 제반 사항과 합의된 내용을 준수하여야 합니다.
        <br />
        8.5. 이용자는 회사의 동의 없이 제 3자에게 서비스를 임의 제공하거나
        계약상의 권리 및 의무를 제 3자에게 처분할 수 없습니다.
        <br />
        8.6. 이용자의 계정 정보에 대해 변경사항(예: 청구서 수신 주소 또는 신용
        카드 만료일 변경)이 발생한 경우 신속하게 개정하셔야 하며, 이를 이행하지
        않아 발생한 문제에 대해서는 회사가 책임질 수 없습니다.
        <br />
        8.7. 이용자는 이용자의 고객에게 CSbye 서비스 사용을 위해 개인 정보를
        포함한 이용자 정보의 제 3자 위탁에 대한 동의를 받아야 합니다.
        <br />
        <br />
        <h4>9. 저작권법</h4>
        9.1. 회사는 타인의 지식재산을 존중하며, 이용자에게도 이러한 권리를
        존중해 달라고 요청합니다. 관련 법에 부합하고, 회사의 저작권 정책에 따라
        저작권 침해에 대한 알림 및 통지를 하는 경우 신속하게 대응합니다.
        <br />
        9.2. 회사는 침해 혐의가 있는 콘텐츠를 삭제 및 차단하고, 침해를 반복하는
        이용자의 계정을 해지할 수 있는 권한이 있습니다.
        <br />
        9.3. 이용자는 서비스의 저작권 침해가 있다고 판단되는
        경우, contact@add-up.co.kr으로 통지하실 수 있습니다.
        <br />
        9.4. 기업 상표의 사용
        <br />
         9.4.1. 서비스 사용 여부와 상관없이 회사 및 이용자의 상표, 상호,
        트레이드마크 및 로고 등(이하 “상표“)은 각 소유권자의 독점적인 재산으로
        모든 권리를 소유합니다.
        <br />
         9.4.2. 회사는 **(i)**제품 설명서, 보도 자료, 소셜 미디어 및 기타 마케팅
        자료에 이용자의 상표를 사용할 수 있습니다. **(ii)**하나 이상의
        보도자료에서 귀하의 진술을 인용할 수 있습니다. (iii) (i), (ii) 외의
        목적으로 이용자의 상표를 사용할 경우 동의를 요청할 것입니다.
        <br />
         9.4.3. 회사는 이용자의 상표를 일반 판촉 목적으로 회사의 고객 목록에
        포함 시킬 수 있습니다.
        <br />
         9.4.4. 회사는 이용자의 상표 사용 지침을 준수할 것이며, 이 사용 지침의
        교부를 이용자에게 요청할 수 있습니다.
        <br />
        <br />
        <h4>10. 기밀 유지</h4>
        10.1. 이용자와 회사는 서로의 계열사, 고객, 직원, 투자자를 포함하는 모든
        관계자의 사업 및 소프트웨어에 관하여 기밀 정보로 식별된 기술, 제품,
        사업, 재무 및 기타 정보(이하 “기밀 정보“)를 기밀로 유지할 것에
        동의합니다.
        <br />
         10.1.1 기밀정보에는 개인 식별 정보가 포함되지 않습니다. 개인 식별
        정보는 개인정보처리방침에 따라 보호됩니다.
        <br />
        10.2. 이용자는 기밀 정보를 항상 보호하며 기밀 정보의 전부 또는 일부를 제
        3자에게 공개, 전달 및 전송하지 않을 것에 동의합니다. 본 약관에 따로
        명시되어 있는 경우를 제외하고, 이용자와 회사는 자신의 목적이나 제 3자의
        이익을 위해 어떠한 기밀 정보를 사용하지 않습니다.
        <br />
        10.3. 이용자나 회사의 요청으로 이용자 또는 회사는 소유하고 있는 기밀
        정보(사본 포함)의 모든 문서, 메모 또는 기타 물리적인 실시 예 및 내용을
        즉시 공개 요청한 당사자(이용자 또는 회사)에게 제공해야 합니다.
        <br />
        <br />
        <h4>11. 이용자 정보 보호</h4>
        11.1. 에드업은 관련 법령이 정하는 바에 따라 이용자의 정보를 보호하고
        관리합니다. 이용자 정보의 보호 및 이용에 대해서는 관련 법령 및 별도로
        고지하는 개인정보처리방침이 적용됩니다.
        <br />
        11.2. 계정 해지되거나 기간 만료 등의 사유로 서비스 사용이 종료되면
        개인정보처리방침에서 고지하는 대로 일정 기간 이후에 이용자의 정보를
        파기합니다.
        <br />
        <br />
        <h4>12. 손해배상</h4>
        12.1. 회사의 고의 또는 과실로 서비스 장애를 발생시키거나 서비스 수준
        협약에서 정한 품질 및 성능에 미달하는 서비스 제공으로 인해 이용자의
        권리를 침해하고 손해를 발생시킨 경우에는 그 손해를 배상합니다.
        <br />
        12.2. 회사의 고의 또는 과실로 발생한 개인 정보 유출에 대해 회사는 그에
        따른 손해를 배상합니다.
        <br />
        12.3. 손해배상의 총액은 이용자가 마지막으로 결제한 서비스 사용 기간에
        지불한 총액을 넘지 않습니다.
        <br />
        12.4. 서비스가 이용자의 권리를 침해하거나 회사의 견해로 CSbye 서비스가
        이용자의 권리 침해 가능성이 있다고 판단되는 경우, 회사는 더는 이용자의
        권리를 침해하지 않도록 수정할 것 입니다.
        <br />
        12.5. 이용자의 고의 또는 과실로 회사가 손해를 입으면 이용자는 그 손해를
        배상하여야 합니다.
        <br />
        <br />
        <h4>13. 면책</h4>
        13.1. 회사는 다음의 사유로 인하여 발생한 손해에 대하여는 책임을
        면합니다.
        <br />
         13.1.1. 부득이한 사유로 서비스 제공 중단의 경우
        <br />
          13.1.1.1. 서비스 개선을 위해 시스템 개선, 설비의 증설, 보수 및 점검,
        시설의 관리 및 운용 등의 사유로 부득이하게 서비스를 제공할 수 없는 경우
        <br />
          13.1.1.2. 해킹 등 전자적 침해 사고나 통신사고 등 예상하지 못한
        서비스의 불안전성에 대응하는 데 필요한 경우
        <br />
          13.1.1.3. 정전, 서비스 설비의 장애 등으로 인하여 정상적인 서비스
        제공이 불가능한 경우
        <br />
         13.1.2. 이용자의 고의 또는 과실로 인한 서비스의 중단, 장애 및 계약
        해지의 경우
        <br />
         13.1.3. 기간통신사업자가 전기통신서비스를 중지하거나 정상적으로
        제공하지 않아 이용자에게 손해가 발생한 것에 대하여 회사에 고의 또는
        과실이 없는 경우
        <br />
         13.1.4. 이용자의 컴퓨터 환경이나, 회사의 고의 또는 과실이 없는 네트워크
        환경으로 인하여 부가적인 문제가 발생한 경우
        <br />
         13.1.5. 이용자의 신상 정보 및 전자우편 주소의 부정확한 기재 등으로
        이용자에게 발생한 손해에 대하여 회사의 고의 또는 과실이 없는 경우
        <br />
         13.1.6. 이용자와 다른 이용자 간, 또는 이용자와 제3자 간에 지식재산권
        침해로 발생한 분쟁으로 인한 손해에 대하여 회사의 고의 또는 과실이 없는
        경우
        <br />
         13.1.7. 권리 침해 주장을 일으킬 수 있는 정보를 활용하여 이용자가
        소프트웨어를 사용한 경우
        <br />
         13.1.8. 본 약관의 허용 범위를 벗어나 이용자가 서비스를 활용한 경우
        <br />
         13.1.9. 회사 이외의 당사자가 수정한 소프트웨어로 침해를 주장한 경우
        <br />
        13.2. 회사는 이용자와 다른 이용자 간, 또는 이용자와 제3자 간에 서비스를
        매개로 발생한 분쟁에 대해 다음 사항에 하나라도 해당하는 경우에는 이로
        인해 발생한 손해에 대하여 책임을 면합니다.
        <br />
         13.2.1. 회사가 「클라우드컴퓨팅 발전 및 이용자 보호에 관한 법률」의
        규정을 위반하지 아니한 경우
        <br />
         13.2.2. 회사가 고의 또는 과실이 없음을 증명한 경우
        <br />
         13.2.3. 다른 이용자 또는 제 3자가 이용자의 권리를 침해하는 때에 회사가
        그 침해 행위를 통제할 권한과 능력이 없는 경우
        <br />
         13.2.4. 다른 이용자 또는 제 3자가 이용자의 권리를 침해하는 때에 회사가
        침해 행위를 통제할 권한과 능력이 있더라도 그 침해 행위로부터 직접적인
        금전적 이익을 얻지 아니한 경우
        <br />
         13.2.5. 다른 이용자 또는 제 3자가 이용자의 권리를 침해하는 때에 회사가
        그 침해 사실을 알았거나 또는 침해가 명백하다는 사실 또는 그 정황을 알게
        된 즉시 그 침해 행위를 중단시킨 경우
        <br />
        <br />
        <h4>14. 기타</h4>
        14.1. CSbye 서비스는 구글 크롬 환경에 최적화되어있습니다. 따라서 이외의
        인터넷 브라우저에서 최적의 서비스가 구현되지 못하는 상황이 발생할 수
        있습니다. 이로 인한 문제에 회사는 책임을 다하기 위해 개선의 노력을 하고
        있습니다.
        <br />
        14.2. 약관의 변경: 본 약관은 변경될 수 있으며, 변경된 약관 및 이전
        약관은 귀하가 언제든지 CSbye 웹페이지 내에서 확인 하실 수 있습니다.
        <br />
         14.2.1. 회사가 약관을 개정할 경우에는 시행 일자 및 개정 사유를 명시하여
        현행 약관과 함께 개정 약관의 적용 일자 7일 전부터 적용 일자 전일까지
        귀하의 이메일 주소로 공지합니다. 단 이용자의 권리, 의무에 중대한 영향을
        주는 변경의 경우에는 적용일 30일 전부터 공지하도록 합니다. 이용자의
        이메일 주소 변경 등으로 인하여 개별 통지가 어려운 경우, 이용자의 이메일
        주소로 공지를 하였음에도 반송된 경우 이 약관에 의한 공지를 함으로써 개별
        통지한 것으로 간주합니다.
        <br />
         14.2.2. 회사가 14.2.1.항의 공지 또는 통보를 하면서 ‘개정 약관의 시행
        일까지 이용자가 거부 의사를 표시하지 아니하면 약관의 개정에 동의한
        것으로 간주한다’라는 내용을 고지하였으나, 이용자가 명시적으로 약관
        변경에 대한 거부 의사를 표시할 수 있습니다. 개정 약관에 대하여 거부
        의사를 표시한 이용자는 계정 해지를 선택할 수 있습니다.
        <br />
        14.3. 양도 등의 제한: 회사와 이용자 모두 이 계약에 따른 권리와 의무 전부
        또는 일부를 상대방의 사전 동의 없이 제 3자에게 양도 또는 담보로 제공할
        수 없습니다.
        <br />
        14.4. 제 3자의 이용: 회사는 이 약관의 목적을 달성하기 위해 서비스 제공
        업체의 계열사 및 자회사의 직원을 포함하여 제 3자를 이용하여 본 계약의
        의무 및 서비스를 수행할 권리를 가집니다. 이 경우 이로 인하여 발생하는
        모든 책임은 회사가 부담합니다.
        <br />
        14.5. 관할 법원: 회사와 귀사 간에 발생한 분쟁으로 소송이 제기되는
        경우에는 민사소송법이 정한 법원을 관할법원으로 합니다. 당사자 한쪽이
        외국 사업자인 경우에는 대한민국 법원이 국제 재판 관할권을 가집니다.
        <br />
        14.6. 준거법: 이 계약의 성립, 효력, 해석 및 이행과 관련하여서는 대한민국
        법을 적용합니다.
        <br />
        <br />
        <h3>[필수] 개인정보 수집 및 이용 동의</h3>
        수집·이용 목적 : 이용자 식별 및 본인여부 확인, 서비스 제공
        <br />
        수집하는 개인정보 항목 : 이름, 이메일주소, 휴대전화번호
        <br />
        보유기간 : 회원 탈퇴 시까지
        <br />
        이는 ㈜에드업이 제공하는 서비스를 이용하기 위해서 수집 및 이용이 필요한
        개인정보입니다. 해당 항목에 동의를 거부할 권리가 있으며, 동의를 거부할
        경우 서비스 이용이 제한됩니다.
        <br />
        <br />
        <h3>[선택] 마케팅 활용 및 광고성 정보 수신 동의</h3>
        전자적 전송매체(SMS/MMS/이메일 등)를 통해, ㈜에드업이 제공하는
        이벤트/혜택 등 다양한 정보(뉴스레터 포함)를 수신하실 수 있고, 기타
        유용한 광고나 정보를 수신하실 수 있습니다. 본 마케팅 활용 및 광고성
        정보수신 동의 항목은 선택사항이므로 동의를 거부하는 경우에도 ㈜에드업
        서비스의 이용에는 영향이 없습니다. 다만 거부 시 동의를 통해 제공 가능한
        각종 혜택, 이벤트 안내를 받아 보실 수 없습니다. 본 수신 동의를
        철회하고자 할 경우에는 고객센터를 통해 언제든 수신동의 철회를 요청하실
        수 있습니다.
      </p>
    </div>
  );
};

export default PolicyModal;
