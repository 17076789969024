import React from "react";
import img from "../../../../Assets/Images/img-customer-banner@3x.png";
import img1 from "../../../../Assets/Images/ic-customer-phone@3x.png";
import img2 from "../../../../Assets/Images/ic-customer-time@3x.png";
import Button from "../../../Common/Button";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../../Modules/routesString";
import * as Scroll from "react-scroll";
function HeroSectionCustomer() {
  const navigation = useNavigate();
  const scroll = Scroll.animateScroll;
  return (
    <div className="heroSectionCustomer">
      <div className="section">
        <div className="section-container">
          <div className="section-container-content">
            <div className="content">
              <div className="title">
                <p>有什麼能幫到您的呢?</p>
              </div>
              <div className="detail">
                <p>{`請確認直接聯繫我們或查看我們的常見問題 (FAQ) 以便更快地找到答案。\n 關於CSbye的一切！`}</p>
              </div>
              <a href="https://add-up.csbye.com/menu/342">
                <div className="button">
                  <Button onClick={() => {}} rightIcon text="1:1 諮詢" />
                </div>
              </a>
            </div>
            <div className="img">
              <img src={img} alt="" />
            </div>
          </div>

          <div className="contact">
            <div className="contact-item">
              <div className="contact-item-img">
                <img src={img1} alt="" />
              </div>
              <div className="contact-item-content">
                <div className="subTit">總機號碼</div>
                <div className="title">1670 - 2055</div>
              </div>
            </div>

            <div className="contact-item">
              <div className="contact-item-img">
                <img src={img2} alt="" />
              </div>
              <div className="contact-item-content">
                <div className="subTit">營業時間</div>
                <div className="content-detail">
                  <span>平日</span>09:00~18:00
                </div>
                <div className="content-detail">
                  <span>午休</span>13:00~14:00
                </div>
              </div>
              <div className="contact-item-note">*週末及節假日休息</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSectionCustomer;
