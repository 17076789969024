import React, { useState } from "react";
import iconUp from "../../../../../Assets/Images/ic-qna-up-arrow@3x.png";
import iconDown from "../../../../../Assets/Images/ic-qna-down-arrow@3x.png";
import "./styles.scss";
function AccordionData({ title, content }: { title: string; content: string }) {
  const [isActive, setIsActive] = useState(false);
  return (
    <React.Fragment>
      <div className="accordion">
        <div className="accordion-item">
          <div
            onClick={() => setIsActive(!isActive)}
            className={`accordion-title ${
              isActive ? "accordion-title-active" : ""
            }`}
          >
            <div>{title}</div>
            <div className="accordion-img">
              {isActive ? (
                <img src={iconUp} alt="" />
              ) : (
                <img src={iconDown} alt="" />
              )}
            </div>
          </div>
          {isActive && <div className="accordion-content">{content}</div>}
        </div>
      </div>
    </React.Fragment>
  );
}

export default AccordionData;
