import { RoutesString } from "../Components/Modules/routesString";
import PageErr from "../Components/Pages/404/PageErr";
import Customer from "../Components/Pages/Customer";
import Home from "../Components/Pages/Home";
import IntroPage from "../Components/Pages/Intro";
import Platform from "../Components/Pages/Platform";
import Price from "../Components/Pages/Price";
import Privacy from "../Components/Pages/Privacy";

import choice from "../Components/Pages/Choice";
import domainLogin from "../Components/Pages/DomainLogin";
import join from "../Components/Pages/Join/join";
import joinEmailCheck from "../Components/Pages/Join/emailCheck";
import companyJoin from "../Components/Pages/Join/companyJoin";
import authCom from "../Components/Pages/Authcom/authCom";
import star from "../Components/Pages/Star";
import free from "../Components/Pages/Free";
import member from "../Components/Pages/member";

// EN
import Customer_en from "../Components/Pages/Customer_en";
import Home_en from "../Components/Pages/Home_en";
import IntroPage_en from "../Components/Pages/Intro_en";
import Platform_en from "../Components/Pages/Platform_en";
import Price_en from "../Components/Pages/Price_en";

import free_en from "../Components/Pages/Free_en";
import member_en from "../Components/Pages/member_en";

// JP
import Customer_jp from "../Components/Pages/Customer_jp";
import Home_jp from "../Components/Pages/Home_jp";
import IntroPage_jp from "../Components/Pages/Intro_jp";
import Platform_jp from "../Components/Pages/Platform_jp";
import Price_jp from "../Components/Pages/Price_jp";

import free_jp from "../Components/Pages/Free_jp";
import member_jp from "../Components/Pages/member_jp";

// CN
import Customer_cn from "../Components/Pages/Customer_cn";
import Home_cn from "../Components/Pages/Home_cn";
import IntroPage_cn from "../Components/Pages/Intro_cn";
import Platform_cn from "../Components/Pages/Platform_cn";
import Price_cn from "../Components/Pages/Price_cn";

import free_cn from "../Components/Pages/Free_cn";
import member_cn from "../Components/Pages/member_cn";

// TW
import Customer_tw from "../Components/Pages/Customer_tw";
import Home_tw from "../Components/Pages/Home_tw";
import IntroPage_tw from "../Components/Pages/Intro_tw";
import Platform_tw from "../Components/Pages/Platform_tw";
import Price_tw from "../Components/Pages/Price_tw";

import free_tw from "../Components/Pages/Free_tw";
import member_tw from "../Components/Pages/member_tw";

const pages = [
  {
    path: RoutesString.PageNotFound,
    component: PageErr,
    exact: true,
  },
  {
    path: RoutesString.Home,
    component: Home,
    exact: true,
  },
  {
    path: RoutesString.Intro,
    component: IntroPage,
    exact: true,
  },

  {
    path: RoutesString.Platform,
    component: Platform,
    exact: true,
  },

  {
    path: RoutesString.Price,
    component: Price,
    exact: true,
  },
  {
    path: RoutesString.Customer,
    component: Customer,
    exact: true,
  },
  {
    path: RoutesString.Privacy,
    component: Privacy,
    exact: true,
  },

  {
    path: RoutesString.authCom,
    component: authCom,
    exact: true,
  },
  {
    path: RoutesString.choice,
    component: choice,
    exact: true,
  },
  {
    path: RoutesString.domainLogin,
    component: domainLogin,
    exact: true,
  },
  {
    path: RoutesString.join,
    component: join,
    exact: true,
  },
  {
    path: RoutesString.joinEmailCheck,
    component: joinEmailCheck,
    exact: true,
  },
  {
    path: RoutesString.companyJoin,
    component: companyJoin,
    exact: true,
  },
  {
    path: RoutesString.star,
    component: star,
    exact: true,
  },
  {
    path: RoutesString.free,
    component: free,
    exact: true,
  },
  {
    path: RoutesString.member,
    component: member,
    exact: true,
  },

  // EN
  {
    path: RoutesString.Customer_en,
    component: Customer_en,
    exact: true,
  },
  {
    path: RoutesString.Home_en,
    component: Home_en,
    exact: true,
  },
  {
    path: RoutesString.Intro_en,
    component: IntroPage_en,
    exact: true,
  },
  {
    path: RoutesString.Platform_en,
    component: Platform_en,
    exact: true,
  },
  {
    path: RoutesString.Price_en,
    component: Price_en,
    exact: true,
  },
  {
    path: RoutesString.free_en,
    component: free_en,
    exact: true,
  },
  {
    path: RoutesString.member_en,
    component: member_en,
    exact: true,
  },

  // jp
  {
    path: RoutesString.Customer_jp,
    component: Customer_jp,
    exact: true,
  },
  {
    path: RoutesString.Home_jp,
    component: Home_jp,
    exact: true,
  },
  {
    path: RoutesString.Intro_jp,
    component: IntroPage_jp,
    exact: true,
  },
  {
    path: RoutesString.Platform_jp,
    component: Platform_jp,
    exact: true,
  },
  {
    path: RoutesString.Price_jp,
    component: Price_jp,
    exact: true,
  },
  {
    path: RoutesString.free_jp,
    component: free_jp,
    exact: true,
  },
  {
    path: RoutesString.member_jp,
    component: member_jp,
    exact: true,
  },

  // CN
  {
    path: RoutesString.Customer_cn,
    component: Customer_cn,
    exact: true,
  },
  {
    path: RoutesString.Home_cn,
    component: Home_cn,
    exact: true,
  },
  {
    path: RoutesString.Intro_cn,
    component: IntroPage_cn,
    exact: true,
  },
  {
    path: RoutesString.Platform_cn,
    component: Platform_cn,
    exact: true,
  },
  {
    path: RoutesString.Price_cn,
    component: Price_cn,
    exact: true,
  },
  {
    path: RoutesString.free_cn,
    component: free_cn,
    exact: true,
  },
  {
    path: RoutesString.member_cn,
    component: member_cn,
    exact: true,
  },

  // TW
  {
    path: RoutesString.Customer_tw,
    component: Customer_tw,
    exact: true,
  },
  {
    path: RoutesString.Home_tw,
    component: Home_tw,
    exact: true,
  },
  {
    path: RoutesString.Intro_tw,
    component: IntroPage_tw,
    exact: true,
  },
  {
    path: RoutesString.Platform_tw,
    component: Platform_tw,
    exact: true,
  },
  {
    path: RoutesString.Price_tw,
    component: Price_tw,
    exact: true,
  },
  {
    path: RoutesString.free_tw,
    component: free_tw,
    exact: true,
  },
  {
    path: RoutesString.member_tw,
    component: member_tw,
    exact: true,
  },
];

export default pages;
