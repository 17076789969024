import { useState } from "react";
import PaginatedItems from "../../../Common/Pagination";
import AccordionData from "./AccordionData";
import "./styles.scss";
import { tab } from "@testing-library/user-event/dist/tab";

function Question() {
  const [tabActive, setTabActive] = useState(0);

  return (
    <div className="question">
      <div className="question-title">常見問題</div>
      <div className="question-tabs">
        {tabs.map((item, index) => (
          <div
            onClick={() => {
               setTabActive(index);
            }}
            key={index}
            className={`tab ${tabActive === index ? "activeTab" : ""}`}
          >
            {item.tit}
          </div>
        ))}
      </div>
      

      <div className="question-accordion">
        
        {accordionData.map(({ type,title, content }, index) => (
          tabActive === 0 ? <AccordionData key={index} title={title} content={content} />
          :
          tabActive === type && <AccordionData key={index} title={title} content={content} />
        ))}
      </div>


      {/* <div className="question-pagination">
        <PaginatedItems />
      </div> */}
    </div>
  );
}

export default Question;

const tabs = [
  {
    id : 0,
    tit: "全部",
    router: "",
  },
  {
    id : 1,
    tit: "運營管理服務",
    router: "",
  },
  {
    id : 2,
    tit: "平台服務",
    router: "",
  },
  {
    id : 3,
    tit: "結算及退款",
    router: "",
  }
];

const accordionData = [
  //자주찾는 질문
  {
    type : 1,
    title: "Q. 什麼是CSBYE?",
    content: `A. ‘CSBYE’現有ADD-UP的服務CS, QA, 營銷和監控服務的的綜合名稱, 在顧客運營管理(CS)上附加意味著離別的"BYE", \n以便幫助您免除客戶管理的後顧之憂，是ADD-UP服務的新名稱。`,
  },
  {
    type : 1,
    title: "Q. 營銷服務中只使用必要的服務也可以嗎？",
    content: `A. CSBYE服務只能使用企業需要的服務。欲了解更多詳情，通過諮詢聯繫我們。`,
  },
  {
    type : 1,
    title: "Q. 比起CSBYE服務，能只使用平台嗎？",
    content: `A. 利用CSBYE平台為想要自行管理顧客的企業提供CSBYE平台服務，您可以在上端的詢價欄中找到有關使用平台的信息。
    `,
  },
  {
    type : 1,
    title: "Q. 我想體驗CSBYE平台！",
    content: `A. 您可以通過CSBYE主頁上端右側的“免費試用”進行頁面免費試用。
    免費體驗構成是PREMIUM商品, 包含30天的使用期，以確保企業管理者的充分審查和順利使用。`,
  },
  {
    type : 1,
    title: "Q. 在哪裡諮詢CSBYE平台？",
    content: `A. 請通過客戶諮詢頁面頂部的"聯繫我們"發送內容。確認後進行回复！`,
  },
  {
    type : 1,
    title: "Q. CSBYE平台是否可以提供諮詢員支持？",
    content: `A. CSBYE平台僅支持月費平台，因此很難支持提供諮詢員。但是，如果您在CSBYE服務中聯繫CS管理，我們將提供相關信息。`,
  },
  //플랫폼 서비스
  {
    type : 2,
    title: "Q. 怎樣註冊呢?",
    content: `A. 可通過CSBYE主頁上端登錄頁面註冊會員。`,
  },
  {
    type : 2,
    title: "Q, 企業管理者的賬戶可以轉讓嗎？",
    content: `A. 平台內的個人信息修改可以授權。
    委託給註冊的諮詢員中的一人, 原有企業管理者變更為諮詢員。`,
  },
  {
    type : 2,
    title: "Q. 當諮詢員休假的時候，諮詢自動分配是什麼情況？",
    content: `A. 此功能僅針對特殊產品可用，諮詢員在個人信息修改中直接設定休假或企業管理者在諮詢員管理中向相關諮詢員設置休假，\n則不包括諮詢的自動分配。`,
  },
  {
    type : 2,
    title: "Q. 哪裡可以查看被列入黑名單的原因？",
    content: `A. 可以在客戶名單管理菜單中確認客​​戶信息。
    請選擇黑名單查詢客戶。原因會自動輸入到客戶備忘錄歷史記錄中。

    `,
  },
  {
    type : 2,
    title: "Q. 如何使用通知服務和SMS傳輸?",
    content: `A. 你可以通過Telegram收到諮詢通知。
    請在通知服務設置中輸入Telegram代幣和Chat ID。
    SMS可以利用ALIGO發送。並輸入您的ALIGO API 密鑰。`,
  },
  //결제 및 환불
  {
    type : 3,
    title: "Q. 什麼是坐席？",
    content: `A. 坐席是可以使用的諮詢員數。
    只有根據諮詢員人數提前購買使用坐席數,才能進行諮詢處理。
    可以自由註冊、刪除諮詢員, 但只有批准狀態的諮詢員才能使用功能。只有處於批准狀態的諮詢員才能佔用坐席。`,
  },
  {
    type : 3,
    title: "Q. 我能在哪裡結算?",
    content: `A. 註冊CSBYE服務會員後，可在服務內結算。
    請參考價格介紹頁面並使用適合您企業的計劃。`,
  },
  {
    type : 3,
    title: "Q. 如何改變商品或坐席數量?",
    content: `A. 平台內信息>結算現狀>商品變更中可以結算。
    改變商品或改變坐席時，取消現有商品後重新結算已改變的商品。`,
  },
  {
    type : 3,
    title: "Q. 如果付款逾期怎麼辦？",
    content: `A. 付款日未正常付款的，視為逾期。

    •逾期後：不再受理該客戶的票券。
   請在登錄後出現的計劃選擇中進行結算。
    
  •逾期7天：賬戶將被關閉，將無法繼續登錄。
   諮詢CSBYE的話，關閉的賬號會臨時打開7天。
   之後，登錄並在指定的時間內在信息>結算現狀中繼續進行計劃結算。
    
 •1年以上: 判斷為閒置的賬戶，發送通知郵件後刪除該賬戶。`,
  },
  {
    type : 3,
    title: "Q. 如何取消產品訂閱？",
    content: `A. 您可以在信息 > 結算現狀中取消產品自動付款。該服務可以使用到最近商品結算期後的一個月內。`,
  },
  {
    type : 3,
    title: "Q. 想知道退款規定。",
    content: `A. 為您介紹退款規定。
    1. 結算服務使用費後，使用服務前，若因消費企業單純改變主意或其他原因取消服務使用，該金額將按照使用期限結算後進行退款處理。
    2. 用戶在使用服務後因不得已的原因想要中斷服務使用時，將相關意向傳達給供應企業，供應企業立即停止提供服務並退還剩餘服務期限的服務費。
    3. 如果在付款期限過後取消（退款）先前付費的服務產品，取消（退款）金額將包含在退款中。
    
    取消訂單時退款金額的時間
    · 信用卡: 自回收完成之日起3個工作日內取消信用卡批准
    · 儲蓄卡 : 自回收完成之日起3個工作日內匯入已取款的銀行卡賬戶
    · 虛擬賬戶 : 自回收完成之日起3個工作日內匯入退款賬戶
    · 其他: 自商品回收完成之日起3個工作日內退款`
  }
];
