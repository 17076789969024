import React from "react";
import Title from "../../../Common/Title";
import imgM from "../../../../Assets/Images/ic-price-basic.png";
import img1 from "../../../../Assets/Images/60_60 01.png";
import img2 from "../../../../Assets/Images/60_60 02.png";
import img3 from "../../../../Assets/Images/60_60 03.png";
import img4 from "../../../../Assets/Images/60_60 04.png";
import img5 from "../../../../Assets/Images/60_60 05.png";
import img6 from "../../../../Assets/Images/60_60 06.png";
import img7 from "../../../../Assets/Images/60_60 07.png";
import "./styles.scss";
import Button from "../../../Common/Button";
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../../Modules/routesString";
import * as Scroll from "react-scroll"


function FREEservice() {
  const navigation = useNavigate()
  const scroll = Scroll.animateScroll
  return (
    <div className="FREEservice">
      <div className="FREEservice-container">
        <Title
          title={
            <>
              <span style={{ color: "#fff" }}> CSbye 플랫폼 Basic</span>
            </>
          }
          detail={'시작하는 스타트업을 위해, CRM플랫폼 무료버전!'}
          
        />
         <div className="FREEVversion">
            <div className="FREEVversion22">
<ul className="basic_M">
<li className="imgMM">
<img src={imgM} alt="" />
</li>
<li>
<ul className="basic__M">
<li className="BASIC"><span style={{ color: "#fff" }}>Basic</span> </li>
<li className="BASIC22">- 기본 제공 기능 -</li>
<li className="BASIC33">서비스 등록 1개</li>
<li className="BASIC33">등록 상담원(관리자) 1명</li>
<li className="BASIC33">일일 답변 개수 최대 60건 가능</li>


</ul>
</li>

</ul>

</div>
        </div>


        <ul className="FREEservice-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="title">{item.title}</div>
              <hr className="linee"></hr>
              <div className="detail">{item.detail}</div>
            </li>
          ))}
        </ul>
        <a href="https://www.csbye.com/domainlogin">
        <div className="FREEservice-container-button">
                <Button onClick={() => {
                  }} rightIcon text="CSbye 플랫폼 무료로 시작하기" />
            </div> </a>



      </div>
    </div>
  );
}

export default FREEservice;

const data = [
  {
    img: img1,
    title: "서비스 고객 포털운영",
    detail: `· 고객 포털 생성 \n · FAQ, 약관/정책, 주요 공지사항 삽입 \n · 문의 유형별 분류 \n · 티켓 세부 기재 항목 생성`,
  },
  {
    img: img2,
    title: "답변 편의 기능",
    detail: `· 중복 문의 일괄 처리 \n · 고객 정보 및 문의 내역 확인 \n · 고객 정보 수집 API`,
  },
  {
    img: img3,
    title: "상담 편의 기능",
    detail: `· 문의 접수/답변 메일 디자인 \n · 답변 템플릿 등록 \n · 답변 미리보기 및 티켓 숨김`,
  },
  {
    img: img4,
    title: "고객관리",
    detail: `· VIP 등록 및 문의 우선 처리 \n · Black 문의 간접 노출`,
  },
  {
    img: img5,
    title: "협업",
    detail: `· 티켓 메모 \n · 티켓 자동 할당`,
  },
  {
    img: img6,
    title: "리포트",
    detail: `· 서비스 별 문의 처리 현황 리포트`,
  },
  {
    img: img7,
    title: "이용 지원",
    detail: `· 이메일 연동 지원`,
  },
];
