import React from "react";
import Button from "../Button";
import "./styles.scss";

interface Props {
  title: string;
  detail: string;
  subTitle: string;
  onPressButton: () => void;
  img: any;
  textButton: string;
  textButtonn: string;
  backgroundColor: string;
}
function Banner({
  title,
  detail,
  subTitle,
  onPressButton,
  img,
  textButton,
  textButtonn,
  backgroundColor,
}: Props) {
  return (
    <div style={{ background: backgroundColor }} className="banner">
      <div className="section">
        <div className="section-container">
          <div className="section-container-content">
            <div className="subtit">
              <span>{subTitle}</span>
            </div>
            <div className="title">
              <p>{title}</p>
            </div>
            <div className="detail">
              <p>{detail}</p>
            </div>

            <div className="button">
              <Button
                size="normal"
                type="outline"
                onClick={onPressButton}
                rightIcon
                text={textButton}
              />
            </div>
            <div className="button">
            <a href="https://csbye.notion.site/b76b7a3976994f6dbc5a0b1fb460a4e1?v=a8ed5b9ad36848b7b25569ded2c9eb68&pvs=4" target="_blank">
              <Button
                size="normal"
                type="outline"
                // onClick={onPressButton}
                rightIcon
                text={textButtonn}
              />
              </a>
            </div>
          </div>

          <div className="section-img">
            <img src={img} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
