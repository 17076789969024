import React from "react";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/img-whatis-qaservice-01@3x.png";
import img2 from "../../../../Assets/Images/img-whatis-qaservice-02@3x.png";
import img3 from "../../../../Assets/Images/img-whatis-qaservice-03@3x.png";
import img4 from "../../../../Assets/Images/img-whatis-qaservice-04@3x.png";
import "./styles.scss";
function QAService() {
  return (
    <div className="qaService">
      <div className="qaService-container">
        <Title
          title={
            <>
              質量保證服務 <span>QA Service</span>
            </>
          }
          detail={`在在線/移動服務推出及更新前，為了穩定的服務，需要通過各種QA將服務缺陷最小化。\n
          具有長期經驗的專業人員通過多種設備及OS執行標準化程序，為客戶提供穩定及滿意的服務。 `}
        />

        <ul className="qaService-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="item-content">
                <div className="title">{item.title}</div>
                <div className="detail">{item.detail}</div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default QAService;

const data = [
  {
    img: img1,
    title: "功能測試",
    detail: `明細評論及測試設計技術 \n功能測試 \n測試環境構建 \n測試及漏洞跟踪`,
  },
  {
    img: img2,
    title: "兼容性測試",
    detail: `各種終端和環境測試、\n 規格（最低/建議）及現有環境測試、\n移動終端及各OS類別PC OS/IE下載及運行測試`,
  },
  {
    img: img3,
    title: "維護測試",
    detail: `更新及服務問題應對 \n更新明細的功能測試 \n實時問題再現及報告`,
  },
  {
    img: img4,
    title: "其他品質保證內容",
    detail: `本地化測試質量改進，\n例如國內服務平衡測試FGT的本地化測試過濾，\n以實現流暢的用戶體驗`,
  },
];
