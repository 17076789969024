import React from 'react'
import Title from '../../../Common/Title'
import img1 from "../../../../Assets/Images/img-whatis-csservice-01@3x.png"
import img2 from "../../../../Assets/Images/img-whatis-csservice-02@3x.png"
import img3 from "../../../../Assets/Images/img-whatis-csservice-03@3x.png"
import img4 from "../../../../Assets/Images/img-whatis-csservice-04@3x.png"
import "./styles.scss"
function CSService() {
  return (
    <div className='csService'>
        <Title title={<>
            客户运营管理服务 <span>CS Service</span>
        </>}
        detail={`这是一个随着消费者品味和标准的多样化，更细致、更准确的客户满意度服务变得非常重要的时代。\n
        CS专业咨询人员以顾客管理经验为基础, 系统地应对消费者咨询, 提供快速、准确的答复，努力提高顾客满意度。 
        `}
        />

        <ul className="csService-list">
            {data.map((item,index) => (
                <li key={index} className='csService-list-item'>
                    <img src={item.img} alt="" />
                    <div className="content">
                        <div className="title">{item.title}</div>
                        <div className="detail">
                            <p>{item.detail1}</p>
                            <p>{item.detail2}</p>
                            <p>{item.detail3}</p>
                            <p>{item.detail4}</p>
                            <p>{item.detail5}</p>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    </div>
  )
}

export default CSService

const data = [
    {
        img: img1,
        title: '市场与社区',
        detail1: '·  与用户进行沟通及护理',
        detail2: '·  投诉趋势分析与提取',
        detail3: '·  通过公告和回复等进行沟通',
        detail4: '·  立即确认游戏中的错误',
        detail5: '·  英文/中文/日文自动翻译功能',
    },
    {
        img: img2,
        title: 'VOC（统计）报告',
        detail1: '·  按周期识别趋势，提示服务方向',
        detail2: '·  通过咨询确认趋势',
        detail3: '·  论坛及评论现状统计',
        detail4: '·  收集各种建议',
        detail5: '·  提供日报、周报和月报',
    },
    {
        img: img3,
        title: '结算及退款管理',
        detail1: '·  从受理询问到退款的综合管理',
        detail2: '·  进行退款及取消结算',
        detail3: '·  代办对外公文等业务',
        detail4: '·  监控滥用退款行为',
    },
    {
        img: img4,
        title: 'VIP服务应对',
        detail1: '·  可提供VIP服务优先应对',
        detail2: '·  拥有按类型分类的系统',
        detail3: '·  拥有VIP应对程序',
        detail4: '·  终止后进行跟踪管理',
    },
]














