import React, { useEffect, useLayoutEffect, useState } from "react";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/03-icon-1@3x.png";
import img2 from "../../../../Assets/Images/03-icon-2@3x.png";
import img3 from "../../../../Assets/Images/03-icon-3@3x.png";
import img4 from "../../../../Assets/Images/03-icon-4@3x.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./styles.scss";
import Button from "../../../Common/Button";
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../../Modules/routesString";
import { Power4 } from "gsap/all";
import * as Scroll from "react-scroll"


gsap.registerPlugin(ScrollTrigger);

function Service() {
  const scroll = Scroll.animateScroll
  const navigation = useNavigate()
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  
  const isTable = width <= 1280;

  //animation
  useLayoutEffect(() => {
    if(isTable) return
    let ctx = gsap.context(() => {
      let timeline = gsap.timeline({
        scrollTrigger: {
          trigger: `.service-container`,
          start: "top center",
        },
      });

      timeline.from(`.service-list`, {
        x: 1030,
        duration: 2,
        autoAlpha: 0,
        ease: Power4.easeOut,
        // delay: 1
      });
    });

    return () => ctx.revert();
  }, [isTable]);
  return (
    <div id="homeService" className="service">
      <div className="service-container">
        <Title
          title={
            <div>
              <span>CSbye</span> Service
            </div>
          }
          detail={`ADD-UP Co.,Ltd. is a service company with customer management service know-how, 

          and is a service company that realizes customer satisfaction by providing a wide range of services 

          such as customer operation management (CS) service, QA (quality assurance), marketing, and monitoring.
          `}
        />

        <ul className="service-list">
          {data.map((item, index) => (
            <li key={index} className="service-item">
              <div className="service-item-img">
                <img src={item.img} alt="" />
                <div className="content3">
                  <div className="content3-title">{item.title}</div>
                  <div className="content3-detail">
                    <p>{item.detail1}</p>
                    <p>{item.detail2}</p>
                    <p>{item.detail3}</p>
                    <p>{item.detail4}</p>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
    
        <div className="service-button">
          <Button rightIcon onClick={() => {
                  scroll.scrollToTop()
            navigation(RoutesString.Intro_en);
          }} text="Details" size="normal" />
        </div>
      </div>
    </div>
  );
}

export default Service;

const data = [
  {
    img: img1,
    title: "Customer Service",
    detail1: "· Market and Community",
    detail2: "· Suggest service direction through VOC(statistics) Report",
    detail3: "· Inquiry reply, payment, and refund management",
    detail4: "· Priority response and service for VIP",
  },
  {
    img: img2,
    title: "Quality Assurance",
    detail1: "· App and web function test",
    detail2: "· Device compatibility test",
    detail3: "· Update and maintenance test",
    detail4: "· Other quality assurance tests",
  },
  {
    img: img3,
    title: "Marketing service",
    detail1: "· Social media posting marketing",
    detail2: "· Event planning and Customer service",
    detail3: "· Various viral video making ",
    detail4: "· Event winner announce notice and prize delivery",
  },
  {
    img: img4,
    title: "24-hour Monitoring",
    detail1: "· 24-hour real-time response",
    detail2: "· Market review replies",
    detail3: "· Prompt response to issues",
    detail4: "· Hourly report provided",
  },
];
