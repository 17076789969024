import React from "react";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/ic-whatis-mornitoring-01@3x.png";
import img2 from "../../../../Assets/Images/ic-whatis-mornitoring-02@3x.png";
import img3 from "../../../../Assets/Images/ic-whatis-mornitoring-03@3x.png";
import img4 from "../../../../Assets/Images/ic-whatis-mornitoring-04@3x.png";
import "./styles.scss";
import Button from "../../../Common/Button";
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../../Modules/routesString";
import * as Scroll from "react-scroll"

function MornitoringService() {
  const navigation = useNavigate()
  const scroll = Scroll.animateScroll
  return (
    <div className="mornitoringService">
      <div className="mornitoringService-container">
        <Title
          title={
            <>
            <span>Mornitoring Service</span>
            </>
          }
          detail={`24 hours a day, 365 days a year management is possible through monitoring professionals to respond to sudden errors\nthat may occur at any time and to respond to real-time trends of users even during vulnerable times.\n
          To prevent abnormal symptoms from being exposed to the customer's service, ADD-UP closely monitors every hour.`}
        />

        <ul className="mornitoringService-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="content">
                <div className="title">{item.title}</div>
                <div className="detail">{item.detail}</div>
              </div>
            </li>
          ))}
        </ul>

            {/* <div className="mornitoringService-container-button">
                <Button onClick={() => {
                  scroll.scrollToTop()
                  navigation(RoutesString.Customer)
                  }} rightIcon text="서비스 문의하기" />
            </div> */}

      </div>
    </div>
  );
}

export default MornitoringService;

const data = [
  {
    img: img1,
    title: "24-hour real-time response",
    detail: `Continuous monitoring situation delivery, cafe and homepage monitoring, in-game access monitoring`,
  },
  {
    img: img2,
    title: "Market Review Responses",
    detail: `Check reviews on Google, App Store, One Store, etc., Check and respond to reviews, Manage various events`,
  },
  {
    img: img3,
    title: "Spread Issues",
    detail: `Prompt spreading in case of issues, Spread through calls and email, Preempive action(notice) upon request `,
  },
  {
    img: img4,
    title: "Reports",
    detail: `Situational reports are available, Deliver monitoring reports every hour, Gather and deliver key opinions.`,
  },
];
