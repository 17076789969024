import React from "react";
import Title from "../../../Common/Title";
// import bg from "../../../../Assets/Images/map-platform.png"
import img from "../../../../Assets/Images/memberimg.png";
import item1 from "../../../../Assets/Images/reason022.png";
import item2 from "../../../../Assets/Images/reason011.png";
import item3 from "../../../../Assets/Images/reason033.png";
import "./styles.scss";

function MemberMap() {
  return (
    <div className="memberMap_jp">
      {/* <div className="memberMap-bg">
            <img src={bg} alt="" />
        </div> */}

      <div className="memberMap_jp-container">
        <Title title={<>{}</>} />
        <div className="map">{/* <img src={img} alt="" /> */}</div>

        <div className="map-list">
          {dataMap.map((item, index) => (
            <div className="item" key={index}>
              <div className="img">
                <img src={item.img} alt="" />
              </div>
              <div className="content">
                <div className="title_jp">{item.title}</div>
                <div className="detail_jp">{item.detail}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MemberMap;

const dataMap = [
  {
    img: item1,
    title: "私がどんな\nお問い合わせを送ったっけ?",
    detail: "CSbyeから自分の\nお問い合わせとご返答を全部確認!",
  },
  {
    img: item2,
    title: "毎日訪問するとポイント\n積み立ての機会を提供",
    detail: "加入するだけで2,000P即時支給!",
  },
  {
    img: item3,
    title: "問題が完全に\n解決されなかった場合には?",
    detail: "返答確認後、同一サービス内\n追加お問い合わせ可能",
  },
];
