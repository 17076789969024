import { ReactComponent as StarSVG } from "Util/Svg/star.svg";

/**
 * 텍스트내용 atomic
 * @param  {number} score 별점
 * @param  {number} setScore 별점수정
 * @returns
 */
const StarPoint = ({
  score,
  setScore,
}: {
  score: number;
  setScore: React.Dispatch<React.SetStateAction<number>>;
}) => {
  return (
    <div className="flex justify-center gap-24">
      {Array.from(Array(5)).map((_, idx: number) => (
        <button
          style={{ marginRight: "24px" }}
          type="button"
          key={idx}
          onClick={() => setScore(idx + 1)}
        >
          <StarSVG fill={idx >= score ? "#fff" : "#F0AB25"} />
        </button>
      ))}
    </div>
  );
};

export default StarPoint;
