import React from "react";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/img-whatis-qaservice-01@3x.png";
import img2 from "../../../../Assets/Images/img-whatis-qaservice-02@3x.png";
import img3 from "../../../../Assets/Images/img-whatis-qaservice-03@3x.png";
import img4 from "../../../../Assets/Images/img-whatis-qaservice-04@3x.png";
import "./styles.scss";
function QAService() {
  return (
    <div className="qaService">
      <div className="qaService-container">
        <Title
          title={
            <>
              质量保证服务 <span>QA Service</span>
            </>
          }
          detail={`在在线/移动服务推出及更新前，为了稳定的服务，需要通过各种QA将服务缺陷最小化。\n
          具有长期经验的专业人员通过多种设备及OS执行标准化程序，为客户提供稳定及满意的服务。`}
        />

        <ul className="qaService-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="item-content">
                <div className="title">{item.title}</div>
                <div className="detail">{item.detail}</div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default QAService;

const data = [
  {
    img: img1,
    title: "功能测试",
    detail: `明细评论及测试设计技术\n 功能测试 \n测试环境构建 \n测试及漏洞跟踪`,
  },
  {
    img: img2,
    title: "兼容性测试",
    detail: `各种终端和环境测试、\n规格（最低/建议）及现有环境测试、\n移动终端及各OS类别PC OS/IE下载及运行测试`,
  },
  {
    img: img3,
    title: "维护测试",
    detail: `更新及服务问题应对\n更新明细的功能测试 \n实时问题再现及报告`,
  },
  {
    img: img4,
    title: "其他品质保证内容",
    detail: `本地化测试质量改进，\n例如国内服务平衡测试FGT的本地化测试过滤，\n以实现流畅的用户体验`,
  },
];
