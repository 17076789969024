import React from "react";
import Button from "../Button";
import "./styles.scss";

interface Props {
  title: string;
  // detail: string;
  subTitle: string;
  onPressButton: () => void;

  textButton: string;
  backgroundColor: string;
}
function Banner1({
  title,
  // detail,
  subTitle,
  onPressButton,
  textButton,

  backgroundColor,
}: Props) {
  return (
    <div style={{ background: backgroundColor }} className="banner1">
      <div className="section">
        <div className="section-container">
          <div className="section-container-content">
            <div className="subtit">
              <span>{subTitle}</span>
            </div>
            <div className="title">
              <p>{title}</p>
            </div>
            {/* <div className="detail">
              <p>{detail}</p>
            </div> */}

<div className="button">
            <a href="http://localhost:3000/domainlogin" target="_blank">
              <Button
                size="normal"
                type="outline"
                // onClick={onPressButton}
                rightIcon
                text={textButton}
              />
              </a>
            </div>
          

  
        </div>
      </div>
      </div>
    </div>
  );
}

export default Banner1;
