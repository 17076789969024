import imgBanner from "../../../../Assets/Images/img-price-banner@3x.png";

import { useNavigate } from "react-router-dom";
import basicIcon from "../../../../Assets/Images/basic@3x.png";
import customizeIcon from "../../../../Assets/Images/customize@3x.png";
import freeIcon from "../../../../Assets/Images/free@3x.png";
import SubImg5 from "../../../../Assets/Images/ic-price-basic@3x.png";
import SubImg4 from "../../../../Assets/Images/ic-price-customize@3x.png";
import SubImg2 from "../../../../Assets/Images/ic-price-premium@3x.png";
import SubImg3 from "../../../../Assets/Images/ic-price-special@3x.png";
import SubImg1 from "../../../../Assets/Images/ic-price-standard@3x.png";
import premiumIcon from "../../../../Assets/Images/premium@3x.png";
import specialIcon from "../../../../Assets/Images/special@3x.png";
import Button from "../../../Common/Button";
import { RoutesString } from "../../../Modules/routesString";

import * as Scroll from "react-scroll";
import "./styles.scss";

function PriceBanner() {
  const navigation = useNavigate();
  const scroll = Scroll.animateScroll;

  const dataPriceTypes = [
    {
      type: "Basic",
      quantity: "1人使用",
      img: SubImg1,
      sheet: "每个坐席",
      title: "免费",
      iconCheck: freeIcon,
      feature: (
        <>
          {/* <span style={{ color: "#b5b5b5" }}>+Free </span> 의 모든 기능 */}
        </>
      ),
      detail: [
        {
          content: `只提供必需的基本咨询功能`,
        },
        {
          content: `每天提供60个咨询回复`,
        },
      ],
      textButton: "免费开始使用。",
      mainColor: "#b8daff",
      onPressButton: () => {
        scroll.scrollToTop();
        navigation(RoutesString.free_cn);
      },
    },
    {
      type: "Basic+",
      quantity: "非常适合 1-2 人、小规模团队",
      img: SubImg5,
      sheet: "每个坐席",
      title: "每月 19,000 韩元",
      iconCheck: basicIcon,
      mainColor: "#90c4fd",
      feature: (
        <>
          +<span style={{ color: "#b8daff" }}> Basic </span> 的所有功能
        </>
      ),
      detail: [
        {
          content: `从收到咨询到得到答复的顺畅沟通`,
        },
        {
          content: `设置适合自己的咨询中心`,
        },
        {
          content: `适合小型团队的基本系统`,
        },
      ],
      textButton: "结算",
      onPressButton: () => {
        alert("需要登录的服务。");
        scroll.scrollToTop();
        navigation(RoutesString.domainLogin);
      },
    },
    {
      type: "Premium",
      quantity: "非常适合大型团队",
      img: SubImg2,
      sheet: "每个坐席",
      title: "每月 29,000 韩元",
      iconCheck: premiumIcon,
      mainColor: "#228cff",
      feature: (
        <>
          +<span style={{ color: "#90c4fd" }}> Basic+</span> 收费制的所有功能
        </>
      ),
      detail: [
        {
          content: `通过SMS/ APP联动进行实时确认`,
        },
        {
          content: `备忘录、预约、分配等咨询特色功能`,
        },
        {
          content: `同时使用多种服务`,
        },
        {
          content: `提供报告格式`,
        },
      ],

      textButton: "结算",
      onPressButton: () => {
        alert("需要登录的服务。");
        scroll.scrollToTop();
        navigation(RoutesString.domainLogin);
      },
    },
    {
      type: "Special",
      quantity: "非常适合大规模合作",
      img: SubImg3,
      sheet: "每个坐席",
      mainColor: "#0062f9",
      iconCheck: specialIcon,
      title: "每月 39,000 韩元",
      feature: (
        <>
          +<span style={{ color: "#228cff" }}> Premium</span> 收费制的所有功能
        </>
      ),
      detail: [
        {
          content: `服务内部咨询循环分配`,
        },
        {
          content: `下载报告数据`,
        },
        {
          content: `使用全平台功能`,
        },
        {
          content: `邮件广告关键词通知功能`,
        },
        {
          content: `所有查询、横幅设置`,
        },
        {
          content: `按咨询员级别设置权限`,
        },
      ],

      textButton: "结算",
      onPressButton: () => {
        alert("需要登录的服务。");
        scroll.scrollToTop();
        navigation(RoutesString.domainLogin);
      },
    },
    {
      type: "Customize",
      quantity: "可提供人力服务",
      img: SubImg4,
      iconCheck: customizeIcon,
      sheet: "每个坐席",
      mainColor: "#222222",
      title: "商谈后决定",
      feature: (
        <>
          +<span style={{ color: "#0062f9" }}> Special</span> 收费制的所有功能
        </>
      ),
      detail: [
        {
          content: `调整服务注册数量`,
        },
        {
          content: `调整咨询员篇数量`,
        },
        {
          content: `添加或删除选择性功能`,
        },
        {
          content: `运营人员服务咨询`,
        },
        {
          content: `注册个人会员广告横幅`,
        },
      ],
      textButton: "联系我们",
      onPressButton: () => {
        scroll.scrollToTop();
        navigation(RoutesString.Customer_cn);
      },
    },
  ];

  return (
    <div className="priceBanner">
      <div className="priceBanner-container">
        <div className="priceBanner-container-content">
          <div className="content">
          <p>ADD-UP CSBYE计划</p>
            <p className="bold">根据企业规模提供需要的服务</p>
            
            <div className="detail">
            通过直接设置所需功能的定制平台结构确认咨询渠道，<br></br>
能够快速高效地管理回复时间延迟等各种问题。<br></br>
现在直接和客户进行沟通吧。
            </div>
          </div>
          <div className="img">
            <img src={imgBanner} alt="" />
          </div>
        </div>

        <div className="priceTypes">
          {dataPriceTypes.map((item, index) => (
            <PriceType
              key={index}
              type={item.type}
              quantity={item.quantity}
              img={item.img}
              sheet={item.sheet}
              title={item.title}
              feature={item.feature}
              detail={item.detail}
              textButton={item.textButton}
              onPressButton={item.onPressButton}
              mainColor={item.mainColor}
              iconCheck={item.iconCheck}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default PriceBanner;

interface Props {
  type: string;
  quantity: string;
  img: any;
  sheet: string;
  title: string;
  feature?: any;
  detail: { content: any }[];
  textButton: string;
  onPressButton: () => void;
  mainColor: string;
  iconCheck:any
}
const PriceType = ({
  type,
  quantity,
  img,
  sheet,
  title,
  detail,
  textButton,
  onPressButton,
  feature,
  mainColor,
  iconCheck
}: Props) => {
  return (
    <div className="priceType">
      <div className="priceType-container">
        <div style={{ background: mainColor }} className="type">
          <span>{type}</span>
        </div>

        <div className="content">
          <div className="quantity">{quantity}</div>
          <div className="img">
            <img src={img} alt="" />
          </div>
          <div className="sheet">{sheet}</div>
          <div className="title">{title}</div>
          <div className="feature">{feature && feature}</div>
          <div className="detail">
            {detail.map((item, index) => (
              <div key={index} className="detail-item">
                <img src={iconCheck} alt="" />
                <div
                  className="detail-item-content"
                  dangerouslySetInnerHTML={{ __html: item.content }}
                ></div>
              </div>
            ))}
          </div>
        </div>

        <div className="button">
          <Button
            onClick={onPressButton}
            className="button-price"
            text={textButton}
            type={"primary"}
            style={{ background: mainColor }}
          />
        </div>
      </div>
    </div>
  );
};
