import React, { useLayoutEffect } from "react";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/02-icon-1@3x.png";
import img2 from "../../../../Assets/Images/02-icon-2@3x.png";
import img3 from "../../../../Assets/Images/02-icon-3@3x.png";
import img4 from "../../../../Assets/Images/02-icon-4@3x.png";
import img5 from "../../../../Assets/Images/02-icon-5@3x.png";
import img6 from "../../../../Assets/Images/02-icon-6@3x.png";
import img7 from "../../../../Assets/Images/02-icon-7@3x.png";
import img8 from "../../../../Assets/Images/02-icon-8@3x.png";
import img9 from "../../../../Assets/Images/02-icon-9@3x.png";
import img10 from "../../../../Assets/Images/02-icon-10@3x.png";
import "./styles.scss";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap, { Power4 } from "gsap";

gsap.registerPlugin(ScrollTrigger);
function Server() {
  //animation
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      let timeline = gsap.timeline({
        scrollTrigger: {
          trigger: `.server-container`,
          start: "top center",
        },
      });
      data.map((item, index) => {
        timeline.from(
          `.item-${index}`,
          {
            scale: 0.1,
            duration: 2,
            autoAlpha: 0,
            ease: Power4.easeOut,
          },
          `frame1+=${index / data.length}`
        );
      });
    });

    return () => ctx.revert();
  }, []);

  return (
    <div className="server">
      <div className="server-container">
        <Title
          title={
            <div>
            <span>CSbye,</span> the new name of ADD-UP service
            </div>
          }
          detail={`As consumers' taste and standard is diversified, \nCustomer Service with much precision and detail is important in current times.\n
          Based on customer management experience, CS professional counselors are systematically responding \nto inquiries and providing quick and accurate replies to increase customer satisfaction.`}
        />

        <ul className="server-list">
          {data.map((item, index) => (
            <li key={index} className={`server-list-item item-${index}`}>
              <img src={item.img} alt="" />
              <p>{item.title}</p>
            </li>
          ))}
        </ul>
      </div>

      <div className="one">
        <div className="two"></div>
      </div>
    </div>
  );
}

export default Server;

const data = [
  {
    img: img1,
    title: "Check inquiries from multiple channels in one place",
  },
  {
    img: img2,
    title: "Fast handling of all customer inquiries\n\n",
  },
  {
    img: img3,
    title: "Customer consultation history management",
  },
  {
    img: img4,
    title: "English, Japanese, and Chinese available\n\n",
  },
  {
    img: img5,
    title: "Provide daily, weekly, and monthly reports\n\n",
  },
  {
    img: img6,
    title: "Check product Quality Assurance\n\n\n\n\n\n",
  },
  {
    img: img7,
    title: "Marketing planning and managing\n\n\n\n\n",
  },
  {
    img: img8,
    title: "24/7 monitoring of game and customer trends, etc.\n\n\n\n",
  },
  {
    img: img9,
    title: "Reduction of workforce management burden and flexibility in workforce utilization",
  },
  {
    img: img10,
    title: "Discounts apply for long-term contracts\n\n\n\n\n",
  },
];
