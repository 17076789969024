import { useState } from "react";
import PaginatedItems from "../../../Common/Pagination";
import AccordionData from "./AccordionData";
import "./styles.scss";
import { tab } from "@testing-library/user-event/dist/tab";

function Question() {
  const [tabActive, setTabActive] = useState(0);

  return (
    <div className="question">
      <div className="question-title">FAQ</div>
      <div className="question-tabs">
        {tabs.map((item, index) => (
          <div
            onClick={() => {
               setTabActive(index);
            }}
            key={index}
            className={`tab ${tabActive === index ? "activeTab" : ""}`}
          >
            {item.tit}
          </div>
        ))}
      </div>
      

      <div className="question-accordion">
        
        {accordionData.map(({ type,title, content }, index) => (
          tabActive === 0 ? <AccordionData key={index} title={title} content={content} />
          :
          tabActive === type && <AccordionData key={index} title={title} content={content} />
        ))}
      </div>


      {/* <div className="question-pagination">
        <PaginatedItems />
      </div> */}
    </div>
  );
}

export default Question;

const tabs = [
  {
    id : 0,
    tit: "All",
    router: "",
  },
  {
    id : 1,
    tit: "CS",
    router: "",
  },
  {
    id : 2,
    tit: "CSbye Platform",
    router: "",
  },
  {
    id : 3,
    tit: "Payment and Refund",
    router: "",
  }
];

const accordionData = [
  //자주찾는 질문
  {
    type : 1,
    title: "Q. What does CSbye mean?",
    content: `A.  'CSbye' is an integrated name for ADD-UP CS, QA, Marketing, and Monitoring services.
    It is a new name for our ADD-UP service that helps you let go of your worries about customer management by Adding ‘BYE’ to Customer Service (CS)`,
  },
  {
    type : 1,
    title: "Q. Can we use only the necessary services among marketing services?",
    content: `A. CSbye service can be used by selecting only the services that companies need. \nFor more details, please contact us through Contact Us.`,
  },
  {
    type : 1,
    title: "Q. Can I just use the platform rather than the CSbye service?",
    content: `We support CSbye platform services for companies that want to manage their customers on their own using the CSbye platform,\n and you can check information on using the platform in the price information section at the top.`,
  },
  {
    type : 1,
    title: "Q. I want to experience the CSbye platform!",
    content: `A. You can try it for free through the ‘Try Free’ page on the top right of the CSbye homepage.
    The free trial configuration is a PREMIUM product and consists of 30 days of use for sufficient review \nand smooth use by the company manager.`,
  },
  {
    type : 1,
    title: "Q. Where can I inquire about the CSbye platform?",
    content: `A. Please inquire through 'Contact Us' at the top of the Customer Inquiry page. \nWe will reply after checking!`,
  },
  {
    type : 1,
    title: "Q. Can we be provided with CSbye platform counselors",
    content: `A. CSbye platform supports only the platform for a monthly fee, so it is difficult to support counselors. \nHowever, if you contact CS Management within the CSbye service, we will deliver the related information.`,
  },
  //플랫폼 서비스
  {
    type : 2,
    title: "Q. How do I sign up?",
    content: `A. You can sign up for membership through the login page at the top of the CSbye homepage.`,
  },
  {
    type : 2,
    title: "Q,  Is it possible to transfer the company manager's account?",
    content: `A.  You can delegate authority in modifying personal information within the platform.
    It is delegated to one of the registered counselors, and the existing manager account is changed to a counselor account.`,
  },
  {
    type : 2,
    title: "Q. What happens to the automatic assignment of inquiries when counselors are on vacation?",
    content: `A. As a feature available only for special products, automatic assignment of inquiries is excluded \nif the agent directly sets vacation settings in Edit Personal Information or\n if the company manager sets vacation settings for the agent in agent management.`,
  },
  {
    type : 2,
    title: "Q. Where can I see the reason for being blacklisted?",
    content: `A. You can check customer information in the customer list management menu.
    Select Blacklist to look up customers. The reason is automatically entered in the customer memo history.
    `,
  },
  {
    type : 2,
    title: "Q. How can I use notification service and SMS transmission?",
    content: `A. You can receive contact notifications via Telegram.
    Please enter your Telegram Token and Chat ID in Notification Service Settings.
    SMS can be sent using Aligo. Please enter your Aligo API key.`,
  },
  //결제 및 환불
  {
    type : 3,
    title: "Q. What is a sheet?",
    content: `A. Sheet is the number of available counselors.
    You must purchase the number of sheets according to the number of counselors in advance to handle inquiries.
    You can freely register and delete agents, but only authorized counselors can use the function. \nOnly authorized agents occupy sheets.`,
  },
  {
    type : 3,
    title: "Q.  Where can I purchase?",
    content: `A. After signing up for the CSbye service, you can make payments within the service.
    Please refer to the pricing information page and use the plan that suits your business.`,
  },
  {
    type : 3,
    title: "Q. How do I change the subscription or change the number of sheets?",
    content: `A.  You can change subscription within the platform from Information > Payment Status > Change subscription.
    In case of subscription change or sheet change, the previous subscription payment will be canceled \nand the new subscription will be repaid.`,
  },
  {
    type : 3,
    title: "Q. What if my payment is overdue?",
    content: `A. If payment is not made normally on the payment date, it will be overdue.

    • immediately after overdue : The platform no longer accepts tickets from customers
    Pay from the plan selection that appears after logging in
    
    • 7 days overdue : Your account will be closed and you will no longer be able to log in.
   Contact CSbye to temporarily open a closed account for 7 days.
   After that, log in and pay for the plan from Information > Payment Status within the period indicated. 
    
    • Over 1 year : It is determined that the account is neglected and the account is deleted after sending a notification email.`,
  },
  {
    type : 3,
    title: "Q. How do I cancel the subscription?",
    content: `A. You can cancel automatic product payment in Information > Payment Status. \nThe service can be used until one month after the last product payment period.`,
  },
  {
    type : 3,
    title: "Q.  What is the refund policy",
    content: `A.  Here is the refund policy.
    1. After payment, if the service is canceled prior to service use due to a simple change of mind of the consuming company or other reasons, the amount is refunded after settlement for the period of use.
    2. If the user wants to stop using the service due to unavoidable reasons after using the service, the user informs the supplier of the intention, and the supplier immediately stops the service and refunds the service fee for the remaining service period.
    3. If a cancellation (refund) of a paid subscription occurs after the payment deadline has elapsed, the cancellation (refund) amount is included in the refund.
    
    Period of refund of payment amount in case of order cancellation
     · Credit card: Cancellation of card approval within 3 business days from the date of retrieval
     · Cash card: Deposit to the card account withdrawn within 3 business days from the date of retrieval
     · Virtual account: Deposit to refund account within 3 business days from the date of retrieval
     · Others: Refund within 3 business days from the date the product retrieval`
  }
];
