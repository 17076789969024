import React from 'react'
import Title from '../../../Common/Title'
import img1 from "../../../../Assets/Images/img-whatis-csservice-01@3x.png"
import img2 from "../../../../Assets/Images/img-whatis-csservice-02@3x.png"
import img3 from "../../../../Assets/Images/img-whatis-csservice-03@3x.png"
import img4 from "../../../../Assets/Images/img-whatis-csservice-04@3x.png"
import "./styles.scss"
function CSService() {
  return (
    <div className='csService'>
        <Title title={<>
            고객 운영관리 서비스 <span>CS Service</span>
        </>}
        detail={`소비자의 취향과 기준이 다양해져서, 더욱 디테일하고 정확한 고객 만족 서비스가 중요한 시대입니다. \n
        CS 전문 상담 인력들은 고객관리 경험을 바탕으로, 소비자 문의를 체계적으로 대응하며 빠르고 정확한 \n
        답변을 제공하여 고객 만족도를 높이는 데 노력하고 있습니다.`}
        />

        <ul className="csService-list">
            {data.map((item,index) => (
                <li key={index} className='csService-list-item'>
                    <img src={item.img} alt="" />
                    <div className="content">
                        <div className="title">{item.title}</div>
                        <div className="detail">
                            <p>{item.detail1}</p>
                            <p>{item.detail2}</p>
                            <p>{item.detail3}</p>
                            <p>{item.detail4}</p>
                            <p>{item.detail5}</p>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    </div>
  )
}

export default CSService

const data = [
    {
        img: img1,
        title: '마켓 및 커뮤니티',
        detail1: '·  유저와 소통 및 케어 진행',
        detail2: '·  불만 동향 분석 및 추출',
        detail3: '·  공지 및 답변 등으로 소통 진행',
        detail4: '·  게임 내 오류 즉시 확인',
        detail5: '·  영·중·일 자동 변역 기능',
    },
    {
        img: img2,
        title: 'VOC(통계) 리포트',
        detail1: '·  주기별 동향 파악, 서비스 방향 제시',
        detail2: '·  문의를 통해 확인된 동향',
        detail3: '·  카페 및 리뷰 등 통계 현황',
        detail4: '·  각종 건의사항 수집',
        detail5: '·  일일, 주간, 월간 보고서 제공',
    },
    {
        img: img3,
        title: '결제 및 환불관리',
        detail1: '·  문의 접수부터 환불까지 종합 관리',
        detail2: '·  환불 및 결제취소 진행',
        detail3: '·  대외 공문 등 업무 대행',
        detail4: '·  환불 어뷰징에 대한 모니터링',
    },
    {
        img: img4,
        title: 'VIP 서비스 응대',
        detail1: '·  VIP 서비스 제공으로 우선 대응 가능',
        detail2: '·  유형별 분류 시스템 보유',
        detail3: '·  VIP 대응 프로세스 보유',
        detail4: '·  종료 후 추적 관리까지 진행',
    },
]














