import React from 'react'
import Banner from '../../Common/Banner1'
import img111 from "../../../Assets/Images/Free_tk2.c7d7f3e27a8f391c6059_en.png"
import img222 from "../../../Assets/Images/bannerB2.png"
import FREEmain from '../Free_en/FREEmain'
import FREEservice from '../Free_en/FREEservice'
import FREEplatform from '../Free_en/FREEplatform'
import "./styles.scss";
// import FREEVersion from './FREEVersion'
//import img1 from "../../../../Assets/Images/freeimg.png";

function Free_en() {
  return (
    <main>
               <div className="FREEMain">
            <img className='img11' src={img111} alt="" />
            <img className='img22' src={img222} alt="" />
        </div>  

        <Banner
        title={`CSbye platform, free for lifetime!`}
        subTitle="If you are a small,
        start-up company with a low volume of inquiries?"
       onPressButton={() => {alert('준비중입니다.')}}
       textButton={"Start CSBYE platform free"}

        backgroundColor="#c1e0ff"
      />
       


     
      <FREEmain />
      <FREEservice />
      {/* <FREEVersion/> */}
      <FREEplatform/>
      {/* <Policy /> */}
    </main>
  )
}




//function Free() {
  //return (
    //<main>
      //  <Banner
        //img={img}
 //       title={`CSbye 플랫폼 평생무료!`}
   //     subTitle="문의량이 적은 소규모, 스타트업 기업이라면?"
     //   detail={``}
       // onPressButton={() => {alert('준비중입니다.')}}
     //   textButton={"CSbye플랫폼 시작하기"}
   //     backgroundColor="#c1e0ff"
    //  />
     // <FREEmain />
 //   </main>
//  )
//}


//const Free = () => {
  //return (
    //<div id="star" className="bg-f2f3f8">
      //무료이용중
     
    //</div>

  //);
  

//};

export default Free_en;
