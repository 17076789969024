import { useState } from "react";
import PaginatedItems from "../../../Common/Pagination";
import AccordionData from "./AccordionData";
import "./styles.scss";
import { tab } from "@testing-library/user-event/dist/tab";

function Question() {
  const [tabActive, setTabActive] = useState(0);

  return (
    <div className="question">
      <div className="question-title">자주 묻는 질문</div>
      <div className="question-tabs">
        {tabs.map((item, index) => (
          <div
            onClick={() => {
              setTabActive(index);
            }}
            key={index}
            className={`tab ${tabActive === index ? "activeTab" : ""}`}
          >
            {item.tit}
          </div>
        ))}
      </div>

      <div className="question-accordion">
        {accordionData.map(({ type, title, content }, index) =>
          tabActive === 0 ? (
            <AccordionData key={index} title={title} content={content} />
          ) : (
            tabActive === type && (
              <AccordionData key={index} title={title} content={content} />
            )
          )
        )}
      </div>

      {/* <div className="question-pagination">
        <PaginatedItems />
      </div> */}
    </div>
  );
}

export default Question;

const tabs = [
  {
    id: 0,
    tit: "전체",
    router: "",
  },
  {
    id: 1,
    tit: "운영 관리 서비스",
    router: "",
  },
  {
    id: 2,
    tit: "플랫폼 서비스",
    router: "",
  },
  {
    id: 3,
    tit: "결제 및 환불",
    router: "",
  },
];

const accordionData = [
  //자주찾는 질문
  {
    type: 1,
    title: "Q. CSbye가 무슨뜻인가요?",
    content: `A. ‘CSbye’는 기존 에드업의 서비스인 CS, QA, 마케팅, 모니터링 서비스의 통합 명칭으로, \n 고객운영관리(CS)에 이별을 뜻하는 ‘bye’를 붙여 고객관리에 대한 걱정을 떠나보낼수 있도록 도와주는 에드업 서비스의 새로운 이름입니다.`,
  },
  {
    type: 1,
    title: "Q. 마케팅 서비스 중 필요한 서비스만 사용하는 것도 가능하나요?",
    content: `A. CSbye 서비스는 기업에서 필요한 서비스만 이용이 가능합니다. 자세한 사항은 문의하기를 통해 문의 주세요.`,
  },
  {
    type: 1,
    title: "Q. CSbye 서비스보다는 플랫폼만 사용할 수 있을까요?",
    content: `A. CSbye 플랫폼을 활용하여 자체적으로 고객 관리를 하고 싶은 기업을 위해 CSbye 플랫폼 서비스를 지원하고 있습니다. \n상단의 가격안내 칸에서 플랫폼 이용에 대한 정보를 확인해 주세요.
    `,
  },
  {
    type: 1,
    title: "Q. CSbye 플랫폼을 체험하고 싶어요!",
    content: `A. CSbye 홈페이지 상단 우측의 ‘무료로 체험하기’ 페이지를 통해 무료 이용이 가능합니다.
    무료 이용은 Basic 상품으로 구성되어있으며, 기업 관리자 1인에 한해 기간 제한 없이 무료로 사용하실 수 있습니다.`,
  },
  {
    type: 1,
    title: "Q. CSbye 플랫폼 문의는 어디서 하나요?",
    content: `A. 고객문의 페이지 상단의 '문의하기' 를 통해 내용을 보내주세요. 확인 후 회신 드리겠습니다!`,
  },
  {
    type: 1,
    title: "Q. CSbye 플랫폼의 상담원 지원이 가능할까요?",
    content: `A. CSbye 플랫폼은 플랫폼만 월정액으로 지원드리고 있어 상담원 지원은 어렵습니다.\n 다만 CSbye 서비스 내 CS관리로 문의 주시면 관련 내용 전달드리겠습니다.`,
  },
  //플랫폼 서비스
  {
    type: 2,
    title: "Q. 가입은 어떻게 하나요?",
    content: `A. CSbye 홈페이지 내 상단 로그인 페이지를 통해 회원가입 하실 수 있습니다.`,
  },
  {
    type: 2,
    title: "Q, 기업관리자 계정 양도가 가능한가요?",
    content: `A. 플랫폼 내 개인정보 수정에서 권한 위임할 수 있습니다.
    등록된 상담원 중 1명에게 위임되고, 기존 기업관리자는 상담원으로 변경됩니다.`,
  },
  {
    type: 2,
    title: "Q. 상담원이 휴가일 때 문의 자동 할당이 어떻게 되나요?",
    content: `A. 스페셜 상품만 이용 가능한 기능으로, 상담원이 개인정보 수정에서 직접 휴가 설정을 하거나 \n기업관리자가 상담원 관리에서 해당 상담원에게 휴가 설정을 해주면 문의 자동 할당이 제외됩니다.`,
  },
  {
    type: 2,
    title: "Q. 블랙리스트 지정 사유는 어디에서 확인할 수 있나요?",
    content: `A. 고객리스트 관리 메뉴에서 고객의 정보를 확인할 수 있습니다.
    블랙리스트를 선택하여 고객을 조회하세요. 사유는 고객 메모 내역에 자동으로 기입됩니다.
    `,
  },
  {
    type: 2,
    title: "Q. 알림 서비스와 SMS 전송은 어떻게 사용할 수 있나요?",
    content: `A. 텔레그램을 통해 문의 알림을 받으실 수 있습니다.
    알림 서비스 설정에서 텔레그램 토큰과 Chat ID를 입력해주세요.
    SMS는 알리고를 이용하여 전송할 수 있습니다. 알리고 API 키를 입력해주세요.`,
  },
  //결제 및 환불
  {
    type: 3,
    title: "Q. 시트란 무엇인가요?",
    content: `A. 시트란 이용 가능한 상담원 수입니다.
    상담원 수에 맞게 이용하실 시트 수를 미리 구매해야 상담 처리를 할 수 있습니다.
    상담원 등록, 삭제는 자유롭게 가능하지만 승인 상태의 상담원만 기능을 이용하실 수 있습니다. 승인 상태의 상담원만 시트를 차지합니다.`,
  },
  {
    type: 3,
    title: "Q. 어디에서 결제할 수 있나요?",
    content: `A. CSbye 서비스 회원가입을 하신 후, 서비스 안에서 결제하실 수 있습니다.
    가격안내 페이지를 참고 후 기업에 맞는 플랜으로 사용하세요.`,
  },
  {
    type: 3,
    title: "Q. 상품 변경 또는 시트 수를 변경하려면 어떻게 하나요?",
    content: `A. 플랫폼 내에서 정보 > 결제 현황 > 상품 변경에서 결제하실 수 있습니다.
    상품 변경 또는 시트 변경 시 기존 상품이 취소 후 변경된 상품으로 재결제 됩니다.`,
  },
  {
    type: 3,
    title: "Q. 결제가 연체되면 어떻게 하나요?",
    content: `A.결제일에 결제가 정상적으로 이뤄지지 않으면 베이직 플랜으로 자동 변경됩니다.`,
  },
  {
    type: 3,
    title: "Q. 상품 구독 취소를 어떻게 하나요?",
    content: `A. 정보 > 결제 현황에서 상품 자동 결제를 해지할 수 있습니다. 서비스는 최근 상품 결제 기간의 한 달 후까지 사용할 수 있습니다.`,
  },
  {
    type: 3,
    title: "Q. 환불 규정이 궁금해요",
    content: `A. 환불 규정 안내 드립니다.
    1. 서비스 이용료 결제 이후, 서비스 이용 전에 수요기업의 단순 변심 또는 기타 사유로 인하여 서비스 사용을 취소한 경우, \n해당 금액은 사용기간만큼 정산 후 환불 처리된다.
    2. 사용자가 서비스 이용 후 부득이한 사유로 서비스 이용을 중단하고자 할 경우, \n해당 의사를 공급기업에 전달하고 공급기업은 즉시 서비스 제공 중지 및 잔여 서비스 기간만큼 서비스 이용료를 환불 조치한다.
    3. 기 결제한 서비스 상품에 대한 취소(환불)가 결제기한이 경과된 이후 발생할 경우 해당 취소(환불) 금액은 환수 대상에 포함된다
    
    주문취소 시 결제 금액 환불 시기
    · 신용카드 : 회수가 완료된 날로부터 3 영업일 내 카드승인 취소
    · 체크카드 : 회수가 완료된 날로부터 3 영업일 내 출금된 카드계좌로 입금
    · 가상계좌 : 회수가 완료된 날로부터 3 영업일 내 환불계좌로 입금
    · 기타 : 상품 회수가 완료된 날로부터 3 영업일 내 환불`,
  },
];
