import React from "react";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/img-whatis-qaservice-01@3x.png";
import img2 from "../../../../Assets/Images/img-whatis-qaservice-02@3x.png";
import img3 from "../../../../Assets/Images/img-whatis-qaservice-03@3x.png";
import img4 from "../../../../Assets/Images/img-whatis-qaservice-04@3x.png";
import "./styles.scss";
function QAService() {
  return (
    <div className="qaService">
      <div className="qaService-container">
        <Title
          title={
            <>
              品質保証サービス <span>QA Service</span>
            </>
          }
          detail={`オンライン/モバイルサービスリリース及びアップデートの安定的なサービスのために\n
          様々なQAを通じてサービスミスを最小化する必要があります。 \n
          長い経歴の専門人力が様々な端末及びOSで標準化された確認を行い、\n
          御客社にはサービス安定性を、ユーザーには満足を提供します。 `}
        />

        <ul className="qaService-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="item-content">
                <div className="title">{item.title}</div>
                <div className="detail22">{item.detail}</div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default QAService;

const data = [
  {
    img: img1,
    title: "機能テスト",
    detail: `明細レビュー及びテスト \n 設計テクニック機能テスト、 \n テスト環境構築 \n テスト及び不具合トラッキング`,
  },
  {
    img: img2,
    title: "互換性テスト",
    detail: `様々な端末及び環境からのテスト、 \n 仕様(最低/勧奨)及び基準環境テスト、\n モバイル端末及びOS別PC OS / IE別 \n ダウンロード及び実行テスト`,
  },
  {
    img: img3,
    title: "メンテナンステスト",
    detail: `アップデート及びサービスイッシュ対応\nアップデート内訳に関する機能テスト\nライブイッシュの再現及びレポート`,
  },
  {
    img: img4,
    title: "その他、品質保証内容",
    detail: `国内サービスのための\n現地化テストフィルタリングなどの\nローカルライジングテスト品質向上、\n現地化バランステスト、\nユーザーの円滑な使用間のためのFGT進行`,
  },
];
