import React from "react";
import img1 from "../../../../Assets/Images/img-platform-function-01@3x.a35d6c5c54b66eac2ba3_cn.png";
import img2 from "../../../../Assets/Images/img-platform-function-02@3x.dc9d14bc9f3c166175f6_cn.png";
import img3 from "../../../../Assets/Images/img-platform-function-03@3x.bac07996fd58dbff576b_cn.png";
import img4 from "../../../../Assets/Images/img-platform-function-04@3x.88302ed3611be7d453aa_cn.png";
import "./styles.scss";
function Instruction() {
  return (
    <div className="instruction">
      {data.map((item, index) => (
        <div
          key={index}
          style={{
            background: item.backgroundColor,
          }}
          className="instruction-container"
        >
          <div
            style={{ flexDirection: index % 2 !== 0 ? "row-reverse" : "row" }}
            className="instruction-container-item"
          >
            <div className="content">
              <div className="title">{item.title}</div>
              <ul className="detail">
                <li>{item.detail1}</li>
                <li>{item.detail2}</li>
                <li>{item.detail3}</li>
              </ul>
            </div>
            <div
              style={{
                justifyContent: index % 2 !== 0 ? "flex-start" : "flex-end",
              }}
              className="img"
            >
              <img src={item.img} alt="" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Instruction;

const data = [
  {
    title: `按咨询类型分类并创建信息输入表格`,
    detail1: "·  大类中，通过分门别类的方式对咨询类型进行分类",
    detail2: "·  在中类别下注册小类别来创建输入便签",
    detail3: "·  利用类别快速掌握及应对热点问题",
    img: img1,
    backgroundColor: "#fff",
  },
  {
    title: `自动输入客户信息`,
    detail1: "·  可与客户数据咨询表单自动绑定",
    detail2: "·  自动收集客户UID及昵称，提升客户便利性",
    detail3:
      "·  通过设备信息路径环境， OS, 轻松采集客户信息",
    img: img2,
    backgroundColor: "#f1f8ff",
  },
  {
    title: `客户管理`,
    detail1: "·  VIP、黑名单等客户类型可分类",
    detail2: "·  提供VIP、通知功能、黑名单咨询等定制服务",
    detail3: "·  通过备忘录记录客户倾向特征",
    img: img3,
    backgroundColor: "#fff",
  },
  {
    title: `咨询员管理`,
    detail1: "· 根据职务按咨询员级别划分权限",
    detail2: "·  根据咨询员的职责决定服务和票券分配",
    img: img4,
    backgroundColor: "#f1f8ff",
  },
];
