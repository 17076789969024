import React from "react";
import Title from "../../../Common/Title";
// import bg from "../../../../Assets/Images/map-platform.png"
import img from "../../../../Assets/Images/memberimg.png";
import item1 from "../../../../Assets/Images/reason022.png";
import item2 from "../../../../Assets/Images/reason011.png";
import item3 from "../../../../Assets/Images/reason033.png";
import "./styles.scss";

function MemberMap() {
  return (
    <div className="memberMap">
      {/* <div className="memberMap-bg">
            <img src={bg} alt="" />
        </div> */}

      <div className="memberMap-container">
        <Title title={<>{}</>} />
        <div className="map">{/* <img src={img} alt="" /> */}</div>

        <div className="map-list">
          {dataMap.map((item, index) => (
            <div className="item" key={index}>
              <div className="img">
                <img src={item.img} alt="" />
              </div>
              <div className="content">
                <div className="title">{item.title}</div>
                <div className="detail">{item.detail}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MemberMap;

const dataMap = [
  {
    img: item1,
    title: "我進行了什麼詢問？",
    detail: "確認我在CSbye上的所有諮詢和答复！",
  },
  {
    img: item2,
    title: "每日訪問時提供積攢積分的機會",
    detail: "僅加入就立即支付2000P！",
  },
  {
    img: item3,
    title: "如果問題沒有完全解決呢？",
    detail: "確認答復後可在同一服務內追加諮詢",
  },
];
