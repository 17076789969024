import React from "react";
import Button from "../../../Common/Button";
import Title2 from "../../../Common/Title2";
import img1 from "../../../../Assets/Images/ic-platform-reason-01@3x2.png";
import img2 from "../../../../Assets/Images/ic-platform-reason-02@3x2.png";
import img3 from "../../../../Assets/Images/ic-platform-reason-03@3x2.png";
import img4 from "../../../../Assets/Images/ic-platform-reason-04@3x2.png";
import img5 from "../../../../Assets/Images/ic-platform-reason-05@3x2.png";
import "./styles.scss";

function mreason() {
  return (
    <div className="reason">
      <div className="reason-container">
        <Title2
          detail="The questions about CS, Confirmation process are good BYE~
          Check all my inquiry replies at CSbye!"
          title={
            <>
              Why do I have to sign-up for<span>CSbye</span>
            </>
          }
        />
        <ul className="reason-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="title_men">{item.title}</div>
              <div className="detail_men">{item.detail}</div>
            </li>
          ))}
        </ul>
        {/* <div className="reason-container-button">
          <Button onClick={() => {alert('준비중입니다.')}} text="무료로 체험하기" rightIcon type="outline" />
        </div> */}
      </div>
    </div>
  );
}

export default mreason;

const data = [
  {
    img: img1,
    title: `Check service \ninquiries\n\n\n`,
    detail: `You can check your service inquiries all in one place.\n\n\n\n\n\n`,
  },
  {
    img: img2,
    title: `Manage replies \nand receive \nadditional inquiries\n\n`,
    detail: `Inquiry reply status is checkable and additional inquiries can be received.\n\n\n\n\n`,
  },
  {
    img: img3,
    title: `Service notice \nand customized \nadvertisement\n\n`,
    detail: `Check service notices, \nsuch as events and\n new game releases, \nand recommend \ncustomized advertisements.\n\n\n`,
  },
  {
    img: img4,
    title: `Point accumulation\n & exchange \n(under development)\n\n`,
    detail: `Points are accumulated \nonly through CSbye activities, \nsuch as receiving inquiries, \nclicking banners,\n and reviewing star ratings, \nand then exchanged \nfor various benefits.`,
  },
  {
    img: img5,
    title: `Easy login\n and membership \nregistration through\n Social Media accounts`,
    detail: `CSbye service is \neasily available with \nthe Social Media accounts\n you are currently using.\n\n\n\n`,
  },
  

];
