import imgBanner from "../../../../Assets/Images/img-price-banner@3x.png";

import { useNavigate } from "react-router-dom";
import basicIcon from "../../../../Assets/Images/basic@3x.png";
import customizeIcon from "../../../../Assets/Images/customize@3x.png";
import freeIcon from "../../../../Assets/Images/free@3x.png";
import SubImg5 from "../../../../Assets/Images/ic-price-basic@3x.png";
import SubImg4 from "../../../../Assets/Images/ic-price-customize@3x.png";
import SubImg2 from "../../../../Assets/Images/ic-price-premium@3x.png";
import SubImg3 from "../../../../Assets/Images/ic-price-special@3x.png";
import SubImg1 from "../../../../Assets/Images/ic-price-standard@3x.png";
import premiumIcon from "../../../../Assets/Images/premium@3x.png";
import specialIcon from "../../../../Assets/Images/special@3x.png";
import Button from "../../../Common/Button";
import { RoutesString } from "../../../Modules/routesString";

import * as Scroll from "react-scroll";
import "./styles.scss";

function PriceBanner() {
  const navigation = useNavigate();
  const scroll = Scroll.animateScroll;

  const dataPriceTypes = [
    {
      type: "Basic",
      quantity: "For 1 person",
      img: SubImg1,
      sheet: "per sheet",
      title: "Free",
      iconCheck: freeIcon,
      feature: (
        <>
          {/* <span style={{ color: "#b5b5b5" }}>+Free </span> 의 모든 기능 */}
        </>
      ),
      detail: [
        {
          content: `Only <span>the basic consultation features</span> that is necessary`,
        },
        {
          content: `Provides 60 inquiry replies per day`,
        },
      ],
      textButton: "Start Free",
      mainColor: "#b8daff",
      onPressButton: () => {
        scroll.scrollToTop();
        navigation(RoutesString.free_en);
      },
    },
    {
      type: "Basic+",
      quantity: "For 1-2 people, small teams",
      img: SubImg5,
      sheet: "per sheet",
      title: "19,000won every month",
      iconCheck: basicIcon,
      mainColor: "#90c4fd",
      feature: (
        <>
          All features of +<span style={{ color: "#b8daff" }}> Basic </span> 
        </>
      ),
      detail: [
        {
          content: `Smooth communication from receipt of inquiries to replies`,
        },
        {
          content: `Customize the counseling center that suits you`,
        },
        {
          content: `Basic system suitable for small teams`,
        },
      ],
      textButton: "Buy",
      onPressButton: () => {
        alert("Login is required.");
        scroll.scrollToTop();
        navigation(RoutesString.domainLogin);
      },
    },
    {
      type: "Premium",
      quantity: "For large team",
      img: SubImg2,
      sheet: "per sheet",
      title: "29,000won every month",
      iconCheck: premiumIcon,
      mainColor: "#228cff",
      feature: (
        <>
          All features of +<span style={{ color: "#90c4fd" }}> Basic+</span>
        </>
      ),
      detail: [
        {
          content: `Real-time confirmation through SMS / APP linkage`,
        },
        {
          content: `Consultation-specific functions such as memos, reservations, assignments, etc.`,
        },
        {
          content: `Simultaneous use of multiple services`,
        },
        {
          content: `Provide report form`,
        },
      ],

      textButton: "Buy",
      onPressButton: () => {
        alert("Login is required.");
        scroll.scrollToTop();
        navigation(RoutesString.domainLogin);
      },
    },
    {
      type: "Special",
      quantity: "For large-scale collaboration",
      img: SubImg3,
      sheet: "per sheet",
      mainColor: "#0062f9",
      iconCheck: specialIcon,
      title: "39,000won every month",
      feature: (
        <>
          All features of +<span style={{ color: "#228cff" }}> Premium</span>
        </>
      ),
      detail: [
        {
          content: `Circular assignment of inquiries within the service`,
        },
        {
          content: `Download report data`,
        },
        {
          content: `Use full platform features`,
        },
        {
          content: `Email ad keyword notification feature`,
        },
        {
          content: `All inquiries, banner settings`,
        },
        {
          content: `Privilege settings by counselor level`,
        },
      ],

      textButton: "Buy",
      onPressButton: () => {
        alert("Login is required.");
        scroll.scrollToTop();
        navigation(RoutesString.domainLogin);
      },
    },
    {
      type: "Customize",
      quantity: "Workforce service available",
      img: SubImg4,
      iconCheck: customizeIcon,
      sheet: "per sheet",
      mainColor: "#222222",
      title: "after Consolation",
      feature: (
        <>
          All features of +<span style={{ color: "#0062f9" }}> Special</span>
        </>
      ),
      detail: [
        {
          content: `Adjust the number of service registrations`,
        },
        {
          content: `Adjust the number of counselors' seats`,
        },
        {
          content: `Add or delete optional features`,
        },
        {
          content: `Operational Personnel Service Inquiry`,
        },
        {
          content: `Personal member advertisement banner registration`,
        },
      ],
      textButton: "Inquire",
      onPressButton: () => {
        scroll.scrollToTop();
        navigation(RoutesString.Customer_en);
      },
    },
  ];

  return (
    <div className="priceBanner">
      <div className="priceBanner-container">
        <div className="priceBanner-container-content">
          <div className="content">
            <p className="bold">Only the services you need according to the size of your business,</p>
            <p>ADD-UP CSbye Plan.</p>
            <div className="detail">
            With this customized platform that allows you to directly set the wanted functions,
            you can quickly and efficiently manage various issues such as
            checking the inquiry channel and delaying response times.
            Now communicate with your customers directly.

            </div>
          </div>
          <div className="img">
            <img src={imgBanner} alt="" />
          </div>
        </div>

        <div className="priceTypes">
          {dataPriceTypes.map((item, index) => (
            <PriceType
              key={index}
              type={item.type}
              quantity={item.quantity}
              img={item.img}
              sheet={item.sheet}
              title={item.title}
              feature={item.feature}
              detail={item.detail}
              textButton={item.textButton}
              onPressButton={item.onPressButton}
              mainColor={item.mainColor}
              iconCheck={item.iconCheck}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default PriceBanner;

interface Props {
  type: string;
  quantity: string;
  img: any;
  sheet: string;
  title: string;
  feature?: any;
  detail: { content: any }[];
  textButton: string;
  onPressButton: () => void;
  mainColor: string;
  iconCheck:any
}
const PriceType = ({
  type,
  quantity,
  img,
  sheet,
  title,
  detail,
  textButton,
  onPressButton,
  feature,
  mainColor,
  iconCheck
}: Props) => {
  return (
    <div className="priceType">
      <div className="priceType-container">
        <div style={{ background: mainColor }} className="type">
          <span>{type}</span>
        </div>

        <div className="content">
          <div className="quantity">{quantity}</div>
          <div className="img">
            <img src={img} alt="" />
          </div>
          <div className="sheet">{sheet}</div>
          <div className="title">{title}</div>
          <div className="feature">{feature && feature}</div>
          <div className="detail">
            {detail.map((item, index) => (
              <div key={index} className="detail-item">
                <img src={iconCheck} alt="" />
                <div
                  className="detail-item-content"
                  dangerouslySetInnerHTML={{ __html: item.content }}
                ></div>
              </div>
            ))}
          </div>
        </div>

        <div className="button">
          <Button
            onClick={onPressButton}
            className="button-price"
            text={textButton}
            type={"primary"}
            style={{ background: mainColor }}
          />
        </div>
      </div>
    </div>
  );
};
