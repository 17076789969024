import React from "react";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/ic-whatis-mornitoring-01@3x.png";
import img2 from "../../../../Assets/Images/ic-whatis-mornitoring-02@3x.png";
import img3 from "../../../../Assets/Images/ic-whatis-mornitoring-03@3x.png";
import img4 from "../../../../Assets/Images/ic-whatis-mornitoring-04@3x.png";
import "./styles.scss";
import Button from "../../../Common/Button";
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../../Modules/routesString";
import * as Scroll from "react-scroll"

function MornitoringService() {
  const navigation = useNavigate()
  const scroll = Scroll.animateScroll
  return (
    <div className="mornitoringService">
      <div className="mornitoringService-container">
        <Title
          title={
            <>
              監控服務  <span>Mornitoring Service</span>
            </>
          }
          detail={`為了應對隨時可能發生的意外故障和在監控脆弱時間段也能實時掌控用戶的趨勢，\n
          通過專業監控人員，可以實現全年365天、每天24小時的管理。\n
          為了避免顧客公司的服務出現異常症狀，我們ADD-UP每小時都會進行密切監控。`}
        />

        <ul className="mornitoringService-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="content">
                <div className="title">{item.title}</div>
                <div className="detail">{item.detail}</div>
              </div>
            </li>
          ))}
        </ul>

            {/* <div className="mornitoringService-container-button">
                <Button onClick={() => {
                  scroll.scrollToTop()
                  navigation(RoutesString.Customer)
                  }} rightIcon text="서비스 문의하기" />
            </div> */}

      </div>
    </div>
  );
}

export default MornitoringService;

const data = [
  {
    img: img1,
    title: "24小時實時應對 ",
    detail: `隨時傳達監控情況，監控論壇及網站，\n監控遊戲登錄`,
  },
  {
    img: img2,
    title: "市場評論應對 ",
    detail: `確認Google、App Store、One Store等上的評論，確認並回複評論，管理各種活動`,
  },
  {
    img: img3,
    title: "故障傳播",
    detail: `發生故障時立即傳播、利用有線電話和郵件進行傳播以及根據要求先採取措施（公告）`,
  },
  {
    img: img4,
    title: "提供報告",
    detail: `提供不同情況的報告，傳達不同時間的監控報告，收集和共享主要意見`,
  },
];
