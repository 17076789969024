import React from "react";
import Title from "../../../Common/Title";
import imgM from "../../../../Assets/Images/ic-price-basic.png";
import img1 from "../../../../Assets/Images/60_60 01.png";
import img2 from "../../../../Assets/Images/60_60 02.png";
import img3 from "../../../../Assets/Images/60_60 03.png";
import img4 from "../../../../Assets/Images/60_60 04.png";
import img5 from "../../../../Assets/Images/60_60 05.png";
import img6 from "../../../../Assets/Images/60_60 06.png";
import img7 from "../../../../Assets/Images/60_60 07.png";
import "./styles.scss";
import Button from "../../../Common/Button";
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../../Modules/routesString";
import * as Scroll from "react-scroll"


function FREEservice() {
  const navigation = useNavigate()
  const scroll = Scroll.animateScroll
  return (
    <div className="FREEservice">
      <div className="FREEservice-container">
        <Title
          title={
            <>
              <span style={{ color: "#fff" }}> CSBYEホームBasic
</span>
            </>
          }
          detail={'始まったばかりのスタートアップのために、CRMホーム無料バージョン!'}
          
        />
         <div className="FREEVversion">
            <div className="FREEVversion22">
<ul className="basic_M">
<li className="imgMM">
<img src={imgM} alt="" />
</li>
<li>
<ul className="basic__M">
<li className="BASIC"><span style={{ color: "#fff" }}>Basic</span> </li>
<li className="BASIC22">- 基本提供機能 -</li>
<li className="BASIC33">サービス登録1個</li>
<li className="BASIC33">登録相談員(管理者) 1名</li>
<li className="BASIC33">一日ご返答回数最大60件まで可能</li>


</ul>
</li>

</ul>

</div>
        </div>


        <ul className="FREEservice-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="title">{item.title}</div>
              <hr className="linee"></hr>
              <div className="detail">{item.detail}</div>
            </li>
          ))}
        </ul>
        <a href="https://www.csbye.com/domainlogin">
        <div className="mornitoringService-container-button">
                <Button onClick={() => {
                  }} rightIcon text="CSBYEホーム無料で始める" />
            </div> </a>



      </div>
    </div>
  );
}

export default FREEservice;

const data = [
  {
    img: img1,
    title: "サービス御客ポータル運営",
    detail: `· 御客ポータル生成 \n · FAQ、約款/政策、重要お知らせ追加 \n · お問い合わせタイプ別分類 \n · チケット細部記載項目生成`,
  },
  {
    img: img2,
    title: "ご返答便意機能",
    detail: `· 重複お問い合わせ一括処理 \n · 御客情報及びお問い合わせ内訳確認 \n · 御客情報収集API`,
  },
  {
    img: img3,
    title: "相談便意機能",
    detail: `· お問い合わせ受付/返答メールデザイン \n · ご返答タンプリっト登録 \n · 返答プレビュー及びチケット隠す`,
  },
  {
    img: img4,
    title: "御客管理",
    detail: `· VIP登録及びお問い合わせ優先処理 \n · Blackお問い合わせ間接露出`,
  },
  {
    img: img5,
    title: "協業",
    detail: `· チケットメモ \n · チケット自動割り当て`,
  },
  {
    img: img6,
    title: "レポート",
    detail: `· サービス別お問い合わせ処理現況レポート`,
  },
  {
    img: img7,
    title: "利用支援",
    detail: `· メール連動支援`,
  },
];
