import React from "react";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/ic-whatis-mornitoring-01@3x.png";
import img2 from "../../../../Assets/Images/ic-whatis-mornitoring-02@3x.png";
import img3 from "../../../../Assets/Images/ic-whatis-mornitoring-03@3x.png";
import img4 from "../../../../Assets/Images/ic-whatis-mornitoring-04@3x.png";
import "./styles.scss";
import Button from "../../../Common/Button";
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../../Modules/routesString";
import * as Scroll from "react-scroll"

function MornitoringService() {
  const navigation = useNavigate()
  const scroll = Scroll.animateScroll
  return (
    <div className="mornitoringService">
      <div className="mornitoringService-container">
        <Title
          title={
            <>
              监控服务  <span>Mornitoring Service</span>
            </>
          }
          detail={`为了应对随时可能发生的意外故障和在监控脆弱时间段也能实时掌控用户的趋势，\n
          通过专业监控人员，可以实现全年365天、每天24小时的管理。\n
          为了避免顾客公司的服务出现异常症状，我们ADD-UP每小时都会进行密切监控。`}
        />

        <ul className="mornitoringService-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="content">
                <div className="title">{item.title}</div>
                <div className="detail">{item.detail}</div>
              </div>
            </li>
          ))}
        </ul>

            {/* <div className="mornitoringService-container-button">
                <Button onClick={() => {
                  scroll.scrollToTop()
                  navigation(RoutesString.Customer)
                  }} rightIcon text="서비스 문의하기" />
            </div> */}

      </div>
    </div>
  );
}

export default MornitoringService;

const data = [
  {
    img: img1,
    title: "24小时实时应对",
    detail: `随时传达监控情况，监控论坛及网站，\n监控游戏登录`,
  },
  {
    img: img2,
    title: "市场评论应对",
    detail: `确认Google、App Store、One Store等上的评论，确认并回复评论，管理各种活动`,
  },
  {
    img: img3,
    title: "故障传播",
    detail: `发生故障时立即传播、利用有线电话和邮件进行传播以及根据要求先采取措施（公告）`,
  },
  {
    img: img4,
    title: "提供报告",
    detail: `提供不同情况的报告，传达不同时间的监控报告，收集和共享主要意见`,
  },
];
