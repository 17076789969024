import React from 'react'
import Title from '../../../Common/Title'
import img1 from "../../../../Assets/Images/img-whatis-csservice-01@3x.png"
import img2 from "../../../../Assets/Images/img-whatis-csservice-02@3x.png"
import img3 from "../../../../Assets/Images/img-whatis-csservice-03@3x.png"
import img4 from "../../../../Assets/Images/img-whatis-csservice-04@3x.png"
import "./styles.scss"
function CSService() {
  return (
    <div className='csService'>
        <Title title={<>
            客戶運營管理服務 <span>CS Service</span>
        </>}
        detail={`這是一個隨著消費者品味和標準的多樣化，更細緻、更準確的客戶滿意度服務變得非常重要的時代。\n
        CS專業諮詢人員以顧客管理經驗為基礎, 系統地應對消費者諮詢, 提供快速、準確的答复，努力提高顧客滿意度。`}
        />

        <ul className="csService-list">
            {data.map((item,index) => (
                <li key={index} className='csService-list-item'>
                    <img src={item.img} alt="" />
                    <div className="content">
                        <div className="title">{item.title}</div>
                        <div className="detail">
                            <p>{item.detail1}</p>
                            <p>{item.detail2}</p>
                            <p>{item.detail3}</p>
                            <p>{item.detail4}</p>
                            <p>{item.detail5}</p>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    </div>
  )
}

export default CSService

const data = [
    {
        img: img1,
        title: '市場與社區',
        detail1: '·  與用戶進行溝通及護理',
        detail2: '·  投訴趨勢分析與提取',
        detail3: '·  通過公告和回復等進行溝通',
        detail4: '·  立即確認遊戲中的錯誤',
        detail5: '·  英文/中文/日文自動翻譯功能',
    },
    {
        img: img2,
        title: 'VOC（統計）報告',
        detail1: '·  按週期識別趨勢，提示服務方向',
        detail2: '·  通過諮詢確認趨勢',
        detail3: '·  論壇及評論現狀統計',
        detail4: '·  收集各種建議',
        detail5: '·  提供日報、週報和月報',
    },
    {
        img: img3,
        title: '結算及退款管理',
        detail1: '·  從受理詢問到退款的綜合管理',
        detail2: '·  進行退款及取消結算',
        detail3: '·  代辦對外公文等業務',
        detail4: '·  監控濫用退款行為',
    },
    {
        img: img4,
        title: 'VIP服務應對',
        detail1: '·  可提供VIP服務優先應對',
        detail2: '·  擁有按類型分類的系統',
        detail3: '·  擁有VIP應對程序',
        detail4: '·  終止後進行跟踪管理',
    },
]














