import React, { useState } from "react";
import "./join.scss";

// import { getDomainLoginApi } from "Apis/apis";
import H2 from "Components/Atoms/Heading/h2";
import Space from "Components/Atoms/Space/space";
import Input from "Components/Atoms/Input/input";
import { ReactComponent as Require } from "Util/Svg/require.svg";
import { ReactComponent as Check } from "Util/Svg/check.svg";
import { ReactComponent as Fail } from "Util/Svg/fail.svg";

import { setJoin } from "Util/function";
import { useNavigate } from "react-router-dom";
import { postJoinApi } from "Apis/apis";

const MAIL_REG =
  /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/;
const PW_REG = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,32}$/;

function Join() {
  const navigate = useNavigate();

  const [mail, setMail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [pw, setPw] = useState("");
  const [pwCheck, setPwCheck] = useState("");

  const pass = (): boolean => {
    if (mail.match(MAIL_REG) === null) {
      alert("이메일을 다시 입력해주세요.");
      return false;
    } else if (name === "") {
      alert("이름을 작성 해주세요.");
      return false;
    } else if (phone === "") {
      alert("전화번호를 작성 해주세요.");
      return false;
    } else if (pw.match(PW_REG) === null) {
      alert("(문자, 숫자, 특수문자 조합(최소 8자, 최대 32자)입니다.");
      return false;
    } else if (pw !== pwCheck) {
      alert("비밀번호 확인이 맞지 않습니다.");
      return false;
    }
    return true;
  };

  const emailCheck = async (e: any) => {
    e.preventDefault();
    const isPass: boolean = pass();
    if (!isPass) return;

    const body = {
      companyId: mail,
      name,
      phoneNumber: phone,
      password: pw,
    };
    const {
      data: { token },
    } = await postJoinApi(body);

    setJoin(token, mail);
    navigate("/emailcheck");
  };

  return (
    <div id="join" className="login-bg more_wrap">
      <div className="domian_login_contents p-center">
        <img className="logo" src="/imgs/logo.png" alt="" />
        <Space y={60} />
        <p>CSbye와 함께할 회원정보를 입력해 주세요</p>
        <Space y={20} />

        <form onSubmit={emailCheck}>
          {/* 이메일 */}
          <div>
            <div className="flex">
              <label className="text-primary">이메일</label>
              <Space x={4} />
              <Require />
            </div>
            <Space y={8} />
            <Input
              required
              id="mail"
              type="email"
              bottom={false}
              placeholder="이메일을 입력해주세요."
              value={mail}
              onChange={(e) => setMail(e.target.value)}
            />
          </div>
          <Space y={24} />
          {/* 이름 */}
          <div>
            <div className="flex">
              <label className="text-primary">이름</label>
              <Space x={4} />
              <Require />
            </div>
            <Space y={8} />
            <Input
              required
              id="name"
              type="text"
              bottom={false}
              placeholder="이름을 입력해주세요."
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <Space y={24} />
          {/* 전화번호 */}
          <div>
            <div className="flex">
              <label className="text-primary">전화번호</label>
              <Space x={4} />
              <Require />
            </div>
            <Space y={8} />
            <Input
              required
              id="phone"
              type="text"
              bottom={false}
              placeholder="(“-” 없이 입력, ex) 01012345678)"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <Space y={24} />
          {/* 비밀번호 확인 */}
          <div>
            <div className="flex">
              <label className="text-primary">비밀번호</label>
              <Space x={4} />
              <Require />
            </div>
            <Space y={8} />
            <div className="relative">
              <Input
                required
                id="pw"
                type="password"
                bottom={false}
                placeholder="(문자, 숫자, 특수문자 조합(최소 8자, 최대 32자)"
                value={pw}
                onChange={(e) => setPw(e.target.value)}
              />
              <div className="absolute icon">
                {pw.match(PW_REG) === null ? <Fail /> : <Check />}
              </div>
            </div>
          </div>
          <Space y={24} />
          {/* 비밀번호 */}
          <div>
            <div className="flex">
              <label className="text-primary">비밀번호</label>
              <Space x={4} />
              <Require />
            </div>
            <Space y={8} />
            <div className="relative">
              <Input
                required
                id="pwCheck"
                type="password"
                bottom={false}
                placeholder="다시 한번 입력하세요."
                value={pwCheck}
                onChange={(e) => setPwCheck(e.target.value)}
              />
              <div className="absolute icon">
                {pw !== pwCheck || pwCheck === "" ? <Fail /> : <Check />}
              </div>
            </div>
          </div>
          <Space y={24} />
          <button className="addup_btn" type="submit">
            다음
          </button>
        </form>
      </div>
    </div>
  );
}

export default Join;
