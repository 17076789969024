import React from "react";
import img1 from "../../../../Assets/Images/01-img-01.png";
import img2 from "../../../../Assets/Images/img-platform-function-02@3x.png";
import img3 from "../../../../Assets/Images/img-platform-function-03@3x.png";
import img4 from "../../../../Assets/Images/img-platform-function-04@3x.png";
import "./styles.scss";

function membermain() {
  return (
    <div className="instruction">
      {data.map((item, index) => (
        <div
          key={index}
          style={{
            background: item.backgroundColor,
          }}
          className="instruction-container"
        >
          <div
            style={{ flexDirection: index % 2 !== 0 ? "row-reverse" : "row" }}
            className="instruction-container-item"
          >
            <div className="content">
              <div className="titleFm">{item.title}</div>
              <ul className="detailFm">
                <li>{item.detail1}</li>
                <li>{item.detail2}</li>
                <li>{item.detail3}</li>
                <li>{item.detail4}</li>
              </ul>
            </div>

            <div
              style={{
                justifyContent: index % 2 !== 0 ? "flex-start" : "flex-end",
              }}
              className="img"
            >
              <img src={item.img} alt="" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default membermain;

const data = [
  {
    title: `一次確認我的諮詢明細`,
    detail1: "·  按服務分類，一次性收集分散的諮詢答复",
    detail2: "·  追加諮詢事項也由CSbye一次性解決",
    detail3: "·  也可以查詢之前顧客諮詢明細",
    detail4: "·  可接受諮詢及確認進展情況",
    img: img1,
    backgroundColor: "#63aeff",
  },
];
