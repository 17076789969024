import React, { useEffect, useState } from "react";
import "./styles.scss";
import checkIcon from "../../../../Assets/Images/ic-price-check-01@3x.png";
import img1 from "../../../../Assets/Images/ic-price-service@3x.png";
import img2 from "../../../../Assets/Images/ic-price-page@3x.png";
import img3 from "../../../../Assets/Images/ic-price-respone@3x.png";
import img4 from "../../../../Assets/Images/ic-price-function@3x.png";
import img5 from "../../../../Assets/Images/ic-price-notification@3x.png";
import img6 from "../../../../Assets/Images/ic-price-customer@3x.png";
import img7 from "../../../../Assets/Images/ic-price-global@3x.png";
import img8 from "../../../../Assets/Images/ic-price-cooperation@3x.png";
import img9 from "../../../../Assets/Images/ic-price-security@3x.png";
import img10 from "../../../../Assets/Images/ic-price-report@3x.png";
import img11 from "../../../../Assets/Images/icon-price-support@3x.png";
function PriceTable() {
  const [isMenuFixed, setIsMenuFixed] = useState(false);
  const header = document.querySelector(".headerSection") as any;

  const stickNavbar = () => {
    const menu = document.querySelector(".priceTable-tab") as any;
    const header = document.querySelector(".headerSection") as any;

    const menuOffset = menu?.offsetTop;
    const scrollPos = window.scrollY;

    if (scrollPos >= menuOffset - header?.clientHeight) {
      setIsMenuFixed(true);
    } else {
      setIsMenuFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  return (
    <div className="priceTable">
      <div className="priceTable-container">
        <div style={{ width: "100%" }} className="priceTable-tab"></div>
        <div
          style={{ top: header?.clientHeight }}
          className={`title-table ${isMenuFixed ? "fixed-menu" : ""}`}
        >
          <div className="main-title title">Planning Specific Features</div>
          <div className="detail">
            <div className="detail-item free">Basic</div>
            <div className="detail-item basic">Basic+</div>
            <div className="detail-item premium">Premium</div>
            <div className="detail-item special">Special</div>
          </div>
        </div>

        <div className="list-table">
          {data.map((item, index) => (
            <div key={index} className="table-item">
              <div className="table-item-title">
                <img src={item.imgAvt} alt="" />
                <span>{item.title}</span>
              </div>
              <div className="table-item-content">
                {item.content.map((content: any, index) => (
                  <div key={content.titleContent} className="item">
                    <div className="title">{content.titleContent}</div>
                    <div className="detail">
                      {content.value.map((child: any, index: number) => (
                        <div key={`${child}+${index}`} className="detail-child">
                          {child === true ? (
                            <img src={checkIcon} alt="checkIcon" />
                          ) : child === false ? (
                            <span>None</span>
                          ) : (
                            child
                          )}
                        </div>
                      ))}
                    </div>
                    {content.subTitContent! && (
                      <div className="subdetail">{content.subTitContent}</div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="table-note">
          <span>* Takes up to 3 business days from product payment to account creation</span>
        </div>
      </div>
    </div>
  );
}

export default PriceTable;

const data = [
  {
    imgAvt: img1,
    title: "Manage Service and customer web portal",
    content: [
      {
        titleContent: "register service",
        value: ["1", "1", "3", "6"],
      },
      {
        titleContent: "Limit the number of replies to inquiries per day",
        value: ["60", "No limit", "No limit", "No limit"],
      },
      {
        titleContent: "Create customer web portal",
        value: [true, true, true, true],
      },
      {
        titleContent: "FAQ, Terms/Policy, Key notice insertion feature",
        value: [true, true, true, true],
      },
      {
        titleContent: "Classify inquiries by type",
        value: [true, true, true, true],
      },
      {
        titleContent: "Create ticket details",
        value: [true, true, true, true],
      },
    ],
  },
  {
    imgAvt: img2,
    title: "Efficient response",
    content: [
      {
        titleContent: "Batch replies of duplicate inquiries",
        value: [true, true, true, true],
      },
      {
        titleContent: "Check customer information and inquiry details",
        value: [true, true, true, true],
      },
      {
        titleContent: "Customer information collection API",
        value: [true, true, true, true],
      },
    ],
  },

  {
    imgAvt: img3,
    title: "Consultation convenience feature",
    content: [
      {
        titleContent: "Inquiry reception mail design",
        value: [true, true, true, true],
      },
      {
        titleContent: "Inquiry response mail design",
        value: [true, true, true, true],
      },
      {
        titleContent: "Reply template registration feature",
        value: [true, true, true, true],
      },
      {
        titleContent: "Reply preview feature",
        value: [true, true, true, true],
      },
      {
        titleContent: "Ticket hiding feature",
        value: [true, true, true, true],
      },
      {
        titleContent: "Shortcut feature during counseling",
        value: [false, true, true, true],
      },
      {
        titleContent: "Labeling feature by service",
        value: [false, true, true, true],
      },
      {
        titleContent: "Reservation reply feature",
        value: [false, false, true, true],
      },
      {
        titleContent: "Temporary reply feature",
        value: [false, false, true, true],
      },
      {
        titleContent: "SMS sending feature",
        value: [false, false, true, true],
      },
    ],
  },

  {
    imgAvt: img4,
    title: "Notification service",
    content: [
      {
        titleContent: "VIP inquiry notification",
        value: [false, false, true, true],
      },
      {
        titleContent: "Notification of all inquiries",
        value: [false, false, false, true],
      },
      {
        titleContent: "Register for inquiry keyword notification (per service)",
        value: [false, false, "5", "No limit"],
      },
    ],
  },

  {
    imgAvt: img6,
    title: "Customer Management",
    content: [
      {
        titleContent: "VIP registration and priority reply of inquiries",
        value: [true, true, true, true],
      },
      {
        titleContent: "Indirect exposure of Black inquiries",
        value: [true, true, true, true],
      },
      {
        titleContent: "Customer Orientation Memmos",
        value: [false, false, true, true],
      },
      {
        titleContent: "Collect reply satisfaction",
        value: [false, false, true, true],
      },
    ],
  },

  {
    imgAvt: img7,
    title: "Globle",
    content: [
      {
        titleContent: "Run web portal by language",
        value: [false, false, "4 languages", "4 languages"],
      },
      {
        titleContent: "Run counseling center by language",
        value: [false, false, "4 languages", "4 languages"],
      },
      {
        titleContent: "Translation feature for inquiries and replies",
        value: [false, false, "4 languages", "4+@ languages"],
      },
    ],
  },

  {
    imgAvt: img8,
    title: "Cooperation",
    content: [
      {
        titleContent: "ticket memo",
        value: [true, true, true, true],
      },
      {
        titleContent: "Automatic ticket assignment",
        value: [true, true, true, true],
      },
      {
        titleContent: "ticket designation assignment",
        value: [false, true, true, true],
      },
      {
        titleContent: "Automatic ticket allocation rate control",
        value: [false, false, false, true],
      },
      {
        titleContent: "Rotation of inquiries in case of vacancy",
        value: [false, false, false, true],
      },
    ],
  },

  {
    imgAvt: img9,
    title: "security",
    content: [
      {
        titleContent: "Prevent concurrent operations on the same account",
        value: [false, true, true, true],
      },
      {
        titleContent: "Counselor service settings",
        value: [false, true, true, true],
      },
      {
        titleContent: "Check team member activity history",
        value: [false, false, true, true],
      },
      {
        titleContent: "Privilege settings by counselor level",
        value: [false, false, false, true],
      },
    ],
  },
  {
    imgAvt: img10,
    title: "Report",
    content: [
      {
        titleContent: "Inquiry processing status report by service",
        value: [true, true, true, true],
      },
      {
        titleContent: "Activity report by counselor",
        value: [false, false, true, true],
      },
      {
        titleContent: "Download report data",
        value: [false, false, false, true],
      },
    ],
  },
  {
    imgAvt: img5,
    title: "Marketing",
    content: [
      {
        titleContent: "Mail ad banner settings",
        value: [false, false, "1", "3"],
      },
      {
        titleContent: "Personal member advertisement banner registration",
        value: [false, false, true, true],
      },
    ],
  },
  {
    imgAvt: img11,
    title: "support for use",
    content: [
      {
        titleContent: "Email linkage support",
        value: [true, true, true, true],
      },
      {
        titleContent: "Inquiry about customizing products",
        value: [false, "proceed after inquiring", "proceed after inquiring", "proceed after inquiring"],
      },
      {
        titleContent: "Management workforce service Inquiries",
        value: [false, "proceed after inquiring", "proceed after inquiring", "proceed after inquiring"],
      },
    ],
  },
];
