import { useState } from "react";
import PaginatedItems from "../../../Common/Pagination";
import AccordionData from "./AccordionData";
import "./styles.scss";
import { tab } from "@testing-library/user-event/dist/tab";

function Question() {
  const [tabActive, setTabActive] = useState(0);

  return (
    <div className="question">
      <div className="question-title">週末及び祝日は休務</div>
      <div className="question-tabs">
        {tabs.map((item, index) => (
          <div
            onClick={() => {
               setTabActive(index);
            }}
            key={index}
            className={`tab ${tabActive === index ? "activeTab" : ""}`}
          >
            {item.tit}
          </div>
        ))}
      </div>
      

      <div className="question-accordion">
        
        {accordionData.map(({ type,title, content }, index) => (
          tabActive === 0 ? <AccordionData key={index} title={title} content={content} />
          :
          tabActive === type && <AccordionData key={index} title={title} content={content} />
        ))}
      </div>


      {/* <div className="question-pagination">
        <PaginatedItems />
      </div> */}
    </div>
  );
}

export default Question;

const tabs = [
  {
    id : 0,
    tit: "全体",
    router: "",
  },
  {
    id : 1,
    tit: "運営管理サービス",
    router: "",
  },
  {
    id : 2,
    tit: "ホームサービス",
    router: "",
  },
  {
    id : 3,
    tit: "決済及びお払い戻し",
    router: "",
  }
];

const accordionData = [
  //자주찾는 질문
  {
    type : 1,
    title: "CSbyeはどんな意味ですか?",
    content: `A.  ‘CSbye’は既存ADD-UPのサービスであるCS、QA、マーケティング、モニタリングサービスの統合名称で、御客運営管理(CS)にお別れを意味する ‘BYE’を付けて御客管理に関する心配とお別れできるようにサポートするADD-UPサービスの新たな名前です。`,
  },
  {
    type : 1,
    title: "Q. マーケティングサービスの中で必要なサービスだけを使用することもできますか?",
    content: `A. CSbyeサービスは企業から必要とされるサービスだけ利用することができます。\n詳しい事項はお問い合わせを通じて詳しく送ってください。`,
  },
  {
    type : 1,
    title: "Q. CSbyeサービスよりはホームだけ使用できますか?",
    content: `A. CSbyeホームを活用して自主的に御客の管理を行いたい企業のためにCSbyeホームサービスを支援しており、\n上段の価格案内でホーム利用に関する情報が確認できます。
    `,
  },
  {
    type : 1,
    title: "Q. CSbyeホームを体験したいです!",
    content: `A. CSbyeホームページ上段右側の ‘無料で体験する’ ページを通じて無料で体験できます。
    無料体験構成はPREMIUM商品であり、企業管理者の十分な検討と円滑な使用のために30日間使用で構成されております。`,
  },
  {
    type : 1,
    title: "Q. CSbyeホームのお問い合わせはどこでできますか?",
    content: `A. 御客お問い合わせページ上段の 'お問い合わせ' を通じて送ってください。確認後ご返答をお送りいたします!`,
  },
  {
    type : 1,
    title: "Q. CSbyeホームの相談員は支援できますか?",
    content: `A.  CSbyeホームはホームだけを月額で支援しており、相談員の支援は難しいです。\nただ、CSbyeサービス内CS管理にお問い合わせをくださると担当部署にお伝えいたします。`,
  },
  //플랫폼 서비스
  {
    type : 2,
    title: "Q. 加入はどうできますか?",
    content: `A. CSbyeホームページ内上段のログインページを通じて会員加入できます。`,
  },
  {
    type : 2,
    title: "Q, 企業管理者アカウント譲渡もできますか?",
    content: `A. ホーム内の個人情報修正から権限委任もできます。
    登録された相談員の中で1名に委任され、既存の企業管理者は相談員に変更されます。`,
  },
  {
    type : 2,
    title: "Q. 相談員がお休みの時、お問い合わせの自動割り当てはどうなりますか?",
    content: `A. スペシャル商品だけ利用できる機能であり、相談員が個人情報修正から直接お休みを設定するか、\n企業管理者が相談員管理で当相談員にお休みを設定するとお問い合わせ自動割り当てから除外されます。`,
  },
  {
    type : 2,
    title: "Q. ブラックリスト指定理由はどこから確認できますか?",
    content: `A. 御客リスト管理メニューから御客情報を確認できます。
    ブラックリストを選択して御客情報をご確認ください。理由は御客メモに自動的に記載されます。
    `,
  },
  {
    type : 2,
    title: "Q. 通知サービスとSMS転送はどう利用できますか?",
    content: `A. Telegramを通じてお問い合わせ通知を受け付けられます。
    通知サービス設定からTelegramトークンとChat IDを入力してください。
    SMSはaligoを通じて伝送できます。aligo APIキーを入力してください。`,
  },
  //결제 및 환불
  {
    type : 3,
    title: "Q. シートは何ですか?",
    content: `A. シートとは、利用できる相談員の数です。
    相談員数に合わせて利用されるシートを先に購入しないと相談処理ができません。
    相談員登録、削除は自由にできますが、承認状態の相談員のみご利よ云うできます。\n承認状態の相談員だけがシートに登録されます。`,
  },
  {
    type : 3,
    title: "Q. 何処で決済できますか?",
    content: `A. CSbyeサービス会員加入後、サービス内で決済できます。
    価格案内ページを参考して企業に会うプランを使用してください。`,
  },
  {
    type : 3,
    title: "Q. 商品の変更またはシート数を変更するためにはどうしたら良いでしょうか?",
    content: `A. ホーム内で情報 > 決済現況 > 商品変更から決済できます。
    商品変更及びシート変更時、既存商品がキャンセルされた後、変更された商品で再決済されます。`,
  },
  {
    type : 3,
    title: "Q. 決済が延滞されたらどうしたら良いでしょうか?",
    content: `A.決済日に決済が正常的に行われなかった場合、延滞状態になります。 

    •延滞直後 : もう御客チケットを受付されません。
    ログイン後表示されるプラン選択から決済を進行してください。 
     
   •7日延滞 : 当アカウントが停止され、ログインできなくなります。
    CSbyeにお問い合わせくださると停止されたアカウントを7日間臨時に使用できるように処理します。
    その後、ログインして案内された期間内に情報 > 決済現況からプラン決済を進行してください。 
     
  •1年以上 : 放置されるアカウントに判断され、通知メール発送後、アカウントが削除されます。`,
  },
  {
    type : 3,
    title: "Q. 商品購読のキャンセル方法を教えてください。",
    content: `A. 情報 > 決済現況から商品自動決済を解除できます。サービスは最近商品決済期間の一か月後まで使用できます。`,
  },
  {
    type : 3,
    title: "Q. お払い戻し規定が知りたいです。",
    content: `A. お払い戻し規定をご案内いたします。
    1. サービス利用料決済後、サービス利用前に需要企業の単純変心またはその他の理由によってサービス使用をキャンセルする場合、\n当金額は使用された期間程精算後お払い戻しの処理になります。
    2. 使用者がサービス利用後、やむを得ない理由でサービス利用を中断する場合には、\n当意思を供給企業にお伝えして供給企業は即時サービス提供中止及び残余サービス期間程利用料をお払い戻し処置します。
    3. 決済されたサービス商品に関するキャンセル(払い戻し)が決済期限が経過された後に発生する場合、\n当キャンセル(払い戻し)金額は回収対象に含まれます。
    
    注文キャンセル時、決済金額払い戻し期間
    · クレジットカード : 回収完了された日から3営業日内にカード承認キャンセル
    · チェックカード : 回収完了された日から3営業日内出金されたカード口座に入金
    · 仮想口座 : 回収完了日から3営業日内払戻口座に入金
    · その他 : 回収完了日から3営業日内に払い戻し`
  }
];
