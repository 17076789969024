import React, { useLayoutEffect } from "react";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/02-icon-1@3x.png";
import img2 from "../../../../Assets/Images/02-icon-2@3x.png";
import img3 from "../../../../Assets/Images/02-icon-3@3x.png";
import img4 from "../../../../Assets/Images/02-icon-4@3x.png";
import img5 from "../../../../Assets/Images/02-icon-5@3x.png";
import img6 from "../../../../Assets/Images/02-icon-6@3x.png";
import img7 from "../../../../Assets/Images/02-icon-7@3x.png";
import img8 from "../../../../Assets/Images/02-icon-8@3x.png";
import img9 from "../../../../Assets/Images/02-icon-9@3x.png";
import img10 from "../../../../Assets/Images/02-icon-10@3x.png";
import "./styles.scss";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap, { Power4 } from "gsap";

gsap.registerPlugin(ScrollTrigger);
function Server() {
  //animation
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      let timeline = gsap.timeline({
        scrollTrigger: {
          trigger: `.server-container`,
          start: "top center",
        },
      });
      data.map((item, index) => {
        timeline.from(
          `.item-${index}`,
          {
            scale: 0.1,
            duration: 2,
            autoAlpha: 0,
            ease: Power4.easeOut,
          },
          `frame1+=${index / data.length}`
        );
      });
    });

    return () => ctx.revert();
  }, []);

  return (
    <div className="server">
      <div className="server-container">
        <Title
          title={
            <div>
              ADD-UPサービスの新たな名前、<span>CSbye</span>
            </div>
          }
          detail={`消費者の好みと基準が多様になり、より細かく正確な御客満足サービスが重要になった時代です。\n
          CS専門相談人力たちは御客管理経験をもとに、消費者お問い合わせを体系的に対応して、速やかで正確な \n
          ご返答を通じて御客満足度を高めるために努力します。`}
        />

        <ul className="server-list">
          {data.map((item, index) => (
            <li key={index} className={`server-list-item item-${index}`}>
              <img src={item.img} alt="" />
              <p>{item.title}</p>
            </li>
          ))}
        </ul>
      </div>

      <div className="one">
        <div className="two"></div>
      </div>
    </div>
  );
}

export default Server;

const data = [
  {
    img: img1,
    title: "様々なチャンネルの \n お問い合わせを一所で確認",
  },
  {
    img: img2,
    title: "すべての御客 \n お問い合わせを \n 速やかに処理",
  },
  {
    img: img3,
    title: "消費者相談 \n 履歴管理",
  },
  {
    img: img4,
    title: "英語、日本語、\n 中国語支援可能",
  },
  {
    img: img5,
    title: "一日、週刊、月刊 \n レポート提供",
  },
  {
    img: img6,
    title: "製品品質検証 \n QA業務随行",
  },
  {
    img: img7,
    title: "マーケティング企画 \n 及び運営業務可能",
  },
  {
    img: img8,
    title: "ゲーム及び \n 御客動向など \n 24時間 \n モニタリング",
  },
  {
    img: img9,
    title: "人力管理 \n 負担減少及び \n 人力活用柔軟化",
  },
  {
    img: img10,
    title: "長期契約時、 \n 割引恵沢適用",
  },
];
