import React from "react";
import Button from "../../../Common/Button";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/ic-platform-reason-01@3x.png";
import img2 from "../../../../Assets/Images/ic-platform-reason-02@3x.png";
import img3 from "../../../../Assets/Images/ic-platform-reason-03@3x.png";
import img4 from "../../../../Assets/Images/ic-platform-reason-04@3x.png";
import img5 from "../../../../Assets/Images/ic-platform-reason-05@3x.png";
import "./styles.scss";

function Reason() {
  return (
    <div className="reason3">
      <div className="reason3-container">
        <Title
          detail="効率的御客管理の始まり、ADD-UP CSbyeホーム"
          title={
            <>
              <span>CSbye ホーム</span>を選択する理由
            </>
          }
        />
        <ul className="reason3-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="title45">{item.title}</div>
              <div className="detail45">{item.detail}</div>
            </li>
          ))}
        </ul>
        {/* <div className="reason-container-button">
          <Button onClick={() => {alert('준비중입니다.')}} text="무료로 체험하기" rightIcon type="outline" />
        </div> */}
      </div>
    </div>
  );
}

export default Reason;

const data = [
  {
    img: img1,
    title: `御客相談特化\nWebホーム構成\n\n`,
    detail: `御客相談特化Webホームで\n速やかに御客と疎通して問題について解決できます。\n\n`,
  },
  {
    img: img2,
    title: `英·中·日 \n 自動翻訳機能\n\n`,
    detail: `3ヶ国語自動翻訳機能を通じて人力採用費用とグローバル的な進出をサポートします。\n\n`,
  },
  {
    img: img3,
    title: `VIP応対\nサービス支援\n\n`,
    detail: `VIP応対サービスを通じて\n集中管理されるべき御客を\n分類してサービスを\n提供できます。\n`,
  },
  {
    img: img4,
    title: `リアルタイムイッシュ\n確認統計利用抽出\n\n`,
    detail: `突然発生するイッシュ達を\n一目で把握できる統計資料の抽出で速やかに解決できる\nようにサポートします。`,
  },
  {
    img: img5,
    title: `オーダーメイド設定\n機能で容易くなる\n御客管理運営`,
    detail: `欲しいサービスだけを\n利用して低費用高効率の御客管理運営をサポートします。\n\n`,
  },
];
