import imgBanner from "../../../../Assets/Images/img-price-banner@3x.png";

import { useNavigate } from "react-router-dom";
import basicIcon from "../../../../Assets/Images/basic@3x.png";
import customizeIcon from "../../../../Assets/Images/customize@3x.png";
import freeIcon from "../../../../Assets/Images/free@3x.png";
import SubImg5 from "../../../../Assets/Images/ic-price-basic@3x.png";
import SubImg4 from "../../../../Assets/Images/ic-price-customize@3x.png";
import SubImg2 from "../../../../Assets/Images/ic-price-premium@3x.png";
import SubImg3 from "../../../../Assets/Images/ic-price-special@3x.png";
import SubImg1 from "../../../../Assets/Images/ic-price-standard@3x.png";
import premiumIcon from "../../../../Assets/Images/premium@3x.png";
import specialIcon from "../../../../Assets/Images/special@3x.png";
import Button from "../../../Common/Button";
import { RoutesString } from "../../../Modules/routesString";

import * as Scroll from "react-scroll";
import "./styles.scss";

function PriceBanner() {
  const navigation = useNavigate();
  const scroll = Scroll.animateScroll;

  const dataPriceTypes = [
    {
      type: "Basic",
      quantity: "1人使用",
      img: SubImg1,
      sheet: "每個坐席",
      title: "免費",
      iconCheck: freeIcon,
      feature: (
        <>
          {/* <span style={{ color: "#b5b5b5" }}>+Free </span> 의 모든 기능 */}
        </>
      ),
      detail: [
        {
          content: `只提供必需的基本諮詢功能`,
        },
        {
          content: `每天提供60個諮詢回复`,
        },
      ],
      textButton: "免費開始使用。",
      mainColor: "#b8daff",
      onPressButton: () => {
        scroll.scrollToTop();
        navigation(RoutesString.free_tw);
      },
    },
    {
      type: "Basic+",
      quantity: "非常適合 1-2 人、小規模團隊",
      img: SubImg5,
      sheet: "每個坐席",
      title: "每月 19,000 韓元",
      iconCheck: basicIcon,
      mainColor: "#90c4fd",
      feature: (
        <>
          +<span style={{ color: "#b8daff" }}> Basic </span> 的所有功能
        </>
      ),
      detail: [
        {
          content: `從收到諮詢到得到答复的順暢溝通`,
        },
        {
          content: `設置適合自己的諮詢中心`,
        },
        {
          content: `適合小型團隊的基本系統`,
        },
      ],
      textButton: "結算",
      onPressButton: () => {
        alert("需要登錄");
        scroll.scrollToTop();
        navigation(RoutesString.domainLogin);
      },
    },
    {
      type: "Premium",
      quantity: "非常適合大型團隊",
      img: SubImg2,
      sheet: "每個坐席",
      title: "每月 29,000 韓元",
      iconCheck: premiumIcon,
      mainColor: "#228cff",
      feature: (
        <>
          +<span style={{ color: "#90c4fd" }}> Basic+</span> 收費制的所有功能
        </>
      ),
      detail: [
        {
          content: `通過SMS/ APP聯動進行實時確認`,
        },
        {
          content: `備忘錄、預約、分配等諮詢特色功能`,
        },
        {
          content: `同時使用多種服務`,
        },
        {
          content: `提供報告格式`,
        },
      ],

      textButton: "結算",
      onPressButton: () => {
        alert("需要登錄");
        scroll.scrollToTop();
        navigation(RoutesString.domainLogin);
      },
    },
    {
      type: "Special",
      quantity: "非常適合大規模合作",
      img: SubImg3,
      sheet: "每個坐席",
      mainColor: "#0062f9",
      iconCheck: specialIcon,
      title: "每月 39,000 韓元",
      feature: (
        <>
          +<span style={{ color: "#228cff" }}> Premium</span> 收費制的所有功能
        </>
      ),
      detail: [
        {
          content: `服務內部諮詢循環分配`,
        },
        {
          content: `下載報告數據`,
        },
        {
          content: `使用全平台功能`,
        },
        {
          content: `郵件廣告關鍵詞通知功能`,
        },
        {
          content: `所有查詢、橫幅設置`,
        },
        {
          content: `按諮詢員級別設置權限`,
        },
      ],

      textButton: "結算",
      onPressButton: () => {
        alert("需要登錄");
        scroll.scrollToTop();
        navigation(RoutesString.domainLogin);
      },
    },
    {
      type: "Customize",
      quantity: "可提供人力服務",
      img: SubImg4,
      iconCheck: customizeIcon,
      sheet: "每個坐席",
      mainColor: "#222222",
      title: "商談後決定",
      feature: (
        <>
          +<span style={{ color: "#0062f9" }}> Special</span> 收費制的所有功能
        </>
      ),
      detail: [
        {
          content: `調整服務註冊數量`,
        },
        {
          content: `調整諮詢員篇數量`,
        },
        {
          content: `添加或刪除選擇性功能`,
        },
        {
          content: `運營人員服務諮詢`,
        },
        {
          content: `註冊個人會員廣告橫幅`,
        },
      ],
      textButton: "聯繫我們",
      onPressButton: () => {
        scroll.scrollToTop();
        navigation(RoutesString.Customer_tw);
      },
    },
  ];

  return (
    <div className="priceBanner">
      <div className="priceBanner-container">
        <div className="priceBanner-container-content">
          <div className="content">
          <p>ADD-UP CSbye 計劃</p>
            <p className="bold">根據企業規模提供需要的服務</p>
            <div className="detail">
            通過直接設置所需功能的定制平台結構確認諮詢渠道，<br></br>
能夠快速高效地管理回复時間延遲等各種問題。<br></br>
現在直接和客戶進行溝通吧。
            </div>
          </div>
          <div className="img">
            <img src={imgBanner} alt="" />
          </div>
        </div>

        <div className="priceTypes">
          {dataPriceTypes.map((item, index) => (
            <PriceType
              key={index}
              type={item.type}
              quantity={item.quantity}
              img={item.img}
              sheet={item.sheet}
              title={item.title}
              feature={item.feature}
              detail={item.detail}
              textButton={item.textButton}
              onPressButton={item.onPressButton}
              mainColor={item.mainColor}
              iconCheck={item.iconCheck}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default PriceBanner;

interface Props {
  type: string;
  quantity: string;
  img: any;
  sheet: string;
  title: string;
  feature?: any;
  detail: { content: any }[];
  textButton: string;
  onPressButton: () => void;
  mainColor: string;
  iconCheck:any
}
const PriceType = ({
  type,
  quantity,
  img,
  sheet,
  title,
  detail,
  textButton,
  onPressButton,
  feature,
  mainColor,
  iconCheck
}: Props) => {
  return (
    <div className="priceType">
      <div className="priceType-container">
        <div style={{ background: mainColor }} className="type">
          <span>{type}</span>
        </div>

        <div className="content">
          <div className="quantity">{quantity}</div>
          <div className="img">
            <img src={img} alt="" />
          </div>
          <div className="sheet">{sheet}</div>
          <div className="title">{title}</div>
          <div className="feature">{feature && feature}</div>
          <div className="detail">
            {detail.map((item, index) => (
              <div key={index} className="detail-item">
                <img src={iconCheck} alt="" />
                <div
                  className="detail-item-content"
                  dangerouslySetInnerHTML={{ __html: item.content }}
                ></div>
              </div>
            ))}
          </div>
        </div>

        <div className="button">
          <Button
            onClick={onPressButton}
            className="button-price"
            text={textButton}
            type={"primary"}
            style={{ background: mainColor }}
          />
        </div>
      </div>
    </div>
  );
};
