import React from 'react'
import Policy from './Policy'
import PriceBanner from './PriceBanner'
import PriceTable from './PriceTable'

function Price() {
  return (
    <main>
      <PriceBanner />
      <PriceTable />
      {/* <Policy /> */}
    </main>
  )
}

export default Price