import "./styles.scss";
import Space from "Components/Atoms/Space/space";
import { Link } from "react-router-dom";

function Choice() {
  return (
    <div id="domian-login" className="login-bg more_wrap">
      <div className="domian_login_contents p-center">
        <img className="choice_logo" src="/imgs/logo.png" alt="" />

        <Space y={24} />
        <div className="flex justify-between">
          <a href="https://member.csbye.com/login">
            <button className="addup_btn choice" type="button">
              일반회원
            </button>
          </a>
          <Link to="/domainlogin">
            <button className="addup_btn choice" type="button">
              기업회원
            </button>
          </Link>
        </div>
        <Space y={48} />
        <div className="desc flex justify-center">
          Copyright © ADD-UP Co ,. Ltd All Rights Reserved.
        </div>
      </div>
    </div>
  );
}

export default Choice;
