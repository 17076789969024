import React from 'react'
import Banner22 from '../../Common/Banner2'
import img1111 from "../../../Assets/Images/member_0003.png"
// import img1111 from "../../../Assets/Images/memberimg.png"
import Membermain from '../member_jp/membermain'
import Mreason from "../member_jp/mreason"
import Member_Map from '../member_jp/memberMap'
import MemberNotice from '../member_jp/memberNotice'
import MemberNotice2 from '../member_jp/memberNotice2'
// import FREEVersion from './FREEVersion'
//import img1 from "../../../../Assets/Images/freeimg.png";

import MemberMap from './memberMap'
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../Modules/routesString";
import * as Scroll from "react-scroll"
function Member() {
  const scroll = Scroll.animateScroll
  return (
    <main>

       
        <Banner22
        img1111={img1111}
        title={`自分だけのプライベートお問い合わせ箱 CSbye `}
        subTitle="自分のお問い合わせ状態と以前の内訳を一目で確認したい時には?"
        detail={``}

       textButton={"一般会員ログイン"}
        onPressButton={() => {{alert('準備中')}}}
          // scroll.scrollToTop()
          // navigation(RoutesString.Customer);       
        textButton2={"ポイント交換する (準備中)"}
        // textButton={"서비스 문의하기"}
        backgroundColor="#63aeff"
      />
      
      
      <Member_Map/>
      <Mreason/>
      <Membermain/>
      <MemberNotice/>
      <MemberNotice2/>
      
      
      {/* <FREEplatform/> */}
      {/* <Policy /> */}
    </main>
  )
}




//function Free() {
  //return (
    //<main>
      //  <Banner
        //img={img}
 //       title={`CSbye 플랫폼 평생무료!`}
   //     subTitle="문의량이 적은 소규모, 스타트업 기업이라면?"
     //   detail={``}
       // onPressButton={() => {alert('준비중입니다.')}}
     //   textButton={"CSbye플랫폼 시작하기"}
   //     backgroundColor="#c1e0ff"
    //  />
     // <FREEmain />
 //   </main>
//  )
//}


//const Free = () => {
  //return (
    //<div id="star" className="bg-f2f3f8">
      //무료이용중
     
    //</div>

  //);
  

//};

export default Member;
