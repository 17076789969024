export const RoutesString = {
  PageNotFound: "/404",
  Home: `/`,
  Intro: "/intro",
  Platform: "/platform",
  Price: "/price",
  Customer: "/customer",
  Privacy: "/privacy",

  authCom: "/authcom",
  choice: "/choice",
  domainLogin: "/domainlogin",
  join: "/join",
  joinEmailCheck: "/emailcheck",
  companyJoin: "/companyJoin",
  star: "/star/:qnaNo/:replyNo",
  free: "/free",
  member: "/member",

  // EN
  Home_en: `/_en`,
  Intro_en: "/intro_en",
  Platform_en: "/platform_en",
  Price_en: "/price_en",
  Customer_en: "/Customer_en",
  free_en: "/free_en",
  member_en: "/member_en",

  // JP
  Home_jp: `/_jp`,
  Intro_jp: "/intro_jp",
  Platform_jp: "/platform_jp",
  Price_jp: "/price_jp",
  Customer_jp: "/Customer_jp",
  free_jp: "/free_jp",
  member_jp: "/member_jp",

  // CN
  Home_cn: `/_cn`,
  Intro_cn: "/intro_cn",
  Platform_cn: "/platform_cn",
  Price_cn: "/price_cn",
  Customer_cn: "/Customer_cn",
  free_cn: "/free_cn",
  member_cn: "/member_cn",

  // TW
  Home_tw: `/_tw`,
  Intro_tw: "/intro_tw",
  Platform_tw: "/platform_tw",
  Price_tw: "/price_tw",
  Customer_tw: "/Customer_tw",
  free_tw: "/free_tw",
  member_tw: "/member_tw",
};
