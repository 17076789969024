import { getDomainsApi } from "Apis/apis";
import Input from "Components/Atoms/Input/input";
import Space from "Components/Atoms/Space/space";
import { ReactComponent as ModalX } from "Util/Svg/modalX.svg";
import { useState } from "react";
import "./findModal.scss";

const FindModal = ({ setModal }: { setModal: any }) => {
  const [contents, setContents] = useState("");
  const [mail, setMail] = useState("");

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (mail === "") {
      alert("내용를 입력해주세요.");
      return;
    }
    const {
      data: { domain },
    } = await getDomainsApi(mail);
    setContents(domain);
  };

  return (
    <div className="bg">
      <div
        id="find-modal"
        style={{ height: "250px", width: "600px" }}
        className="fixed p-center modal"
      >
        <div className="flex justify-between">
          <h2>웹주소 찾기</h2>
          <button onClick={() => setModal(false)}>
            <ModalX />
          </button>
        </div>
        <Space y={24} />
        <h3>
          <p>가입시 등록하신 이메일 주소를 입력하시면</p>
          <p>CSBYE도메인를 찾을 수 있습니다.</p>
        </h3>
        <Space y={24} />
        {contents !== "" ? (
          <p className="text-18 font-bold text-[#181818]">
            현재 등록하신 도메인는{" "}
            <span className="text-sky-500">{contents}</span> 입니다.
          </p>
        ) : (
          <form className="flex" onSubmit={onSubmit}>
            <Input
              value={mail}
              onChange={(e) => setMail(e.target.value)}
              id="mail"
              placeholder="이메일주소 입력"
            />
            <Space x={24} />
            <button className="addup_btn find">찾기</button>
            <Space y={20} />
          </form>
        )}
      </div>
    </div>
  );
};

export default FindModal;
