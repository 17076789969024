import React from "react";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/ic-whatis-marketing-01@3x.png";
import img2 from "../../../../Assets/Images/ic-whatis-marketing-02@3x.png";
import img3 from "../../../../Assets/Images/ic-whatis-marketing-03@3x.png";
import img4 from "../../../../Assets/Images/ic-whatis-marketing-04@3x.png";
import "./styles.scss";
function MarketingService() {
  return (
    <div className="marketingService">
      <div className="marketingService-container">
        <Title
          title={
            <>
            <span>Marketing Service</span>
            </>
          }
          detail={`Marketing using various channels became important\ndue to rapidly changing trends and social media expansion to diverse areas.\n
          We are capable of marketings with blogs, Instagram postings,\nand also one-stop marketing, from event planning to prize delivery.`}
        />

        <ul className="marketingService-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="title">{item.title}</div>
              <div className="detail">{item.detail}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default MarketingService;

const data = [
  {
    img: img1,
    title: "SNS Marketing Agency",
    detail: `Social marketing mass postings such as blogs and Facebook, exposure through high-quality influencers`,
  },
  {
    img: img2,
    title: "Event planning and management",
    detail: `Event planning and\n web page production, etc. \nthat meets the needs of \nthe client, event web page making, and event-related Customer Service.`,
  },
  {
    img: img3,
    title: "Viral Video Making",
    detail: `Creating various viral videos such as Youtube. Video production according to the client's budget. Low-cost, high-efficiency video making.`,
  },
  {
    img: img4,
    title: "Prize delivery",
    detail: `Send prizes to the winners \nof events, Noting users of \nthe event results, \nand collection of taxes \nand public charges,\n Convenient to deliver \ndue to courier contract.`,
  },
];
