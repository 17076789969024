import React from "react";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/img-whatis-qaservice-01@3x.png";
import img2 from "../../../../Assets/Images/img-whatis-qaservice-02@3x.png";
import img3 from "../../../../Assets/Images/img-whatis-qaservice-03@3x.png";
import img4 from "../../../../Assets/Images/img-whatis-qaservice-04@3x.png";
import "./styles.scss";
function QAService() {
  return (
    <div className="qaService">
      <div className="qaService-container">
        <Title
          title={
            <>
              Quality Assurance Service <span>QA Service</span>
            </>
          }
          detail={`To give stable service to users before the launching and update of online/mobile service,\nwe should minimize service defects through various QA.\n
          Professionals with long experience perform standardized tests with various devices\nand OS to provide service stability to customers and satisfaction to users.`}
        />

        <ul className="qaService-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="item-content">
                <div className="title">{item.title}</div>
                <div className="detail">{item.detail}</div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default QAService;

const data = [
  {
    img: img1,
    title: "Function Test",
    detail: `Specification review test design\ntechniques functional testing,\nTest environment construction\ntesting, and bug tracking`,
  },
  {
    img: img2,
    title: "Compatibility test",
    detail: `Tests with various devices and environments,\nSpecification (minimum/recommended)\nand base environment testing,\nDownload and execution tests\nby mobile devices OS and PC OS/IE.`,
  },
  {
    img: img3,
    title: "Maintenance test",
    detail: `Function tests for Update\nand Service issue response\nupdate history.\nLive issue test and report.`,
  },
  {
    img: img4,
    title: "Other warranty details",
    detail: `Localization tests, such as localization test\nfiltering for domestic service.\nFGT for quality-improvement balance-test\nuser's smooth usability.`,
  },
];
