import React from 'react'
import Banner from '../../Common/Banner3'
import img from "../../../Assets/Images/img-platform-banner@3x.png"
import Intro from './Intro'
import Reason from './Reason'
import Instruction from './Instruction'
import PlatformMap from './Map'
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../Modules/routesString";
import * as Scroll from "react-scroll"
function Platform_en() {
  const navigation = useNavigate();
  const scroll = Scroll.animateScroll
  return (
    <main>
        <Banner
        img={img}
        title={`CSbye, a platform \nthat gets closer and closer to Customer satisfaction.`}
        subTitle="Smooth communication with customers, achieve high service satisfaction"
        detail={`CSbye is a CRM platform that utilizes ADD-UP customer management service experiences It is web-based, easy to access, and focuses on the convenience of counselors. It can be applied to various fields and provides stable and efficient services to increase customer satisfaction.`}
        onPressButton={() => {
          scroll.scrollToTop()
          navigation(RoutesString.free_en);
        }}
        textButton={"Try Free"}
        textButtonn={"Platform Manual"}
        backgroundColor="#162a56"
      />
      <Intro />
      <Reason />
      <Instruction />
      <PlatformMap />
    </main>
  )
}

export default Platform_en