import React from "react";
import Title from "../../../Common/Title";
import img from "../../../../Assets/Images/img-platform-contact-graph@3x_jp.png";
import img_mobile from "../../../../Assets/Images/img-platform-contact-graph@3x.png";
import userIcon from "../../../../Assets/Images/e-img-platform-change-01@3x.png";
import globalIcon from "../../../../Assets/Images/e-img-platform-change-02@3x.png";
import downloadIcon from "../../../../Assets/Images/e-img-platform-change-03@3x.png";
import "./styles.scss";
function Intro() {
  return (
    <div className="platform-intro">
      <Title
        title={
          <>
            <span>CSbye</span>を通じる新たな変化
          </>
        }
        detail={`CSbyeホームは御客サービスの革新を起こす強力な道具です。\n
        データ視覚化を通じてリアルタイムで状況をモニタリングし、早い意思決定ができるようにします。\n
        当機能を通じて御客サービスの効率性と品質を向上させ、御客満足度を高めるに役立ちます。`}
      />
      <div className="platform-intro-img">
        <img src={img} alt="" />
      </div>
      <div className="platform-intro-img-mobile">
        <div className="topItems">
          <div className="topItem">
            <div className="topItem-tit">‘A’社御客</div>
            <div className="topItem-detail">
              <p>
                “電話、メール、カカオ相談などで <br />
                使用されるお問い合わせページが多様で複雑.”
              </p>
            </div>
          </div>
          <div className="topItem">
            <div className="topItem-tit">‘B’社御客</div>
            <div className="topItem-detail">
              <p>
                “翻訳が必要なのに、 <br /> 管理人力を採用するしかないかな?”
              </p>
            </div>
          </div>
          <div className="topItem">
            <div className="topItem-tit">‘C’社御客</div>
            <div className="topItem-detail">
              <p>
              前にも同じ問題があっ <br /> たけどどうしたんだっけ
              </p>
            </div>
          </div>
        </div>

        <div className="divide"></div>

        <div className="middleItem">
          <span>CSbye 使用</span>
        </div>

        <div className="divide"></div>

        <div className="bottomItems">
          <div className="bottomItem">
            <div className="icon">
              <img src={userIcon} alt="" />
            </div>
            <div className="text">
              <span>
              広まる御客接点、<br />
              相談員のストレス減少、
                <br />
                離職率減少
              </span>
            </div>
          </div>
          <div className="bottomItem">
            <div className="icon">
              <img src={globalIcon} alt="" />
            </div>
            <div className="text">
              <span>
              国際的に進出する <br /> スタート点の準備及び <br /> 外国語人力採用費用減少
              </span>
            </div>
          </div>
          <div className="bottomItem">
            <div className="icon">
              <img src={downloadIcon} alt="" />
            </div>
            <div className="text">
              <span>
              どんどん積み重なる <br /> データで突然発生する <br /> イッシュの速やかな <br /> 解決及び予防
               
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Intro;
