import React from "react";
import Button from "../../../Common/Button";
import Title2 from "../../../Common/Title2";
import img1 from "../../../../Assets/Images/ic-platform-reason-01@3x2.png";
import img2 from "../../../../Assets/Images/ic-platform-reason-02@3x2.png";
import img3 from "../../../../Assets/Images/ic-platform-reason-03@3x2.png";
import img4 from "../../../../Assets/Images/ic-platform-reason-04@3x2.png";
import img5 from "../../../../Assets/Images/ic-platform-reason-05@3x2.png";
import "./styles.scss";

function mreason() {
  return (
    <div className="reason">
      <div className="reason-container">
        <Title2
          detail="對CS的好奇心，現在確認步驟是BYE~
          在CSbye確認我所有諮詢的答复！"
          title={
            <>
              必須加入<span>CSbye</span>的理由
            </>
          }
        />
        <ul className="reason-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="title">{item.title}</div>
              <div className="detail">{item.detail}</div>
            </li>
          ))}
        </ul>
        {/* <div className="reason-container-button">
          <Button onClick={() => {alert('준비중입니다.')}} text="무료로 체험하기" rightIcon type="outline" />
        </div> */}
      </div>
    </div>
  );
}

export default mreason;

const data = [
  {
    img: img1,
    title: `使用服務諮詢確認`,
    detail: `可以在一個地方一次性確認我使用的服務的諮詢。`,
  },
  {
    img: img2,
    title: `管理答复及接收追加諮詢`,
    detail: `諮詢答復進行情況可以追加接受諮詢。`,
  },
  {
    img: img3,
    title: `服務公告及定制廣告`,
    detail: `確認活動及新遊戲上市等服務的公告事項，並推薦定制廣告。`,
  },
  {
    img: img4,
    title: `積分累積 & 交換（開發中）`,
    detail: `僅通過接受諮詢、點擊橫幅、評分評論等CSbye活動積累積分後，可兌換多種福利。`,
  },
  {
    img: img5,
    title: `通過SNS輕鬆登錄，註冊會員`,
    detail: `現在使用的SNS可以簡便地使用CSbye服務。`,
  },
  

];
