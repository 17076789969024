import React, { useEffect, useMemo } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Footer from "../Components/Common/Footer";
import FooterEN from "../Components/Common/Footer_en";
import FooterJP from "../Components/Common/Footer_jp";
import FooterCN from "../Components/Common/Footer_cn";
import FooterTW from "../Components/Common/Footer_tw";
import Header from "../Components/Common/Header/Header";
import HeaderEN from "../Components/Common/Header_en/Header";
import HeaderJP from "../Components/Common/Header_jp/Header";
import HeaderCN from "../Components/Common/Header_cn/Header";
import HeaderTW from "../Components/Common/Header_tw/Header";
import { RoutesString } from "../Components/Modules/routesString";
import pages from "./pages";
import * as Scroll from "react-scroll";
import path from "path";

const NO_LAYOUT_LIST = [
  "authcom",
  "choice",
  "domainlogin",
  "join",
  "star",
  "email",
];

const RenderHeader = (isHeader: boolean) => {
  const { pathname } = useLocation();

  if (isHeader) {
    switch (pathname) {
      case "/_en":
      case "/intro_en":
      case "/platform_en":
      case "/price_en":
      case "/Customer_en":
      case "/member_en":
      case "/free_en":
        return <HeaderEN />;
      case "/_jp":
      case "/intro_jp":
      case "/platform_jp":
      case "/price_jp":
      case "/Customer_jp":
      case "/member_jp":
      case "/free_jp":
        return <HeaderJP />;
      case "/_cn":
      case "/intro_cn":
      case "/platform_cn":
      case "/price_cn":
      case "/Customer_cn":
      case "/member_cn":
      case "/free_cn":
        return <HeaderCN />;
      case "/_tw":
      case "/intro_tw":
      case "/platform_tw":
      case "/price_tw":
      case "/Customer_tw":
      case "/member_tw":
      case "/free_tw":
        return <HeaderTW />;
      default:
        return <Header />;
    }
  }
  /*
  if (isHeader) {
    return <Header />;
  }
  */
};

const RenderFooter = (isFooter: boolean) => {
  const { pathname } = useLocation();

  if (isFooter) {
    switch (pathname) {
      case "/_en":
      case "/intro_en":
      case "/platform_en":
      case "/price_en":
      case "/Customer_en":
      case "/member_en":
      case "/free_en":
        return <FooterEN />;
      case "/_jp":
      case "/intro_jp":
      case "/platform_jp":
      case "/price_jp":
      case "/Customer_jp":
      case "/member_jp":
      case "/free_jp":
        return <FooterJP />;
      case "/_cn":
      case "/intro_cn":
      case "/platform_cn":
      case "/price_cn":
      case "/Customer_cn":
      case "/member_cn":
      case "/free_cn":
        return <FooterCN />;
      case "/_tw":
      case "/intro_tw":
      case "/platform_tw":
      case "/price_tw":
      case "/Customer_tw":
      case "/member_tw":
      case "/free_tw":
        return <FooterTW />;
      default:
        return <Footer />;
    }
  }
  /*
  if (isFooter) {
    return <Footer />;
  }
  */
};

function Router() {
  const location = useLocation();
  const pathname = location.pathname;

  let navigate = useNavigate();

  useEffect(() => {
    //HANDLE AGAIN IF ADD PAGE NEW
    const pageNotFound = pages.find((e) => e.path === pathname);

    if (!pageNotFound && !pathname.includes("star")) {
      return navigate(RoutesString.PageNotFound);
    }
  }, [pathname, navigate]);

  const isHistoryCheck = useMemo(() => {
    const url = pathname.toLowerCase();
    const isheader = NO_LAYOUT_LIST.findIndex((e) => url.includes(e)) === -1;
    if (url === RoutesString.PageNotFound || !isheader) {
      return false;
    }
    return true;
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => {
      var scroll = Scroll.animateScroll;
      scroll.scrollToTop();
    }, 100);
  }, []);

  return (
    <>
      {RenderHeader(isHistoryCheck)}
      <Routes>
        {pages.map((item) => {
          return (
            <Route
              key={item.path}
              path={item.path}
              element={item.component()}
            ></Route>
          );
        })}
      </Routes>
      {RenderFooter(isHistoryCheck)}
    </>
  );
}

export default Router;
