/* eslint-disable jsx-a11y/iframe-has-title */
import ReactPlayer from "react-player";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./styles.scss";

function HeroSection() {
  const videoURL = `https://www.add-up.co.kr/asset/video_addup.mp4`;

  return (
    <div className="hero-section">
      <div className="iframe">
        <ReactPlayer
          url={videoURL}
          playing={true}
          width={"100%"}
          height={"100%"}
          muted={true}
          loop={true}
        />

        <div className="overlay">
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
