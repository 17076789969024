import React from "react";
import Title from "../../../Common/Title";
import img from "../../../../Assets/Images/img-platform-contact-graph@3x.png";
import img_mobile from "../../../../Assets/Images/img-platform-contact-graph@3x.png";
import userIcon from "../../../../Assets/Images/e-img-platform-change-01@3x.png";
import globalIcon from "../../../../Assets/Images/e-img-platform-change-02@3x.png";
import downloadIcon from "../../../../Assets/Images/e-img-platform-change-03@3x.png";
import "./styles.scss";
function Intro() {
  return (
    <div className="platform-intro">
      <Title
        title={
          <>
            <span>CSbye</span>를 통한 새로운 변화
          </>
        }
        detail={`CSbye 플랫폼은 고객 서비스의 혁신을 이끌어내는 강력한 도구입니다. 데이터 시각화를 통해 \n
        실시간으로 상황을 모니터링하고, 빠른 의사결정을 가능케 합니다. 이를 통해 고객 서비스의 효율성과 \n
        품질을 향상시키며, 고객들의 만족도를 높이는 데 큰 도움을 줍니다.`}
      />
      <div className="platform-intro-img">
        <img src={img} alt="" />
      </div>
      <div className="platform-intro-img-mobile">
        <div className="topItems">
          <div className="topItem">
            <div className="topItem-tit">‘A’사 고객</div>
            <div className="topItem-detail">
              <p>
                “전화, 메일, 카카오 상담 등 사용되는 <br />
                문의 페이지가 다양해서 너무 복잡해.”
              </p>
            </div>
          </div>
          <div className="topItem">
            <div className="topItem-tit">‘B’사 고객</div>
            <div className="topItem-detail">
              <p>
                “번역이 필요한데, 관련 인력을 따로 <br /> 고용해야하나?”
              </p>
            </div>
          </div>
          <div className="topItem">
            <div className="topItem-tit">‘C’사 고객</div>
            <div className="topItem-detail">
              <p>
                저번에도 이런 문제가 있었는데, 어떻게 <br /> 해결했었지?
              </p>
            </div>
          </div>
        </div>

        <div className="divide"></div>

        <div className="middleItem">
          <span>CSbye 사용</span>
        </div>

        <div className="divide"></div>

        <div className="bottomItems">
          <div className="bottomItem">
            <div className="icon">
              <img src={userIcon} alt="" />
            </div>
            <div className="text">
              <span>
                넓어지는 고객 접점, <br />
                상담원의 스트레스 감소,
                <br />
                이직률 감소
              </span>
            </div>
          </div>
          <div className="bottomItem">
            <div className="icon">
              <img src={globalIcon} alt="" />
            </div>
            <div className="text">
              <span>
                국제 시장으로 진출하는 <br /> 시작점 마련 및 외국어 <br /> 인력
                채용비용 감소
              </span>
            </div>
          </div>
          <div className="bottomItem">
            <div className="icon">
              <img src={downloadIcon} alt="" />
            </div>
            <div className="text">
              <span>
                차곡차곡 쌓여가는 <br /> 데이터로 갑작스러운 이슈 <br /> 빠른
                해결 및 예방
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Intro;
