import React from "react";
import Button from "../../../Common/Button";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/ic-platform-reason-01@3x.png";
import img2 from "../../../../Assets/Images/ic-platform-reason-02@3x.png";
import img3 from "../../../../Assets/Images/ic-platform-reason-03@3x.png";
import img4 from "../../../../Assets/Images/ic-platform-reason-04@3x.png";
import img5 from "../../../../Assets/Images/ic-platform-reason-05@3x.png";
import "./styles.scss";

function Reason() {
  return (
    <div className="reason3">
      <div className="reason3-container">
        <Title
          detail="Start of efficient customer management, ADD-UP CSbye platform"
          title={
            <>
             Why you should choose <span>CSbye</span>
            </>
          }
        />
        <ul className="reason3-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="title46">{item.title}</div>
              <div className="detail45">{item.detail}</div>
            </li>
          ))}
        </ul>
        {/* <div className="reason-container-button">
          <Button onClick={() => {alert('준비중입니다.')}} text="무료로 체험하기" rightIcon type="outline" />
        </div> */}
      </div>
    </div>
  );
}

export default Reason;

const data = [
  {
    img: img1,
    title: `Customer consultation\n specialized \nweb platform`,
    detail: `With a web platform specialized in customer consultation, you can quickly communicate with customers and solve issues.\n\n`,
  },
  {
    img: img2,
    title: `Automatic \ntranslation \nfor EN/CN/JP`,
    detail: `Through automatic translation into three languages,we will reduce the cost of recruiting and become a stepping stone for entering the global market.`,
  },
  {
    img: img3,
    title: `VIP \nService\n\n`,
    detail: `Through the VIP service, you can classify and provide services to customers who need to be managed firmly.\n\n\n`,
  },
  {
    img: img4,
    title: `Create statistical \ndata for \nreal-time-issue`,
    detail: `We help you solve problems quickly by creating statistical data that can identify sudden issues at a glance.\n\n\n`,
  },
  {
    img: img5,
    title: `Easy customer management with settings customization`,
    detail: `We help you with low-cost, high-efficiency customer management by using only the services you want.\n\n\n`,
  },
];
