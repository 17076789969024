import React from "react";
import img from "../../../../Assets/Images/img-customer-banner@3x.png";
import img1 from "../../../../Assets/Images/ic-customer-phone@3x.png";
import img2 from "../../../../Assets/Images/ic-customer-time@3x.png";
import Button from "../../../Common/Button";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../../Modules/routesString";
import * as Scroll from "react-scroll";
function HeroSectionCustomer() {
  const navigation = useNavigate();
  const scroll = Scroll.animateScroll;
  return (
    <div className="heroSectionCustomer">
      <div className="section">
        <div className="section-container">
          <div className="section-container-content">
            <div className="content">
              <div className="title">
                <p>何かお困りでしょうか?</p>
              </div>
              <div className="detail">
                <p>{`直接お問い合わせするかよくある質問(FAQ)を確認して\nより速やかに答えを探してみてください。 \n CSbyeのすべてをご案内します!`}</p>
              </div>
              <a href="https://add-up.csbye.com/menu/342">
                <div className="button">
                  <Button
                    onClick={() => {}}
                    rightIcon
                    text="1:1 お問い合わせする"
                  />
                </div>
              </a>
            </div>
            <div className="img">
              <img src={img} alt="" />
            </div>
          </div>

          <div className="contact">
            <div className="contact-item">
              <div className="contact-item-img">
                <img src={img1} alt="" />
              </div>
              <div className="contact-item-content">
                <div className="subTit">体表連絡先</div>
                <div className="title">1670 - 2055</div>
              </div>
            </div>

            <div className="contact-item">
              <div className="contact-item-img">
                <img src={img2} alt="" />
              </div>
              <div className="contact-item-content">
                <div className="subTit">運営時間</div>
                <div className="content-detail">
                  <span>平日</span>09:00~18:00
                </div>
                <div className="content-detail">
                  <span>お昼休み</span>13:00~14:00
                </div>
              </div>
              <div className="contact-item-note3">*週末及び祝日は休務</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSectionCustomer;
