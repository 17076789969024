import React from "react";
import Button from "../Button";
import "./styles.scss";

interface Props {
  title: string;
  detail: string;
  subTitle: string;
  onPressButton: () => void;
  img1111: any;
  textButton: string;
  textButton2: string;
  // button: string;
  backgroundColor: string;
}
function Banner22({
  title,
  detail,
  subTitle,
  img1111,
  textButton,
  onPressButton,
  textButton2,
  // button
  backgroundColor,
}: Props) {
  return (
    <div style={{ background: backgroundColor }} className="banner2">
      <div className="section">
        <div className="section-container">
          <div className="section-container-content">
            <div className="subtit">
              <span>{subTitle}</span>
            </div>
            <div className="title">
              <p>{title}</p>
            </div>
            <div className="detail">
              <p>{detail}</p>
            </div>

            
          </div>
          
          <div className="section-img1111">
            <img src={img1111} alt="" />
          </div>
<div className="button_main">
            <div className="button">
            <a href="https://member.csbye.com/login">
              <Button
                size="normal"
                type="outline"
                // onClick={onPressButton}
                rightIcon
                text={textButton}
              />
              </a>
            </div>
            
          <div className="button2">
            {/* <a href="https://member.csbye.com/login"> */}
              <Button
                size="normal"
                type="primary"
                onClick={onPressButton}
                rightIcon
                text={textButton2}
              />
              {/* </a> */}
            </div>

</div>
         

         
          {/* <div className="memberMain">{button}
      <a href="https://www.csbye.com/price">
            <div className="mornitoringService-container-button">
                <Button onClick={() => {
                  // scroll.scrollToTop()
                  // navigation(RoutesString.Price)
                  }} rightIcon text="CSBYE 플랫폼 플랜 보러가기" />
            </div></a>
            </div> */}
        </div>
      </div>
    </div>
  );
}

export default Banner22;
