import React from "react";
import img1 from "../../../../Assets/Images/img-platform-function-01@3x.a35d6c5c54b66eac2ba3_tw.png";
import img2 from "../../../../Assets/Images/img-platform-function-02@3x.dc9d14bc9f3c166175f6_tw.png";
import img3 from "../../../../Assets/Images/img-platform-function-03@3x.bac07996fd58dbff576b_tw.png";
import img4 from "../../../../Assets/Images/img-platform-function-04@3x.88302ed3611be7d453aa_tw.png";
import "./styles.scss";
function Instruction() {
  return (
    <div className="instruction">
      {data.map((item, index) => (
        <div
          key={index}
          style={{
            background: item.backgroundColor,
          }}
          className="instruction-container"
        >
          <div
            style={{ flexDirection: index % 2 !== 0 ? "row-reverse" : "row" }}
            className="instruction-container-item"
          >
            <div className="content">
              <div className="title">{item.title}</div>
              <ul className="detail">
                <li>{item.detail1}</li>
                <li>{item.detail2}</li>
                <li>{item.detail3}</li>
              </ul>
            </div>
            <div
              style={{
                justifyContent: index % 2 !== 0 ? "flex-start" : "flex-end",
              }}
              className="img"
            >
              <img src={item.img} alt="" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Instruction;

const data = [
  {
    title: `按諮詢類型分類並創建信息輸入表格`,
    detail1: "·  大類中，通過分門別類的方式對諮詢類型進行分類",
    detail2: "·  在中類別下註冊小類別來創建輸入便簽",
    detail3: "·  利用類別快速掌握及應對熱點問題",
    img: img1,
    backgroundColor: "#fff",
  },
  {
    title: `自動輸入客戶信息`,
    detail1: "·  可與客戶數據諮詢表單自動綁定",
    detail2: "·  自動收集客戶UID及暱稱，提升客戶便利性",
    detail3:
      "·  通過設備信息路徑環境， OS, 輕鬆採集客戶信息",
    img: img2,
    backgroundColor: "#f1f8ff",
  },
  {
    title: `客戶管理`,
    detail1: "·  VIP、黑名單等客戶類型可分類",
    detail2: "·  提供VIP、通知功能、黑名單諮詢等定制服務",
    detail3: "·  通過備忘錄記錄客戶傾向特徵",
    img: img3,
    backgroundColor: "#fff",
  },
  {
    title: `諮詢員管理`,
    detail1: "·  根據職務按諮詢員級別劃分權限",
    detail2: "·  根據諮詢員的職責決定服務和票券分配",
    img: img4,
    backgroundColor: "#f1f8ff",
  },
];
