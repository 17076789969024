import React, { useEffect, useState } from "react";
import "./styles.scss";
import checkIcon from "../../../../Assets/Images/ic-price-check-01@3x.png";
import img1 from "../../../../Assets/Images/ic-price-service@3x.png";
import img2 from "../../../../Assets/Images/ic-price-page@3x.png";
import img3 from "../../../../Assets/Images/ic-price-respone@3x.png";
import img4 from "../../../../Assets/Images/ic-price-function@3x.png";
import img5 from "../../../../Assets/Images/ic-price-notification@3x.png";
import img6 from "../../../../Assets/Images/ic-price-customer@3x.png";
import img7 from "../../../../Assets/Images/ic-price-global@3x.png";
import img8 from "../../../../Assets/Images/ic-price-cooperation@3x.png";
import img9 from "../../../../Assets/Images/ic-price-security@3x.png";
import img10 from "../../../../Assets/Images/ic-price-report@3x.png";
import img11 from "../../../../Assets/Images/icon-price-support@3x.png";
function PriceTable() {
  const [isMenuFixed, setIsMenuFixed] = useState(false);
  const header = document.querySelector(".headerSection") as any;

  const stickNavbar = () => {
    const menu = document.querySelector(".priceTable-tab") as any;
    const header = document.querySelector(".headerSection") as any;

    const menuOffset = menu?.offsetTop;
    const scrollPos = window.scrollY;

    if (scrollPos >= menuOffset - header?.clientHeight) {
      setIsMenuFixed(true);
    } else {
      setIsMenuFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  return (
    <div className="priceTable">
      <div className="priceTable-container">
        <div style={{ width: "100%" }} className="priceTable-tab"></div>
        <div
          style={{ top: header?.clientHeight }}
          className={`title-table ${isMenuFixed ? "fixed-menu" : ""}`}
        >
          <div className="main-title title">プラン別機能</div>
          <div className="detail">
            <div className="detail-item free">Basic</div>
            <div className="detail-item basic">Basic+</div>
            <div className="detail-item premium">Premium</div>
            <div className="detail-item special">Special</div>
          </div>
        </div>

        <div className="list-table">
          {data.map((item, index) => (
            <div key={index} className="table-item">
              <div className="table-item-title">
                <img src={item.imgAvt} alt="" />
                <span>{item.title}</span>
              </div>
              <div className="table-item-content">
                {item.content.map((content: any, index) => (
                  <div key={content.titleContent} className="item">
                    <div className="title">{content.titleContent}</div>
                    <div className="detail">
                      {content.value.map((child: any, index: number) => (
                        <div key={`${child}+${index}`} className="detail-child">
                          {child === true ? (
                            <img src={checkIcon} alt="checkIcon" />
                          ) : child === false ? (
                            <span>未提供</span>
                          ) : (
                            child
                          )}
                        </div>
                      ))}
                    </div>
                    {content.subTitContent! && (
                      <div className="subdetail">{content.subTitContent}</div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="table-note">
          <span>* 製品決済後アカウント生成まで最大3日(運営日基準)所要</span>
        </div>
      </div>
    </div>
  );
}

export default PriceTable;

const data = [
  {
    imgAvt: img1,
    title: "サービス運営及び御客ポータル運営",
    content: [
      {
        titleContent: "サービス登録",
        value: ["1個", "1個", "3個", "6個"],
      },
      {
        titleContent: "一日お問い合わせ返答回数制限",
        value: ["60個", "無制限", "無制限", "無制限"],
      },
      {
        titleContent: "御客ポータル生成",
        value: [true, true, true, true],
      },
      {
        titleContent: "FAQ、約款/政策、重要お知らせ追加機能",
        value: [true, true, true, true],
      },
      {
        titleContent: "お問い合わせタイプ別分類",
        value: [true, true, true, true],
      },
      {
        titleContent: "チケット詳細記載項目生成",
        value: [true, true, true, true],
      },
    ],
  },
  {
    imgAvt: img2,
    title: "効率的なご返答処理",
    content: [
      {
        titleContent: "重複お問い合わせ一括処理",
        value: [true, true, true, true],
      },
      {
        titleContent: "御客情報及びお問い合わせ内訳確認",
        value: [true, true, true, true],
      },
      {
        titleContent: "御客情報収集API",
        value: [true, true, true, true],
      },
    ],
  },

  {
    imgAvt: img3,
    title: "相談便意機能",
    content: [
      {
        titleContent: "お問い合わせ受付メールデザイン",
        value: [true, true, true, true],
      },
      {
        titleContent: "お問い合わせの返答メールデザイン",
        value: [true, true, true, true],
      },
      {
        titleContent: "返答タンプレット登録機能",
        value: [true, true, true, true],
      },
      {
        titleContent: "返答プレビュー機能",
        value: [true, true, true, true],
      },
      {
        titleContent: "チケット隠し機能",
        value: [true, true, true, true],
      },
      {
        titleContent: "相談中ページ移動便意機能",
        value: [false, true, true, true],
      },
      {
        titleContent: "サービス別ラベリング機能",
        value: [false, true, true, true],
      },
      {
        titleContent: "予約返答機能",
        value: [false, false, true, true],
      },
      {
        titleContent: "臨時返答機能",
        value: [false, false, true, true],
      },
      {
        titleContent: "SMS伝送機能",
        value: [false, false, true, true],
      },
    ],
  },

  {
    imgAvt: img4,
    title: "通知サービス",
    content: [
      {
        titleContent: "VIP御客お問い合わせ通知",
        value: [false, false, true, true],
      },
      {
        titleContent: "全体お問い合わせ通知",
        value: [false, false, false, true],
      },
      {
        titleContent: "お問い合わせキーワード通知登録(サービスごと)",
        value: [false, false, "5個", " 無制限"],
      },
    ],
  },

  {
    imgAvt: img6,
    title: "御客管理",
    content: [
      {
        titleContent: "VIP登録及びお問い合わせ優先処理",
        value: [true, true, true, true],
      },
      {
        titleContent: "Blackお問い合わせ間接露出",
        value: [true, true, true, true],
      },
      {
        titleContent: "御客性向メモ",
        value: [false, false, true, true],
      },
      {
        titleContent: "返答満足度収集",
        value: [false, false, true, true],
      },
    ],
  },

  {
    imgAvt: img7,
    title: "グローバル",
    content: [
      {
        titleContent: "御客ポータル言語別運営",
        value: [false, false, "4言語", "4言語"],
      },
      {
        titleContent: "相談センター言語別運営",
        value: [false, false, "4言語", "4言語"],
      },
      {
        titleContent: "お問い合わせ及び返答翻訳機能",
        value: [false, false, "4言語", "4+@言語"],
      },
    ],
  },

  {
    imgAvt: img8,
    title: "協業",
    content: [
      {
        titleContent: "チケットメモ",
        value: [true, true, true, true],
      },
      {
        titleContent: "チケット自動割り当て",
        value: [true, true, true, true],
      },
      {
        titleContent: "チケット指定割り当て",
        value: [false, true, true, true],
      },
      {
        titleContent: "チケット自動割り当て比率調節",
        value: [false, false, false, true],
      },
      {
        titleContent: "欠員時、お問い合わせ循環配分",
        value: [false, false, false, true],
      },
    ],
  },

  {
    imgAvt: img9,
    title: "保安",
    content: [
      {
        titleContent: "同一アカウント同時作業防止",
        value: [false, true, true, true],
      },
      {
        titleContent: "相談員担当サービス設定",
        value: [false, true, true, true],
      },
      {
        titleContent: "チーム員活動記録確認",
        value: [false, false, true, true],
      },
      {
        titleContent: "相談員等級別権限設定",
        value: [false, false, false, true],
      },
    ],
  },
  {
    imgAvt: img10,
    title: "レポート",
    content: [
      {
        titleContent: "サービス別お問い合わせ処理現況レポート",
        value: [true, true, true, true],
      },
      {
        titleContent: "相談員別活動レポート",
        value: [false, false, true, true],
      },
      {
        titleContent: "レポートデータダウンロード",
        value: [false, false, false, true],
      },
    ],
  },
  {
    imgAvt: img5,
    title: "マーケティング",
    content: [
      {
        titleContent: "メール広告バナー設定",
        value: [false, false, "1個", "3個"],
      },
      {
        titleContent: "個人会員広告バナー登録",
        value: [false, false, true, true],
      },
    ],
  },
  {
    imgAvt: img11,
    title: "利用支援",
    content: [
      {
        titleContent: "メール連動支援",
        value: [true, true, true, true],
      },
      {
        titleContent: "カスタマイズ商品お問い合わせ",
        value: [false, "お問い合わせ後進行", "お問い合わせ後進行", "お問い合わせ後進行"],
      },
      {
        titleContent: "運営人力サービスお問い合わせ",
        value: [false, "お問い合わせ後進行", "お問い合わせ後進行", "お問い合わせ後進行"],
      },
    ],
  },
];
