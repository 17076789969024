import Button from "Components/Atoms/Button/button";
import Space from "Components/Atoms/Space/space";
import { Link } from "react-router-dom";

const AuthComplete = () => {
  return (
    <div
      style={{ height: "100vh", backgroundColor: "#f2f3f8" }}
      className="px-16 flex justify-center item-center"
    >
      <Space y={16} />
      <img src="/imgs/authcom.png" alt="" />
    </div>
  );
};

export default AuthComplete;
