import React from "react";
import Title from "../../../Common/Title";
import imgM from "../../../../Assets/Images/ic-price-basic.png";
import img1 from "../../../../Assets/Images/60_60 01.png";
import img2 from "../../../../Assets/Images/60_60 02.png";
import img3 from "../../../../Assets/Images/60_60 03.png";
import img4 from "../../../../Assets/Images/60_60 04.png";
import img5 from "../../../../Assets/Images/60_60 05.png";
import img6 from "../../../../Assets/Images/60_60 06.png";
import img7 from "../../../../Assets/Images/60_60 07.png";
import "./styles.scss";
import Button from "../../../Common/Button";
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../../Modules/routesString";
import * as Scroll from "react-scroll"


function FREEservice() {
  const navigation = useNavigate()
  const scroll = Scroll.animateScroll
  return (
    <div className="FREEservice">
      <div className="FREEservice-container">
        <Title
          title={
            <>
              <span style={{ color: "#fff" }}>CSbye 平台 Basic</span>
            </>
          }
          detail={'对于初创企业来说，免费版的CRM平台！'}
          
        />
         <div className="FREEVversion">
            <div className="FREEVversion22">
<ul className="basic_M">
<li className="imgMM">
<img src={imgM} alt="" />
</li>
<li>
<ul className="basic__M">
<li className="BASIC"><span style={{ color: "#fff" }}>Basic</span> </li>
<li className="BASIC22">- 提供基本功能 -</li>
<li className="BASIC33">服务注册1个</li>
<li className="BASIC33">注册咨询员（管理者）1名</li>
<li className="BASIC33">每天最多回复60条</li>


</ul>
</li>

</ul>

</div>
        </div>


        <ul className="FREEservice-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="title">{item.title}</div>
              <hr className="linee"></hr>
              <div className="detail">{item.detail}</div>
            </li>
          ))}
        </ul>
        <a href="https://www.csbye.com/domainlogin">
        <div className="mornitoringService-container-button">
                <Button onClick={() => {
                  }} rightIcon text="免费启动 CSbye 平台" />
            </div> </a>



      </div>
    </div>
  );
}

export default FREEservice;

const data = [
  {
    img: img1,
    title: "服务客户门户网站运营",
    detail: `· 创建客户门户网站 \n · 插入FAQ、条款/政策、主要公告事项 \n · 按咨询类型分类 \n · 创建票券详细记录项目`,
  },
  {
    img: img2,
    title: "回复便利功能",
    detail: `· 批量处理重复咨询 \n · 确认客户信息及询价详情 \n · 客户信息采集API`,
  },
  {
    img: img3,
    title: "咨询便利功能",
    detail: `· 接受咨询/回复邮件设计 \n · 注册答复模板 \n · 预览答复并隐藏票券`,
  },
  {
    img: img4,
    title: "客户管理",
    detail: `· VIP注册并优先处理咨询 \n · 间接曝光Black咨询 `,
  },
  {
    img: img5,
    title: "合作",
    detail: `· 票券备忘录 \n · 自动分配票券`,
  },
  {
    img: img6,
    title: "报告",
    detail: `· 各服务咨询处理现状报告`,
  },
  {
    img: img7,
    title: "支持使用",
    detail: `· 支持电子邮件联动`,
  },
];
