import React from "react";
import "./styles.scss";
interface Props {
    title: JSX.Element,
    detail?: string
    textWhite?: boolean
}
function Title({title,detail,textWhite = false}:Props) {
  return (
    <div className="titleComponent">
      <div style={{color: textWhite ? "#fff": undefined}} className="title">
      {title}
      </div>
      <div className="detail">
        <p style={{color: textWhite ? "#fff": undefined}}> 
        {detail}
        </p>
      </div>
    </div>
  );
}

export default Title;
