import { useState } from "react";
import PaginatedItems from "../../../Common/Pagination";
import AccordionData from "./AccordionData";
import "./styles.scss";
import { tab } from "@testing-library/user-event/dist/tab";

function Question() {
  const [tabActive, setTabActive] = useState(0);

  return (
    <div className="question">
      <div className="question-title">常见问题</div>
      <div className="question-tabs">
        {tabs.map((item, index) => (
          <div
            onClick={() => {
               setTabActive(index);
            }}
            key={index}
            className={`tab ${tabActive === index ? "activeTab" : ""}`}
          >
            {item.tit}
          </div>
        ))}
      </div>
      

      <div className="question-accordion">
        
        {accordionData.map(({ type,title, content }, index) => (
          tabActive === 0 ? <AccordionData key={index} title={title} content={content} />
          :
          tabActive === type && <AccordionData key={index} title={title} content={content} />
        ))}
      </div>


      {/* <div className="question-pagination">
        <PaginatedItems />
      </div> */}
    </div>
  );
}

export default Question;

const tabs = [
  {
    id : 0,
    tit: "全部",
    router: "",
  },
  {
    id : 1,
    tit: "运营管理服务",
    router: "",
  },
  {
    id : 2,
    tit: "平台服务",
    router: "",
  },
  {
    id : 3,
    tit: "结算及退款",
    router: "",
  }
];

const accordionData = [
  //자주찾는 질문
  {
    type : 1,
    title: "Q. 什么是CSbye?",
    content: `A. ‘CSbye’现有ADD-UP的服务CS, QA, 营销和监控服务的的综合名称, 在顾客运营管理(CS)上附加意味着离别的"BYE", \n以便帮助您免除客户管理的后顾之忧，是ADD-UP服务的新名称。`,
  },
  {
    type : 1,
    title: "营销服务中只使用必要的服务也可以吗？",
    content: `CSbye服务只能使用企业需要的服务。欲了解更多详情，通过咨询联系我们。`,
  },
  {
    type : 1,
    title: "Q. 比起CSbye服务，能只使用平台吗？",
    content: `A. 利用CSbye平台为想要自行管理顾客的企业提供CSbye平台服务，您可以在上端的询价栏中找到有关使用平台的信息。`,
  },
  {
    type : 1,
    title: "Q. 我想体验CSbye平台！",
    content: `A. 您可以通过CSbye主页上端右侧的“免费试用”进行页面免费试用。
    免费体验构成是PREMIUM商品, 包含30天的使用期，以确保企业管理者的充分审查和顺利使用。`,
  },
  {
    type : 1,
    title: "Q. 在哪里咨询CSbye平台？",
    content: `A. 请通过客户咨询页面顶部的"联系我们"发送内容。 确认后进行回复！`,
  },
  {
    type : 1,
    title: "Q. CSbye平台是否可以提供咨询员支持？",
    content: `A. CSbye平台仅支持月费平台，因此很难支持提供咨询员。但是，如果您在CSbye服务中联系CS管理，我们将提供相关信息。`,
  },
  //플랫폼 서비스
  {
    type : 2,
    title: "Q. 怎样注册呢?",
    content: `A. 可通过CSbye主页上端登录页面注册会员。`,
  },
  {
    type : 2,
    title: "Q, 企业管理者的账户可以转让吗？",
    content: `A. 平台内的个人信息修改可以授权。
    委托给注册的咨询员中的一人, 原有企业管理者变更为咨询员。`,
  },
  {
    type : 2,
    title: "Q. 当咨询员休假的时候，咨询自动分配是什么情况？",
    content: `A. 此功能仅针对特殊产品可用，咨询员在个人信息修改中直接设定休假或企业管理者在咨询员管理中向相关咨询员设置休假，\n则不包括咨询的自动分配。`,
  },
  {
    type : 2,
    title: "Q. 哪里可以查看被列入黑名单的原因？",
    content: `A. 可以在客户名单管理菜单中确认客​​户信息。
    请选择黑名单查询客户。原因会自动输入到客户备忘录历史记录中。.
    `,
  },
  {
    type : 2,
    title: "Q. 如何使用通知服务和SMS传输?",
    content: `A. 你可以通过Telegram收到咨询通知。
    请在通知服务设置中输入Telegram代币和Chat ID。
    SMS可以利用ALIGO发送。并输入您的ALIGO API 密钥。`,
  },
  //결제 및 환불
  {
    type : 3,
    title: "Q. 什么是坐席？",
    content: `A. 坐席是可以使用的咨询员数。
    只有根据咨询员人数提前购买使用坐席数,才能进行咨询处理。
    可以自由注册、删除咨询员, 但只有批准状态的咨询员才能使用功能。只有处于批准状态的咨询员才能占用坐席。`,
  },
  {
    type : 3,
    title: "Q. 我能在哪里结算?",
    content: `A. 注册CSbye服务会员后，可在服务内结算。
    请参考价格介绍页面并使用适合您企业的计划。`,
  },
  {
    type : 3,
    title: "Q. 如何改变商品或坐席数量?",
    content: `A. 平台内信息>结算现状>商品变更中可以结算。
    改变商品或改变坐席时，取消现有商品后重新结算已改变的商品。`,
  },
  {
    type : 3,
    title: "Q. 如果付款逾期怎么办？",
    content: `A. 付款日未正常付款的，视为逾期。

    •逾期后：不再受理该客户的票券。
    请在登录后出现的计划选择中进行结算。
     
   •逾期7天：账户将被关闭，将无法继续登录。
    咨询CSbye的话，关闭的账号会临时打开7天。
    之后，登录并在指定的时间内在信息>结算现状中继续进行计划结算。
     
  •1年以上: 判断为闲置的账户，发送通知邮件后删除该账户。`,
  },
  {
    type : 3,
    title: "Q. 如何取消产品订阅？",
    content: `A. 您可以在信息 > 结算现状中取消产品自动付款。该服务可以使用到最近商品结算期后的一个月内。`,
  },
  {
    type : 3,
    title: "Q. 想知道退款规定。",
    content: `A. 为您介绍退款规定。
    1. 结算服务使用费后，使用服务前，若因消费企业单纯改变主意或其他原因取消服务使用，该金额将按照使用期限结算后进行退款处理。
    2. 用户在使用服务后因不得已的原因想要中断服务使用时，将相关意向传达给供应企业，供应企业立即停止提供服务并退还剩余服务期限的服务费。
    3. 如果在付款期限过后取消（退款）先前付费的服务产品，取消（退款）金额将包含在退款中。
    
    取消订单时退款金额的时间
    · 信用卡: 自回收完成之日起3个工作日内取消信用卡批准
    · 储蓄卡 : 自回收完成之日起3个工作日内汇入已取款的银行卡账户
    · 虚拟账户 : 自回收完成之日起3个工作日内汇入退款账户
    · 其他: 自商品回收完成之日起3个工作日内退款`
  }
];
