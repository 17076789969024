import React from "react";
import Title from "../../../Common/Title";
import imgM from "../../../../Assets/Images/ic-price-basic.png";
import img1 from "../../../../Assets/Images/60_60 01.png";
import img2 from "../../../../Assets/Images/60_60 02.png";
import img3 from "../../../../Assets/Images/60_60 03.png";
import img4 from "../../../../Assets/Images/60_60 04.png";
import img5 from "../../../../Assets/Images/60_60 05.png";
import img6 from "../../../../Assets/Images/60_60 06.png";
import img7 from "../../../../Assets/Images/60_60 07.png";
import "./styles.scss";
import Button from "../../../Common/Button";
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../../Modules/routesString";
import * as Scroll from "react-scroll"


function FREEservice() {
  const navigation = useNavigate()
  const scroll = Scroll.animateScroll
  return (
    <div className="FREEservice">
      <div className="FREEservice-container">
        <Title
          title={
            <>
              <span style={{ color: "#fff" }}> CSbye 平台 Basic</span>
            </>
          }
          detail={'對於初創企業來說，免費版的CRM平台！'}
          
        />
         <div className="FREEVversion">
            <div className="FREEVversion22">
<ul className="basic_M">
<li className="imgMM">
<img src={imgM} alt="" />
</li>
<li>
<ul className="basic__M">
<li className="BASIC"><span style={{ color: "#fff" }}>Basic</span> </li>
<li className="BASIC22">- 提供基本功能 -</li>
<li className="BASIC33">服務註冊1個</li>
<li className="BASIC33">註冊諮詢員（管理者）1名</li>
<li className="BASIC33">每天最多回复60條</li>


</ul>
</li>

</ul>

</div>
        </div>


        <ul className="FREEservice-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="title">{item.title}</div>
              <hr className="linee"></hr>
              <div className="detail">{item.detail}</div>
            </li>
          ))}
        </ul>
        <a href="https://www.csbye.com/domainlogin">
        <div className="mornitoringService-container-button">
                <Button onClick={() => {
                  }} rightIcon text="免費啟動 CSBYE 平台" />
            </div> </a>



      </div>
    </div>
  );
}

export default FREEservice;

const data = [
  {
    img: img1,
    title: "服務客戶門戶網站運營",
    detail: `· 創建客戶門戶網站 \n · 插入FAQ、條款/政策、主要公告事項 \n · 按諮詢類型分類 \n · 創建票券詳細記錄項目`,
  },
  {
    img: img2,
    title: "回复便利功能",
    detail: `· 批量處理重複諮詢 \n · 確認客戶信息及詢價詳情 \n · 客戶信息採集API`,
  },
  {
    img: img3,
    title: "諮詢便利功能",
    detail: `· 接受諮詢/回复郵件設計 \n · 註冊答复模板 \n · 預覽答复並隱藏票券`,
  },
  {
    img: img4,
    title: "客戶管理",
    detail: `· VIP註冊並優先處理諮詢 \n · 間接曝光Black諮詢 `,
  },
  {
    img: img5,
    title: "合作",
    detail: `· 票券備忘錄 \n · 自動分配票券`,
  },
  {
    img: img6,
    title: "報告",
    detail: `· 各服務諮詢處理現狀報告`,
  },
  {
    img: img7,
    title: "支持使用",
    detail: `· 支持電子郵件聯動`,
  },
];
