import React from "react";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/ic-whatis-mornitoring-01@3x.png";
import img2 from "../../../../Assets/Images/ic-whatis-mornitoring-02@3x.png";
import img3 from "../../../../Assets/Images/ic-whatis-mornitoring-03@3x.png";
import img4 from "../../../../Assets/Images/ic-whatis-mornitoring-04@3x.png";
import "./styles.scss";
import Button from "../../../Common/Button";
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../../Modules/routesString";
import * as Scroll from "react-scroll"

function MornitoringService() {
  const navigation = useNavigate()
  const scroll = Scroll.animateScroll
  return (
    <div className="mornitoringService">
      <div className="mornitoringService-container">
        <Title
          title={
            <>
              モニタリングサービス  <span>Mornitoring Service</span>
            </>
          }
          detail={`何時発生されるか分からない不具合現象の対応と、ユーザーのリアルタイムの動向把握ができるように\n
          専門モニタリング要員を通じて24時間365日管理されます。\n 
          御客社のサービスに異常症状が発生しないように我々ADD-UPから毎時間密着モニタリングを進行します`}
        />

        <ul className="mornitoringService-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="content">
                <div className="title">{item.title}</div>
                <div className="detail">{item.detail}</div>
              </div>
            </li>
          ))}
        </ul>

            {/* <div className="mornitoringService-container-button">
                <Button onClick={() => {
                  scroll.scrollToTop()
                  navigation(RoutesString.Customer)
                  }} rightIcon text="서비스 문의하기" />
            </div> */}

      </div>
    </div>
  );
}

export default MornitoringService;

const data = [
  {
    img: img1,
    title: "24時間リアルタイム対応",
    detail: `常時的にモニタリング状態お伝え、\nカフェ及びホームページモニタリング、\nインゲーム接続モニタリング`,
  },
  {
    img: img2,
    title: "マーケットレビュー対応",
    detail: `Google · App Store · One Storeなどレビュー確認及び応対可能、各種のイベント管理可能`,
  },
  {
    img: img3,
    title: "不具合伝播",
    detail: `不具合発生時、迅速に伝播進行、\n有線電話、メールなどを通じて伝播、\n要請によって戦処置(お知らせ)対応`,
  },
  {
    img: img4,
    title: "レポート提供",
    detail: `状態別レポート提供、毎時間別モニタリングレポートお伝え、重要ご意見集合及び共有`,
  },
];
