import React from "react";
import Button from "../../../Common/Button";
import Title2 from "../../../Common/Title2";
import img1 from "../../../../Assets/Images/ic-platform-reason-01@3x2.png";
import img2 from "../../../../Assets/Images/ic-platform-reason-02@3x2.png";
import img3 from "../../../../Assets/Images/ic-platform-reason-03@3x2.png";
import img4 from "../../../../Assets/Images/ic-platform-reason-04@3x2.png";
import img5 from "../../../../Assets/Images/ic-platform-reason-05@3x2.png";
import "./styles.scss";

function mreason() {
  return (
    <div className="reason">
      <div className="reason-container">
        <Title2
          detail="CSに関する気になるところ、確認段階はもうBYE~
          お問い合わせとご返答のすべての確認はCSbyeで!"
          title={
            <>
              <span>CSbye</span>に加入する理由
            </>
          }
        />
        <ul className="reason-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="title">{item.title}</div>
              <div className="detail">{item.detail}</div>
            </li>
          ))}
        </ul>
        {/* <div className="reason-container-button">
          <Button onClick={() => {alert('준비중입니다.')}} text="무료로 체험하기" rightIcon type="outline" />
        </div> */}
      </div>
    </div>
  );
}

export default mreason;

const data = [
  {
    img: img1,
    title: `利用サービスお問い合わせ確認`,
    detail: `自分が使用しているサービスのお問い合わせを一所で一目で確認できます。`,
  },
  {
    img: img2,
    title: `ご返答管理及び追加お問い合わせを送る`,
    detail: `お問い合わせの進行状況で追加のお問い合わせの受付ができます。`,
  },
  {
    img: img3,
    title: `サービスお知らせ及びオーダーメイド広告`,
    detail: `イベント及び新規ゲームよリリースなど、サービスのお知らせの確認及びオーダーメイドをお勧めします。`,
  },
  {
    img: img4,
    title: `ポイント積み立て & 交換 (開発中)`,
    detail: `お問い合わせ受付、バナークリック、レビューなど、CSbye活動だけでポイントを積立後、様々な恵沢で交換できます。`,
  },
  {
    img: img5,
    title: `SNSを通じて簡単にログイン、会員加入`,
    detail: `現在ご利用されているSNSで簡単にCSbyeサービスを利用できます。`,
  },
  

];
