import React from "react";
import logo from "../../../Assets/Images/ic-logo-bk@3x.png";
import img1 from "../../../Assets/Images/btm-icon-01@3x.png";
import img2 from "../../../Assets/Images/btm-icon-02@3x.png";
import coopImg1 from "../../../Assets/Images/site-01@3x.png";
import coopImg2 from "../../../Assets/Images/site-02@3x.png";
import "./styles.scss";
import { Link } from "react-router-dom";
import { RoutesString } from "../../Modules/routesString";
import * as Scroll from "react-scroll";

function Footer() {
  let scroll = Scroll.animateScroll;
  return (
    <footer>
      <div className="leftItem">
        <a href="/" className="logo">
          <img src={logo} alt="" />
        </a>

        <div className="detail">
          <div className="detail-contact">
            <div className="addup">
              <div className="contact-item2">株式会社ADD-UP</div>
            </div>
            <div className="phone">
              <div className="contact-item">代表理事 : KANG HAN</div>
              <div className="contact-item itemRight">
                作業者登録番号 : 571-88-01208
              </div>
            </div>
            <div className="phone">
              <div className="contact-item">
                通信販売番号 : 第2020-光州東区-0216号
              </div>
            </div>
            <div className="address">
              <div className="contact-item">
                住所 : 光州広域市東区錦南路245(全日ビル), 7階 701号
              </div>
            </div>

            <div className="contact">
              <div className="contact-item">代表電話 : 1670-2055</div>
              <div className="contact-item itemRight">FAX : 070-7610-7260</div>
            </div>
            <div className="contact">
              <div className="contact-item item">
                メール : contact@add-up.co.kr
              </div>
            </div>

            <div className="mail">
              <div className="contact-item">個人情報責任者 : PARK JIHYE</div>
              <div className="contact-item itemRight">
                連絡先 : contact@add-up.co.kr
              </div>
            </div>
          </div>
        </div>

        <div className="private">
          <Link
            onClick={() => {
              scroll.scrollToTop();
            }}
            to={RoutesString.Privacy}>
            個人情報処理方針
          </Link>
        </div>

        <p className="copyright">Copyright © ADD-UP All rights reserved.</p>
      </div>

      <div className="rightItem">
        <div className="cooperate">
          <p>協力サイト</p>
          <a href="https://www.add-up.co.kr">
            <div className="cooperate-img">
              <img src={coopImg1} alt="" />
            </div>
          </a>
          <a href="https://www.add-fit.com/">
            <div className="cooperate-img">
              <img src={coopImg2} alt="" />
            </div>
          </a>
        </div>

        <div className="items">
          <a href="https://www.add-up.co.kr/asset/COMPANY_PROFILE_ADD-UP(0503).pdf">
            <div className="item">
              <div className="item-img">
                <img src={img1} alt="" />
              </div>
              <div className="item-detail">
                会社紹介書 <br />
                ダウンロード
              </div>
            </div>
          </a>

          <a href="https://www.add-up.co.kr/theme/addup/ebook/add-up_ebook.html">
            <div className="item">
              <div className="item-img">
                <img src={img2} alt="" />
              </div>
              <div className="item-detail">
                e-book <br />
                で見る
              </div>
            </div>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
