import React from 'react'
import Title from '../../../Common/Title'
import bg from "../../../../Assets/Images/map-platform.png"
import img from "../../../../Assets/Images/_e-img-platform-stats.448717bff4353eedd785_tw.png"
import item1 from "../../../../Assets/Images/ic-platform-stats-01@3x.png"
import item2 from "../../../../Assets/Images/ic-platform-stats-02@3x.png"
import item3 from "../../../../Assets/Images/ic-platform-stats-03@3x.png"
import "./styles.scss"

function PlatformMap() {
  return (
    <div className='platformMap'>
        
        <div className="platformMap-bg">
            <img src={bg} alt="" />
        </div>

        <div className="platformMap-container">
        <Title title={<>{`統計功能`}</>} />
        <div className="map">
            <img src={img} alt="" />
        </div>
        <div className="map-list">
          {dataMap.map((item,index) => (
            <div className="item" key={index}>
              <div className="img">
                <img src={item.img} alt="" />
              </div>
              <div className="content2">
                <div className="title">{item.title}</div>
                <div className="detail">{item.detail}</div>
              </div>
            </div>
          ))}
        </div>
        </div>
    </div>
  )
}

export default PlatformMap

const dataMap = [
    {
      img: item1,
      title: '統計數據一目了然',
      detail: '用圖表確認全部統計'
    },
    {
      img: item2,
      title: 'Excel文件單獨收藏',
      detail: '下載統計數據至Excel'
    },
    {
      img: item3,
      title: '提供多種統計數據',
      detail: '按工作/類別/諮詢員/評分/評論分類等'
    },
]