import React from "react";
import Title from "../../../Common/Title";
import img from "../../../../Assets/Images/img-platform-contact-graph@3x_cn.png";
import img_mobile from "../../../../Assets/Images/img-platform-contact-graph@3x.png";
import userIcon from "../../../../Assets/Images/e-img-platform-change-01@3x.png";
import globalIcon from "../../../../Assets/Images/e-img-platform-change-02@3x.png";
import downloadIcon from "../../../../Assets/Images/e-img-platform-change-03@3x.png";
import "./styles.scss";
function Intro() {
  return (
    <div className="platform-intro">
      <Title
        title={
          <>
            <span>CSbye</span>带来的新变化
          </>
        }
        detail={`CSbye平台是引领客户服务创新的有力工具。

        通过数据可视化实时监控情况并快速做出决策。
        
        通过这些可以提高顾客服务的效率和质量，对提高顾客满意度有很大的帮助。`}
      />
      <div className="platform-intro-img">
        <img src={img} alt="" />
      </div>
      <div className="platform-intro-img-mobile">
        <div className="topItems">
          <div className="topItem">
            <div className="topItem-tit">‘A’公司客户</div>
            <div className="topItem-detail">
              <p>
                “使用电话、邮件、Kakao咨询等的 <br />
                咨询页面多种多样，太复杂了”
              </p>
            </div>
          </div>
          <div className="topItem">
            <div className="topItem-tit">‘B’公司客户</div>
            <div className="topItem-detail">
              <p>
                “我需要翻译，需要另外聘请人吗？”
              </p>
            </div>
          </div>
          <div className="topItem">
            <div className="topItem-tit">‘C’公司客户</div>
            <div className="topItem-detail">
              <p>
              我以前也遇到过这个问题，你是怎么解决的？
              </p>
            </div>
          </div>
        </div>

        <div className="divide"></div>

        <div className="middleItem">
          <span>使用CSbye</span>
        </div>

        <div className="divide"></div>

        <div className="bottomItems">
          <div className="bottomItem">
            <div className="icon">
              <img src={userIcon} alt="" />
            </div>
            <div className="text">
              <span>
              扩大客户接触点， <br />
              减少咨询员的压力，
                <br />
                减少离职率
              </span>
            </div>
          </div>
          <div className="bottomItem">
            <div className="icon">
              <img src={globalIcon} alt="" />
            </div>
            <div className="text">
              <span>
              提供进入国际市场 <br /> 的起点，降低聘用外语人 <br /> 员的成本 
              </span>
            </div>
          </div>
          <div className="bottomItem">
            <div className="icon">
              <img src={downloadIcon} alt="" />
            </div>
            <div className="text">
              <span>
              用层层积累的 <br /> 数据快速解决及预 <br /> 防突如其来的问题
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Intro;
