import React from "react";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/img-price-banner@3x.png";
import img2 from "../../../../Assets/Images/ic-whatis-mornitoring-02@3x.png";
import img3 from "../../../../Assets/Images/ic-whatis-mornitoring-03@3x.png";
import img4 from "../../../../Assets/Images/ic-whatis-mornitoring-04@3x.png";
import "./styles.scss";
import Button from "../../../Common/Button";
import { useNavigate } from "react-router-dom";
import { RoutesString } from "../../../Modules/routesString";
import * as Scroll from "react-scroll"

function FREEplatform() {
  const navigation = useNavigate()
  const scroll = Scroll.animateScroll
  return (
    <div className="FREEplatform">
      <div className="FREEplatform-container">
        <Title
          title={
            <>
              <span style={{ color: "#fff" }}>ADD-UP CSbye platform Plans</span>
            </>
          }
          detail={`If you need professional customer service as a growing company?`}
        />
              <img src={img1} alt="" />
    
            <div className="mornitoringService-container-button">
                <Button onClick={() => {
                  scroll.scrollToTop()
                  navigation(RoutesString.Price_en)
                  }} rightIcon text="Check out the CSbye platform plans" />
            </div>

      </div>
    </div>
  );
}

export default FREEplatform;

