import React from "react";
import Button from "../../../Common/Button";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/ic-platform-reason-01@3x.png";
import img2 from "../../../../Assets/Images/ic-platform-reason-02@3x.png";
import img3 from "../../../../Assets/Images/ic-platform-reason-03@3x.png";
import img4 from "../../../../Assets/Images/ic-platform-reason-04@3x.png";
import img5 from "../../../../Assets/Images/ic-platform-reason-05@3x.png";
import "./styles.scss";

function Reason() {
  return (
    <div className="reason3">
      <div className="reason3-container">
        <Title
          detail="高效客户管理的开始，ADD-UP CSBYE平台"
          title={
            <>
              选择<span>CSBYE平台</span>的理由
            </>
          }
        />
        <ul className="reason3-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="title5">{item.title}</div>
              <div className="detail5">{item.detail}</div>
            </li>
          ))}
        </ul>
        {/* <div className="reason-container-button">
          <Button onClick={() => {alert('준비중입니다.')}} text="무료로 체험하기" rightIcon type="outline" />
        </div> */}
      </div>
    </div>
  );
}

export default Reason;

const data = [
  {
    img: img1,
    title: `组成顾客咨询 \n专业Web平台`,
    detail: `通过咨询专业Web平台， \n 可以快速与客户沟 \n 通并解决问题。\n\n`,
  },
  {
    img: img2,
    title: `英文/中文/日文\n自动翻译功能`,
    detail: `通过3国语言自动翻译功能，\n 将降低人力招聘费用和成为 \n 进军国际市场的垫脚石。\n\n`,
  },
  {
    img: img3,
    title: `支持VIP \n 应对服务`,
    detail: `通过VIP应对服务， \n 可以对需要集中管理的 \n 客户进行分类提供服务。\n\n`,
  },
  {
    img: img4,
    title: `实时热点确认 \n 提取统计资料`,
    detail: `我们通过提取统计数 \n 据帮助您快速解决问题， \n这些统计数据可以一目了 \n然地识别突发问题。 `,
  },
  {
    img: img5,
    title: `以定制功能轻松 \n 管理顾客运营`,
    detail: `帮助您仅使用您想要的 \n 服务来进行低成本， \n 高效率的客户管理\n\n`,
  },
];
