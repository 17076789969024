import React from "react";
import Button from "../../../Common/Button";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/ic-platform-reason-01@3x.png";
import img2 from "../../../../Assets/Images/ic-platform-reason-02@3x.png";
import img3 from "../../../../Assets/Images/ic-platform-reason-03@3x.png";
import img4 from "../../../../Assets/Images/ic-platform-reason-04@3x.png";
import img5 from "../../../../Assets/Images/ic-platform-reason-05@3x.png";
import "./styles.scss";

function Reason() {
  return (
    <div className="reason3">
      <div className="reason3-container">
        <Title
          detail="高效客戶管理的開始，ADD-UP CSBYE平台"
          title={
            <>
              選擇<span>CSBYE平台</span>的理由
            </>
          }
        />
        <ul className="reason3-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="title5">{item.title}</div>
              <div className="detail5">{item.detail}</div>
            </li>
          ))}
        </ul>
        {/* <div className="reason-container-button">
          <Button onClick={() => {alert('준비중입니다.')}} text="무료로 체험하기" rightIcon type="outline" />
        </div> */}
      </div>
    </div>
  );
}

export default Reason;

const data = [
  {
    img: img1,
    title: `組成顧客諮詢\n專業Web平台`,
    detail: `通過諮詢專業Web平台，\n可以快速與客戶溝\n通並解決問題。\n\n`,
  },
  {
    img: img2,
    title: `英文/中文/日文\n自動翻譯功能`,
    detail: `通過3國語言自動翻譯功能，將降低人力招聘費用和成為\n進軍國際市場的墊腳石。\n\n`,
  },
  {
    img: img3,
    title: `支持VIP\n應對服務`,
    detail: `通過VIP應對服務，\n可以對需要集中管理的\n客戶進行分類提供服務。\n\n`,
  },
  {
    img: img4,
    title: `實時熱點確認 \n提取統計資料`,
    detail: `我們通過提取統計數\n據幫助您快速解決問題，\n這些統計數據可以一目了\n然地識別突發問題。`,
  },
  {
    img: img5,
    title: `以定制功能輕鬆\n管理顧客運營`,
    detail: `幫助您僅使用您想要的\n服務來進行低成本、\n高效率的客戶管理。\n\n`,
  },
];
