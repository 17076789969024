import React from "react";
import Title from "../../../Common/Title";
import img1 from "../../../../Assets/Images/ic-whatis-marketing-01@3x.png";
import img2 from "../../../../Assets/Images/ic-whatis-marketing-02@3x.png";
import img3 from "../../../../Assets/Images/ic-whatis-marketing-03@3x.png";
import img4 from "../../../../Assets/Images/ic-whatis-marketing-04@3x.png";
import "./styles.scss";
function MarketingService() {
  return (
    <div className="marketingService">
      <div className="marketingService-container">
        <Title
          title={
            <>
              마케팅 서비스 <span>Marketing Service</span>
            </>
          }
          detail={`빠르게 변하는 최근 트렌드와 다양한 영역 SNS 확대로 다양한 채널의 마케팅이 중요해진 시대입니다. \n
            바이럴, 블로그 포스팅, 인스 타그램 등 각종 SNS 기반 마케팅 대행 업무를 진행하고, \n 
            이벤트 기획 및 처리, 경품 발송까지 원스탑으로 마케팅이 가능합니다.`}
        />

        <ul className="marketingService-container-list">
          {data.map((item, index) => (
            <li key={index} className="item">
              <img src={item.img} alt="" />
              <div className="title">{item.title}</div>
              <div className="detail">{item.detail}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default MarketingService;

const data = [
  {
    img: img1,
    title: "SNS 마케팅 대행",
    detail: `블로그 및 페이스북 등 \n 소셜 마케팅 대량 포스팅 진행 \n 고품질 인플루언서를 통한 노출`,
  },
  {
    img: img2,
    title: "이벤트 기획 및 관리",
    detail: `이벤트 기획 및 웹 페이지 제작 \n 고객사의 니즈에 맞는 \n 이벤트 기획 및 웹 페이지 제작 \n 이벤트 관련 CS 상담 업무 처리`,
  },
  {
    img: img3,
    title: "바이럴 영상 제작",
    detail: `유튜브 등 다양한 바이럴 영상\n 고객사 예산에 맞춘 영상 제작 \n 저비용 고효율의 영상 제작`,
  },
  {
    img: img4,
    title: "경품 발송 업무",
    detail: `이벤트 결과에 따른 경품 발송\n 결과 통보 및 제세공과금 수납\n 택배사 계약, 경품 발송 용이`,
  },
];
