import React from "react";

const H3 = ({
  name,
  isPrimary = false,
}: {
  name: string;
  isPrimary?: boolean;
}) => {
  const textColor = isPrimary ? "text-primary" : "text-181818";

  return <h2 className={`text-20 bold ${textColor}`}>{name}</h2>;
};

export default H3;
