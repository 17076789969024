import React from 'react'
import Title from '../../../Common/Title'
import bg from "../../../../Assets/Images/map-platform.png"
import img from "../../../../Assets/Images/_e-img-platform-stats.png"
import item1 from "../../../../Assets/Images/ic-platform-stats-01@3x.png"
import item2 from "../../../../Assets/Images/ic-platform-stats-02@3x.png"
import item3 from "../../../../Assets/Images/ic-platform-stats-03@3x.png"
import "./styles.scss"

function PlatformMap() {
  return (
    <div className='platformMap'>
        
        <div className="platformMap-bg">
            <img src={bg} alt="" />
        </div>

        <div className="platformMap-container">
        <Title title={<>{`통계 기능`}</>} />
        <div className="map">
            <img src={img} alt="" />
        </div>
        <div className="map-list">
          {dataMap.map((item,index) => (
            <div className="item" key={index}>
              <div className="img">
                <img src={item.img} alt="" />
              </div>
              <div className="content">
                <div className="title">{item.title}</div>
                <div className="detail">{item.detail}</div>
              </div>
            </div>
          ))}
        </div>
        </div>
    </div>
  )
}

export default PlatformMap

const dataMap = [
    {
      img: item1,
      title: '한눈에 파악 가능한 통계',
      detail: '그래프로 전체 통계를 확인'
    },
    {
      img: item2,
      title: '엑셀 파일 개별 소장',
      detail: '통계 데이터를 엑셀로 다운로드'
    },
    {
      img: item3,
      title: '다양한 통계 제공',
      detail: '일/카테고리/상담원/별점/리뷰별 등'
    },
]